import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: {layout: 'login'},
    component: () => import('@/views/Login')
  },
  {
    path: '/',
    name: 'Home',
    meta: {layout: 'objects', requiresAuth: true},
    component: () => import('@/views/Home.vue')
  },
  ///-----------------------
  {
    path: '/objects',
    name: 'Object',
    meta: {layout: 'objects', requiresAuth: true},
    component: () => import('@/views/Objects')
  },
  {
    path: '/objects/apartments',
    name: 'ObjectsApartment',
    meta: {
      layout: 'objects',
      requiresAuth: true,
      type: "Об'єкти",
      class: 'apartment'
    },
    component: () => import('@/views/Objects/Apartments')
  },
  {
    path: '/objects/houses',
    name: 'ObjectsHouse',
    meta: {layout: 'objects', requiresAuth: true, type: "Об'єкти", class: 'house'},
    component: () => import('@/views/Objects/Houses')
  },
  {
    path: '/objects/commerce',
    name: 'ObjectsCommerce',
    meta: {
      layout: 'objects',
      requiresAuth: true,
      type: "Об'єкти",
      class: 'commerce'
    },
    component: () => import('@/views/Objects/Commerce')
  },
  ///-----------------------
  {
    path: '/rent',
    name: 'Rent',
    meta: {layout: 'rent', requiresAuth: true},
    component: () => import('@/views/Rent')
  },
  {
    path: '/rent/apartments',
    name: 'RentApartment',
    meta: {
      layout: 'rent',
      requiresAuth: true,
      type: "Об'єкти",
      class: 'apartment'
    },
    component: () => import('@/views/Rent/Apartments')
  },
  {
    path: '/rent/houses',
    name: 'RentHouse',
    meta: {layout: 'rent', requiresAuth: true, type: "Об'єкти", class: 'house'},
    component: () => import('@/views/Rent/Houses')
  },
  {
    path: '/rent/commerce',
    name: 'RentCommerce',
    meta: {
      layout: 'rent',
      requiresAuth: true,
      type: "Об'єкти",
      class: 'commerce'
    },
    component: () => import('@/views/Rent/Commerce')
  },
  ///-----------------------
  {
    path: '/contacts',
    name: 'Contacts',
    meta: {layout: 'contacts', requiresAuth: true},
    component: () => import('@/views/Contacts')
  },
  {
    path: '/seekers',
    name: 'Seekers',
    meta: {layout: 'seekers', requiresAuth: true},
    component: () => import('@/views/Seekers')
  },
  {
    path: '/buildings',
    name: 'Buildings',
    meta: {layout: 'buildings', requiresAuth: true},
    component: () => import('@/views/Buildings')
  },
  {
    path: '/buyers',
    name: 'Buyers',
    meta: {layout: 'buyers', requiresAuth: true},
    component: () => import('@/views/Buyers')
  },
  {
    path: '/buyers/apartments',
    name: 'buyersApt',
    meta: {layout: 'buyers', requiresAuth: true, class: 'apartment'},
    component: () => import('@/views/Buyers/Apartments')
  },
  {
    path: '/buyers/houses',
    name: 'buyersHouse',
    meta: {layout: 'buyers', requiresAuth: true, class: 'house'},
    component: () => import('@/views/Buyers/Houses')
  },
  {
    path: '/buyers/commerce',
    name: 'buyersCom',
    meta: {layout: 'buyers', requiresAuth: true, class: 'commerce'},
    component: () => import('@/views/Buyers/Commerce')
  },
  {
    path: '/archive/buyers',
    name: 'ArchiveBuyers',
    meta: {layout: 'archive', requiresAuth: true, type: 'buyers'},
    component: () => import('@/views/Archive/Buyers')
  },
  {
    path: '/archive/buyers/apartments',
    name: 'ArchiveBuyersApartments',
    meta: {
      layout: 'archive',
      requiresAuth: true,
      class: 'apartment',
      type: 'buyers'
    },
    component: () => import('@/views/Archive/Buyers/Apartments')
  },
  {
    path: '/archive/buyers/houses',
    name: 'ArchiveBuyersHouses',
    meta: {
      layout: 'archive',
      requiresAuth: true,
      class: 'house',
      type: 'buyers'
    },
    component: () => import('@/views/Archive/Buyers/Houses')
  },
  {
    path: '/archive/buyers/commerce',
    name: 'ArchiveBuyersCommerce',
    meta: {
      layout: 'archive',
      requiresAuth: true,
      class: 'commerce',
      type: 'buyers'
    },
    component: () => import('@/views/Archive/Buyers/Commerce')
  },

  {
    path: '/renters',
    name: 'Renters',
    meta: {layout: 'renters', requiresAuth: true},
    component: () => import('@/views/Renters')
  },
  {
    path: '/renters/apartments',
    name: 'rentersApt',
    meta: {layout: 'renters', requiresAuth: true, class: 'apartment'},
    component: () => import('@/views/Renters/Apartments')
  },
  {
    path: '/renters/houses',
    name: 'rentersHouse',
    meta: {layout: 'renters', requiresAuth: true, class: 'house'},
    component: () => import('@/views/Renters/Houses')
  },
  {
    path: '/renters/commerce',
    name: 'rentersCom',
    meta: {layout: 'renters', requiresAuth: true, class: 'commerce'},
    component: () => import('@/views/Renters/Commerce')
  },
  {
    path: '/archive/renters',
    name: 'ArchiveRenters',
    meta: {layout: 'archive', requiresAuth: true, type: 'renters'},
    component: () => import('@/views/Archive/Renters')
  },
  {
    path: '/archive/renters/apartments',
    name: 'ArchiveRentersApartments',
    meta: {
      layout: 'archive',
      requiresAuth: true,
      class: 'apartment',
      type: 'renters'
    },
    component: () => import('@/views/Archive/Renters/Apartments')
  },
  {
    path: '/archive/renters/houses',
    name: 'ArchiveRentersHouses',
    meta: {
      layout: 'archive',
      requiresAuth: true,
      class: 'house',
      type: 'renters'
    },
    component: () => import('@/views/Archive/Renters/Houses')
  },
  {
    path: '/archive/renters/commerce',
    name: 'ArchiveRentersCommerce',
    meta: {
      layout: 'archive',
      requiresAuth: true,
      class: 'commerce',
      type: 'renters'
    },
    component: () => import('@/views/Archive/Renters/Commerce')
  },
  {
    path: '/archive/objects',
    name: 'ArchiveObjects',
    meta: {layout: 'archive', requiresAuth: true, type: 'objects'},
    component: () => import('@/views/Archive/Objects')
  },
  {
    path: '/archive/objects/apartments',
    name: 'ArchiveObjectsApartments',
    meta: {
      layout: 'archive',
      requiresAuth: true,
      class: 'apartment',
      type: 'objects'
    },
    component: () => import('@/views/Archive/Objects/Apartments')
  },
  {
    path: '/archive/objects/houses',
    name: 'ArchiveObjectsHouses',
    meta: {
      layout: 'archive',
      requiresAuth: true,
      class: 'house',
      type: 'objects'
    },
    component: () => import('@/views/Archive/Objects/Houses')
  },
  {
    path: '/archive/objects/commerce',
    name: 'ArchiveObjectsCommerce',
    meta: {
      layout: 'archive',
      requiresAuth: true,
      class: 'commerce',
      type: 'objects'
    },
    component: () => import('@/views/Archive/Objects/Commerces')
  },
  {
    path: '/archive/rent',
    name: 'ArchiveRent',
    meta: {layout: 'archive', requiresAuth: true, type: 'rent'},
    component: () => import('@/views/Archive/Rent')
  },
  {
    path: '/archive/rent/apartments',
    name: 'ArchiveRentApartments',
    meta: {
      layout: 'archive',
      requiresAuth: true,
      class: 'apartment',
      type: 'rent'
    },
    component: () => import('@/views/Archive/Rent/Apartments')
  },
  {
    path: '/archive/rent/houses',
    name: 'ArchiveRentHouses',
    meta: {
      layout: 'archive',
      requiresAuth: true,
      class: 'house',
      type: 'rent'
    },
    component: () => import('@/views/Archive/Rent/Houses')
  },
  {
    path: '/archive/rent/commerce',
    name: 'ArchiveRentCommerce',
    meta: {
      layout: 'archive',
      requiresAuth: true,
      class: 'commerce',
      type: 'rent'
    },
    component: () => import('@/views/Archive/Rent/Commerces')
  },
  {
    path: '/favorites/objects',
    name: 'FavoritesObjects',
    meta: {layout: 'favorites', requiresAuth: true, type: 'objects'},
    component: () => import('@/views/Favorites/Objects')
  },
  {
    path: '/favorites/objects/apartments',
    name: 'FavoriteObjectsApartments',
    meta: {
      layout: 'favorites',
      requiresAuth: true,
      class: 'apartment',
      type: 'objects'
    },
    component: () => import('@/views/Favorites/Objects/Apartments')
  },
  {
    path: '/favorites/objects/houses',
    name: 'FavoriteObjectsHouses',
    meta: {
      layout: 'favorites',
      requiresAuth: true,
      class: 'house',
      type: 'objects'
    },
    component: () => import('@/views/Favorites/Objects/Houses')
  },
  {
    path: '/favorites/objects/commerce',
    name: 'FavoriteObjectsCommerce',
    meta: {
      layout: 'favorites',
      requiresAuth: true,
      class: 'commerce',
      type: 'objects'
    },
    component: () => import('@/views/Favorites/Objects/Commerces')
  },
  {
    path: '/favorites/rent',
    name: 'FavoritesRent',
    meta: {layout: 'favorites', requiresAuth: true, type: 'rent'},
    component: () => import('@/views/Favorites/Rent')
  },
  {
    path: '/favorites/rent/apartments',
    name: 'FavoriteRentApartments',
    meta: {
      layout: 'favorites',
      requiresAuth: true,
      class: 'apartment',
      type: 'rent'
    },
    component: () => import('@/views/Favorites/Rent/Apartments')
  },
  {
    path: '/favorites/rent/houses',
    name: 'FavoriteRentHouses',
    meta: {
      layout: 'favorites',
      requiresAuth: true,
      class: 'house',
      type: 'rent'
    },
    component: () => import('@/views/Favorites/Rent/Houses')
  },
  {
    path: '/favorites/rent/commerce',
    name: 'FavoriteRentCommerce',
    meta: {
      layout: 'favorites',
      requiresAuth: true,
      class: 'commerce',
      type: 'rent'
    },
    component: () => import('@/views/Favorites/Rent/Commerces')
  },
  {
    path: '/favorites/buyers',
    name: 'FavoritesBuyers',
    meta: {layout: 'favorites', requiresAuth: true, type: 'buyers'},
    component: () => import('@/views/Favorites/Buyers')
  },
  {
    path: '/favorites/buyers/apartments',
    name: 'FavoriteBuyersApartments',
    meta: {
      layout: 'favorites',
      requiresAuth: true,
      class: 'apartment',
      type: 'buyers'
    },
    component: () => import('@/views/Favorites/Buyers/Apartments')
  },
  {
    path: '/favorites/buyers/houses',
    name: 'FavoriteBuyersHouses',
    meta: {
      layout: 'favorites',
      requiresAuth: true,
      class: 'house',
      type: 'buyers'
    },
    component: () => import('@/views/Favorites/Buyers/Houses')
  },
  {
    path: '/favorites/buyers/commerce',
    name: 'FavoriteBuyersCommerce',
    meta: {
      layout: 'favorites',
      requiresAuth: true,
      class: 'commerce',
      type: 'buyers'
    },
    component: () => import('@/views/Favorites/Buyers/Commerce')
  },

  {
    path: '/favorites/renters',
    name: 'FavoritesRenters',
    meta: {layout: 'favorites', requiresAuth: true, type: 'renters'},
    component: () => import('@/views/Favorites/Renters')
  },
  {
    path: '/favorites/renters/apartments',
    name: 'FavoriteRentersApartments',
    meta: {
      layout: 'favorites',
      requiresAuth: true,
      class: 'apartment',
      type: 'renters'
    },
    component: () => import('@/views/Favorites/Renters/Apartments')
  },
  {
    path: '/favorites/renters/houses',
    name: 'FavoriteRentersHouses',
    meta: {
      layout: 'favorites',
      requiresAuth: true,
      class: 'house',
      type: 'renters'
    },
    component: () => import('@/views/Favorites/Renters/Houses')
  },
  {
    path: '/favorites/renters/commerce',
    name: 'FavoriteRentersCommerce',
    meta: {
      layout: 'favorites',
      requiresAuth: true,
      class: 'commerce',
      type: 'renters'
    },
    component: () => import('@/views/Favorites/Renters/Commerce')
  },

  {
    path: '/actions',
    name: 'Actions',
    meta: {layout: 'actions', requiresAuth: true},
    component: () => import('@/views/Actions'),
    children: [
      {
        path: 'show',
        name: 'ActionsShow',
        meta: {layout: 'actions', requiresAuth: true, type: 'show'},
        component: () => import('@/views/Actions/Action')
      },
      {
        path: 'review',
        name: 'ActionsReview',
        meta: {layout: 'actions', requiresAuth: true},
        component: () => import('@/views/Actions/Action')
      },
      {
        path: 'bout',
        name: 'ActionsBout',
        meta: {layout: 'actions', requiresAuth: true},
        component: () => import('@/views/Actions/Action')
      },
      {
        path: 'call',
        name: 'ActionsCall',
        meta: {layout: 'actions', requiresAuth: true},
        component: () => import('@/views/Actions/Action')
      }
    ]
  },
  {
    path: '/users',
    name: 'Users',
    meta: {
      layout: 'users',
      requiresAuth: true
    },
    component: () => import('@/views/Users')
  },
  {
    path: '/stats',
    redirect: '/stats/objects',
    name: 'Stats',
    meta: {
      layout: 'stats',
      requiresAuth: true
    },
    component: () => import('@/views/Stats'),
    children: [
      {
        path: 'objects',
        name: 'StatsObjects',
        meta: {layout: 'stats', requiresAuth: true},
        component: () => import('@/views/Stats/Objects')
      },
      {
        path: 'buyers',
        name: 'StatsBuyers',
        meta: {layout: 'stats', requiresAuth: true},
        component: () => import('@/views/Stats/Buyers')
      },
      {
        path: 'actions',
        name: 'StatsActions',
        meta: {layout: 'stats', requiresAuth: true},
        component: () => import('@/views/Stats/Actions')
      },
      {
        path: 'seekers',
        name: 'StatsSeekers',
        meta: {layout: 'stats', requiresAuth: true},
        component: () => import('@/views/Stats/Seekers')
      },
      {
        path: 'rent',
        name: 'StatsRent',
        meta: {layout: 'stats', requiresAuth: true},
        component: () => import('@/views/Stats/Rent')
      },
      {
        path: 'renters',
        name: 'StatsRenters',
        meta: {layout: 'stats', requiresAuth: true},
        component: () => import('@/views/Stats/Renters')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters.isLoggedIn
  const requiredAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiredAuth && !isLoggedIn) {
    next('/login')
  } else {
    next()
  }
})

export default router
