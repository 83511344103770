<template>
  <div class="contacts-layout">
    <ContactsNavbar />
    <Sidebar />
    <div class="page__wrapper">
        <router-view></router-view>
    </div>
  </div>
</template>

<script>
import ContactsNavbar from "@/views/Contacts/Navbar";
import Sidebar from "@/components/Sidebar";

export default {
  name: "ContactsLayout",
  data: () => ({

  }),
  components: {
    ContactsNavbar,
    Sidebar,
  },
  computed: {
    error() {
      return this.$store.getters.error
    }
  },
  watch: {
    error(error) {
      const data = error.response?.data ?? error
      this.$error(data?.message ?? data?.error)
    }
  },
  created() {
    window.scrollTo(0, 0)
    if (!this.$store.getters.constants) {
      this.$store.dispatch('fetchConstants')
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

@include tablet {
  .page__wrapper {
    top: 134px;
  }
}
</style>
