<template>
  <div class="info__inner">
    <div class="info__header">
      <div class="info__nav">
        <div class="nav__link" @click="block = 'main'" :class="{'nav__link--active': block === 'main'}">
          Основна
        </div>
        <div class="nav__link" @click="block = 'contacts'" :class="{'nav__link--active': block === 'contacts'}">
          Контакти
        </div>
      </div>
      <div class="info__buttons">
        <div class="info__buttons-right">
          <div
            v-if="user.role === localConstants.USER_ROLES.ADMIN || user.id === seeker.responsibleId || seeker.isResponsibleBlocked"
            class="card__button button__icon button__icon--border"
            @click.stop.prevent
          >
            <Multiselect
              class="mSelect-dropdown"
              :options="list"
              :searchable="false"
              :show-labels="false"
              :reset-after="true"
              :value="selectedMenuItem"
              placeholder=""
              open-direction="bottom"
              @select="actions"
            />
          </div>
          <div class="button__icon button--onmobile" @click="closeModal()">
            <i class="ri-close-fill"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="info__content">
      <div class="info__main" v-if="block === 'main'">
        <div class="main__header">
          <div class="main__header-bottom">
            <div class="main__id">ID {{ seeker.id }}</div>
            <div class="main__date"><i class="i-calendar"></i> {{ seeker.createdAt | date }}</div>
          </div>
        </div>
        <div class="main__content">
          <div class="main__row main__row--dark">
            <div class="main__row-left">
              <i class="i-employees"></i>
              <div class="main__text">Автор</div>
            </div>
            <div class="main__row-right">
              <div class="main__row-letter">
                <div>{{ seeker.authorName[0] }}</div>
              </div>
              <div class="main__text">{{ seeker.authorName }}</div>
            </div>
          </div>
          <div class="main__row main__row--dark">
            <div class="main__row-left">
              <i class="i-employees"></i>
              <div class="main__text">Відповідальний агент</div>
            </div>
            <div class="main__row-right">
              <div class="main__row-letter">
                <div>
                  {{ seeker.responsibleName[0] }}
                </div>
              </div>
              <div class="main__text">{{ seeker.responsibleName }}</div>
            </div>
          </div>
          <div class="main__row main__row--dark">
            <div class="main__text">Останній дзвінок</div>
            <div class="main__text">
              <i class="i-calendar"></i>
              <div v-if="seeker.lastCallAt">{{ moment(seeker.lastCallAt).format('DD.MM.YYYY') }}</div>
              <div v-else>-</div>
            </div>
          </div>
          <div class="main__row main__row--dark">
            <div class="main__text">{{ seeker.typeName }}</div>
            <div class="main__text">
              <span v-if="seeker.type === localConstants.SEEKER_TYPES.COOPERATIVE">{{ seeker.street }} {{ seeker.houseNo }}</span>
              <span v-else-if="seeker.type === localConstants.SEEKER_TYPES.ACTIVITY">{{ seeker.activity }}</span>
            </div>
          </div>
          <div class="main__row main__row--dark main__row--area">
            <div class="main__text">Про Шукача</div>
            <div class="main__text">
              <textarea id="autoresizing" disabled>{{ seeker.description }}</textarea>
            </div>
          </div>
          <div class="main__rel rel">
            <Relation v-for="(rel, i) in seeker.relations" :key="i" :relation="rel" />
          </div>
        </div>
      </div>
      <div class="info__contacts" v-if="block === 'contacts'">
        <button class="contacts__button button button--fill" @click="changeLastCall">Зберегти дзвінок</button>
        <div class="contacts__item" v-for="contact in seeker.contacts" :key="contact.id">
          <div class="contacts__header">
            <div class="contacts__letter">{{ contact.name[0] }}</div>
            <div class="contacts__name">{{ contact.name }}</div>
            <div class="contacts__role">Роль: {{ contact.roles.map(x => x.name).join(' / ') }}</div>
            <div class="contacts__date">Дата народження: {{ contact.birthday }}</div>
            <div v-if="contact.company" class="contacts__company">
              Компанія: "{{ contact.company.name }}"
              <span v-if="contact.jobPosition">({{ contact.jobPosition.name }})</span>
            </div>
          </div>
          <div class="contacts__phones" v-for="phone in contact.phones" :key="phone.id">
            <div class="contacts__phone-wrapper">
              <a :href="'tel:' + phone.phone" @click.stop><i class="ri-phone-fill"></i></a>
              <div class="contacts__phone">{{ phone.phone }}</div>
              <div class="contacts__messengers">
                <button
                  class="button__circle"
                  :class="{'button__circle--blue': phone.isOnViber}"
                  @click="sendMessage({...phone, message: 'viber'})"
                >
                  <i class="i-viber"></i>
                </button>
                <i
                  class="i-telegram button__circle"
                  :class="{
                    'button__circle--blue': phone.isOnTelegram
                  }"
                ></i>
                <i
                  class="i-whatsapp button__circle"
                  :class="{
                    'button__circle--blue': phone.isOnWhatsapp
                  }"
                ></i>
                <i
                  class="i-messanger button__circle"
                  :class="{
                    'button__circle--blue': phone.isOnFacebook
                  }"
                ></i>
              </div>
            </div>
          </div>
          <div class="contacts__rel">
            <Relation v-for="(rel, i) in contact.relations" :key="i" :relation="rel" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import localConstants from '@/utils/localConstants'
import {mapGetters} from 'vuex'
import moment from 'moment'
import Relation from '@/components/Relation'
import ModalUploadSeeker from '@/views/Seekers/Upload'

export default {
  name: 'InfoSeeker',
  props: ['seeker'],
  components: {
    Multiselect,
    Relation,
  },
  data: () => ({
    localConstants,
    moment,
    block: 'main',
    list: ['Редагувати'],
    selectedMenuItem: null,
  }),
  computed: {
    ...mapGetters({
      user: 'user',
      users: 'users/users',
      constants: 'constants',
    }),
  },
  async created() {
    if (this.user.role === localConstants.USER_ROLES.ADMIN) {
      this.list.push('Видалити')
    }
    if (this.seeker.contacts?.length > 0) {
      const relations = await this.$store.dispatch('contacts/fetchRelations', this.seeker.contacts.map(x => x.id))
      for (const contact of this.seeker.contacts) {
        contact.relations = relations.filter(x => x.id === contact.id)
      }
    }
  },
  mounted() {
    let textarea = document.querySelector('#autoresizing')
    function autoResize() {
      if (textarea) {
        textarea.style.height = 'auto'
        textarea.style.height = textarea.scrollHeight + 'px'
        textarea.disable = 'true'
      }
    }
    autoResize()
  },
  methods: {
    actions(option) {
      if (option === 'Редагувати') {
        this.$modal.display(ModalUploadSeeker, {seeker: this.seeker}, {name: 'modalUploadSeeker'})
      } else if (option === 'Видалити') {
        this.$store.dispatch('seekers/delete', this.seeker.id)
        this.closeModal()
      }
    },
    async changeLastCall() {
      try {
        await this.$store.dispatch(`seekers/toggleLastCall`, this.seeker.id)
        this.seeker.lastCallAt = new Date()
        this.closeModal()
      } catch (err) {
        this.$store.commit('setError', err)
      }
    },
    sendMessage(phone) {
      const number = phone.phone.slice(1, 4) + phone.phone.slice(6, 9) + phone.phone.slice(10, 14)
      switch (phone.message) {
        case 'viber':
          if (phone.isOnViber) document.location.href = `viber://chat?number=${number}`
          break
      }
    },
    closeModal() {
      this.$modal.hide('InfoSeeker')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/info.scss';
</style>
<style lang="scss">
@import '@/assets/styles/_variables.scss';

.chart__tooltip {
  color: $blue;
  background-color: transparent;
}

.apexcharts-text {
  fill: $white;
}
</style>

<style lang="scss" scoped>
@import 'style';
.main__text {
  width: 100%;
  & > textarea {
    resize: none;
    width: 100%;
    &:disabled {
      background-color: transparent;
      color: #fff;
      border: none;
    }
  }
}
</style>
