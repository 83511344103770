<template>
  <div class="upload__container upload-action">
    <div class="upload__top">
      <div class="upload__title">
        <span v-if="user">Редагування користувача</span>
        <span v-else>Додавання користувача</span>
      </div>
      <div class="upload__buttons">
        <div class="button button--fill button--grey" @click="$modal.hide('UploadUser')">Скасувати</div>
        <div class="button button--fill" @click="send">Зберегти</div>
        <div class="button__icon" @click="$modal.hide('UploadUser')">
          <i class="ri-close-circle-line"></i>
        </div>
        <div class="button__icon button__icon--blue" @click="send">
          <i class="ri-save-3-line"></i>
        </div>
      </div>
    </div>
    <div class="upload__main">
      <div class="upload__part upload__part-role">
        <div class="upload__part-title">Роль:</div>
        <div class="upload__part-main">
          <div
            class="indicator indicator--rounded indicator--yellow"
            :class="{'indicator--active': role === 3}"
            @click="role = 3"
          >
            Офіс-Менеджер
          </div>
          <div
            class="indicator indicator--rounded indicator--red"
            :class="{'indicator--active': role === 4}"
            @click="role = 4"
          >
            Адмін
          </div>
          <div
            class="indicator indicator--rounded indicator--blue"
            :class="{'indicator--active': role === 2}"
            @click="role = 2"
          >
            Агент
          </div>
          <div
            class="indicator indicator--rounded indicator--grey"
            :class="{'indicator--active': role === 1}"
            @click="role = 1"
          >
            Гість
          </div>
        </div>
      </div>
      <div class="upload__part upload__part-rank">
        <div class="upload__part-title">Ранг:</div>
        <div class="upload__part-main">
          <div
            class="indicator indicator--rounded indicator--def"
            :class="{'indicator--active': rank === 1}"
            @click="rank = 1"
          >
            Стажер
          </div>
          <div
            class="indicator indicator--rounded indicator--def"
            :class="{'indicator--active': rank === 2}"
            @click="rank = 2"
          >
            Новачок
          </div>
          <div
            class="indicator indicator--rounded indicator--def"
            :class="{'indicator--active': rank === 3}"
            @click="rank = 3"
          >
            Агент
          </div>
          <div
            class="indicator indicator--rounded indicator--def"
            :class="{'indicator--active': rank === 4}"
            @click="rank = 4"
          >
            Професіонал
          </div>
        </div>
      </div>
      <div class="upload__part upload__part-name">
        <label
          class="field__label"
          :class="{
            'field__label--filled': fullName
          }"
        >
          <div class="field__title field__title--required">Логін</div>
          <input v-model="fullName" class="field__input" placeholder="Логін" />
        </label>
        <label
          class="field__label"
          :class="{
            'field__label--filled': lastName,
            'field__label--invalid': $v.lastName.$dirty && !$v.lastName.required
          }"
        >
          <div class="field__title field__title--required">Прізвище</div>
          <input v-model="lastName" class="field__input" placeholder="Прізвище" />
        </label>
        <label
          class="field__label"
          :class="{
            'field__label--filled': firstName,
            'field__label--invalid': $v.firstName.$dirty && !$v.firstName.required
          }"
        >
          <div class="field__title field__title--required">Ім'я</div>
          <input v-model="firstName" class="field__input" placeholder="Ім'я" />
        </label>
        <label
          class="field__label"
          :class="{
            'field__label--filled': fathersName
          }"
        >
          <div class="field__title field__title--required">По Батькові</div>
          <input v-model="fathersName" class="field__input" placeholder="По Батькові" />
        </label>
      </div>
      <div class="upload__part upload__part--half">
        <label
          class="field__label"
          :class="{
            'field__label--filled': email,
            'field__label--invalid': ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email)
          }"
        >
          <div class="field__title field__title--required">E-mail</div>
          <input v-model="email" class="field__input" placeholder="E-mail" />
        </label>
        <label
          class="field__label"
          :class="{
            'field__label--filled': password,
            'field__label--invalid': $v.password.$dirty && !$v.password.required && !$v.password.minLength
          }"
        >
          <div class="field__title field__title--required">Пароль</div>
          <input v-model="password" class="field__input" placeholder="Пароль" />
        </label>
      </div>
      <div class="upload__part upload__part--third">
        <div
          class="field__label"
          :class="{
            'field__label--filled': isOnContract,
            'field__label--invalid': $v.isOnContract.$dirty && !$v.isOnContract.required
          }"
        >
          <div class="field__title">Контракт</div>
          <Multiselect
            :options="choice"
            v-model="isOnContract"
            label="name"
            trackBy="id"
            :showLabels="false"
            :searchable="false"
            :maxHeight="200"
            class="mSelect"
            placeholder="Контракт"
          />
        </div>
        <div
          class="field__label"
          :class="{
            'field__label--filled': isOfficial,
            'field__label--invalid': $v.isOfficial.$dirty && !$v.isOfficial.required
          }"
        >
          <div class="field__title">Офіційно</div>
          <Multiselect
            :options="official"
            v-model="isOfficial"
            label="name"
            trackBy="id"
            :showLabels="false"
            :searchable="false"
            :maxHeight="200"
            class="mSelect"
            placeholder="Офіційно"
          />
        </div>
        <label
          class="field__label field__label-tel"
          :class="{
            'field__label--filled': phone,
            'field__label--invalid': ($v.phone.$dirty && !$v.phone.required) || ($v.phone.$dirty && !$v.phone.minLength)
          }"
        >
          <div class="field__title field__title--required">Телефон</div>
          <input v-model="phone" v-mask="'##########'" class="field__input" placeholder="Телефон" />
        </label>
      </div>
      <div v-if="user" class="upload__part">
        <label class="field__label">
          <button class="button button--fill is-red" @click="openDelModal">Видалити</button>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import {required, minLength, email, requiredIf} from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import DeleteModal from '../Delete'

export default {
  name: 'UploadUser',
  props: {
    user: Object,
    default: null
  },
  components: {
    Multiselect,
    DatePicker
  },
  data: () => ({
    firstName: null,
    lastName: null,
    fathersName: null,
    fullName: null,
    rank: 1,
    role: 3,
    email: null,
    phone: null,
    isOnContract: {name: 'Є', id: true},
    isOfficial: {name: 'Так', id: true},
    password: null,
    choice: [
      {name: 'Є', id: true},
      {name: 'Немає', id: false}
    ],
    official: [
      {name: 'Так', id: true},
      {name: 'Ні', id: false}
    ]
  }),
  methods: {
    async send() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const data = {
        fullName: this.fullName,
        firstName: this.firstName,
        lastName: this.lastName,
        rank: this.rank,
        role: this.role,
        email: this.email,
        phone: this.phone,
        isOnContract: this.isOnContract.id,
        isOfficial: this.isOfficial.id
      }
      this.fathersName ? (data.fathersName = this.fathersName) : null
      this.password ? (data.password = this.password) : null

      if (this.user) {
        await this.$store.dispatch('users/update', {id: this.user.id, user: data})
      } else {
        await this.$store.dispatch('users/create', data)
      }
      this.$modal.hide('UploadUser')
    },
    openDelModal() {
      this.$modal.display(DeleteModal, {user: this.user}, {name: 'DelUser', width: 420, height: 388})
    }
  },
  async created() {
    const constants = (this.constants = this.$store.getters.constants)
    const user = this.user
    if (this.user) {
      this.firstName = this.user.firstName
      this.lastName = this.user.lastName
      this.fathersName = this.user.fathersName
      this.fullName = this.user.fullName
      this.rank = this.user.rank
      this.role = this.user.role
      this.email = this.user.email
      this.phone = this.user.phone
      this.isOnContract = this.user.isOnContract ? {name: 'Є', id: true} : {name: 'Немає', id: false}
      this.isOfficial = this.user.isOfficial ? {name: 'Так', id: true} : {name: 'Ні', id: false}
    }
  },
  validations: {
    firstName: {required},
    lastName: {required},
    fathersName: {},
    fullName: {},
    rank: {required},
    role: {required},
    email: {required, email},
    phone: {required, minLength: minLength(10)},
    isOnContract: {required},
    isOfficial: {required},
    password: {
      required: requiredIf(function() {
        if (!this.user) return true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
.upload__main {
  overflow-y: scroll;
}
</style>

<style lang="scss">
.upload-action .vc-popover-content-wrapper {
  position: fixed !important;
}
</style>
