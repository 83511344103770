<template>
  <div class="info__inner">
    <div class="info__header">
      <div class="info__nav">
        <div class="nav__link" @click="block = 'main'" :class="{'nav__link--active': block === 'main'}">Основна</div>
        <div class="nav__link" @click="block = 'photo'" :class="{'nav__link--active': block === 'photo'}">Фото</div>
        <div class="nav__link" @click="block = 'other'" :class="{'nav__link--active': block === 'other'}">
          Звіт
        </div>
        <div class="nav__link" @click="block = 'contacts'" :class="{'nav__link--active': block === 'contacts'}">
          Контакти
        </div>
      </div>
      <div class="info__buttons">
        <div class="info__buttons-left">
          <div class="info__icon">
            <i v-if="info.type === 'apartment'" class="ri-building-4-fill"></i>
            <i v-if="info.type === 'house'" class="ri-home-5-fill"></i>
            <i v-if="info.type === 'commerce'" class="ri-store-fill"></i>
          </div>
        </div>
        <div class="info__buttons-right">
          <div class="button__icon" @click="favorites">
            <i class="i-bookmark" :class="{'i-bookmark--blue': info.isSaved}"></i>
          </div>
          <div class="button__icon" @click.stop.prevent>
            <Multiselect
              class="mSelect-dropdown"
              :options="list"
              :searchable="false"
              :show-labels="false"
              :reset-after="true"
              :value="item"
              placeholder
              open-direction="bottom"
              @select="actions"
            />
          </div>
          <div class="button__icon button--onmobile" @click="$modal.hide('InfoObject')">
            <i class="ri-close-fill"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="info__content">
      <div class="info__main" v-if="block === 'main'">
        <div class="main__header">
          <div class="main__header-top">
            <div class="main__price card__price">$ {{ info.price }}</div>
            <div class="main__indicators">
              <div class="card__tag card__tag--yellow main__indicator">{{ info.motivation }}</div>
              <div class="card__tag card__tag--green main__indicator">{{ info.propertyStatus | propertyStatus }}</div>
              <div class="card__tag card__tag--red main__indicator">{{ info.contract }}</div>
            </div>
          </div>
          <div class="main__header-middle">{{ info.title }}</div>
          <div class="main__header-bottom">
            <div class="main__id">ID {{ info.id }}</div>
            <div class="main__date">
              <i class="i-calendar"></i>
              {{ info.createdAt | date }}
            </div>
            <Relation v-for="(rel, i) in info.relations" type="info-tile" :relation="rel" :compact="true" :key="i" />
            <div class="main__address">
              <i class="i-location"></i>
              <span>{{ info.settlement }},</span>
              <span v-if="info.districtName">{{ info.districtName }},</span>
              <span>вул. {{ info.street }}</span>
              <span>{{ info.houseNo }}</span>
              <span>кв.{{ info.aptNo }}</span>
            </div>
          </div>
        </div>
        <div class="main__content">
          <div class="main__row main__row--dark">
            <div class="main__row-left">
              <i class="i-employees"></i>
              <div class="main__text">Автор</div>
            </div>
            <div class="main__row-right">
              <div class="main__row-letter">
                <div class>{{ info.authorName[0] }}</div>
              </div>
              <div class="main__text">{{ info.authorName }}</div>
            </div>
          </div>
          <div class="main__row main__row--dark">
            <div class="main__row-left">
              <i class="i-employees"></i>
              <div class="main__text">Відповідальний агент</div>
            </div>
            <div class="main__row-right">
              <div class="main__row-letter">
                <div class>{{ info.responsibleName[0] }}</div>
              </div>
              <div class="main__text">{{ info.responsibleName }}</div>
            </div>
          </div>
          <div class="main__row">
            <div class="main__text">К-сть кімнат</div>
            <div class="main__text">{{ info.noOfRooms }}</div>
          </div>
          <div class="main__row" v-if="info.type === 'apartment'">
            <div class="main__text">Вид/Тип</div>
            <div class="main__text">
              {{ info.material | apartmentMaterial }}/{{ info.buildingType | apartmentType }}
            </div>
          </div>
          <div class="main__row" v-else-if="info.type === 'house'">
            <div class="main__text">Вид/Тип</div>
            <div class="main__text">{{ info.material | buildingMaterial }}/{{ info.buildingType | buildingType }}</div>
          </div>
          <div class="main__row" v-else>
            <div class="main__text">Призначення</div>
            <div class="main__text">{{ info.buildingType | commerceType }}</div>
          </div>
          <div class="main__row">
            <div class="main__text">Поверх</div>
            <div class="main__text">{{ info.floor }}/{{ info.floors }}</div>
          </div>
          <div class="main__row">
            <div class="main__text">Площа м²</div>
            <div class="main__text">{{ info.squareTotal }}/{{ info.squareLiving }}/{{ info.squareKitchen }}</div>
          </div>
          <div class="main__row" v-if="info.squareLand">
            <div class="main__text">Площа ділянки м²</div>
            <div class="main__text">{{ info.squareLand }}</div>
          </div>
          <div class="main__row" v-if="info.type !== 'house'">
            <div class="main__text">Ремонт/Авт. опал.</div>
            <div class="main__text">
              <span class="card__span" v-if="info.renovated">Є</span>
              <span class="card__span" v-else>Немає</span>
              <span class="card__span">/</span>
              <span class="card__span">{{ info.autonomousHeatType }}</span>
            </div>
          </div>
          <div class="main__row" v-if="info.type === 'house'">
            <div class="main__text">Ремонт</div>
            <div class="main__text">
              <span class="card__span" v-if="info.renovated">Є</span>
              <span class="card__span" v-else>Немає</span>
            </div>
          </div>
          <div class="main__row">
            <div class="main__text">Під іпотеку</div>
            <div class="main__text">{{ info.mortgagePurchase ? 'Так' : 'Ні' }}</div>
          </div>
          <div class="main__row">
            <div class="main__text">Джерело</div>
            <div class="main__text">
              {{ info.source | sourcePath(constants.PROPERTY_SOURCES) }} {{ (info.seeker ? ' > ID ' + info.seeker.id : '' ) }}
            </div>
          </div>
          <div class="main__row" v-if="info.type === 'house'">
            <div class="main__text">Гараж</div>
            <div class="main__text">
              <span class="card__span" v-if="info.garage">Є</span>
              <span class="card__span" v-else>Немає</span>
            </div>
          </div>
          <div class="main__row" v-if="info.type === 'house'">
            <div class="main__text">Рік побудови</div>
            <div class="main__text">
              <span class="card__span">{{ info.builtAt }}</span>
            </div>
          </div>
          <div class="main__row" v-if="info.type === 'house'">
            <div class="main__text">Кадастровий номер</div>
            <div class="main__text">{{ info.registrationNo }}</div>
          </div>
          <div class="main__row">
            <div class="main__text">Останній дзвінок</div>
            <div class="main__text">
              <i class="i-calendar"></i>
              <div v-if="info.lastCallAt">{{ info.lastCallAt | date }}</div>
              <div v-else>-</div>
            </div>
          </div>
          <div class="main__row">
            <div class="main__text">Зміна ціни</div>
            <div class="main__text">
              <i class="i-calendar"></i>
              <div v-if="info.priceUpdatedAt">{{ info.priceUpdatedAt | date }}</div>
              <div v-else>-</div>
            </div>
          </div>
          <div class="main__row main__row--area">
            <div class="main__text">Рекламний текст</div>
            <div class="main__text">
              <textarea id="autoresizing" disabled>{{ info.description }}</textarea>
            </div>
          </div>
          <div class="main__row main__row--area">
            <div class="main__text">Причина продажу</div>
            <div class="main__text">
              <span class="card__span">{{ info.reasonToSell }}</span>
            </div>
          </div>
          <div class="main__row main__row--area">
            <div class="main__text">Коментарі</div>
            <div class="main__text">
              <span class="card__span">{{ info.comments }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="info__photo" v-if="block === 'photo'">
        <div class="photo__header">Фото</div>
        <div class="photo__content">
          <img :src="img.fileURL" class="photo" v-for="img in info.photos" :key="img.id" />
        </div>
      </div>
      <div class="info__contacts" v-if="block === 'contacts'">
        <button class="contacts__button button button--fill" @click="changeLastCall">Зберегти дзвінок</button>
        <div class="contacts__item" v-for="contact in info.contacts" :key="contact.id">
          <div class="contacts__header">
            <div class="contacts__letter">{{ contact.name[0] }}</div>
            <div class="contacts__name">{{ contact.name }}</div>
            <div class="contacts__role">Роль: {{ contact.roles.map(x => x.name).join(' / ') }}</div>
            <div class="contacts__date">Дата народження: {{ contact.birthday }}</div>
            <div v-if="contact.company" class="contacts__company">
              Компанія: "{{ contact.company.name }}"
              <span v-if="contact.jobPosition">({{ contact.jobPosition.name }})</span>
            </div>
          </div>
          <div class="contacts__phones" v-for="phone in contact.phones" :key="phone.id">
            <div class="contacts__phone-wrapper">
              <a :href="'tel:' + phone.phone" @click.stop><i class="ri-phone-fill"></i></a>
              <div class="contacts__phone">{{ phone.phone }}</div>
              <div class="contacts__messengers">
                <button
                  class="button__circle"
                  :class="{'button__circle--blue': phone.isOnViber}"
                  @click="sendMessage({...phone, message: 'viber'})"
                >
                  <i class="i-viber"></i>
                </button>
                <i
                  class="i-telegram button__circle"
                  :class="{
                    'button__circle--blue': phone.isOnTelegram
                  }"
                ></i>
                <i
                  class="i-whatsapp button__circle"
                  :class="{
                    'button__circle--blue': phone.isOnWhatsapp
                  }"
                ></i>
                <i
                  class="i-messanger button__circle"
                  :class="{
                    'button__circle--blue': phone.isOnFacebook
                  }"
                ></i>
              </div>
            </div>
          </div>
          <div class="contacts__rel">
            <Relation v-for="(rel, i) in contact.relations" :key="i" :relation="rel" />
          </div>
        </div>
      </div>
      <div class="info__other" v-if="block === 'other'">
        <ObjectReport :report="report" :id="info.id" />
      </div>
    </div>
  </div>
</template>

<script>
// import VueApexCharts from 'vue-apexcharts'
import Multiselect from 'vue-multiselect'
import ModalArchive from '@/components/ModalArchive'
import Edit from '../Upload'
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import Relation from '@/components/Relation'
import ObjectReport from '@/components/ObjectReport'


export default {
  name: 'InfoObject',
  props: ['info'],
  components: {
    Multiselect,
    // apexchart: VueApexCharts,
    Relation,
    ObjectReport,
  },
  data: () => ({
    block: 'main',
    list: ['Редагувати', 'В Архів'],
    item: '',
    moment,
    // source: null,
    series: [
      {
        data: []
      }
    ],
    chartOptions: {
      chart: {
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        brush: {
          enabled: false,
          target: undefined,
          autoScaleYaxis: false
        }
      },
      stroke: {
        curve: 'smooth'
      },
      tooltip: {
        theme: 'dark',
        custom: function({series, seriesIndex, dataPointIndex}) {
          return (
            '<div class="chart__tooltip">' + '<span>$ ' + series[seriesIndex][dataPointIndex] + '</span>' + '</div>'
          )
        }
      },
      xaxis: {
        categories: [],
        tooltip: {
          enabled: false
        }
      }
    }
  }),
  async created() {
    if (this.info.contacts?.length > 0) {
      const relations = await this.$store.dispatch('contacts/fetchRelations', this.info.contacts.map(x => x.id))
      for (const contact of this.info.contacts) {
        contact.relations = relations.filter(x => x.id === contact.id)
      }
    }
  },
  async mounted() {
    let textarea = document.querySelector('#autoresizing')
    function autoResize() {
      textarea.style.height = 'auto'
      textarea.style.height = textarea.scrollHeight + 'px'
      textarea.disable = 'true'
    }
    autoResize()
    await this.$store.dispatch('objects/fetchReport', { id: this.info.id })
    // this.changeStats(this.prices)
  },
  computed: {
    ...mapState({
      prices: s => s.objects.prices
    }),
    ...mapGetters({
      constants: 'constants',
      report: 'objects/report',
    })
  },
  methods: {
    async favorites() {
      const id = this.info.id
      const isSaved = this.info.isSaved
      let path = ''
      switch (this.info.type) {
        case 'apartment':
          path = 'objects/apartments/favorites'
          break
        case 'house':
          path = 'objects/house/favorites'
          break
        case 'commerce':
          path = 'objects/commerce/favorites'
          break
      }
      try {
        await this.$store.dispatch(path, {id, isSaved})
        this.obj.isSaved = !this.obj.isSaved
      } catch (err) {}
    },
    actions(option) {
      const type = this.info.type
      if (option === 'Редагувати') {
        if (type === 'apartment') {
          this.$modal.hide('InfoObject')
          this.$modal.display(Edit, {info: this.info, type: 'apartment'}, {name: 'UploadObject', clickToClose: false})
        } else if (type === 'house') {
          this.$modal.hide('InfoObject')
          this.$modal.display(Edit, {info: this.info, type: 'house'}, {name: 'UploadObject', clickToClose: false})
        } else if (type === 'commerce') {
          this.$modal.hide('InfoObject')
          this.$modal.display(Edit, {info: this.info, type: 'commerce'}, {name: 'UploadObject', clickToClose: false})
        }
      } else if (option === 'В Архів') {
        this.$modal.display(
          ModalArchive,
          {info: this.info, entity: 'objects', action: 'archive'},
          {transition: 'none', classes: 'modalArchive', width: '500px', height: '350px', name: 'ModalArchive'}
        )
      }
    },
    async changeLastCall() {
      const type = this.info.type
      let obgType = ''
      if (type === 'apartment') {
        obgType = 'apartments'
      } else if (type === 'house') {
        obgType = 'houses'
      } else if (type === 'commerce') {
        obgType = 'commerce'
      }
      try {
        await this.$store.dispatch(`objects/${obgType}/toggleLastCall`, this.info.id)
        this.info.lastCallAt = new Date()
        this.$modal.hide('InfoObject')
      } catch (err) {
        this.$store.commit('setError', err)
      }
    },
    // changeStats(stats) {
    //   const prices = stats.map(s => s.price)
    //   const dates = stats.map(s => {
    //     return moment(s.createdAt).format('DD.MM.YYYY')
    //   })
    //   this.series = [{data: prices}]
    //   this.chartOptions = {
    //     ...this.chartOptions,
    //     xaxis: {
    //       categories: dates,
    //       tooltip: {
    //         enabled: false
    //       }
    //     }
    //   }
    // },
    sendMessage(phone) {
      const number = phone.phone.slice(1, 4) + phone.phone.slice(6, 9) + phone.phone.slice(10, 14)
      switch (phone.message) {
        case 'viber':
          if (phone.isOnViber) document.location.href = `viber://chat?number=${number}`
          break
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/info.scss';
</style>
<style lang="scss">
@import '@/assets/styles/_variables.scss';

.chart__tooltip {
  color: $blue;
  background-color: transparent;
}

.apexcharts-text {
  fill: $white;
}
</style>
