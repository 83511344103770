<template>
  <div class="actions-layout">
    <Navbar />
    <Sidebar />
    <div class="page__wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Navbar from '@/views/Users/Navbar'
import Sidebar from '@/components/Sidebar'

export default {
  name: 'UsersLayout',
  components: {
    Navbar,
    Sidebar
  },
  computed: {
    error() {
      return this.$store.getters.error
    }
  },
  watch: {
    error(error) {
      const data = error.response?.data ?? error
      this.$error(data?.message ?? data?.error)
    }
  },
  created() {
    if (!this.$store.getters.constants) {
      this.$store.dispatch('fetchConstants')
    }
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.page__wrapper {
  top: 74px;
}
</style>
