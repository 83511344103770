<template>
  <div class="upload__choice buyers">
    <div class="buyers__top">
      <div class="buyers__title">Виберіть покупця</div>
    </div>
    <div class="buyers__main">
      <div class="buyers__card" @click="selectBuyer('apartment')">
        <div class="buyers__icon">
          <i class="ri-building-4-fill"></i>
        </div>
        <div class="buyers__name">Квартира</div>
      </div>
      <div class="buyers__card" @click="selectBuyer('house')">
        <div class="buyers__icon">
          <i class="ri-home-5-fill"></i>
        </div>
        <div class="buyers__name">Будинок</div>
      </div>
      <div class="buyers__card" @click="selectBuyer('commerce')">
        <div class="buyers__icon">
          <i class="ri-store-fill"></i>
        </div>
        <div class="buyers__name">Комерція</div>
      </div>
    </div>
  </div>
</template>

<script>
import Upload from '@/views/Buyers/Upload'

export default {
  name: 'uploadChoice',
  methods: {
    selectBuyer(buyer) {
      this.$modal.display(
        Upload,
        {buyer},
        {transition: 'none', classes: 'modal', width: '1000px', height: '92%', clickToClose: false, name: 'UploadBuyer'},
      )
      this.$modal.hide('UploadChoice')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style.scss';
</style>
