var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "data__inner data_uphoto" }, [
    _c("div", { staticClass: "data__uphoto-input" }, [
      _c(
        "label",
        { staticClass: "field__label label_uphoto", attrs: { id: "dropbox" } },
        [
          _c("input", {
            staticClass: "field__files input__uphoto",
            attrs: { type: "file", multiple: "", accept: "image/*" },
            on: {
              change: function($event) {
                return _vm.handleFileUpload($event)
              }
            }
          }),
          _vm._m(0),
          _c("span", { staticClass: "data__uphoto-text" })
        ]
      )
    ]),
    _c(
      "div",
      { staticClass: "data__uphoto-wrapper" },
      _vm._l(_vm.imagePreview, function(image, i) {
        return _c(
          "div",
          {
            key: i,
            staticClass: "data__uphoto",
            on: {
              click: function($event) {
                return _vm.del(i)
              }
            }
          },
          [
            _c("img", {
              staticClass: "data__uphoto-img",
              attrs: { src: image }
            }),
            _c("i", { staticClass: "i-close data__uphoto-remove" })
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "data__uphoto-wrapper" },
      _vm._l(_vm.photos, function(image, i) {
        return _c(
          "div",
          {
            key: i,
            staticClass: "data__uphoto",
            on: {
              click: function($event) {
                return _vm.delPhoto(image)
              }
            }
          },
          [
            _c("img", {
              staticClass: "data__uphoto-img",
              attrs: { src: image.fileURL }
            }),
            _c("i", { staticClass: "i-close data__uphoto-remove" })
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("i", { staticClass: "i-upload" }, [
      _c("span", [_vm._v("Перетягніть зображення або натисніть, щоб вибрати")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }