import axios from 'axios'
import router from '@/router'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    pagination: {},
    commerce: [],
    oneCommerce: {},
    page: 1,
    // SELECTION
    selectionActive: false,
    // FILTER PARAMS
    searchParams: {},
    sortParams: {},
    filterParamsLocal: {},
    filterParamsSend: {},
    filterParamsActive: 0,
    filterParamsOriginal: {
      districts: [],
      settlements: [],
      streets: [],
      created: null,
      priceFrom: null,
      priceTill: null,
      material: [],
      buildingType: [],
      floorFrom: null,
      floorTill: null,
      floor: [],
      floors: [],
      noOfRooms: [],
      autonomousHeatType: null,
      squareTotalTill: null,
      squareTotalFrom: null,
      squareLivingTill: null,
      squareLivingFrom: null,
      squareKitchenFrom: null,
      squareKitchenTill: null,
      squareLandFrom: null,
      squareLandTill: null,
      renovated: null,
      agent: [],
      garage: null,
      archivedAt: null,
      archivedTill: null,
      archivedReason: [],
      soldBy: [],
      relation: null,
      status: [],
      motivation: [],
      contract: [],
      source: [],
      seekerId: null,
      contactRoles: []
    }
  },
  mutations: {
    set(state, commerce) {
      state.commerce = _.unionBy(state.commerce, commerce, 'id')
    },
    setCommerce(state, oneCommerce) {
      state.oneCommerce = oneCommerce
    },
    updateCommerce(state, commerce) {
      const foundCommerce = state.commerce.find(x => x.id === commerce.id)
      Object.assign(foundCommerce, commerce)
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.commerce = []
      state.page = 1
      state.selectionActive = false
    },
    changePage(state) {
      state.page++
    },
    // SELECTION
    setSelectionActive(state, isActive) {
      state.selectionActive = isActive
      state.commerce.forEach(commerce => {
        commerce.isSelected = false
      });
    },
    selectObject(state, params) {
      const commerce = state.commerce.find(x => x.id === params.id)
      commerce.isSelected = params.isSelected
    },
    // FILTER PARAMS
    filterParamsLocal(state, params) {
      state.filterParamsLocal = params
    },
    filterParamsSend(state, params) {
      state.filterParamsSend = params
    },
    filterParamsActive(state, number) {
      state.filterParamsActive = number
    },
    sortParams(state, params) {
      state.sortParams = params
    },
    resetFilterParams(state) {
      state.filterParams = {}
      state.filterParamsActive = {}
    },
    searchParams(state, params) {
      state.searchParams = params
    }
  },
  actions: {
    async fetch({commit, getters}) {
      const page = getters.page
      try {
        const url = process.env.VUE_APP_BACKEND
        const commerce = await axios.get(url + '/properties/commerce', {
          params: {
            page,
            ...getters.filterParamsSend,
            ...getters.sortParams,
            ...getters.searchParams
          }
        })
        commit('pagination', commerce.data.pagination)
        commit('set', commerce.data.properties.map(x => ({ ...x, isSelected: false })))
        commit('changePage')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async fetchCommerce({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const oneCommerce = await axios.get(url + '/properties/commerce/' + id)
        commit('setCommerce', oneCommerce.data.property)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async favorites({commit, dispatch}, {id, isSaved}) {
      if (!isSaved) {
        try {
          const url = process.env.VUE_APP_BACKEND
          await axios.patch(url + '/properties/commerce/' + id + '/favorites')
        } catch (err) {
          commit('setError', err, {root: true})
          throw err
        }
      } else {
        try {
          const url = process.env.VUE_APP_BACKEND
          await axios.delete(url + '/properties/commerce/' + id + '/favorites')
        } catch (err) {
          commit('setError', err, {root: true})
          throw err
        }
      }
      commit('resetFavObjects', 'commerce', {root: true})
      await dispatch('fetchFavoritesObjects', 'commerce', {root: true})
    },
    async create({commit, dispatch}, {data, formData, newContacts}) {
      if (newContacts.length) {
        const contactsIDs = await dispatch('contacts/create', newContacts, {root: true})
        data.contactsIDs = data.contactsIDs.concat(contactsIDs)
      }
      const url = process.env.VUE_APP_BACKEND
      try {
        const resp = await axios.post(url + '/properties/commerce', data)
        if (formData) {
          const id = resp.data.property.id
          try {
            await axios.post(url + '/properties/commerce/' + id + '/photos', formData)
            commit('reset')
            dispatch('fetch')
          } catch (err) {
            commit('setError', err, {root: true})
            throw err
          }
        } else {
          commit('reset')
          dispatch('fetch')
        }
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async edit({commit, dispatch}, {data, formData, newContacts, id, delID}) {
      if (newContacts.length) {
        const contactsIDs = await dispatch('contacts/create', newContacts, {root: true})
        data.contactsIDs = data.contactsIDs.concat(contactsIDs)
      }
      const url = process.env.VUE_APP_BACKEND

      try {
        const response = await axios.put(url + '/properties/commerce/' + id, data)
        if (formData) {
          await axios.post(url + '/properties/commerce/' + id + '/photos', formData)
        }
        if (delID.length) {
          await axios.delete(url + '/properties/commerce/' + id + '/photos', {data: {photosIDs: delID}})
        }
        commit('updateCommerce', response.data.property)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async archive({commit, dispatch}, {id, data}) {
      const url = process.env.VUE_APP_BACKEND
      try {
        await axios.delete(url + '/properties/commerce/' + id + '/archive', {data})
        commit('reset')
        dispatch('fetch')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async archiveSelected({state, commit, dispatch}, { data }) {
      const url = process.env.VUE_APP_BACKEND
      try {
        data.ids = state.commerce.filter(x => x.isSelected).map(x => x.id)
        await axios.delete(url + '/properties/commerce/archive', { data })
        commit('reset')
        dispatch('fetch')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async toggleLastCall({commit}, id) {
      const url = process.env.VUE_APP_BACKEND
      try {
        await axios.patch(url + '/properties/commerce/' + id + '/toggleLastCall')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    }
  },
  getters: {
    commerce: s => s.commerce,
    oneCommerce: s => s.oneCommerce,
    pagination: s => s.pagination,
    page: s => s.page,
    // FILTER PARAMS
    filterParamsOriginal: s => s.filterParamsOriginal,
    filterParamsLocal: s => s.filterParamsLocal,
    filterParamsSend: s => s.filterParamsSend,
    filterParamsActive: s => s.filterParamsActive,
    sortParams: s => s.sortParams,
    searchParams: s => s.searchParams,
    selectionActive: s => s.selectionActive
  }
}
