var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "company" },
    [
      _c(
        "Multiselect",
        {
          staticClass: "mSelect",
          attrs: {
            options: _vm.foundCompanies,
            value: _vm.selectedCompany,
            disabled: _vm.isDisabled,
            label: "name",
            trackBy: "id",
            showLabels: false,
            searchable: true,
            placeholder: "Введіть назву компанії",
            "internal-search": false,
            closeOnSelect: true,
            multiple: false
          },
          on: { select: _vm.select, "search-change": _vm.search },
          scopedSlots: _vm._u([
            {
              key: "singleLabel",
              fn: function(props) {
                return _c("span", {}, [
                  _vm._v(
                    _vm._s(props.option.name) +
                      " (" +
                      _vm._s(props.option.type) +
                      ")"
                  )
                ])
              }
            },
            {
              key: "option",
              fn: function(props) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(props.option.name) +
                      " (" +
                      _vm._s(props.option.type) +
                      ") "
                  )
                ]
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "noOptions" }, slot: "noOptions" }, [
            _vm._v("Введіть назву компанії")
          ]),
          _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
            _vm._v("Компанію не знайдено")
          ])
        ]
      ),
      _vm.isCreateButtonVisible
        ? _c(
            "div",
            {
              staticClass: "company__button--wrapper",
              on: { click: _vm.create }
            },
            [_c("i", { staticClass: "i-plus company__button" })]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }