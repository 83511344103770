import apartments from './apartments'
import houses from './houses'
import commerce from './commerce'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    prices: {},
    report: {},
  },
  mutations: {
    setPrices(state, prices) {
      state.prices = prices
    },
    setReport(state, report) {
      state.report = report
    },
  },
  actions: {
    async fetchPrices({commit, getters}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const prices = await axios.get(url + `/properties/${id}/prices-history`)
        commit('setPrices', prices.data.prices)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async fetchReport({commit}, { id, range }) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const { data } = await axios.get(url + `/reports/properties/${id}`, {
          params: {
            ...range,
          }
        })
        commit('setReport', data.report)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
  },
  getters: {
    prices: s => s.prices,
    report: s => s.report,
  },
  modules: {
    apartments,
    houses,
    commerce
  }
}
