export default function commerceTypes(value) {
  let type;

  switch (value) {
    case 1:
      type = "Склад";
      break;
    case 2:
      type = "Торгове";
      break;
    case 3:
      type = "Кафе";
      break;
    case 4:
      type = "Офіс";
      break;
    case 5:
      type = "Виробниче";
      break;
    case 6:
      type = "Вільне";
      break;
    case 7:
      type = "Інше";
      break;
  }
  return type
}
