<template>
  <div class="navbar__wrapper" id="navbar">
    <div class="navbar__inner">
      <div class="navbar__top">
        <div class="container">
          <div class="navbar__top-inner">
            <div class="navbar__left">
              <div class="button button--fill button-mobile-nav" @click="openSidebar">
                <i class="ri-menu-unfold-line"></i>
              </div>
              <div class="navbar__left-mobile">
                <div v-if="componentName === 'StatsActions'" class="navbar__left-icon">
                  <i class="ri-focus-line"></i>
                </div>
                <div v-if="componentName === 'StatsObjects'" class="navbar__left-icon">
                  <i class="ri-database-line"></i>
                </div>
                <div v-if="componentName === 'StatsBuyers'" class="navbar__left-icon">
                  <i class="ri-shopping-bag-fill"></i>
                </div>
                <div v-if="componentName === 'StatsSeekers'" class="navbar__left-icon">
                  <i class="ri-user-search-line"></i>
                </div>
                <div v-if="componentName === 'StatsRent'" class="navbar__left-icon">
                  <i class="ri-database-line"></i>
                </div>
                <div v-if="componentName === 'StatsRenters'" class="navbar__left-icon">
                  <i class="ri-database-line"></i>
                </div>
              </div>
              <div class="navbar__center-inner">
                <router-link active-class="navbar__link--active" to="/stats/objects" class="link navbar__link">
                  <span class="navbar__link-text">Об'єкти</span>
                </router-link>
                <router-link active-class="navbar__link--active" to="/stats/buyers" class="link navbar__link">
                  <span class="navbar__link-text">Покупці</span>
                </router-link>
                <router-link active-class="navbar__link--active" to="/stats/actions" class="link navbar__link">
                  <span class="navbar__link-text">Дії</span>
                </router-link>
                <router-link active-class="navbar__link--active" to="/stats/seekers" class="link navbar__link">
                  <span class="navbar__link-text">Шукачі</span>
                </router-link>
                <router-link active-class="navbar__link--active" to="/stats/rent" class="link navbar__link">
                  <span class="navbar__link-text">Орендодавці</span>
                </router-link>
                <router-link active-class="navbar__link--active" to="/stats/renters" class="link navbar__link">
                  <span class="navbar__link-text">Орендарі</span>
                </router-link>
              </div>
            </div>
            <div class="navbar__right">
              <div class="navbar__notifications">
                <Notifications />
              </div>
              <div class="user__block">
                <div class="user__icon">{{ letter }}</div>
                <div class="user__name">{{ name }}</div>
                <div class="user__param">
                  <Multiselect
                    class="mSelect-dropdown"
                    :options="list"
                    :searchable="false"
                    :show-labels="false"
                    :reset-after="true"
                    :value="uAction"
                    placeholder=""
                    @select="userfn"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NavbarActions v-if="componentName === 'StatsActions'" />
      <NavbarObjects v-if="componentName === 'StatsObjects'" />
      <NavbarBuyers v-if="componentName === 'StatsBuyers'" />
      <NavbarSeekers v-if="componentName === 'StatsSeekers'" />
      <NavbarRent v-if="componentName === 'StatsRent'" />
      <NavbarRenters v-if="componentName === 'StatsRenters'" />
    </div>
  </div>
</template>

<script>
import NavbarActions from './Actions'
import NavbarObjects from './Objects'
import NavbarBuyers from './Buyers'
import NavbarSeekers from './Seekers'
import NavbarRent from './Rent'
import NavbarRenters from './Renters'
import Multiselect from 'vue-multiselect'
import Sidebar from '@/components/Sidebar'
import SidebarMobile from '@/components/SidebarMobile'
import Notifications from '@/components/Notifications'

export default {
  name: 'StatsNavbar',
  components: {
    Multiselect,
    NavbarActions,
    NavbarObjects,
    NavbarBuyers,
    NavbarSeekers,
    NavbarRent,
    NavbarRenters,
    Notifications,
  },
  data: () => ({
    name: '',
    letter: '',
    list: ['Аккаунт', 'Вийти'],
    uAction: '',
  }),
  computed: {
    user() {
      return this.$store.getters.user
    },
    componentName() {
      return this.$route.name
    }
  },
  async created() {
    if (Object.keys(this.user).length === 0) {
      await this.$store.dispatch('getUser')
    }
    this.name = this.user.fullName
    this.letter = this.user?.fullName[0]
  },
  methods: {
    userfn(option) {
      if (option === 'Вийти') {
        this.$store.dispatch('logout')
        this.$router.go('/login')
      }
    },
    openSidebar() {
      const mobile = document.documentElement.clientWidth <= 768
      let SidebarModal = {}
      mobile ? (SidebarModal = SidebarMobile) : (SidebarModal = Sidebar)
      this.$modal.display(
        SidebarModal,
        {},
        {
          name: 'Sidebar',
          transition: 'none',
          classes: 'modalSidebar',
          width: '210px',
          height: '100%',
          maxHeight: 1024,
          shiftX: 0,
          shiftY: 0,
          adaptive: true
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style.scss';
</style>
