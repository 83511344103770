var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal__wrapper" }, [
    _c("div", { staticClass: "modal__top" }, [
      _c(
        "div",
        {
          staticClass: "button__icon button--onmobile",
          on: {
            click: function($event) {
              return _vm.$modal.hide("CompanyUpload")
            }
          }
        },
        [_c("i", { staticClass: "ri-close-line" })]
      )
    ]),
    _c("div", { staticClass: "modal__middle" }, [
      _c(
        "div",
        {
          staticClass: "field field__label",
          class: {
            "field__label--filled": _vm.name,
            "field__label--invalid": _vm.$v.name.$dirty && !_vm.$v.name.required
          }
        },
        [
          _c("div", { staticClass: "field__title field__title--required" }, [
            _vm._v("Назва компанії")
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name"
              }
            ],
            staticClass: "field__input",
            attrs: { placeholder: "Назва компанії" },
            domProps: { value: _vm.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.name = $event.target.value
              }
            }
          })
        ]
      ),
      _c(
        "div",
        {
          staticClass: "field field__label",
          class: {
            "field__label--filled": _vm.type,
            "field__label--invalid": _vm.$v.type.$dirty && !_vm.$v.type.required
          }
        },
        [
          _c("div", { staticClass: "field__title field__title--required" }, [
            _vm._v("Тип компанії")
          ]),
          _c("Multiselect", {
            attrs: {
              options: _vm.constants.COMPANY_TYPES,
              label: "name",
              trackBy: "id",
              showLabels: false,
              searchable: false,
              placeholder: "Тип компанії"
            },
            model: {
              value: _vm.type,
              callback: function($$v) {
                _vm.type = $$v
              },
              expression: "type"
            }
          })
        ],
        1
      ),
      _c(
        "button",
        { staticClass: "button button--fill", on: { click: _vm.send } },
        [_vm._v("Створити")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }