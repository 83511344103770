<template>
  <div class="navbar__wrapper">
    <div class="navbar__inner">
      <div class="navbar__top">
        <div class="container">
          <div class="navbar__top-inner">
            <div class="navbar__left">
              <div class="button button--fill button-mobile-nav" @click="openSidebar">
                <i class="ri-menu-unfold-line"></i>
              </div>
              <div class="navbar__left-mobile">
                <i class="ri-key-2-line"></i>
                <div v-if="type === 'apartment'" class="navbar__left-icon">
                  <i class="ri-building-4-fill"></i>
                  {{ apartmentNumber }}
                </div>
                <div v-if="type === 'house'" class="navbar__left-icon">
                  <i class="ri-home-5-fill"></i>
                  {{ houseNumber }}
                </div>
                <div v-if="type === 'commerce'" class="navbar__left-icon">
                  <i class="ri-store-fill"></i>
                  {{ commerceNumber }}
                </div>
              </div>
              <div class="navbar__center-inner">
                <router-link active-class="navbar__link--active" to="/renters/apartments" class="link navbar__link">
                  <span class="navbar__link-text">Квартири</span>
                  <span class="navbar__link-icon">{{ apartmentNumber }}</span>
                </router-link>
                <router-link active-class="navbar__link--active" to="/renters/houses" class="link navbar__link">
                  <span class="navbar__link-text ">Будинки</span>
                  <span class="navbar__link-icon">{{ houseNumber }}</span>
                </router-link>
                <router-link active-class="navbar__link--active" to="/renters/commerce" class="link navbar__link">
                  <span class="navbar__link-text">Комерція</span>
                  <span class="navbar__link-icon">{{ commerceNumber }}</span>
                </router-link>
              </div>
            </div>
            <div class="navbar__right">
              <button class="button__icon" @click="createRenter"><i class="ri-add-circle-line"></i></button>
              <button class="button button--fill" @click="createRenter">
                Додати орендаря
              </button>
              <div class="navbar__notifications">
                <Notifications />
              </div>
              <div class="user__block">
                <div class="user__icon">{{ letter }}</div>
                <div class="user__name">{{ user }}</div>
                <div class="user__param">
                  <Multiselect
                    class="mSelect-dropdown"
                    :options="list"
                    :searchable="false"
                    :show-labels="false"
                    :reset-after="true"
                    :value="uAction"
                    placeholder=""
                    @select="userfn"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="navbar__bottom">
        <div class="container">
          <div class="navbar__bottom-inner">
            <div class="navbar__left">
              <label class="search__label">
                <i class="i-search"></i>
                <input
                  class="search__input"
                  type="search"
                  :value="search"
                  placeholder="Введіть номер телефону або ID..."
                  @input="searchRenter"
                />
              </label>
            </div>
            <div class="navbar__right">
              <div class="dropdown__layout">
                <span>Сортувати за:</span>
                <Multiselect
                  ref="multiselect"
                  :options="listSort"
                  v-model="itemSort"
                  label="name"
                  trackBy="id"
                  :value="itemSort"
                  :showLabels="false"
                  :searchable="false"
                  :allowEmpty="false"
                  placeholder="Сортувати"
                  class="mSelect-sort"
                  @select="sorted"
                  @close="dropdownActive = false"
                  @open="dropdownActive = true"
                />
              </div>
              <div style="display: flex;" class="button__wrapper">
                <button
                  class="button button__checkbox button--small button--grey"
                  :class="{'button__checkbox--active': selectionActive}"
                  @click="setSelectionActiveness(!selectionActive)"
                >
                  <i :class="{'ri-checkbox-fill': selectionActive, 'ri-checkbox-line': !selectionActive}"></i>
                </button>
                <button
                  v-if="selectionActive"
                  class="button button--small button--grey button__archive"
                  @click="archiveSelected()"
                >
                  <span class="button__text">Архівувати</span>
                </button>
              </div>
              <div class="button__wrapper">
                <button
                  class="button button--small button--grey"
                  :class="{'ri-filter-fill-blue': activeParams}"
                  @click="openFilter"
                >
                  <i class="ri-filter-fill">
                    <span v-if="activeParams">{{ activeParams }}</span>
                  </i>
                  <span class="button__text">Фільтри</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Sidebar from '@/components/Sidebar'
import SidebarMobile from '@/components/SidebarMobile'
import Choice from '@/views/Renters/Upload/uploadChoice'
import Filter from '@/views/Renters/Filter'
import ModalArchive from '@/components/ModalArchive'
import Notifications from '@/components/Notifications'

export default {
  name: 'NavbarRenters',
  components: {
    Multiselect,
    Notifications,
  },
  data: () => ({
    user: {},
    letter: '',
    list: ['Аккаунт', 'Вийти'],
    uAction: '',
    search: '',
    itemSort: {name: 'Останні'},
    listSort: [
      {name: 'Нові', id: 'createdAt-desc'},
      {name: 'Старі', id: 'createdAt-asc'},
      {name: 'Дзвінок +', id: 'lastCallAt-desc'},
      {name: 'Дзвінок -', id: 'lastCallAt-asc'},
      {name: 'Більші', id: 'squareTotal-desc'},
      {name: 'Менші', id: 'squareTotal-asc'},
      {name: 'Здано до +', id: 'rentedTill-asc'},
      {name: 'Здано до -', id: 'rentedTill-desc'},
    ],
    dropdownActive: false
  }),
  computed: {
    apartmentNumber() {
      return this.$store.getters['renters/apartments/pagination'].total
    },
    houseNumber() {
      return this.$store.getters['renters/houses/pagination'].total
    },
    commerceNumber() {
      return this.$store.getters['renters/commerce/pagination'].total
    },
    type() {
      return this.$route.meta.class
    },
    activeParams() {
      switch (this.type) {
        case 'apartment':
          return this.$store.getters['renters/apartments/filterParamsActive']
        case 'house':
          return this.$store.getters['renters/houses/filterParamsActive']
        case 'commerce':
          return this.$store.getters['renters/commerce/filterParamsActive']
        default:
          return 0
      }
    },
    selectionActive() {
      switch (this.type) {
        case 'apartment':
          return this.$store.getters['renters/apartments/selectionActive']
        case 'house':
          return this.$store.getters['renters/houses/selectionActive']
        case 'commerce':
          return this.$store.getters['renters/commerce/selectionActive']
        default:
          return false
      }
    }
  },
  watch: {
    type(type) {
      if (type === 'apartment') {
        this.search = this.$store.getters['renters/apartments/searchParams']['filter[phoneOrID]']
      } else if (type === 'house') {
        this.search = this.$store.getters['renters/houses/searchParams']['filter[phoneOrID]']
      } else if (type === 'commerce') {
        this.search = this.$store.getters['renters/commerce/searchParams']['filter[phoneOrID]']
      }
    }
  },
  async created() {
    if (Object.keys(this.$store.getters.user).length === 0) {
      await this.$store.dispatch('getUser')
    }
    const user = this.$store.getters.user
    this.user = user.fullName
    this.letter = user?.fullName[0]

    if (!this.apartmentNumber) {
      await this.$store.dispatch('renters/apartments/fetch')
    }
    if (!this.houseNumber) {
      await this.$store.dispatch('renters/houses/fetch')
    }
    if (!this.commerceNumber) {
      await this.$store.dispatch('renters/commerce/fetch')
    }
  },
  methods: {
    userfn(option) {
      if (option === 'Вийти') {
        this.$store.dispatch('logout')
        this.$router.go('/login')
      }
    },
    searchRenter(e) {
      this.search = e.target.value
      this.searchObject(e)
    },
    searchObject: _.debounce(async function(e) {
      if (this.type === 'house') {
        this.$store.commit('renters/houses/reset')
        this.$store.commit('renters/houses/searchParams', {'filter[phoneOrID]': e.target.value})
        this.$store.dispatch('renters/houses/fetch')
      } else if (this.type === 'apartment') {
        this.$store.commit('renters/apartments/reset')
        this.$store.commit('renters/apartments/searchParams', {'filter[phoneOrID]': e.target.value})
        this.$store.dispatch('renters/apartments/fetch')
      } else if (this.type === 'commerce') {
        this.$store.commit('renters/commerce/reset')
        this.$store.commit('renters/commerce/searchParams', {'filter[phoneOrID]': e.target.value})
        this.$store.dispatch('renters/commerce/fetch')
      }
    }, 500),
    async sorted(option) {
      let params = {
        sortBy: option.id
      }
      if (this.type === 'house') {
        this.$store.commit('renters/houses/reset')
        this.$store.commit('renters/houses/sortParams', params)
        this.$store.dispatch('renters/houses/fetch')
      } else if (this.type === 'apartment') {
        this.$store.commit('renters/apartments/reset')
        this.$store.commit('renters/apartments/sortParams', params)
        this.$store.dispatch('renters/apartments/fetch')
      } else if (this.type === 'commerce') {
        this.$store.commit('renters/commerce/reset')
        this.$store.commit('renters/commerce/sortParams', params)
        this.$store.dispatch('renters/commerce/fetch')
      }
    },
    openFilter() {
      this.$modal.display(
        Filter,
        {},
        {
          transition: 'none',
          classes: 'modalFilter',
          width: '400px',
          height: '100%',
          shiftX: 1,
          shiftY: 1,
          name: 'FilterRenters',
          maxHeight: 3000
        }
      )
    },
    openSidebar() {
      const mobile = document.documentElement.clientWidth <= 768
      let SidebarModal = {}
      mobile ? (SidebarModal = SidebarMobile) : (SidebarModal = Sidebar)
      this.$modal.display(
        SidebarModal,
        {},
        {
          name: 'Sidebar',
          transition: 'none',
          classes: 'modalSidebar',
          width: '210px',
          height: '100%',
          shiftX: 0,
          shiftY: 0,
          adaptive: true
        }
      )
    },
    createRenter() {
      this.$modal.display(
        Choice,
        {},
        {transition: 'none', classes: 'modalChoice', width: '500px', height: '350px', name: 'UploadChoice'}
      )
    },
    setSelectionActiveness(isActive) {
      if (this.type === 'house') {
        this.$store.commit('renters/houses/setSelectionActive', isActive)
      } else if (this.type === 'apartment') {
        this.$store.commit('renters/apartments/setSelectionActive', isActive)
      } else if (this.type === 'commerce') {
        this.$store.commit('renters/commerce/setSelectionActive', isActive)
      }
    },
    archiveSelected() {
      this.$modal.display(
        ModalArchive,
        {info: { type: this.type }, isBulk: true, entity: 'renters', action: 'archive'},
        {transition: 'none', classes: 'modalArchive', width: '500px', height: '350px', name: 'ModalArchive'}
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  .button__checkbox {
    i {
      font-size: 19px;
      margin-right: 0;
    }

    &--active {
      padding-left: 10px;
      padding-right: 10px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .button__archive {
    padding-left: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
</style>