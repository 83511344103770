<template>
  <div class="navbar__wrapper" id="navbar">
    <div class="navbar__inner">
      <div class="navbar__top">
        <div class="container">
          <div class="navbar__top-inner">
            <div class="navbar__left">
              <div class="button button--fill button-mobile-nav" @click="openSidebar">
                <i class="ri-menu-unfold-line"></i>
              </div>
              <div class="navbar__left-mobile">
                <i v-if="isBuyers" class="ri-shopping-bag-fill"></i>
                <div v-if="isApartment" class="navbar__left-icon">
                  <i class="ri-building-4-fill"></i>
                  {{ apartmentsNumber }}
                </div>
                <div v-if="isHouse" class="navbar__left-icon">
                  <i class="ri-home-5-fill"></i>
                  {{ housesNumber }}
                </div>
                <div v-if="isCommerce" class="navbar__left-icon">
                  <i class="ri-store-fill"></i>
                  {{ commerceNumber }}
                </div>
              </div>
              <div class="navbar__center-inner">
                <router-link active-class="navbar__link--active" :to="linkApartments" class="link navbar__link">
                  <span class="navbar__link-text">Квартири</span>
                  <span class="navbar__link-icon">{{ apartmentsNumber }}</span>
                </router-link>
                <router-link active-class="navbar__link--active" :to="linkHouses" class="link navbar__link">
                  <span class="navbar__link-text ">Будинки</span>
                  <span class="navbar__link-icon">{{ housesNumber }}</span>
                </router-link>
                <router-link active-class="navbar__link--active" :to="linkCommerce" class="link navbar__link">
                  <span class="navbar__link-text">Комерція</span>
                  <span class="navbar__link-icon">{{ commerceNumber }}</span>
                </router-link>
              </div>
            </div>

            <div class="navbar__right">
              <div class="navbar__notifications">
                <Notifications />
              </div>
              <div class="user__block">
                <div class="user__icon">{{ letter }}</div>
                <div class="user__name">{{ user }}</div>
                <div class="user__param">
                  <Multiselect
                    class="mSelect-dropdown"
                    :options="list"
                    :searchable="false"
                    :show-labels="false"
                    :reset-after="true"
                    :value="uAction"
                    placeholder=""
                    @select="userfn"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="navbar__bottom">
        <div class="container">
          <div class="navbar__bottom-inner">
            <div class="navbar__left">
              <label class="search__label">
                <i class="i-search"></i>
                <input class="search__input" v-model="search" placeholder="Введіть номер телефону або ID..." />
              </label>
            </div>
            <div class="navbar__right">
              <div style="display: flex;" class="button__wrapper">
                <button
                  class="button button__checkbox button--small button--grey"
                  :class="{'button__checkbox--active': selectionActive}"
                  @click="setSelectionActiveness(!selectionActive)"
                >
                  <i :class="{'ri-checkbox-fill': selectionActive, 'ri-checkbox-line': !selectionActive}"></i>
                </button>
                <button
                  v-if="selectionActive"
                  class="button button--small button--grey button__archive"
                  @click="toggleSelectAll()"
                >
                  <span class="button__text">Всі</span>
                </button>
                <button
                  v-if="selectionActive"
                  class="button button--small button--grey button__archive"
                  @click="restoreSelected()"
                >
                  <span class="button__text">Відновити</span>
                </button>
                <button
                  v-if="selectionActive"
                  class="button button--small button--grey button__archive"
                  @click="deleteSelected()"
                >
                  <span class="button__text">Видалити</span>
                </button>
              </div>
              <div class="button__wrapper">
                <button
                  class="button button--small button--grey"
                  :class="{'ri-filter-fill-blue': activeParams}"
                  @click="openFilter"
                >
                  <i class="ri-filter-fill">
                    <span v-if="activeParams">{{ activeParams }}</span>
                  </i>
                  <span class="button__text">Фільтри</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import FiltersBuyers from '@/views/Buyers/Filter'
import FiltersObjects from '@/views/Objects/Filter'
import FiltersRent from '@/views/Rent/Filter'
import FiltersRenters from '@/views/Renters/Filter'
import Sidebar from '@/components/Sidebar'
import SidebarMobile from '@/components/SidebarMobile'
import Notifications from '@/components/Notifications'

export default {
  name: 'NavbarArchive',
  components: {
    Multiselect,
    Notifications,
  },
  data: () => ({
    user: {},
    letter: '',
    list: ['Аккаунт', 'Вийти'],
    uAction: '',
    search: null,
  }),
  computed: {
    linkApartments() {
      return `/archive/${this.type}/apartments`
    },
    linkHouses() {
      return `/archive/${this.type}/houses`
    },
    linkCommerce() {
      return `/archive/${this.type}/commerce`
    },
    apartmentsNumber() {
      return this.$store.getters[`archive/${this.type}/apartments/pagination`]?.total
    },
    housesNumber() {
      return this.$store.getters[`archive/${this.type}/houses/pagination`]?.total
    },
    commerceNumber() {
      return this.$store.getters[`archive/${this.type}/commerce/pagination`]?.total
    },
    isApartment() {
      return this.class === 'apartment'
    },
    isHouse() {
      return this.class === 'house'
    },
    isCommerce() {
      return this.class === 'commerce'
    },
    isBuyers() {
      return this.type === 'buyers'
    },
    isRenters() {
      return this.type === 'renters'
    },
    isObjects() {
      return this.type === 'objects'
    },
    isRent() {
      return this.type === 'rent'
    },
    group() {
      switch (this.class) {
        case 'apartment': return 'apartments'
        case 'house': return 'houses'
        case 'commerce': return 'commerce'
        default: 'apartments'
      }
    },
    class() {
      return this.$route.meta.class
    },
    type() {
      return this.$route.meta.type
    },
    activeParams() {
      return this.$store.getters[`archive/${this.type}/${this.group}/filterParamsActive`]
    },
    selectionActive() {
      return this.$store.getters[`archive/${this.type}/${this.group}/selectionActive`]
    }
  },
  async created() {
    if (Object.keys(this.$store.getters.user).length === 0) {
      await this.$store.dispatch('getUser')
    }
    const user = this.$store.getters.user
    this.user = user.fullName
    this.letter = user?.fullName[0]
    if (!this.$store.getters['archive/buyers/apartments/pagination']?.total) {
      await this.$store.dispatch('archive/buyers/apartments/fetch')
    }
    if (!this.$store.getters['archive/buyers/houses/pagination']?.total) {
      await this.$store.dispatch('archive/buyers/houses/fetch')
    }
    if (!this.$store.getters['archive/buyers/commerce/pagination']?.total) {
      await this.$store.dispatch('archive/buyers/commerce/fetch')
    }
    if (!this.$store.getters['archive/objects/apartments/pagination']?.total) {
      await this.$store.dispatch('archive/objects/apartments/fetch')
    }
    if (!this.$store.getters['archive/objects/houses/pagination']?.total) {
      await this.$store.dispatch('archive/objects/houses/fetch')
    }
    if (!this.$store.getters['archive/objects/commerce/pagination']?.total) {
      await this.$store.dispatch('archive/objects/commerce/fetch')
    }
    if (!this.$store.getters['archive/renters/apartments/pagination']?.total) {
      await this.$store.dispatch('archive/renters/apartments/fetch')
    }
    if (!this.$store.getters['archive/renters/houses/pagination']?.total) {
      await this.$store.dispatch('archive/renters/houses/fetch')
    }
    if (!this.$store.getters['archive/renters/commerce/pagination']?.total) {
      await this.$store.dispatch('archive/renters/commerce/fetch')
    }
    if (!this.$store.getters['archive/rent/apartments/pagination']?.total) {
      await this.$store.dispatch('archive/rent/apartments/fetch')
    }
    if (!this.$store.getters['archive/rent/houses/pagination']?.total) {
      await this.$store.dispatch('archive/rent/houses/fetch')
    }
    if (!this.$store.getters['archive/rent/commerce/pagination']?.total) {
      await this.$store.dispatch('archive/rent/commerce/fetch')
    }
  },
  methods: {
    userfn(option) {
      if (option === 'Вийти') {
        this.$store.dispatch('logout')
        this.$router.go('/login')
      }
    },
    searchObject: _.debounce(v => {
      if (v.search !== '') {
        let request = {
          'filter[phoneOrID]': v.search
        }
        v.$store.commit(`archive/${v.type}/${v.group}/reset`)
        v.$store.commit(`archive/${v.type}/${v.group}/searchParams`, request)
        v.$store.dispatch(`archive/${v.type}/${v.group}/fetch`)
      } else {
        v.search = null
      }
    }, 500),
    openFilter() {
      let component = {}
      if (this.isBuyers) {
        component.filter = FiltersBuyers
        component.name = 'FilterBuyers'
      } else if (this.isObjects) {
        component.filter = FiltersObjects
        component.name = 'FilterObjects'
      } else if (this.isRenters) {
        component.filter = FiltersRenters
        component.name = 'FiltersRenters'
      } else if (this.isRent) {
        component.filter = FiltersRent
        component.name = 'FiltersRent'
      }
      this.$modal.display(
        component.filter,
        {},
        {
          transition: 'none',
          classes: 'modalFilter',
          width: '400px',
          height: '100%',
          shiftX: 1,
          shiftY: 1,
          name: component.name,
          maxHeight: 3000
        }
      )
    },
    openSidebar() {
      const mobile = document.documentElement.clientWidth <= 768
      let SidebarModal = {}
      mobile ? (SidebarModal = SidebarMobile) : (SidebarModal = Sidebar)
      this.$modal.display(
        SidebarModal,
        {},
        {
          name: 'Sidebar',
          transition: 'none',
          classes: 'modalSidebar',
          width: '210px',
          height: '100%',
          maxHeight: 1024,
          shiftX: 0,
          shiftY: 0,
          adaptive: true
        }
      )
    },
    setSelectionActiveness(isActive) {
      this.$store.commit(`archive/${this.type}/${this.group}/setSelectionActive`, isActive)
    },
    toggleSelectAll() {
      this.$store.commit(`archive/${this.type}/${this.group}/toggleSelectAll`)
    },
    async restoreSelected() {
      await this.$store.dispatch(`archive/${this.type}/${this.group}/restoreSelected`)
    },
    async deleteSelected() {
      await this.$store.dispatch(`archive/${this.type}/${this.group}/deleteSelected`)
    },
  },
  watch: {
    async search() {
      if (this.search) {
        this.searchObject(this)
      } else if (this.search === '') {
        this.search = null
        this.searchObject(this)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import 'style.scss';
</style>
