import axios from 'axios'

export default {
  state: {
    settlements: [],
    streets: [],
    users: [],
    error: null,
    constants: null
  },
  mutations: {
    setError(state, error) {
      state.error = error.response
    },
    setSettlements(state, settlements) {
      state.settlements = settlements
    },
    setStreets(state, streets) {
      state.streets = streets
    },
    setUsers(state, users) {
      state.users = users
    },
    setError(state, error) {
      state.error = error
    },
    setConstants(state, constants) {
      state.constants = constants
    },
    clearError(state) {
      state.error = null
    }
  },
  actions: {
    async fetchSettlements({commit}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const settlements = await axios.get(url + '/settlements')
        commit('setSettlements', settlements.data.settlements)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
    async fetchStreets({commit}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const streets = await axios.get(url + '/streets')
        commit('setStreets', streets.data.streets)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
    async fetchConstants({commit}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const constants = await axios.get(url + '/constants')
        commit('setConstants', constants.data)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
    async fetchUsers({commit}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const users = await axios.get(url + '/users', {
          params: {
            perPage: 100
          }
        })
        commit('setUsers', users.data.users)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
  },
  getters: {
    error: s => s.error,
    token: s => !!s.constants,
    settlements: s => s.settlements,
    streets: s => s.streets,
    users: s => s.users,
    constants: s => s.constants
  }
}
