import apartments from './apartments'
import houses from './houses'
import commerce from './commerce'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    prices: {}
  },
  mutations: {
    setPrices(state, prices) {
      state.prices = prices
    }
  },
  actions: {
    async fetchPrices({commit, getters}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const prices = await axios.get(url + `/properties/${id}/prices-history`)
        commit('setPrices', prices.data.prices)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    }
  },
  getters: {
    prices: s => s.prices
  },
  modules: {
    apartments,
    houses,
    commerce
  }
}
