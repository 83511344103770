import objects from './objects'
import buyers from './buyers'
import renters from './renters'
import rent from './rent'

export default {
  namespaced: true,
  modules: {
    objects,
    buyers,
    renters,
    rent,
  }
}
