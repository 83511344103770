var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upload__container upload-building" }, [
    _c("div", { staticClass: "upload__left" }, [
      _c("div", { staticClass: "upload__navigation-title" }, [
        _vm._v("Категорії")
      ]),
      _c("div", { staticClass: "upload__navigation" }, [
        _c(
          "div",
          {
            staticClass: "upload__navigation-link-wrapper",
            on: {
              click: function($event) {
                return _vm.select("mainDataBox")
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "upload__navigation-link",
                class: {
                  "upload__navigation-link--active": _vm.mainDataBox,
                  "upload__navigation-link--error":
                    this.$v.sendData.$invalid && this.$v.sendData.$dirty
                }
              },
              [_vm._v(" Основне ")]
            ),
            _c("div", { staticClass: "upload__navigation-icon" })
          ]
        ),
        _c(
          "div",
          {
            staticClass: "upload__navigation-link-wrapper",
            on: {
              click: function($event) {
                return _vm.select("contactsBox")
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "upload__navigation-link",
                class: {
                  "upload__navigation-link--active": _vm.contactsBox,
                  "upload__navigation-link--error": _vm.contactsBoxError
                }
              },
              [_vm._v(" Контакти ")]
            ),
            _c("div", { staticClass: "upload__navigation-icon" })
          ]
        )
      ])
    ]),
    _c("div", { staticClass: "upload__right" }, [
      _c("div", { staticClass: "upload__top" }, [
        _c("div", { staticClass: "upload__title" }, [
          _vm.building
            ? _c("span", [
                _vm._v(
                  "Редагування Року будівлі - ID " +
                    _vm._s(_vm.building.id) +
                    " "
                )
              ])
            : _c("span", [_vm._v("Додавання Року будівлі ")])
        ]),
        _vm._m(0),
        _c("div", { staticClass: "upload__buttons" }, [
          _c(
            "div",
            {
              staticClass: "upload__button button button--fill button--grey",
              on: { click: _vm.closeModal }
            },
            [_vm._v("Скасувати")]
          ),
          _c(
            "div",
            {
              staticClass: "upload__button button button--fill",
              on: { click: _vm.send }
            },
            [_vm._v("Зберегти")]
          ),
          _c(
            "div",
            { staticClass: "button__icon", on: { click: _vm.closeModal } },
            [_c("i", { staticClass: "ri-close-circle-line" })]
          ),
          _c(
            "div",
            {
              staticClass: "button__icon button__icon--blue",
              on: { click: _vm.send }
            },
            [_c("i", { staticClass: "ri-save-3-line" })]
          )
        ])
      ]),
      _c("div", { staticClass: "upload__navigation--mobile" }, [
        _c(
          "div",
          {
            staticClass: "upload__navigation-link-wrapper",
            on: {
              click: function($event) {
                return _vm.select("mainDataBox")
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "upload__navigation-link",
                class: {
                  "upload__navigation-link--active": _vm.mainDataBox,
                  "upload__navigation-link--error":
                    this.$v.sendData.$invalid && this.$v.sendData.$dirty
                }
              },
              [_vm._v(" Основне ")]
            ),
            _c("div", { staticClass: "upload__navigation-icon" })
          ]
        ),
        _c(
          "div",
          {
            staticClass: "upload__navigation-link-wrapper",
            on: {
              click: function($event) {
                return _vm.select("contactsBox")
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "upload__navigation-link",
                class: {
                  "upload__navigation-link--active": _vm.contactsBox,
                  "upload__navigation-link--error": _vm.contactsBoxError
                }
              },
              [_vm._v(" Контакти ")]
            ),
            _c("div", { staticClass: "upload__navigation-icon" })
          ]
        )
      ]),
      _c("div", { staticClass: "upload__main" }, [
        _vm.mainDataBox
          ? _c("div", { staticClass: "data__inner data_main" }, [
              _c("div", { staticClass: "data__top" }, [
                _c(
                  "div",
                  {
                    staticClass: "field__label",
                    class: {
                      "field__label--filled": _vm.sendData.street,
                      "field__label--invalid":
                        _vm.$v.sendData.street.$dirty &&
                        !_vm.$v.sendData.street.required
                    }
                  },
                  [
                    _c("div", { staticClass: "field__title" }, [
                      _vm._v("Вулиця")
                    ]),
                    _c(
                      "Multiselect",
                      {
                        staticClass: "mSelect",
                        attrs: {
                          options: _vm.streets,
                          showLabels: false,
                          placeholder: "Вулиця"
                        },
                        model: {
                          value: _vm.sendData.street,
                          callback: function($$v) {
                            _vm.$set(_vm.sendData, "street", $$v)
                          },
                          expression: "sendData.street"
                        }
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "noResult" }, slot: "noResult" },
                          [_vm._v("Отакої, вулиці немає :(")]
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "data__middle" }, [
                _c(
                  "div",
                  {
                    staticClass: "field__label",
                    class: {
                      "field__label--filled": _vm.sendData.buildingNo,
                      "field__label--invalid":
                        _vm.$v.sendData.buildingNo.$dirty &&
                        !_vm.$v.sendData.buildingNo.required
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "field__title field__title--required" },
                      [_vm._v("№ буд.")]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.sendData.buildingNo,
                          expression: "sendData.buildingNo"
                        }
                      ],
                      staticClass: "field__input",
                      domProps: { value: _vm.sendData.buildingNo },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.sendData,
                            "buildingNo",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "field__label",
                    class: {
                      "field__label--filled": _vm.sendData.floorsNumber,
                      "field__label--invalid":
                        _vm.$v.sendData.floorsNumber.$dirty &&
                        !_vm.$v.sendData.floorsNumber.required
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "field__title field__title--required" },
                      [_vm._v("Поверхів")]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.sendData.floorsNumber,
                          expression: "sendData.floorsNumber"
                        }
                      ],
                      staticClass: "field__input",
                      domProps: { value: _vm.sendData.floorsNumber },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.sendData,
                            "floorsNumber",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "field__label",
                    class: {
                      "field__label--filled": _vm.sendData.year
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "field__title field__title--required" },
                      [_vm._v("Рік")]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.sendData.year,
                          expression: "sendData.year"
                        }
                      ],
                      staticClass: "field__input",
                      domProps: { value: _vm.sendData.year },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.sendData, "year", $event.target.value)
                        }
                      }
                    })
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "field__label",
                    class: {
                      "field__label--filled": _vm.sendData.emergency
                    }
                  },
                  [
                    _c("div", { staticClass: "field__title" }, [
                      _vm._v("Аварійність")
                    ]),
                    _c("Multiselect", {
                      staticClass: "mSelect",
                      attrs: {
                        options: _vm.localConstants.YES_NO,
                        label: "name",
                        trackBy: "id",
                        showLabels: false,
                        searchable: false,
                        placeholder: "Аварійність"
                      },
                      model: {
                        value: _vm.sendData.emergency,
                        callback: function($$v) {
                          _vm.$set(_vm.sendData, "emergency", $$v)
                        },
                        expression: "sendData.emergency"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "field__label",
                    class: {
                      "field__label--filled": _vm.sendData.cooperativeType,
                      "field__label--invalid":
                        _vm.$v.sendData.cooperativeType.$dirty &&
                        !_vm.$v.sendData.cooperativeType.required
                    }
                  },
                  [
                    _c("div", { staticClass: "field__title" }, [
                      _vm._v("ОСББ/Управління")
                    ]),
                    _c("Multiselect", {
                      staticClass: "mSelect",
                      attrs: {
                        options: _vm.constants.COOPERATIVE_TYPES,
                        label: "name",
                        trackBy: "id",
                        showLabels: false,
                        searchable: false,
                        placeholder: "ОСББ/Управління"
                      },
                      model: {
                        value: _vm.sendData.cooperativeType,
                        callback: function($$v) {
                          _vm.$set(_vm.sendData, "cooperativeType", $$v)
                        },
                        expression: "sendData.cooperativeType"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "field__label",
                    class: {
                      "field__label--filled": _vm.sendData.cooperativeName,
                      "field__label--invalid":
                        _vm.$v.sendData.cooperativeName.$dirty &&
                        !_vm.$v.sendData.cooperativeName.required
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "field__title field__title--required" },
                      [_vm._v("Назва ОСББ чи Управління")]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.sendData.cooperativeName,
                          expression: "sendData.cooperativeName"
                        }
                      ],
                      staticClass: "field__input",
                      domProps: { value: _vm.sendData.cooperativeName },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.sendData,
                            "cooperativeName",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]
                )
              ]),
              _c("div", { staticClass: "data__bottom" }, [
                _c(
                  "label",
                  {
                    staticClass: "field__label",
                    class: {
                      "field__label--filled": _vm.sendData.comment
                    }
                  },
                  [
                    _c("div", { staticClass: "field__title" }, [
                      _vm._v("Коментар")
                    ]),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.sendData.comment,
                          expression: "sendData.comment"
                        }
                      ],
                      staticClass: "field__text",
                      domProps: { value: _vm.sendData.comment },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.sendData, "comment", $event.target.value)
                        }
                      }
                    })
                  ]
                )
              ])
            ])
          : _vm._e(),
        _vm.contactsBox
          ? _c("div", { staticClass: "data__inner data_contacts" }, [
              _c(
                "article",
                { staticClass: "data_contacts__top" },
                [
                  _c("ContactSearch", {
                    attrs: {
                      selectedContacts: _vm.selectedContacts,
                      title: "Виберіть контакт",
                      placeholder: "Введіть телефон або імʼя",
                      permittedRoles: _vm.permittedRoles
                    },
                    on: {
                      sendContacts: function(contacts) {
                        return (_vm.selectedContacts = contacts)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "article",
                { staticClass: "data_contacts__bottom" },
                [
                  _c("Contact", {
                    attrs: {
                      newContacts: _vm.newContacts,
                      visibleBox: _vm.visibleBox,
                      permittedRoles: _vm.permittedRoles
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "upload__icons" }, [
      _c("i", { staticClass: "ri-user-search-line" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }