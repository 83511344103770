<template>
  <div class="filters__inner">
    <div class="filters__top">
      <div class="filters__title">
        <span class="filters__title-span">Фільтри</span>
      </div>
      <div class="filters__navigation">
        <div class="button__icon" @click="reset">
          <i class="ri-filter-off-fill"></i>
        </div>
        <div
          class="button__icon filters__button--blue"
          @click="send"
        >
          <i class="ri-filter-fill"></i>
          <!-- {{paramsActive}} -->
        </div>
      </div>
    </div>
    <div class="filters__main">
      <div class="filters__main-part filters__main-part--active">
        <div v-if="isAdmin" class="part__main">
          <label class="field__label">
            <div class="field__title">Тип</div>
            <Multiselect
              :options="type"
              v-model="params.type"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              placeholder="Тип контакту"
            />
          </label>
        </div>
        <div v-if="isAdmin" class="part__main">
          <label class="field__label">
            <div class="field__title">Вид</div>
            <Multiselect
              :options="propType"
              v-model="params.propType"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              placeholder="Вид контакту"
            />
          </label>
        </div>
        <div class="part__main">
          <label class="field__label">
            <div class="field__title">Роль</div>
            <Multiselect
              :options="roles"
              v-model="params.role"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              placeholder="Роль"
            />
          </label>
        </div>
        <div class="part__main">
          <div class="field__label">
            <div class="field__title">Компанія</div>
            <CompanySearch
              :selected="params.company"
              @select="(company) => params.company = company"
            />
          </div>
        </div>
        <div class="part__main">
          <label class="field__label">
            <div class="field__title">Посада</div>
            <Multiselect
              :options="constants.CONTACT_JOB_POSITIONS"
              v-model="params.jobPosition"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              placeholder="Посада"
            />
          </label>
        </div>
        <div class="part__main">
          <label class="field__label">
            <div class="field__title">Актуальність</div>
            <Multiselect
              :options="check"
              v-model="params.isArchived"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              placeholder="Актуальність"
            />
          </label>
        </div>
        <div v-if="isAdmin" class="part__main">
          <label class="field__label">
            <div class="field__title">Золотий клієнт</div>
            <Multiselect
              :options="constants.GOLDEN_CONTACT_TYPES"
              v-model="params.goldenType"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              placeholder="Золотий клієнт"
            />
          </label>
        </div>
        <div class="part__main">
          <div class="field__title">Дата народження</div>
          <date-picker
            v-model="params.birthday"
            is-range
            isDark
            color="blue"
            :popover="{ visibility: 'focus'}"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <div class="datePicker__fields">
                <input class="field__input" :value="inputValue.start" v-on="inputEvents.start" />
                <input :value="inputValue.end" v-on="inputEvents.end" class="field__input" />
              </div>
            </template>
          </date-picker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Multiselect from 'vue-multiselect'
import _ from 'lodash'
import CompanySearch from '@/components/CompanySearch'
import localConstants from '@/utils/localConstants'

export default {
  name: 'FilterContacts',
  components: {
    DatePicker,
    Multiselect,
    CompanySearch,
  },
  data: () => ({
    localConstants,
    params: {},
    constants: {},
    type: [
      {id: 'buyers', name: 'Покупець'},
      {id: 'properties', name: "Об'єкт"},
      {id: 'rent-properties', name: "Орендодавець"},
      {id: 'renters', name: "Орендар"},
    ],
    propType: [
      {id: 'apartment', name: 'Квартира'},
      {id: 'house', name: 'Будинок'},
      {id: 'commerce', name: 'Комерція'},
    ],
    check: [
      {name: 'Актуально', id: false},
      {name: 'Видалено', id: true},
    ],
  }),
  created() {
    if (!_.isEmpty(this.$store.getters['contacts/filterParamsLocal'])) {
      this.params = _.cloneDeep(this.$store.getters['contacts/filterParamsLocal'])
    } else {
      this.params = _.cloneDeep(this.$store.getters['contacts/filterParamsOriginal'])
    }
    this.constants = this.$store.getters.constants
  },
  methods: {
    async send() {
      const p = this.params
      const params = {
        'filter[contactType]': p.type?.id,
        'filter[propertyType]': p.propType?.id,
        'filter[role]': p.role?.id,
        'filter[jobPosition]': p.jobPosition?.id,
        'filter[isArchived]': p.isArchived?.id,
        'filter[goldenType]': p.goldenType?.id,
        'filter[companyId]': p.company?.id,
        'filter[birthday][from]': p.birthday.start ? p.birthday.start.format() : null,
        'filter[birthday][till]': p.birthday.end ? p.birthday.end.format() : null,
      }
      this.$store.commit('contacts/filterParamsActive', this.paramsActive)
      this.$store.commit('contacts/filterParamsSend', params)
      this.$store.commit('contacts/filterParamsLocal', p)

      this.$store.commit('contacts/reset')
      await this.$store.dispatch('contacts/fetch')
      this.$modal.hide('FilterContacts')
    },
    reset() {
      this.params = _.cloneDeep(this.$store.getters['contacts/filterParamsOriginal'])
    },
    
  },
  computed: {
    paramsActive() {
      let params = 0
      for(const p in this.params) {
        if (!_.isEmpty(this.params[p])) {
          params++
        }
      }
      return params
    },
    isAdmin() {
      return this.$store.getters.user.role === 4
    },
    roles() {
      const { CLIENT, CANDIDATE, SEEKER, BUILDING_YEAR } = this.localConstants.CONTACT_ROLES
      const forbiddenRoles = [CLIENT, CANDIDATE, SEEKER, BUILDING_YEAR]
      return this.isAdmin
             ? this.constants.CONTACT_ROLES 
             : this.constants.CONTACT_ROLES.filter(role => !forbiddenRoles.some(x => x === role.id))
    }
  },
}
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>
