var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "info__inner" }, [
    _c("div", { staticClass: "info__header" }, [
      _c("div", { staticClass: "info__nav" }, [
        _c(
          "div",
          {
            staticClass: "nav__link",
            class: { "nav__link--active": _vm.block === "main" },
            on: {
              click: function($event) {
                _vm.block = "main"
              }
            }
          },
          [_vm._v(" Основна ")]
        ),
        _c(
          "div",
          {
            staticClass: "nav__link",
            class: { "nav__link--active": _vm.block === "contacts" },
            on: {
              click: function($event) {
                _vm.block = "contacts"
              }
            }
          },
          [_vm._v(" Контакти ")]
        )
      ]),
      _c("div", { staticClass: "info__buttons" }, [
        _c("div", { staticClass: "info__buttons-right" }, [
          _vm.user.role === _vm.localConstants.USER_ROLES.ADMIN
            ? _c(
                "div",
                {
                  staticClass: "card__button button__icon button__icon--border",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c("Multiselect", {
                    staticClass: "mSelect-dropdown",
                    attrs: {
                      options: _vm.list,
                      searchable: false,
                      "show-labels": false,
                      "reset-after": true,
                      value: _vm.selectedMenuItem,
                      placeholder: "",
                      "open-direction": "bottom"
                    },
                    on: { select: _vm.actions }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "button__icon button--onmobile",
              on: {
                click: function($event) {
                  return _vm.$modal.hide("InfoBuilding")
                }
              }
            },
            [_c("i", { staticClass: "ri-close-fill" })]
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "info__content" }, [
      _vm.block === "main"
        ? _c("div", { staticClass: "info__main" }, [
            _c("div", { staticClass: "main__header" }, [
              _c("div", { staticClass: "main__header-bottom" }, [
                _c("div", { staticClass: "main__id" }, [
                  _vm._v("ID " + _vm._s(_vm.building.id))
                ]),
                _c("div", { staticClass: "main__date" }, [
                  _c("i", { staticClass: "i-calendar" }),
                  _vm._v(" " + _vm._s(_vm._f("date")(_vm.building.createdAt)))
                ])
              ])
            ]),
            _c("div", { staticClass: "main__content" }, [
              _c("div", { staticClass: "main__row main__row--dark" }, [
                _vm._m(0),
                _c("div", { staticClass: "main__row-right" }, [
                  _c("div", { staticClass: "main__row-letter" }, [
                    _c("div", [_vm._v(_vm._s(_vm.building.authorName[0]))])
                  ]),
                  _c("div", { staticClass: "main__text" }, [
                    _vm._v(_vm._s(_vm.building.authorName))
                  ])
                ])
              ]),
              _c("div", { staticClass: "main__row main__row--dark" }, [
                _c("div", { staticClass: "main__text" }, [
                  _vm._v("ОСББ/Управління")
                ]),
                _c("div", { staticClass: "main__text" }, [
                  _vm._v(_vm._s(_vm.building.cooperativeTypeName))
                ])
              ]),
              _c("div", { staticClass: "main__row main__row--dark" }, [
                _c("div", { staticClass: "main__text" }, [_vm._v("Назва")]),
                _c("div", { staticClass: "main__text" }, [
                  _vm._v('"' + _vm._s(_vm.building.cooperativeName) + '"')
                ])
              ]),
              _c("div", { staticClass: "main__row main__row--dark" }, [
                _c("div", { staticClass: "main__text" }, [
                  _vm._v("Останній дзвінок")
                ]),
                _c("div", { staticClass: "main__text" }, [
                  _c("i", { staticClass: "i-calendar" }),
                  _vm.building.lastCallAt
                    ? _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm
                              .moment(_vm.building.lastCallAt)
                              .format("DD.MM.YYYY")
                          )
                        )
                      ])
                    : _c("div", [_vm._v("-")])
                ])
              ]),
              _c("div", { staticClass: "main__row" }, [
                _c("div", { staticClass: "main__text" }, [_vm._v("Вулиця")]),
                _c("div", { staticClass: "main__text" }, [
                  _vm._v(_vm._s(_vm.building.street))
                ])
              ]),
              _c("div", { staticClass: "main__row" }, [
                _c("div", { staticClass: "main__text" }, [_vm._v("№ буд.")]),
                _c("div", { staticClass: "main__text" }, [
                  _vm._v(_vm._s(_vm.building.buildingNo))
                ])
              ]),
              _c("div", { staticClass: "main__row" }, [
                _c("div", { staticClass: "main__text" }, [_vm._v("Поверхів")]),
                _c("div", { staticClass: "main__text" }, [
                  _vm._v(_vm._s(_vm.building.floorsNumber))
                ])
              ]),
              _c("div", { staticClass: "main__row" }, [
                _c("div", { staticClass: "main__text" }, [_vm._v("Рік")]),
                _c("div", { staticClass: "main__text" }, [
                  _vm._v(_vm._s(_vm.building.year ? _vm.building.year : "-"))
                ])
              ]),
              _c("div", { staticClass: "main__row" }, [
                _c("div", { staticClass: "main__text" }, [
                  _vm._v("Аварійність")
                ]),
                _c("div", { staticClass: "main__text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.building.emergency != null
                        ? _vm.building.emergency
                          ? "Так"
                          : "Ні"
                        : "-"
                    )
                  )
                ])
              ]),
              _c("div", { staticClass: "main__row main__row--area" }, [
                _c("div", { staticClass: "main__text" }, [_vm._v("Коментар")]),
                _c("div", { staticClass: "main__text" }, [
                  _c("span", { staticClass: "card__span" }, [
                    _vm._v(_vm._s(_vm.building.comment))
                  ])
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.block === "contacts"
        ? _c(
            "div",
            { staticClass: "info__contacts" },
            [
              _c(
                "button",
                {
                  staticClass: "contacts__button button button--fill",
                  on: { click: _vm.changeLastCall }
                },
                [_vm._v("Зберегти дзвінок")]
              ),
              _vm._l(_vm.building.contacts, function(contact) {
                return _c(
                  "div",
                  { key: contact.id, staticClass: "contacts__item" },
                  [
                    _c("div", { staticClass: "contacts__header" }, [
                      _c("div", { staticClass: "contacts__letter" }, [
                        _vm._v(_vm._s(contact.name[0]))
                      ]),
                      _c("div", { staticClass: "contacts__name" }, [
                        _vm._v(_vm._s(contact.name))
                      ]),
                      _c("div", { staticClass: "contacts__role" }, [
                        _vm._v(
                          "Роль: " +
                            _vm._s(
                              contact.roles
                                .map(function(x) {
                                  return x.name
                                })
                                .join(" / ")
                            )
                        )
                      ]),
                      _c("div", { staticClass: "contacts__date" }, [
                        _vm._v("Дата народження: " + _vm._s(contact.birthday))
                      ]),
                      contact.company
                        ? _c("div", { staticClass: "contacts__company" }, [
                            _vm._v(
                              ' Компанія: "' +
                                _vm._s(contact.company.name) +
                                '" '
                            ),
                            contact.jobPosition
                              ? _c("span", [
                                  _vm._v(
                                    "(" + _vm._s(contact.jobPosition.name) + ")"
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]),
                    _vm._l(contact.phones, function(phone) {
                      return _c(
                        "div",
                        { key: phone.id, staticClass: "contacts__phones" },
                        [
                          _c(
                            "div",
                            { staticClass: "contacts__phone-wrapper" },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "tel:" + phone.phone },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "ri-phone-fill" })]
                              ),
                              _c("div", { staticClass: "contacts__phone" }, [
                                _vm._v(_vm._s(phone.phone))
                              ]),
                              _c(
                                "div",
                                { staticClass: "contacts__messengers" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "button__circle",
                                      class: {
                                        "button__circle--blue": phone.isOnViber
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.sendMessage(
                                            Object.assign({}, phone, {
                                              message: "viber"
                                            })
                                          )
                                        }
                                      }
                                    },
                                    [_c("i", { staticClass: "i-viber" })]
                                  ),
                                  _c("i", {
                                    staticClass: "i-telegram button__circle",
                                    class: {
                                      "button__circle--blue": phone.isOnTelegram
                                    }
                                  }),
                                  _c("i", {
                                    staticClass: "i-whatsapp button__circle",
                                    class: {
                                      "button__circle--blue": phone.isOnWhatsapp
                                    }
                                  }),
                                  _c("i", {
                                    staticClass: "i-messanger button__circle",
                                    class: {
                                      "button__circle--blue": phone.isOnFacebook
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    _c(
                      "div",
                      { staticClass: "contacts__rel" },
                      _vm._l(contact.relations, function(rel, i) {
                        return _c("Relation", {
                          key: i,
                          attrs: { relation: rel }
                        })
                      }),
                      1
                    )
                  ],
                  2
                )
              })
            ],
            2
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main__row-left" }, [
      _c("i", { staticClass: "i-employees" }),
      _c("div", { staticClass: "main__text" }, [_vm._v("Автор")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }