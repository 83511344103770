<template>
  <div class="modal__wrapper">
    <div class="modal__top">
      <div class="button__icon button--onmobile" @click="$modal.hide('CompanyUpload')">
        <i class="ri-close-line"></i>
      </div>
    </div>
    <div class="modal__middle">
      <div
        class="field field__label"
        :class="{
          'field__label--filled': name,
          'field__label--invalid': $v.name.$dirty && !$v.name.required
        }"
      >
        <div class="field__title field__title--required">Назва компанії</div>
        <input class="field__input" v-model="name" placeholder="Назва компанії" />
      </div>
      <div
        class="field field__label"
        :class="{
          'field__label--filled': type,
          'field__label--invalid': $v.type.$dirty && !$v.type.required
        }"
      >
        <div class="field__title field__title--required">Тип компанії</div>
        <Multiselect
          :options="constants.COMPANY_TYPES"
          v-model="type"
          label="name"
          trackBy="id"
          :showLabels="false"
          :searchable="false"
          placeholder="Тип компанії"
        />
      </div>
      <button class="button button--fill" @click="send">Створити</button>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import {required} from 'vuelidate/lib/validators'
import localConstants from '@/utils/localConstants'

export default {
  name: 'CompanyUpload',
  components: {
    Multiselect
  },
  data: () => ({
    localConstants,
    constants: null,
    name: null,
    type: null
  }),
  async created() {
    this.constants = this.$store.getters.constants
  },
  methods: {
    async send() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      const payload = {
        name: this.name,
        type: this.type.id
      }

      await this.$store.dispatch('companies/create', payload)
      this.$modal.hide('CompanyUpload')
    }
  },
  validations: {
    name: {required},
    type: {required}
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
