import objects from './objects/stats'
import buyers from './buyers/stats'
import actions from './actions/stats'
import seekers from './seekers/stats'
import rent from './rent/stats'
import renters from './renters/stats'

export default {
  namespaced: true,
  modules: {
    objects,
    buyers,
    actions,
    seekers,
    rent,
    renters,
  }
}
