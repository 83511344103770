<template>
  <div class="archive-layout">
    <NavbarArchive />
    <Sidebar />
    <div class="page__wrapper">
        <router-view></router-view>
    </div>
  </div>
</template>

<script>
import NavbarArchive from "@/views/Archive/Navbar";
import Sidebar from "@/components/Sidebar";

export default {
  name: "ArchiveLayout",
  components: {
    NavbarArchive,
    Sidebar,
  },
  computed: {
    type() {
      return this.$route.meta.type
    }
  },
  watch: {
    error(error) {
      const data = error.response?.data ?? error
      this.$error(data?.message ?? data?.error)
    }
  },
  created() {
    window.scrollTo(0, 0)
    if (!this.$store.getters.constants) {
      this.$store.dispatch('fetchConstants')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

@include tablet {
  .page__wrapper {
    top: 160px;
  }
}
</style>
