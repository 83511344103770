<template>
  <div class="modal__wrapper">
    <div class="modal__top">
      <div class="button__icon button--onmobile" @click="$modal.hide('ModalArchive')">
        <i class="ri-close-line"></i>
      </div>
    </div>
    <div class="modal__middle">
      <div
        class="field__label"
        :class="{
          'field__label--filled': reasons,
          'field__label--invalid': $v.reasons.$dirty && !$v.reasons.required
        }"
      >
        <div class="field__title field__title--required">Причина</div>
        <Multiselect
          :options="archivedReasons"
          v-model="reasons"
          label="name"  
          trackBy="id"
          :allowEmpty="false"
          :showLabels="false"
          :searchable="false"
          :maxHeight="250"
          class="mSelect"
          placeholder="Причина"
        />
      </div>
      <label
        class="field__label"
        v-if="reasons.id === 1 || reasons.id === 2 || reasons.id === 3"
        :class="{
          'field__label--filled': soldBy,
          'field__label--invalid': $v.soldBy.$dirty && !$v.soldBy.required
        }"
      >
        <div class="field__title">Ким реалізовано</div>
        <Multiselect
          :options="constants.SOLD_BYS"
          v-model="soldBy"
          label="name"
          trackBy="id"
          :allowEmpty="false"
          :showLabels="false"
          :searchable="false"
          placeholder
          class="mSelect"
        />
      </label>
      <div
        v-if="reasons.id === 1 || reasons.id === 5"
        class="field__label"
        :class="{
          'field__label--filled': date,
          'field__label--invalid': $v.date.$dirty && !$v.date.required
        }"
      >
        <div class="field__title">Видалено до</div>
        <date-picker v-model="date" isDark color="blue" :popover="{visibility: 'focus', placement: 'top-start'}">
          <template v-slot="{inputValue, inputEvents}">
            <input class="field__input" :value="inputValue" v-on="inputEvents" readonly />
          </template>
        </date-picker>
      </div>
      <button class="button button--fill" @click="send">{{ isArchiveAction ? 'Перемістити в архів' : 'Зберегти' }}</button>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import {required, requiredIf} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'
import moment from 'moment'

export default {
  name: 'ModalArchive',
  props: {
    info: { default: undefined },
    isBulk: { default: false },
    entity: { default: 'objects' }, // 'objects' | 'rent' | 'buyers' | 'renters'
    action: { default: 'archive' }, // 'archive' | 'update'
  },
  components: {
    Multiselect,
    DatePicker
  },
  data: () => ({
    reasons: '',
    soldBy: '',
    date: null,
    users: [],
    price: '',
  }),
  computed: {
    ...mapGetters(['constants']),
    isArchiveAction() {
      return this.action === 'archive'
    },
    isUpdateAction() {
      return this.action === 'update'
    },
    type() {
      let type
      if (this.entity === 'objects' || this.entity === 'rent')
        type = this.info.type
      else if (this.entity === 'buyers' || this.entity === 'renters')
        type = this.info.lookingFor
      else throw new Error('Unknown type')

      switch(type) {
        case 'apartment':
          return 'apartments'
        case 'house':
          return 'houses'
        case 'commerce':
          return 'commerce'
        default:
          throw new Error('Unknown type')
      }
    },
    archivedReasons() {
      switch (this.entity) {
        case 'objects':
          return this.constants.ARCHIVED_REASONS
        case 'buyers':
          return this.constants.ARCHIVED_REASONS.filter(reason => reason.id !== 3 && reason.id !== 7)
        case 'rent':
          return this.constants.RENT_ARCHIVED_REASONS.filter(reason => reason.id !== 6)
        case 'renters':
          return this.constants.RENT_ARCHIVED_REASONS.filter(reason => reason.id !== 8)
        default:
          throw new Error('Unknown type')
      }
    }
  },
  async created() {
    if (this.isUpdateAction) {
      this.reasons = this.archivedReasons.find(r => r.id === this.info.archivedReason)
      this.date = moment(this.info.archivedTill).toDate()
      this.soldBy = this.constants.SOLD_BYS.find(s => s.id === this.info.soldBy)
    }

    await this.$store.dispatch('fetchUsers')
    this.users = this.$store.getters.users
  },
  methods: {
    async send() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      try {
        const { action, payload } = this.isArchiveAction ? this.getArchiveData() : this.getUpdateData()
        await this.$store.dispatch(action, payload)

        this.$modal.hide('ModalArchive')
        this.$modal.hideAll()
      } catch (err) {}
    },
    getArchiveData() {
      const data = {
        archivedReason: this.reasons.id
      }
      if (this.reasons.id === 1 || this.reasons.id === 5) {
        data.archivedTill = this.date.format()
      }
      if (this.reasons.id === 2 || this.reasons.id === 3 || this.reasons.id === 1) {
        data.soldBy = this.soldBy.id
      }

      return {
        action: `${this.entity}/${this.type}/${this.isBulk ? 'archiveSelected' : 'archive'}`,
        payload: this.isBulk ? { data } : { id: this.info.id, data }
      }
    },
    getUpdateData() {
      const data = {
        archivedReason: this.reasons.id
      }
      if (this.reasons.id === 1 || this.reasons.id === 5) {
        data.archivedTill = this.date.format()
      }
      if (this.reasons.id === 2 || this.reasons.id === 3 || this.reasons.id === 1) {
        data.soldBy = this.soldBy.id
      }

     return {
        action: `archive/${this.entity}/${this.type}/update`,
        payload: { id: this.info.id, data }
     }
    }
  },
  validations: {
    reasons: {required},
    soldBy: {
      required: requiredIf(function() {
        if (this.reasons.id === 2 || this.reasons.id === 3) {
          return true
        }
      })
    },
    date: {
      required: requiredIf(function() {
        if (this.reasons.id === 1 || this.reasons.id === 5) {
          return true
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
