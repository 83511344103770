import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    apartments: [],
    apartmentsPagination: {},
    apartmentsCurrentPage: 1,

    houses: [],
    housesPagination: {},
    housesCurrentPage: 1,

    commerce: [],
    commercePagination: {},
    commerceCurrentPage: 1
  },
  mutations: {
    set(state, {objects, type}) {
      if (type === 'apartment') {
        state.apartments = _.unionBy(state.apartments, objects, 'id')
      } else if (type === 'house') {
        state.houses = _.unionBy(state.houses, objects, 'id')
      } else if (type === 'commerce') {
        state.commerce = _.unionBy(state.commerce, objects, 'id')
      }
    },
    reset(state, type) {
      if (type === 'apartment') {
        state.apartments = []
        state.apartmentsCurrentPage = 1
      } else if (type === 'house') {
        state.houses = []
        state.housesCurrentPage = 1
      } else if (type === 'commerce') {
        state.commerce = []
        state.commerceCurrentPage = 1
      }
    },
    setPagination(state, {pagination, type}) {
      if (type === 'apartment') {
        state.apartmentsPagination = pagination;
      } else if (type === 'house') {
        state.housesPagination = pagination;
      } else if (type === 'commerce') {
        state.commercePagination = pagination;
      }
    },
    changePage(state, type) {
      if (type === 'apartment') {
        state.apartmentsCurrentPage++;
      } else if (type === 'house') {
        state.housesCurrentPage++;
      } else if (type === 'commerce') {
        state.commerceCurrentPage++;
      }
    }
  },
  actions: {
    async fetch({commit, getters}, type) {
      let page, way
      if (type === 'apartment') {
        page = getters.apartmentsCurrentPage
        way = 'apartments'
      } else if (type === 'house') {
        page = getters.housesCurrentPage
        way = 'houses'
      } else if (type === 'commerce') {
        page = getters.commerceCurrentPage
        way = 'commerce'
      }

      try {
        const url = process.env.VUE_APP_BACKEND
        const response = await axios.get(url + '/rent/properties/' + way + '/favorites', {
          params: {
            page,
            ...getters.filterParams,
            ...getters.sortParams
          }
        })
        commit('setPagination', {pagination: response.data.pagination, type})
        commit('set', {objects: response.data.favorites, type})
        commit('changePage', type)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    }
  },
  getters: {
    apartments: s => s.apartments,
    apartmentsPagination: s => s.apartmentsPagination,
    apartmentsCurrentPage: s => s.apartmentsCurrentPage,

    houses: s => s.houses,
    housesPagination: s => s.housesPagination,
    housesCurrentPage: s => s.housesCurrentPage,

    commerce: s => s.commerce,
    commercePagination: s => s.commercePagination,
    commerceCurrentPage: s => s.commerceCurrentPage
  }
}
