<template>
  <div class="filters__inner">
    <div class="filters__top">
      <div class="filters__title">
        <span class="filters__title-span">Фільтри</span>
      </div>
      <div class="filters__navigation">
        <div class="button__icon" @click="reset">
          <i class="ri-filter-off-fill"></i>
        </div>
        <div class="button__icon filters__button--blue" @click="send">
          <i class="ri-filter-fill"></i>
        </div>
      </div>
    </div>
    <div class="filters__main">
      <div class="filters__main-part" :class="{'filters__main-part--active': filtersShow.agent}">
        <div class="part__title" @click="filtersShow.agent = !filtersShow.agent">
          <span class="part__title-text" :class="{'text-blue': filterActive.agent}">Відповідальний агент</span>
          <span class="part__title-icon">
            <i class="i-chevron"></i>
          </span>
        </div>
        <div class="part__main">
          <div class="field__label">
            <Multiselect
              :options="users"
              v-model="params.agent"
              label="fullName"
              trackBy="id"
              :multiple="true"
              :showLabels="false"
              :searchable="false"
              :closeOnSelect="false"
              placeholder="Відповідальний агент"
            >
            </Multiselect>
          </div>
        </div>
      </div>

      <div class="filters__main-part" :class="{'filters__main-part--active': filtersShow.type}">
        <div class="part__title" @click="filtersShow.type = !filtersShow.type">
          <span class="part__title-text" :class="{'text-blue': filterActive.type}">Звідки</span>
          <span class="part__title-icon">
            <i class="i-chevron"></i>
          </span>
        </div>
        <div class="part__main">
          <div class="field__label">
            <Multiselect
              :options="constants.SEEKER_TYPES"
              v-model="params.type"
              label="name"
              trackBy="id"
              :multiple="true"
              :showLabels="false"
              :searchable="false"
              placeholder="Звідки"
            >
            </Multiselect>
          </div>
        </div>

        <div class="part__main">
          <div class="field__label">
            <Multiselect :options="streets" v-model="params.street" :showLabels="false" placeholder="Вулиця">
              <span slot="noResult">Отакої, вулиці немає :(</span>
            </Multiselect>
          </div>
        </div>

        <div class="part__main">
          <div class="field__label">
            <Multiselect
              :options="constants.SEEKER_ACTIVITIES"
              v-model="params.activity"
              :showLabels="false"
              :searchable="false"
              placeholder="Діяльність"
              class="mSelect"
            />
          </div>
        </div>
      </div>

      <div class="filters__main-part" :class="{'filters__main-part--active': filtersShow.date}">
        <div class="part__title" @click="filtersShow.date = !filtersShow.date">
          <span class="part__title-text" :class="{'text-blue': filterActive.date}">Дата внесення</span>
          <span class="part__title-icon">
            <i class="i-chevron"></i>
          </span>
        </div>
        <div class="part__main">
          <div class="field__title">Дата створення</div>
          <date-picker v-model="params.createdAt" is-range isDark color="blue" :popover="{visibility: 'focus'}">
            <template v-slot="{inputValue, inputEvents}">
              <div class="datePicker__fields">
                <input class="field__input" :value="inputValue.start" v-on="inputEvents.start" />
                <input :value="inputValue.end" v-on="inputEvents.end" class="field__input" />
              </div>
            </template>
          </date-picker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import _ from 'lodash'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Multiselect from 'vue-multiselect'
import localConstants from '@/utils/localConstants'

export default {
  name: 'FilterSeekers',
  components: {
    DatePicker,
    Multiselect
  },
  data: () => ({
    localConstants,
    params: {
      agent: [],
      type: [],
      street: null,
      activity: null,
      createdAt: null
    },
    filtersShow: {
      agent: false,
      type: false,
      date: false
    },
    filterActive: {
      agent: false,
      type: false,
      date: false,
    }
  }),
  computed: {
    ...mapGetters({
      constants: 'constants',
      streets: 'streets',
      user: 'user',
      users: 'users/users',
      filterParamsOriginal: 'seekers/filterParamsOriginal',
      filterParamsLocal: 'seekers/filterParamsLocal',
      filterParamsSend: 'seekers/filterParamsSend',
      filterParamsActive: 'seekers/filterParamsActive',
    }),
    activeParams() {
      let params = 0
      for (const p in this.params) {
        if (!_.isEmpty(this.params[p])) {
          params++
        }
      }
      return params
    }
  },
  watch: {
    params: {
      deep: true,
      handler(p) {
        this.filterActive.agent = Boolean(p.agent.length)
        this.filterActive.type = Boolean(p.type.length)|| Boolean(p.street) || Boolean(p.activity)
        this.filterActive.date = Boolean(p.createdAt)
      }
    }
  },
  methods: {
    async send() {
      const params = this.params
      const paramsToSend = {
        'filter[responsibleId]': params.agent ? params.agent.map(agent => agent.id) : null, 
        'filter[type]': params.type ? params.type.map(type => type.id) : null,
        'filter[street]': params.street ? params.street : null,
        'filter[activity]': params.activity ? params.activity : null,
        'filter[createdAt][from]': params.createdAt ? params.createdAt.start.format() : null,
        'filter[createdAt][till]': params.createdAt ? params.createdAt.end.format() : null,
      }
      this.$store.commit('seekers/filterParamsLocal', params)
      this.$store.commit('seekers/filterParamsSend', paramsToSend)
      this.$store.commit('seekers/filterParamsActive', this.activeParams)

      this.$store.commit('seekers/reset')
      await this.$store.dispatch('seekers/fetch')
      this.$modal.hide('FilterSeekers')
    },
    reset() {
      this.params = _.cloneDeep(this.filterParamsOriginal)
      for (const f in this.filtersShow) {
        this.filtersShow[f] = false
      }
    }
  },
  async created() {
    if (Object.keys(this.filterParamsLocal).length) {
      this.params = _.cloneDeep(this.filterParamsLocal)
    } else {
      this.params = _.cloneDeep(this.filterParamsOriginal)
      if (!this.params.agent.length) {
        this.params.agent.push(this.user)
      }
    }

    if (Object.keys(this.user).length === 0) {
      await this.$store.dispatch('getUser')
    }

    await this.$store.dispatch('users/fetch')
    await this.$store.dispatch('fetchStreets')
  }
}
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>
