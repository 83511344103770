import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    contacts: [],
    foundContacts: [],
    relation: {},
    contact: {},
    pagination: {},
    page: 1,
    // FILTER PARAMS
    searchParams: null, // to contacts page search
    filterSearchParams: null, // to search contacts in components
    filterParamsLocal: {},
    filterParamsSend: {},
    filterParamsActive: 0,
    filterParamsOriginal: {
      type: null,
      propType: null,
      company: null,
      isArchived: null,
      birthday: {}
    }
  },
  mutations: {
    set(state, contacts) {
      state.contacts = _.unionBy(state.contacts, contacts, 'id')
    },
    setFoundContacts(state, contacts) {
      state.foundContacts = contacts
    },
    updateContact(state, updatedContact) {
      const contactIndex = state.contacts.findIndex(x => x.id === updatedContact.id)
      state.contacts = state.contacts.map((contact, i) => i === contactIndex ? updatedContact : contact)

      const foundContactIndex = state.foundContacts.findIndex(x => x.id === updatedContact.id)
      state.foundContacts = state.foundContacts.map((contact, i) => i === foundContactIndex ? updatedContact : contact)
    },
    setContact(state, contact) {
      state.contact = contact
    },
    setRelation(state, relation) {
      state.relation = relation
    },
    reset(state) {
      state.foundContacts = []
      state.contacts = []
      state.page = 1
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    changePage(state) {
      state.page++
    },
    // FILTER PARAMS
    filterParamsLocal(state, params) {
      state.filterParamsLocal = params
    },
    filterParamsSend(state, params) {
      state.filterParamsSend = params
    },
    filterParamsActive(state, number) {
      state.filterParamsActive = number
    },
    sortParams(state, params) {
      state.sortParams = params
    },
    resetFilterParams(state) {
      state.filterParams = {}
      state.filterParamsActive = 0
    },
    searchParams(state, params) {
      state.searchParams = params
    },
    filterSearchParams(state, params) {
      state.filterSearchParams = params
    },
  },
  actions: {
    async fetch({commit, getters}) {
      const page = getters.page
      try {
        const url = process.env.VUE_APP_BACKEND
        const contacts = await axios.get(url + '/contacts', {
          params: {
            page,
            ...getters.filterParamsSend,
            ...getters.searchParams,
            ...getters.sortParams,
          }
        })
        commit('pagination', contacts.data.pagination)
        commit('set', contacts.data.contacts)
        commit('changePage')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async search({commit, getters}, { query, doNotCommit }) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const response = await axios.get(url + '/contacts/search', {
          params: {
            q: query ?? getters.filterSearchParams
          }
        })
        if (!doNotCommit) {
          commit('setFoundContacts', response.data.contacts)
        }
        return response.data.contacts        
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async create({commit, dispatch}, newContacts) {
      const url = process.env.VUE_APP_BACKEND
      let contactsIDs = []
      try {
        for (const item of newContacts) {
          let contact = await axios.post(url + '/contacts', item)
          contactsIDs.push(contact.data.contact.id)
        }
        commit('reset')
        await dispatch('fetch')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
      return contactsIDs
    },
    async update({commit}, {contact, id}) {
      const url = process.env.VUE_APP_BACKEND
      try {
        const response = await axios.put(url + '/contacts/' + id, contact)
        commit('updateContact', response.data.contact)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async patch({commit}, {payload, id}) {
      const url = process.env.VUE_APP_BACKEND
      try {
        const response = await axios.patch(url + '/contacts/' + id, payload)
        commit('updateContact', response.data.contact)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async delete({commit}, id) {
      const url = process.env.VUE_APP_BACKEND
      try {
        await axios.delete(url + '/contacts/' + id)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async fetchRelation({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const response = await axios.get(url + '/contacts/' + id + '/related')
        commit('setRelation', response.data.relations)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async fetchRelations({commit}, ids) {
      try {
        if (!ids || !ids.length) {
          return []
        }

        const response = await axios.get(process.env.VUE_APP_BACKEND + '/contacts/related', { params: { ids }})
        return response.data.relations ?? []
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    }
  },
  getters: {
    contacts: s => s.contacts,
    foundContacts: s => s.foundContacts,
    contact: s => s.contact,
    relation: s => s.relation,
    pagination: s => s.pagination,
    page: s => s.page,
    // FILTER PARAMS
    filterParamsOriginal: s => s.filterParamsOriginal,
    filterParamsLocal: s => s.filterParamsLocal,
    filterParamsSend: s => s.filterParamsSend,
    filterParamsActive: s => s.filterParamsActive,
    filterSearchParams: s => s.filterSearchParams,
    searchParams: s => s.searchParams
  }
}
