<template>
  <div class="navbar__wrapper" id="navbar">
    <div class="navbar__inner">
      <div class="navbar__top">
        <div class="container">
          <div class="navbar__top-inner">
            <div class="navbar__left">
              <div class="button button--fill button-mobile-nav" @click="openSidebar">
                <i class="ri-menu-unfold-line"></i>
              </div>
              <div class="navbar__left-mobile">
                <div class="navbar__left-icon">
                  <i class="ri-focus-line"></i>
                  {{ seekersNumber }}
                </div>
              </div>
              <div class="navbar__center-inner">
                <div class="navbar__center-inner">
                  <span class="link navbar__link navbar__link--active">
                    <span class="navbar__link-text">Шукачі</span>
                    <span class="navbar__link-icon">{{ seekersNumber }}</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="navbar__right">
              <button class="button button--fill" @click="addSeeker">Додати шукача</button>
              <button class="button__icon" @click="addSeeker"><i class="ri-add-circle-line"></i></button>
              <div class="navbar__notifications">
                <Notifications />
              </div>
              <div class="user__block">
                <div class="user__icon">{{ userNameFirstLetter }}</div>
                <div class="user__name">{{ userName }}</div>
                <div class="user__param">
                  <Multiselect
                    class="mSelect-dropdown"
                    :options="list"
                    :searchable="false"
                    :show-labels="false"
                    :reset-after="true"
                    :value="uSeeker"
                    placeholder
                    @select="userfn"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="navbar__middle">
        <div class="container">
          <div class="navbar__middle-inner">
            <div class="navbar__left">
              <label class="search__label">
                <i class="ri-search-2-line"></i>
                <input class="search__input" :value="search" @input="searchSeeker" placeholder="Введіть номер телефону або ID..." />
              </label>
            </div>
            <div class="navbar__right">
              <div class="dropdown__layout">
                <span>Сортувати за:</span>
                <Multiselect
                  ref="multiselect"
                  :options="listSort"
                  v-model="itemSort"
                  label="name"
                  trackBy="id"
                  :value="itemSort"
                  :showLabels="false"
                  :searchable="false"
                  :allowEmpty="false"
                  placeholder="Сортувати"
                  class="mSelect-sort"
                  @select="sorted"
                  @close="dropdownActive = false"
                  @open="dropdownActive = true"
                />
              </div>
              <div class="button__wrapper">
                <button
                  class="button button--small button--grey"
                  :class="{'ri-filter-fill-blue': activeParams}"
                  @click="openFilter"
                >
                  <i class="ri-filter-fill">
                    <span v-if="activeParams">{{ activeParams }}</span>
                  </i>
                  <span class="button__text">Фільтри</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Multiselect from 'vue-multiselect'
import Sidebar from '@/components/Sidebar'
import SidebarMobile from '@/components/SidebarMobile'
import UploadSeeker from '@/views/Seekers/Upload'
import Filters from '@/views/Seekers/Filters'
import Notifications from '@/components/Notifications'

export default {
  name: 'NavbarSeekers',
  components: {
    Multiselect,
    Notifications,
  },
  data: () => ({
    list: ['Аккаунт', 'Вийти'],
    uSeeker: '',
    search: null,
    itemSort: {name: 'Останні'},
    listSort: [
      {name: 'Нові', id: 'createdAt-desc'},
      {name: 'Старі', id: 'createdAt-asc'},
      {name: 'Дзвінок +', id: 'lastCallAt-desc'},
      {name: 'Дзвінок -', id: 'lastCallAt-asc'},
    ],
    dropdownActive: false
  }),
  async created() {
    if (Object.keys(this.user).length === 0) {
      await this.$store.dispatch('getUser')
    }

    if (!this.pagination.total) {
      this.$store.dispatch('seekers/fetch')
    }

    this.search = this.searchParams['filter[phoneOrID]']
    const {sortBy} = this.sortParams
    if (sortBy) {
      this.itemSort = this.listSort.find(x => x.id === sortBy)
    }
  },
  watch: {
    async search() {
      if (this.search) {
        this.searchObject(this)
      } else if (this.search === '') {
        this.search = null
        this.searchObject(this)
      }
    }
  },
  computed: {
    ...mapGetters({
      activeParams: 'seekers/filterParamsActive',
      pagination: 'seekers/pagination',
      searchParams: 'seekers/searchParams',
      sortParams: 'seekers/sortParams',
      user: 'user',
    }),
    seekersNumber() {
      return this.pagination.total
    },
    userName() {
      return this.user?.fullName
    },
    userNameFirstLetter() {
      return this.user?.fullName?.[0]
    }
  },
  methods: {
    userfn(option) {
      if (option === 'Вийти') {
        this.$store.dispatch('logout')
        this.$router.go('/login')
      }
    },
    searchSeeker(e) {
      this.search = e.target.value
      this.searchObject(e)
    },
    searchObject: _.debounce(v => {
      v.$store.commit('seekers/reset')
      v.$store.commit('seekers/searchParams', {'filter[phoneOrID]': v.search})
      v.$store.dispatch('seekers/fetch')
    }, 500),
    openSidebar() {
      const mobile = document.documentElement.clientWidth <= 768
      let SidebarModal = {}
      mobile ? (SidebarModal = SidebarMobile) : (SidebarModal = Sidebar)
      this.$modal.display(
        SidebarModal,
        {},
        {
          name: 'Sidebar',
          transition: 'none',
          classes: 'modalSidebar',
          width: '210px',
          height: '100%',
          maxHeight: 1024,
          shiftX: 0,
          shiftY: 0,
          adaptive: true
        }
      )
    },
    async sorted(option) {
      this.$store.commit('seekers/reset')
      this.$store.commit('seekers/sortParams', { sortBy: option.id })
      this.$store.dispatch('seekers/fetch')
    },
    addSeeker() {
      const styles = this.$modal.styles
      this.$modal.show(
        UploadSeeker,
        {},
        {...styles, name: 'modalUploadSeeker'},
        {'before-open': this.$modal.open, 'before-close': this.$modal.close}
      )
    },
    openFilter() {
      this.$modal.display(
        Filters,
        {},
        {
          transition: 'none',
          classes: 'modalFilter',
          width: '400px',
          height: '100%',
          shiftX: 1,
          shiftY: 1,
          name: 'FilterSeekers',
          maxHeight: 3000
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
