var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contact__wrapper" }, [
    _c("div", { staticClass: "contact__header" }, [
      _c("div", { staticClass: "contact__title" }, [
        _vm._v("Додавання нового контакту")
      ]),
      _c("i", {
        staticClass: "i-plus button__icon button__icon--blue",
        on: { click: _vm.addContact }
      })
    ]),
    _c(
      "div",
      { staticClass: "contact__list" },
      _vm._l(_vm.newContacts, function(contact, index) {
        return _c("div", { key: index, staticClass: "contact__item" }, [
          _c("div", { staticClass: "contact__item-top" }, [
            _c("div", { staticClass: "contact__date-label" }, [
              _vm._v("Дата народження")
            ]),
            _c("i", {
              staticClass: "contact__expand i-expand",
              class: { "i-expand--open": _vm.visibleBox[index].v },
              on: {
                click: function($event) {
                  return _vm.toggleInfo(index)
                }
              }
            }),
            _c("div", { staticClass: "contact__name" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: contact.name,
                    expression: "contact.name"
                  }
                ],
                staticClass: "field__input contact__input",
                attrs: { placeholder: "Введіть ім'я" },
                domProps: { value: contact.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(contact, "name", $event.target.value)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "contact__date" }, [
              _c(
                "span",
                [
                  _c("i", { staticClass: "i-calendar" }),
                  _c("date-picker", {
                    attrs: {
                      isDark: "",
                      color: "blue",
                      popover: { visibility: "focus" }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var inputValue = ref.inputValue
                            var inputEvents = ref.inputEvents
                            return [
                              _c(
                                "input",
                                _vm._g(
                                  {
                                    staticClass: "field__input",
                                    attrs: { readonly: "" },
                                    domProps: { value: inputValue }
                                  },
                                  inputEvents
                                )
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: contact.birthday,
                      callback: function($$v) {
                        _vm.$set(contact, "birthday", $$v)
                      },
                      expression: "contact.birthday"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "contact__date--mobile" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: contact.birthday,
                    expression: "contact.birthday"
                  }
                ],
                staticClass: "field__date--mobile",
                attrs: { type: "date" },
                domProps: { value: contact.birthday },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(contact, "birthday", $event.target.value)
                  }
                }
              })
            ]),
            _c(
              "div",
              { staticClass: "contact__who field__label" },
              [
                _c("Multiselect", {
                  staticClass: "mSelect",
                  attrs: {
                    options: _vm.roles,
                    multiple: true,
                    closeOnSelect: false,
                    label: "name",
                    trackBy: "id",
                    showLabels: false,
                    searchable: false,
                    placeholder: "Роль"
                  },
                  model: {
                    value: contact.roles,
                    callback: function($$v) {
                      _vm.$set(contact, "roles", $$v)
                    },
                    expression: "contact.roles"
                  }
                })
              ],
              1
            ),
            _c("button", { staticClass: "button__icon" }, [
              _c("i", {
                staticClass: "i-remove",
                on: {
                  click: function($event) {
                    return _vm.removeContact(index)
                  }
                }
              })
            ])
          ]),
          _vm.isCompany(contact) && _vm.visibleBox[index].v
            ? _c(
                "div",
                { staticClass: "contact__item-middle" },
                [
                  _c("CompanySearch", {
                    attrs: {
                      selected: contact.company,
                      type: _vm.getCompanyType(contact),
                      placeholder: "Компанія"
                    },
                    on: {
                      select: function(company) {
                        return (contact.company = company)
                      }
                    }
                  }),
                  _c("Multiselect", {
                    attrs: {
                      options: _vm.constants.CONTACT_JOB_POSITIONS,
                      label: "name",
                      trackBy: "id",
                      showLabels: false,
                      searchable: false,
                      placeholder: "Посада"
                    },
                    model: {
                      value: contact.jobPosition,
                      callback: function($$v) {
                        _vm.$set(contact, "jobPosition", $$v)
                      },
                      expression: "contact.jobPosition"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.visibleBox[index].v
            ? _c("div", { staticClass: "contact__item-bottom" }, [
                _c("div", { staticClass: "contact__item-main" }, [
                  _c(
                    "div",
                    { staticClass: "contacts__phones-box" },
                    _vm._l(contact.phones, function(phone, indexPhone) {
                      return _c(
                        "div",
                        { key: indexPhone, staticClass: "contacts__phone-box" },
                        [
                          _c("div", { staticClass: "search__wrapper" }, [
                            _c(
                              "div",
                              { staticClass: "chips__wrapper--compact" },
                              [
                                _c("input", {
                                  staticClass: "field__input search__input",
                                  attrs: { placeholder: "Введіть телефон" },
                                  domProps: { value: phone.phone },
                                  on: {
                                    focus: function() {
                                      return (phone.isListVisible = true)
                                    },
                                    blur: function() {
                                      return (phone.isListVisible = false)
                                    },
                                    input: function(event) {
                                      return _vm.phoneInputChange(
                                        event,
                                        phone,
                                        index,
                                        indexPhone
                                      )
                                    }
                                  }
                                })
                              ]
                            ),
                            phone.isListVisible
                              ? _c(
                                  "div",
                                  { staticClass: "search__select" },
                                  _vm._l(phone.foundContacts, function(c) {
                                    return _c(
                                      "div",
                                      {
                                        key: c.id,
                                        staticClass: "search__item"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "search__phone contact__phone"
                                          },
                                          [_vm._v(_vm._s(c.phones[0].phone))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "search__name" },
                                          [
                                            _c("div", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "contact__name"
                                                },
                                                [_vm._v(_vm._s(c.name))]
                                              )
                                            ])
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e()
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "button__circle",
                              class: {
                                "button__circle--blue": phone.isOnViber
                              },
                              on: {
                                click: function($event) {
                                  phone.isOnViber = !phone.isOnViber
                                }
                              }
                            },
                            [_c("i", { staticClass: "i-viber" })]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "button__circle",
                              class: {
                                "button__circle--blue": phone.isOnTelegram
                              },
                              on: {
                                click: function($event) {
                                  phone.isOnTelegram = !phone.isOnTelegram
                                }
                              }
                            },
                            [_c("i", { staticClass: "i-telegram" })]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "button__circle",
                              class: {
                                "button__circle--blue": phone.isOnWhatsapp
                              },
                              on: {
                                click: function($event) {
                                  phone.isOnWhatsapp = !phone.isOnWhatsapp
                                }
                              }
                            },
                            [_c("i", { staticClass: "i-whatsapp" })]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "button__circle",
                              class: {
                                "button__circle--blue": phone.isOnFacebook
                              },
                              on: {
                                click: function($event) {
                                  phone.isOnFacebook = !phone.isOnFacebook
                                }
                              }
                            },
                            [_c("i", { staticClass: "i-messanger" })]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "button__circle",
                              on: {
                                click: function($event) {
                                  return _vm.removePhone(index, indexPhone)
                                }
                              }
                            },
                            [_c("i", { staticClass: "ri-close-fill" })]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "contact__item-footer",
                    on: {
                      click: function($event) {
                        return _vm.addPhone(index)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "i-plus" }),
                    _c("span", { staticClass: "contact__item-footer-title" }, [
                      _vm._v("Додати ще один номер")
                    ])
                  ]
                )
              ])
            : _vm._e()
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }