var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal__wrapper" }, [
    _c("div", { staticClass: "modal__top" }, [
      _c(
        "div",
        {
          staticClass: "button__icon button--onmobile",
          on: {
            click: function($event) {
              return _vm.$modal.hide("ModalArchive")
            }
          }
        },
        [_c("i", { staticClass: "ri-close-line" })]
      )
    ]),
    _c("div", { staticClass: "modal__middle" }, [
      _c(
        "div",
        {
          staticClass: "field__label",
          class: {
            "field__label--filled": _vm.reasons,
            "field__label--invalid":
              _vm.$v.reasons.$dirty && !_vm.$v.reasons.required
          }
        },
        [
          _c("div", { staticClass: "field__title field__title--required" }, [
            _vm._v("Причина")
          ]),
          _c("Multiselect", {
            staticClass: "mSelect",
            attrs: {
              options: _vm.archivedReasons,
              label: "name",
              trackBy: "id",
              allowEmpty: false,
              showLabels: false,
              searchable: false,
              maxHeight: 250,
              placeholder: "Причина"
            },
            model: {
              value: _vm.reasons,
              callback: function($$v) {
                _vm.reasons = $$v
              },
              expression: "reasons"
            }
          })
        ],
        1
      ),
      _vm.reasons.id === 1 || _vm.reasons.id === 2 || _vm.reasons.id === 3
        ? _c(
            "label",
            {
              staticClass: "field__label",
              class: {
                "field__label--filled": _vm.soldBy,
                "field__label--invalid":
                  _vm.$v.soldBy.$dirty && !_vm.$v.soldBy.required
              }
            },
            [
              _c("div", { staticClass: "field__title" }, [
                _vm._v("Ким реалізовано")
              ]),
              _c("Multiselect", {
                staticClass: "mSelect",
                attrs: {
                  options: _vm.constants.SOLD_BYS,
                  label: "name",
                  trackBy: "id",
                  allowEmpty: false,
                  showLabels: false,
                  searchable: false,
                  placeholder: ""
                },
                model: {
                  value: _vm.soldBy,
                  callback: function($$v) {
                    _vm.soldBy = $$v
                  },
                  expression: "soldBy"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.reasons.id === 1 || _vm.reasons.id === 5
        ? _c(
            "div",
            {
              staticClass: "field__label",
              class: {
                "field__label--filled": _vm.date,
                "field__label--invalid":
                  _vm.$v.date.$dirty && !_vm.$v.date.required
              }
            },
            [
              _c("div", { staticClass: "field__title" }, [
                _vm._v("Видалено до")
              ]),
              _c("date-picker", {
                attrs: {
                  isDark: "",
                  color: "blue",
                  popover: { visibility: "focus", placement: "top-start" }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var inputValue = ref.inputValue
                        var inputEvents = ref.inputEvents
                        return [
                          _c(
                            "input",
                            _vm._g(
                              {
                                staticClass: "field__input",
                                attrs: { readonly: "" },
                                domProps: { value: inputValue }
                              },
                              inputEvents
                            )
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2773341649
                ),
                model: {
                  value: _vm.date,
                  callback: function($$v) {
                    _vm.date = $$v
                  },
                  expression: "date"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "button",
        { staticClass: "button button--fill", on: { click: _vm.send } },
        [
          _vm._v(
            _vm._s(_vm.isArchiveAction ? "Перемістити в архів" : "Зберегти")
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }