<template>
  <div class="upload__container">
    <div class="upload__left">
      <div class="upload__navigation-title">Категорії</div>
      <div class="upload__navigation">
        <div class="upload__navigation-link-wrapper" @click="select('mainDataBox')">
          <div
            class="upload__navigation-link"
            :class="{
              'upload__navigation-link--active': mainDataBox,
              'upload__navigation-link--error': this.$v.sendData.$invalid && this.$v.sendData.$dirty
            }"
          >
            Параметри
          </div>
          <div class="upload__navigation-icon"></div>
        </div>
        <div class="upload__navigation-link-wrapper" @click="select('otherDataBox')">
          <div
            class="upload__navigation-link"
            :class="{
              'upload__navigation-link--active': otherDataBox,
              'upload__navigation-link--error': this.$v.sendDataOther.$invalid && this.$v.sendDataOther.$dirty
            }"
          >
            Додаткові
          </div>
          <div class="upload__navigation-icon"></div>
        </div>
        <div class="upload__navigation-link-wrapper" @click="select('contactsBox')">
          <div
            class="upload__navigation-link"
            :class="{
              'upload__navigation-link--active': contactsBox,
              'upload__navigation-link--error': contactsBoxError
            }"
          >
            Контакти
          </div>
          <div class="upload__navigation-icon"></div>
        </div>
        <div class="upload__navigation-link-wrapper" @click="select('photosBox')">
          <div class="upload__navigation-link" :class="{'upload__navigation-link--active': photosBox}">Фото</div>
          <div class="upload__navigation-icon"></div>
        </div>
      </div>
    </div>
    <div class="upload__right">
      <div class="upload__top">
        <div class="upload__title">
          <span v-if="info">Редагування </span>
          <span v-else>Додавання </span>
          <span v-if="type === 'apartment'">Квартири</span>
          <span v-if="type === 'house'">Будинку</span>
          <span v-if="type === 'commerce'">Комерції</span>
          <span v-if="id"> - ID {{ id }}</span>
        </div>
        <div class="upload__icons">
          <i v-if="type === 'apartment'" class="ri-building-4-fill"></i>
          <i v-if="type === 'house'" class="ri-home-5-fill"></i>
          <i v-if="type === 'commerce'" class="ri-store-fill"></i>
        </div>
        <div class="upload__buttons">
          <div class="upload__button button button--fill button--grey" @click="$modal.hide('UploadObject')">
            Скасувати
          </div>
          <div class="upload__button button button--fill" @click="send">
            Зберегти
          </div>

          <div class="button__icon" @click="$modal.hide('UploadObject')">
            <i class="ri-close-circle-line"></i>
          </div>
          <div class="button__icon button__icon--blue" @click="send">
            <i class="ri-save-3-line"></i>
          </div>
        </div>
      </div>
      <div class="upload__navigation--mobile">
        <div class="upload__navigation-link-wrapper" @click="select('mainDataBox')">
          <div
            class="upload__navigation-link"
            :class="{
              'upload__navigation-link--active': mainDataBox,
              'upload__navigation-link--error': this.$v.sendData.$invalid && this.$v.sendData.$dirty
            }"
          >
            Параметри
          </div>
          <div class="upload__navigation-icon"></div>
        </div>
        <div class="upload__navigation-link-wrapper" @click="select('otherDataBox')">
          <div
            class="upload__navigation-link"
            :class="{
              'upload__navigation-link--active': otherDataBox,
              'upload__navigation-link--error': this.$v.sendDataOther.$invalid && this.$v.sendDataOther.$dirty
            }"
          >
            Додаткові
          </div>
          <div class="upload__navigation-icon"></div>
        </div>
        <div class="upload__navigation-link-wrapper" @click="select('contactsBox')">
          <div
            class="upload__navigation-link"
            :class="{
              'upload__navigation-link--active': contactsBox,
              'upload__navigation-link--error': contactsBoxError
            }"
          >
            Контакти
          </div>
          <div class="upload__navigation-icon"></div>
        </div>
        <div class="upload__navigation-link-wrapper" @click="select('photosBox')">
          <div class="upload__navigation-link" :class="{'upload__navigation-link--active': photosBox}">Фото</div>
          <div class="upload__navigation-icon"></div>
        </div>
      </div>
      <div class="upload__main">
        <div v-if="mainDataBox" class="data__inner data_main">
          <div class="data__top">
            <label
              class="field__label"
              :class="{
                'field__label--filled': sendData.title,
                'field__label--invalid': $v.sendData.title.$dirty && !$v.sendData.title.required
              }"
            >
              <div class="field__title field__title--required">Заголовок</div>
              <input class="field__input" v-model="sendData.title" placeholder="Введіть заголовок" />
            </label>
            <label
              class="field__label"
              :class="{
                'field__label--filled': sendData.price,
                'field__label--invalid':
                  ($v.sendData.price.$dirty && !$v.sendData.price.required) || !$v.sendData.price.numeric
              }"
            >
              <div class="field__title field__title--required">Ціна</div>
              <input class="field__input" type="tel" v-model.number="sendData.price" v-mask="'#######'" />
            </label>
          </div>
          <div class="data__part">
            <div
              class="field__label"
              :class="{
                'field__label--filled': sendData.settlement,
                'field__label--invalid': $v.sendData.settlement.$dirty && !$v.sendData.settlement.required
              }"
            >
              <div class="field__title field__title--required">Населений Пункт</div>
              <Multiselect
                :options="settlements"
                v-model="sendData.settlement"
                label="name"
                trackBy="name"
                :showLabels="false"
                :allowEmpty="false"
                placeholder="Населений Пункт"
                class="mSelect"
              />
            </div>
            <div
              class="field__label"
              v-if="sendData.settlement.name === 'Рівне'"
              :class="{
                'field__label--filled': sendData.districtID,
                'field__label--invalid': $v.sendData.districtID.$dirty && !$v.sendData.districtID.required
              }"
            >
              <div class="field__title field__title--required">Район</div>
              <Multiselect
                :options="districtsRivne"
                v-model="sendData.districtID"
                label="name"
                trackBy="id"
                :showLabels="false"
                :searchable="false"
                placeholder="Район"
                class="mSelect"
              />
            </div>
            <div
              class="field__label"
              :class="{
                'field__label--filled': sendData.street,
                'field__label--invalid': $v.sendData.street.$dirty && !$v.sendData.street.required
              }"
            >
              <div class="field__title field__title--required">Вулиця</div>
              <Multiselect
                :options="streets"
                v-model="sendData.street"
                :showLabels="false"
                placeholder="Виберіть Вулицю"
                class="mSelect"
              >
                <span slot="noResult">Отакої, вулиці немає :(</span>
              </Multiselect>
            </div>
          </div>
          <div class="data__middle">
            <label
              class="field__label"
              :class="{
                'field__label--filled': sendData.houseNo
              }"
            >
              <div class="field__title">№ будинку</div>
              <the-mask
                :mask="'IIIII'"
                v-model.trim="sendData.houseNo"
                :tokens="customToken"
                class="field__input"
              ></the-mask>
            </label>
            <label
              v-if="type === 'apartment'"
              class="field__label"
              :class="{
                'field__label--filled': sendData.aptNo
              }"
            >
              <div class="field__title">№ квартири</div>
              <input class="field__input" type="tel" v-model.number="sendData.aptNo" v-mask="'###'" />
            </label>
            <div
              v-if="type === 'commerce'"
              class="field__label"
              :class="{
                'field__label--filled': sendData.floor,
                'field__label--invalid': $v.sendData.floor.$dirty && !$v.sendData.floor.required
              }"
            >
              <div class="field__title">Поверх</div>
              <Multiselect
                :options="localConstants.PROPERTY_COMMERCE_FLOORS"
                v-model="sendData.floor"
                :showLabels="false"
                :maxHeight="200"
                :searchable="false"
                class="mSelect"
                placeholder="Поверх"
              >
                <span slot="noResult">Занадто високо 😱</span>
              </Multiselect>
            </div>
            <div
              v-if="type === 'commerce'"
              class="field__label"
              :class="{
                'field__label--filled': sendData.floors,
                'field__label--invalid': $v.sendData.floors.$dirty && !$v.sendData.floors.required
              }"
            >
              <div class="field__title">Поверхів</div>
              <Multiselect
                :options="localConstants.PROPERTY_COMMERCE_FLOORS"
                v-model="sendData.floors"
                :searchable="false"
                :showLabels="false"
                :maxHeight="200"
                class="mSelect"
                placeholder="Поверхів"
              />
            </div>
            <div
              v-if="type !== 'commerce'"
              class="field__label"
              :class="{
                'field__label--filled': sendData.material,
                'field__label--invalid': $v.sendData.material.$dirty && !$v.sendData.material.required
              }"
            >
              <div class="field__title">Вид</div>
              <Multiselect
                v-if="type === 'apartment'"
                :options="constants.APARTMENT_MATERIALS"
                v-model="sendData.material"
                label="name"
                trackBy="id"
                :showLabels="false"
                :searchable="false"
                :maxHeight="200"
                class="mSelect"
                placeholder="Вид"
              />
              <Multiselect
                v-if="type === 'house'"
                :options="constants.HOUSE_MATERIALS"
                v-model="sendData.material"
                label="name"
                trackBy="id"
                :showLabels="false"
                :searchable="false"
                :maxHeight="200"
                class="mSelect"
                placeholder="Вид"
              />
            </div>
            <div
              class="field__label"
              :class="{
                'field__label--filled': sendData.buildingType,
                'field__label--invalid': $v.sendData.buildingType.$dirty && !$v.sendData.buildingType.required
              }"
            >
              <div v-if="type === 'commerce'" class="field__title field__title--required">Призначення</div>
              <div v-else class="field__title field__title--required">Тип</div>
              <Multiselect
                v-if="type === 'apartment'"
                :options="constants.APARTMENT_TYPES"
                v-model="sendData.buildingType"
                label="name"
                trackBy="id"
                :showLabels="false"
                :searchable="false"
                :maxHeight="200"
                class="mSelect"
                placeholder="Тип"
              />
              <Multiselect
                v-if="type === 'house'"
                :options="constants.HOUSE_TYPES"
                v-model="sendData.buildingType"
                label="name"
                trackBy="id"
                :showLabels="false"
                :searchable="false"
                :maxHeight="200"
                class="mSelect"
                placeholder="Тип"
              />
              <Multiselect
                v-if="type === 'commerce'"
                :options="constants.COMMERCE_BUILDING_TYPES"
                v-model="sendData.buildingType"
                label="name"
                trackBy="id"
                :showLabels="false"
                :searchable="false"
                :maxHeight="200"
                class="mSelect"
                placeholder="Призначення"
              />
            </div>
            <label
              v-if="type === 'house'"
              class="field__label"
              :class="{
                'field__label--filled': sendData.squareLand,
                'field__label--invalid': $v.sendData.squareLand.$dirty && !$v.sendData.squareLand.required
              }"
            >
              <div class="field__title field__title--required">
                Площа ділянки
              </div>
              <input class="field__input" type="tel" v-model="sendData.squareLand" v-mask="'###'" />
            </label>
            <div
              class="field__label"
              :class="{
                'field__label--filled': sendData.noOfRooms,
                'field__label--invalid': $v.sendData.noOfRooms.$dirty && !$v.sendData.noOfRooms.required
              }"
            >
              <div class="field__title">Кількість кімнат</div>
              <Multiselect
                :options="localConstants.PROPERTY_ROOMS"
                v-model="sendData.noOfRooms"
                label="name"
                trackBy="id"
                :showLabels="false"
                :searchable="false"
                :maxHeight="200"
                class="mSelect"
                placeholder="к-сть кімнат"
              />
            </div>
            <label
              class="field__label"
              :class="{
                'field__label--filled': sendData.squareTotal,
                'field__label--invalid': $v.sendData.squareTotal.$dirty && !$v.sendData.squareTotal.required
              }"
            >
              <div class="field__title">Загальна площа</div>
              <input class="field__input" type="tel" v-model="sendData.squareTotal" v-mask="'###'" />
            </label>
            <label
              class="field__label"
              :class="{
                'field__label--filled': sendData.squareLiving,
                'field__label--invalid': $v.sendData.squareLiving.$dirty && !$v.sendData.squareLiving.required
              }"
            >
              <div v-if="type === 'commerce'" class="field__title">Корисна площа</div>
              <div v-else class="field__title">Житлова площа</div>
              <input class="field__input" type="tel" v-model="sendData.squareLiving" v-mask="'###'" />
            </label>
            <label
              v-if="type !== 'commerce'"
              class="field__label"
              :class="{
                'field__label--filled': sendData.squareKitchen,
                'field__label--invalid': $v.sendData.squareKitchen.$dirty && !$v.sendData.squareKitchen.required
              }"
            >
              <div class="field__title field__title--required">Площа кухні</div>
              <input class="field__input" type="tel" v-model="sendData.squareKitchen" v-mask="'##'" />
            </label>
            <div
              v-if="type === 'apartment'"
              class="field__label"
              :class="{
                'field__label--filled': sendData.floor,
                'field__label--invalid': $v.sendData.floor.$dirty && !$v.sendData.floor.required
              }"
            >
              <div class="field__title">Поверх</div>
              <Multiselect
                :options="localConstants.PROPERTY_APARTMENT_FLOORS"
                v-model="sendData.floor"
                :searchable="false"
                :showLabels="false"
                :maxHeight="200"
                class="mSelect"
                placeholder="Поверх"
              >
                <span slot="noResult">Занадто високо 😱</span>
              </Multiselect>
            </div>
            <div
              v-if="type === 'house'"
              class="field__label"
              :class="{
                'field__label--filled': sendData.floors,
                'field__label--invalid': $v.sendData.floors.$dirty && !$v.sendData.floors.required
              }"
            >
              <div class="field__title">Поверхів</div>
              <Multiselect
                :options="localConstants.PROPERTY_HOUSE_FLOORS"
                v-model="sendData.floors"
                :showLabels="false"
                :searchable="false"
                :maxHeight="200"
                class="mSelect"
                placeholder="Поверхів"
              />
            </div>
            <div
              v-if="type === 'apartment'"
              class="field__label"
              :class="{
                'field__label--filled': sendData.floors,
                'field__label--invalid': $v.sendData.floors.$dirty && !$v.sendData.floors.required
              }"
            >
              <div class="field__title">Поверхів</div>
              <Multiselect
                :options="localConstants.PROPERTY_APARTMENT_FLOORS"
                v-model="sendData.floors"
                :searchable="false"
                :showLabels="false"
                :maxHeight="200"
                class="mSelect"
                placeholder="Поверхів"
              />
            </div>
            <div
              class="field__label"
              :class="{
                'field__label--filled': sendData.renovated,
                'field__label--invalid': $v.sendData.renovated.$dirty && !$v.sendData.renovated.required
              }"
            >
              <div class="field__title">Ремонт</div>
              <Multiselect
                :options="localConstants.PROPERTY_TOGGLE"
                v-model="sendData.renovated"
                label="name"
                trackBy="id"
                :showLabels="false"
                :searchable="false"
                :maxHeight="200"
                class="mSelect"
                placeholder="Ремонт"
              />
            </div>
            <div
              v-if="type !== 'house'"
              class="field__label"
              :class="{
                'field__label--filled': sendData.autonomousHeatType,
                'field__label--invalid': $v.sendData.autonomousHeatType.$dirty && !$v.sendData.autonomousHeatType.required
              }"
            >
              <div class="field__title">Автономне опалення</div>
              <Multiselect
                :options="localConstants.AUTONOMOUS_HEAT_TYPE"
                v-model="sendData.autonomousHeatType"
                :showLabels="false"
                :searchable="false"
                :maxHeight="200"
                class="mSelect"
                placeholder="Опалення"
              />
            </div>
            <div
              v-if="type === 'house'"
              class="field__label"
              :class="{
                'field__label--filled': sendData.garage,
                'field__label--invalid': $v.sendData.garage.$dirty && !$v.sendData.garage.required
              }"
            >
              <div class="field__title">Гараж</div>
              <Multiselect
                :options="localConstants.PROPERTY_TOGGLE"
                v-model="sendData.garage"
                label="name"
                trackBy="id"
                :showLabels="false"
                :searchable="false"
                :maxHeight="200"
                class="mSelect"
                placeholder="Гараж"
              />
            </div>
            <label
              v-if="type === 'house'"
              class="field__label"
              :class="{
                'field__label--filled': sendData.builtAt && $v.sendData.builtAt.minLength,
                'field__label--invalid': $v.sendData.builtAt.$dirty && !$v.sendData.builtAt.minLength
              }"
            >
              <div class="field__title field__title--required">
                Рік побудови
              </div>
              <input class="field__input" v-model="sendData.builtAt" v-mask="'#### Q#'" />
            </label>
          </div>
          <div class="data__bottom">
            <label
              v-if="type === 'house'"
              class="field__label field__label--text"
              :class="{
                'field__label--filled': sendData.registrationNo && $v.sendData.registrationNo.minLength,
                'field__label--invalid': $v.sendData.registrationNo.$dirty && !$v.sendData.registrationNo.minLength
              }"
            >
              <div class="field__title">Кадастровий номер</div>
              <input class="field__input" v-model="sendData.registrationNo" v-mask="'##########:##:###:####'" />
            </label>
            <label
              class="field__label"
              :class="{
                'field__label--filled': sendData.description,
                'field__label--invalid': $v.sendData.description.$dirty && !$v.sendData.description.required
              }"
            >
              <div class="field__title">Рекламний текст</div>
              <textarea class="field__text field__text--ads" v-model="sendData.description"></textarea>
            </label>
            <label
              class="field__label"
              style="margin-top: 20px"
              :class="{
                'field__label--filled': sendData.comments,
                'field__label--invalid': $v.sendData.comments.$dirty && !$v.sendData.comments.required
              }"
            >
              <div class="field__title">Коментарі</div>
              <textarea class="field__text" v-model="sendData.comments"></textarea>
            </label>
          </div>
        </div>
        <DataOther :data="sendDataOther" :v="$v" v-if="otherDataBox" />
        <UploadContacts
          v-if="contactsBox"
          :newContacts="newContacts"
          :selectedContacts="selectedContacts"
          :visibleBox="visibleBox"
          :v="$v"
          @selectContacts="contacts => selectedContacts = contacts"
        />
        <UploadPhotos v-if="photosBox" :files="files" :photos="info ? info.photos : null" :delPhotos="delPhotos" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import localConstants from '@/utils/localConstants'
import {required, numeric, minLength, requiredIf} from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import moment from 'moment'

import UploadPhotos from './UploadPhotos'
import UploadContacts from './UploadContacts'
import DataOther from './UploadDataOther'

export default {
  name: 'UploadObject',
  props: {
    info: Object,
    default: null
  },
  components: {
    Multiselect,
    Treeselect,
    DataOther,
    UploadContacts,
    UploadPhotos
  },
  data: () => ({
    localConstants,
    mainDataBox: true,
    otherDataBox: false,
    contactsBox: false,
    photosBox: false,
    sendData: {
      title: '',
      price: '',
      squareTotal: '',
      squareLiving: '',
      houseNo: '',
      description: '',
      renovated: '',
      noOfRooms: '',
      buildingType: '',
      floors: '',
      settlement: {name: 'Рівне'},
      motivation: null,
      districtID: null,
      street: '',
      material: '',
      autonomousHeatType: '',
      squareKitchen: '',
      aptNo: '',
      floor: null,
      comments: '',
      squareLand: '',
      builtAt: ''
    },
    sendDataOther: {
      source: null,
      contract: null,
      reasonToSell: '',
      responsibleID: null,
      propertyStatus: null,
      agent: null,
      lastCallAt: null,
      motivation: null,
      mortgagePurchase: null,
      seeker: null,
    },

    selectedContacts: [],
    newContacts: [],
    visibleBox: [],
    settlements: [],
    districtsRivne: [],
    type: '',
    id: null,
    files: [],
    streets: [],
    delPhotos: [],
    customToken: {
      I: {pattern: /[0-9а-яА-ЯіІ]/}
    }
  }),
  computed: {
    ...mapState({
      constants: s => s.main.constants,
      users: s => s.users.users
    }),
    contactsBoxError() {
      let error1
      let error2
      if (this.$v.contactsIDs) {
        error1 = this.$v.contactsIDs.$invalid
        error2 = this.$v.contactsIDs.$dirty
      } else if (this.$v.newContacts) {
        error1 = this.$v.newContacts.$invalid
        error2 = this.$v.newContacts.$dirty
      }
      let error3 = false
      if (error1 && error2) {
        error3 = true
      }
      return error3
    },
    contactsIDs() {
      return this.selectedContacts?.map(c => c.id) ?? []
    }
  },
  methods: {
    async send() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      let data = {
        title: this.sendData.title,
        price: this.sendData.price,
        floors: this.sendData.floors,
        buildingType: this.sendData.buildingType.id,
        description: this.sendData.description,
        squareLiving: this.sendData.squareLiving,
        squareTotal: this.sendData.squareTotal,
        noOfRooms: this.sendData.noOfRooms.id,
        renovated: this.sendData.renovated.id,
        settlement: this.sendData.settlement.name,
        comments: this.sendData.comments,
        street: this.sendData.street,
        mortgagePurchase: this.sendDataOther.mortgagePurchase.id,
        contract: this.sendDataOther.contract,
        source: this.sendDataOther.source,
        reasonToSell: this.sendDataOther.reasonToSell,
        responsibleID: this.sendDataOther.agent.id,
        propertyStatus: this.sendDataOther.propertyStatus.id,
        motivation: this.sendDataOther.motivation,
        contactsIDs: this.contactsIDs
      }
      data.seekerId = data.source === this.localConstants.PROPERTY_SOURCES_SEEKER_ID ? this.sendDataOther.seeker.id : null
      if (this.sendData.districtID) {
        data.districtID = this.sendData.districtID.id
      }
      if (this.sendData.houseNo) {
        data.houseNo = this.sendData.houseNo
      }
      if (this.sendData.aptNo) {
        data.aptNo = this.sendData.aptNo
      }
      if (this.sendDataOther.lastCallAt) {
        data.lastCallAt = moment(this.sendDataOther.lastCallAt).format('DD-MM-YYYY')
      }
      if (this.type !== 'commerce') {
        data.material = this.sendData.material.id
        data.squareKitchen = this.sendData.squareKitchen
      }
      if (this.type !== 'house') {
        data.autonomousHeatType = this.sendData.autonomousHeatType
        data.floor = this.sendData.floor
      }
      if (this.type === 'house') {
        data.garage = this.sendData.garage.id
        data.squareLand = this.sendData.squareLand
        data.registrationNo = this.sendData.registrationNo || null
        data.builtAt = this.sendData.builtAt
      }
      const newContacts = this.newContacts.map(c => ({
          name: c.name,
          phones: c.phones.map(x => ({
            phone: x.phone,
            isOnTelegram: x.isOnTelegram,
            isOnFacebook: x.isOnFacebook,
            isOnViber: x.isOnViber,
            isOnWhatsapp: x.isOnWhatsapp
          })),
          roles: c.roles.map(x => x.id),
          companyId: c.company?.id || null,
          jobPosition: c.jobPosition?.id || null,
          birthday: c.birthday?.format() || null
        }))

      let formData = null
      if (this.files.length) {
        formData = new FormData()
        this.files.forEach(f => formData.append('photos', f))
      }
      const delID = this.delPhotos.map(p => p.id)
      let sendObject = ''
      if (this.type === 'apartment') {
        this.info ? (sendObject = 'objects/apartments/edit') : (sendObject = 'objects/apartments/create')
      } else if (this.type === 'house') {
        if (this.info) {
          sendObject = 'objects/houses/edit'
        } else {
          sendObject = 'objects/houses/create'
        }
      } else if (this.type === 'commerce') {
        if (this.info) {
          sendObject = 'objects/commerce/edit'
        } else {
          sendObject = 'objects/commerce/create'
        }
      }
      try {
        await this.$store.dispatch(sendObject, {
          data,
          newContacts,
          formData,
          id: this.id,
          delID
        })
        this.$modal.hide('UploadObject')
      } catch (err) {}
    },
    select(box) {
      this.mainDataBox = this.otherDataBox = this.contactsBox = this.photosBox = false
      this[box] = true
    },
    closeModal() {
      this.$modal.close()
      this.$modal.hideAll()
    }
  },
  async created() {
    this.type = this.$attrs.type
    await Promise.all([
      this.$store.dispatch('users/fetch'),
      this.$store.dispatch('fetchSettlements'),
      this.$store.dispatch('fetchStreets')
    ])
    this.settlements = this.$store.getters.settlements
    this.streets = this.$store.getters.streets
    this.districtsRivne = this.settlements.find(s => s.name === 'Рівне').districts

    if (this.info) {
      const info = this.info
      this.sendData = Object.assign(this.sendData, this.info)
      const data = this.sendData
      this.id = info.id

      data.settlement = {name: info.settlement}
      data.noOfRooms = this.localConstants.PROPERTY_ROOMS.find(room => room.id === info.noOfRooms)
      data.renovated = data.renovated === false ? {id: false, name: 'Немає'} : {id: true, name: 'Є'}
      data.districtID = {id: info.districtID, name: this.info.districtName}

      if (this.type === 'apartment') {
        data.buildingType = this.constants.APARTMENT_TYPES.find(type => type.id === info.buildingType)
        data.material = this.constants.APARTMENT_MATERIALS.find(material => material.id === info.material)
        // data.autonomousHeatType = data.autonomousHeatType === false ? {id: false, name: 'Немає'} : {id: true, name: 'Є'}
      } else if (this.type === 'house') {
        data.range = {name: info.range}
        data.garage = data.garage === false ? {id: false, name: 'Немає'} : {id: true, name: 'Є'}
        data.buildingType = this.constants.HOUSE_TYPES.find(type => type.id === info.buildingType)
        data.material = this.constants.HOUSE_MATERIALS.find(material => material.id === info.material)
      } else if (this.type === 'commerce') {
        data.buildingType = this.constants.COMMERCE_BUILDING_TYPES.find(type => type.id === info.buildingType)
        // data.autonomousHeatType = data.autonomousHeatType === false ? {id: false, name: 'Немає'} : {id: true, name: 'Є'}
      }

      this.selectedContacts = info.contacts

      this.sendDataOther.agent = this.users.find(user => user.id === info.responsibleID)
      this.sendDataOther.propertyStatus = this.constants.PROPERTY_STATUSES.find(status => status.id === info.propertyStatus)
      this.sendDataOther.motivation = info.motivation
      this.sendDataOther.source = info.source
      this.sendDataOther.seeker = info.seeker
      this.sendDataOther.reasonToSell = info.reasonToSell
      this.sendDataOther.lastCallAt = info.lastCallAt
      this.sendDataOther.contract = info.contract
      this.sendDataOther.mortgagePurchase = localConstants.YES_NO.find(status => status.id === info.mortgagePurchase)
    }
  },
  validations: {
    sendData: {
      title: {required},
      price: {required, numeric},
      floors: {required},
      buildingType: {required},
      districtID: {
        required: requiredIf(function() {
          if (this.sendData.settlement.name === 'Рівне') {
            return true
          }
        })
      },
      description: {required},
      comments: {required},
      squareLiving: {required},
      squareTotal: {required},
      noOfRooms: {required},
      renovated: {required},
      settlement: {required},
      street: {required},
      material: {
        required: requiredIf(function() {
          if (this.type !== 'commerce') {
            return true
          }
        })
      },
      autonomousHeatType: {
        required: requiredIf(function() {
          if (this.type !== 'house') {
            return true
          }
        })
      },
      squareKitchen: {
        required: requiredIf(function() {
          if (this.type !== 'commerce') {
            return true
          }
        })
      },
      floor: {
        required: requiredIf(function() {
          if (this.type !== 'house') {
            return true
          }
        })
      },
      garage: {
        required: requiredIf(function() {
          if (this.type === 'house') {
            return true
          }
        })
      },
      squareLand: {
        required: requiredIf(function() {
          if (this.type === 'house') {
            return true
          }
        })
      },
      builtAt: {
        required: requiredIf(function() {
          if (this.type === 'house') {
            return true
          }
        }),
        minLength: minLength(6)
      },
      registrationNo: {
        minLength: minLength(22)
      }
    },
    sendDataOther: {
      source: {required},
      seeker: { 
        required: requiredIf(function() {
          return this.sendDataOther.source == this.localConstants.PROPERTY_SOURCES_SEEKER_ID
        }),
      },
      motivation: {required},
      contract: {required},
      reasonToSell: {required},
      agent: {required},
      propertyStatus: {required},
      lastCallAt: {},
      mortgagePurchase: {required}
    },
    contactsIDs: {
      required: requiredIf(function() {
        if (this.newContacts.length) {
          return false
        } else {
          return true
        }
      })
    },
    newContacts: {
      required: requiredIf(function() {
        if (this.newContacts.length) {
          return true
        } else {
          return false
        }
      }),
      $each: {
        birthday: {},
        name: {required},
        phones: {
          required,
          $each: {
            phone: {required, minLength: minLength(10)}
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>

<style lang="scss">
.multiselect__tags {
  max-height: 70px;
  overflow: auto;
}
</style>
