<template>
  <div class="filters__inner">
    <div class="filters__top">
      <div class="filters__title">
        <span class="filters__title-span">Фільтри</span>
      </div>
      <div class="filters__navigation">
        <div class="button__icon" @click="reset">
          <i class="ri-filter-off-fill"></i>
        </div>
        <div class="button__icon filters__button--blue" @click="send">
          <i class="ri-filter-fill"></i>
        </div>
      </div>
    </div>
    <div class="filters__main">
      <div class="filters__main-part filters__main-part--active">
        <div class="part__main">
          <div class="field__label">
            <div class="field__title">Вулиця</div>
            <Multiselect
              v-model="params.street"
              :options="streets"
              :multiple="true"
              :showLabels="false"
              :closeOnSelect="false"
              placeholder="Вулиця"
            >
              <span slot="noResult">Отакої, вулиці немає :(</span>
            </Multiselect>
          </div>
        </div>
        <div class="part__main">
          <div class="field__label">
            <div class="field__title">Аварійність</div>
              <Multiselect
                :options="localConstants.YES_NO"
                v-model="params.emergency"
                label="name"
                trackBy="id"
                :showLabels="false"
                :searchable="false"
                placeholder="Аварійність"
                class="mSelect"
              />
          </div>
        </div>
        <div class="part__main">
          <div class="field__label">
            <div class="field__title">ОСББ/Управління</div>
              <Multiselect
                :options="constants.COOPERATIVE_TYPES"
                v-model="params.cooperativeType"
                label="name"
                trackBy="id"
                :showLabels="false"
                :searchable="false"
                placeholder="ОСББ/Управління"
                class="mSelect"
              />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import _ from 'lodash'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Multiselect from 'vue-multiselect'
import localConstants from '@/utils/localConstants'

export default {
  name: 'Filter',
  components: {
    DatePicker,
    Multiselect
  },
  data: () => ({
    localConstants,
    params: {
      street: null,
      emergency: null,
      cooperativeType: null
    },
  }),
  computed: {
    ...mapState({
      filterParamsOriginal: s => s.buildings.filterParamsOriginal,
      filterParamsLocal: s => s.buildings.filterParamsLocal,
      constants: s => s.main.constants,
      streets: s => s.main.streets,
    }),
    activeParams() {
      let params = 0
      for (const p in this.params) {
        if (!_.isEmpty(this.params[p])) {
          params++
        }
      }
      return params
    }
  },
  methods: {
    async send() {
      const params = this.params
      const paramsToSend = {
        'filter[street]': params.street?.length ? params.street : null,
        'filter[emergency]': params.emergency?.id,
        'filter[cooperativeType]': params.cooperativeType?.id,
      }
      this.$store.commit('buildings/filterParamsLocal', params)
      this.$store.commit('buildings/filterParamsSend', paramsToSend)
      this.$store.commit('buildings/filterParamsActive', this.activeParams)

      this.$store.commit('buildings/reset')
      await this.$store.dispatch('buildings/fetch')
      this.$modal.hide('Filter')
    },
    reset() {
      this.params = _.cloneDeep(this.filterParamsOriginal)
    }
  },
  async created() {
    if (Object.keys(this.filterParamsLocal).length) {
      this.params = _.cloneDeep(this.filterParamsLocal)
    } else {
      this.params = _.cloneDeep(this.filterParamsOriginal)
    }

    await this.$store.dispatch('fetchStreets')
  }
}
</script>
