import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
import objects from './objects'
import rent from './rent'
import buyers from './buyers'
import renters from './renters'
import archive from './archive'
import notifications from './notifications'
import users from './users/users'

import favorites from './favorites'
import favoritesBuyers from './favorites/buyers'
import favoritesRenters from './favorites/renters'
import favoritesObjects from './favorites/objects'

import actions from './actions/actions'
import seekers from './seekers/seekers'
import buildings from './buildings/buildings'
import stats from './stats'

import main from './main'
import contacts from './contacts/contacts'
import companies from './companies/companies'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    auth,
    objects,
    buyers,
    rent,
    renters,
    contacts,
    archive,
    users,
    stats,
    seekers,
    buildings,
    companies,
    notifications,

    main,
    favorites,
    favoritesObjects,
    favoritesBuyers,
    favoritesRenters,
    actions,
  }
})
