import axios from 'axios';

export default {
  state: {
    objectsFavApt: [],
    objectFavApt: {},
    paginationFavObjectsApt: {},
    pageFavObjectsApt: 1,
    objectsFavHouse: [],
    objectFavAHouse: {},
    paginationFavObjectsHouse: {},
    pageFavObjectsHouse: 1,
    objectsFavCom: [],
    objectFavCom: {},
    paginationFavObjectsCom: {},
    pageFavObjectsCom: 1,
  },
  mutations: {
    setFavObjects(state, {objects, type}) {
      type === 'apartment' ? state.objectsFavApt = state.objectsFavApt.concat(objects) : null
      type === 'house' ? state.objectsFavHouse = state.objectsFavHouse.concat(objects) : null
      type === 'commerce' ? state.objectsFavCom = state.objectsFavCom.concat(objects) : null
    },
    setFavObject(state, {object, type}) {
      type === 'apartment' ? state.objectFavApt = object : null
      type === 'house' ? state.objectFavAHouse = object : null
      type === 'commerce' ? state.objectFavCom = object : null
    },
    paginationFavObjects(state, {paginationObjects, type}) {
      type === 'apartment' ? state.paginationFavObjectsApt = paginationObjects : null
      type === 'house' ? state.paginationFavObjectsHouse = paginationObjects : null
      type === 'commerce' ? state.paginationFavObjectsCom = paginationObjects : null
    },
    resetFavObjects(state, type) {
      if (type === 'apartment') {
        state.objectsFavApt = []
        state.pageFavObjectsApt = 1
      } else if (type === 'house') {
        state.objectsFavHouse = []
        state.pageFavObjectsHouse = 1
      } else if (type === 'commerce') {
        state.objectsFavCom = []
        state.pageFavObjectsCom = 1
      }
    },
    changePageFavObj(state, type) {
      type === 'apartment' ? state.pageFavObjectsApt++ : null
      type === 'house' ? state.pageFavObjectsHouse++ : null
      type === 'commerce' ? state.pageFavObjectsCom++ : null
    }
  },
  actions: {
    async fetchFavoritesObjects({commit, getters}, type) {
      let page = Number
      let way = String
      type === 'apartment' ? page = getters.pageFavObjectsApt : null
      type === 'house' ? page = getters.pageFavObjectsHouse : null
      type === 'commerce' ? page = getters.pageFavObjectsCom : null

      type === 'apartment' ? way = 'apartments' : null
      type === 'house' ? way = 'houses' : null
      type === 'commerce' ? way = 'commerce' : null

      try {
        const url = process.env.VUE_APP_BACKEND;
        const objects = await axios.get(url + '/properties/' + way + '/favorites', {
          params: {
            page,
            ...getters.filterParams,
            ...getters.sortParams,
          }
        });
        commit('paginationFavObjects', {paginationObjects: objects.data.pagination, type})
        commit('setFavObjects', {objects: objects.data.favorites, type})
        commit('changePageFavObj', type)
      } catch (e) {
        commit("setError", e);
        throw e
      }
    },
  },
  getters: {
    objectsFavApt: s => s.objectsFavApt,
    objectFavApt: s => s.objectFavApt,
    paginationFavObjectsApt: s => s.paginationFavObjectsApt,
    pageFavObjectsApt: s => s.pageFavObjectsApt,

    objectsFavHouse: s => s.objectsFavHouse,
    objectFavAHouse: s => s.objectFavAHouse,
    paginationFavObjectsHouse: s => s.paginationFavObjectsHouse,
    pageFavObjectsHouse: s => s.pageFavObjectsHouse,

    objectsFavCom: s => s.objectsFavCom,
    objectFavCom: s => s.objectFavCom,
    paginationFavObjectsCom: s => s.paginationFavObjectsCom,
    pageFavObjectsCom: s => s.pageFavObjectsCom,
  }
}