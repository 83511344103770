import Vue from 'vue'
import App from './App.vue'
// import "./registerServiceWorker";
import router from './router'
import store from './store'
import Axios from 'axios'
import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'
import MaskedInput from 'vue-masked-input'
import Toasted from 'vue-toasted'
import MessagePlugin from '@/utils/message.plugin'
import VModal from 'vue-js-modal/dist/index.nocss.js'
// import VModal from 'vue-js-modal';
/// === FILTERS ===
import buildingTypeFilter from '@/filters/buildingType.filter.js'
import buildingMaterialFilter from '@/filters/buildingMaterial.filter.js'
import apartmentTypeFilter from '@/filters/apartmentType.filter.js'
import apartmentMaterialFilter from '@/filters/apartmentMaterial.filter.js'
import sourcePathFilter from '@/filters/sourcePath.filter.js'
import propertyStatusFilter from '@/filters/propertyStatus.filter.js'
import dateFilter from '@/filters/date.filter.js'
import districts from '@/filters/districts.filter.js'
import commerceType from '@/filters/commerceType.filter'
/// === END FILTERS ===

/// === CARDS ===
/// === END CARDS ===

Vue.config.productionTip = false

Vue.prototype.$http = Axios
const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

Vue.filter('buildingType', buildingTypeFilter)
Vue.filter('buildingMaterial', buildingMaterialFilter)
Vue.filter('apartmentType', apartmentTypeFilter)
Vue.filter('apartmentMaterial', apartmentMaterialFilter)
Vue.filter('sourcePath', sourcePathFilter)
Vue.filter('propertyStatus', propertyStatusFilter)
Vue.filter('date', dateFilter)
Vue.filter('districts', districts)
Vue.filter('commerceType', commerceType)

Vue.use(Vuelidate)
Vue.use(VueTheMask)
Vue.use(Toasted)
Vue.use(MessagePlugin)
Vue.use(VModal)

Vue.component('MaskedInput', MaskedInput)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
