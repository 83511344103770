import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    pagination: {},
    apartments: [],
    apartment: {},
    page: 1,
    // SELECTION
    selectionActive: false,
    // FILTER PARAMS
    searchParams: {},
    sortParams: {},
    filterParamsLocal: {},
    filterParamsSend: {},
    filterParamsActive: 0,
    filterParamsOriginal: {
      districts: [],
      settlements: [],
      created: null,
      priceFrom: null,
      priceTill: null,
      material: [],
      buildingType: [],
      floor: [],
      noOfRooms: [],
      status: [],
      autonomousHeatType: null,
      squareTotalTill: null,
      squareTotalFrom: null,
      squareLandFrom: null,
      squareLandTill: null,
      renovated: null,
      agent: [],
      garage: null,
      range: [],
      archivedAt: null,
      archivedTill: null,
      archivedReason: [],
      soldBy: [],
      relation: null,
      source: [],
      contactRoles: []
    }
  },
  mutations: {
    set(state, apartments) {
      state.apartments = _.unionBy(state.apartments, apartments, 'id')
    },
    setApartment(state, apartment) {
      state.apartment = apartment
    },
    updateApartment(state, apartment) {
      const foundApartment = state.apartments.find(x => x.id === apartment.id)
      Object.assign(foundApartment, apartment)
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.apartments = []
      state.page = 1
      state.selectionActive = false
    },
    changePage(state) {
      state.page++
    },
    // SELECTION
    setSelectionActive(state, isActive) {
      state.selectionActive = isActive
      state.apartments.forEach(apartment => {
        apartment.isSelected = false
      });
    },
    selectRenter(state, params) {
      const apartment = state.apartments.find(x => x.id === params.id)
      apartment.isSelected = params.isSelected
    },
    // FILTER PARAMS
    filterParamsLocal(state, params) {
      state.filterParamsLocal = params
    },
    filterParamsSend(state, params) {
      state.filterParamsSend = params
    },
    filterParamsActive(state, number) {
      state.filterParamsActive = number
    },
    sortParams(state, params) {
      state.sortParams = params
    },
    resetFilterParams(state) {
      state.filterParams = {}
      state.filterParamsActive = {}
    },
    searchParams(state, params) {
      state.searchParams = params
    }
  },
  actions: {
    async fetch({commit, getters}) {
      const page = getters.page
      try {
        const url = process.env.VUE_APP_BACKEND
        const apartments = await axios.get(url + '/rent/buyers/apartments', {
          params: {
            page,
            ...getters.filterParamsSend,
            ...getters.sortParams,
            ...getters.searchParams
          }
        })
        commit('pagination', apartments.data.pagination)
        commit('set', apartments.data.buyers.map(x => ({ ...x, isSelected: false })))
        commit('changePage')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async fetchApartment({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const apartment = await axios.get(url + '/rent/buyers/apartments/' + id)
        commit('setApartment', apartment.data.buyer)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async favorites({commit, dispatch}, {id, isSaved}) {
      if (!isSaved) {
        try {
          const url = process.env.VUE_APP_BACKEND
          await axios.patch(url + '/rent/buyers/apartments/' + id + '/favorites')
        } catch (err) {
          commit('setError', err, {root: true})
          throw err
        }
      } else {
        try {
          const url = process.env.VUE_APP_BACKEND
          await axios.delete(url + '/rent/buyers/apartments/' + id + '/favorites')
        } catch (err) {
          commit('setError', err, {root: true})
          throw err
        }
      }
      commit('resetFavRenters', 'apartment', {root: true})
      await dispatch('fetchFavoritesRenters', 'apartment', {root: true})
    },
    async create({commit, dispatch}, {data, newContacts}) {
      if (newContacts.length) {
        const contactsIDs = await dispatch('contacts/create', newContacts, {root: true})
        data.contactsIDs = data.contactsIDs.concat(contactsIDs)
      }
      const url = process.env.VUE_APP_BACKEND
      try {
        await axios.post(url + '/rent/buyers/apartments/', data)
        commit('reset')
        dispatch('fetch')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async edit({commit, dispatch}, {data, newContacts, id, delID}) {
      if (newContacts.length) {
        const contactsIDs = await dispatch('contacts/create', newContacts, {root: true})
        data.contactsIDs = data.contactsIDs.concat(contactsIDs)
      }
      const url = process.env.VUE_APP_BACKEND
      try {
        const response = await axios.put(url + '/rent/buyers/apartments/' + id, data)
        commit('updateApartment', response.data.buyer)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async archive({commit, dispatch}, {id, data}) {
      const url = process.env.VUE_APP_BACKEND
      try {
        await axios.delete(url + '/rent/buyers/apartments/' + id + '/archive', {data})
        commit('reset')
        dispatch('fetch')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async archiveSelected({state, commit, dispatch}, { data }) {
      const url = process.env.VUE_APP_BACKEND
      try {
        data.ids = state.apartments.filter(x => x.isSelected).map(x => x.id)
        await axios.delete(url + '/rent/buyers/apartments/archive', { data })
        commit('reset')
        dispatch('fetch')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async toggleLastCall({commit}, id) {
      const url = process.env.VUE_APP_BACKEND
      try {
        await axios.patch(url + '/rent/buyers/apartments/' + id + '/toggleLastCall')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    }
  },
  getters: {
    apartments: s => s.apartments,
    apartment: s => s.apartment,
    pagination: s => s.pagination,
    page: s => s.page,
    // FILTER PARAMS
    filterParamsOriginal: s => s.filterParamsOriginal,
    filterParamsLocal: s => s.filterParamsLocal,
    filterParamsSend: s => s.filterParamsSend,
    filterParamsActive: s => s.filterParamsActive,
    sortParams: s => s.sortParams,
    searchParams: s => s.searchParams,
    selectionActive: s => s.selectionActive
  }
}
