<template>
  <div class="data__inner data_main">
    <div class="data__top data__part">
      <label
        class="field__label"
        :class="{
          'field__label--filled': data.agent,
          'field__label--invalid': v.sendDataOther.agent.$dirty && !v.sendDataOther.agent.required
        }"
      >
        <div class="field__title">Відповідальний агент</div>
        <Multiselect
          :options="users"
          v-model="data.agent"
          label="fullName"
          trackBy="id"
          :showLabels="false"
          :searchable="false"
          placeholder="Відповідальний агент"
          class="mSelect"
        >
        </Multiselect>
      </label>
    </div>
    <div class="data__middle data__part">
      <label
        class="field__label"
        :class="{
          'field__label--filled': data.relevance,
          'field__label--invalid': v.sendDataOther.relevance.$dirty && !v.sendDataOther.relevance.required
        }"
      >
        <div class="field__title">Актуальність</div>
        <Multiselect
          :options="constants.RENT_RELEVANCE"
          v-model="data.relevance"
          :showLabels="false"
          :searchable="false"
          label="name"
          trackBy="id"
          placeholder="Актуальність"
          class="mSelect"
        />
      </label>
      <label
        v-if="isRented"
        class="field__label"
        :class="{
          'field__label--filled': data.rentedByBuyerId,
        }"
      >
        <div class="field__title field__title--required">ID Орендаря</div>
        <input class="field__input" v-model="data.rentedByBuyerId" placeholder="ID Орендаря" />
      </label>
      <label
        v-if="isRented"
        class="field__label"
        :class="{
          'field__label--filled': data.rentedTill,
          'field__label--invalid': v.sendDataOther.rentedTill.$dirty && !v.sendDataOther.rentedTill.required
        }"
      >
        <div class="field__title">Здано до</div>
        <date-picker
          v-model="data.rentedTill"
          isDark
          color="blue"
          :popover="{visibility: 'focus', placement: 'top-start'}"
        >
          <template v-slot="{inputValue, inputEvents}">
            <input class="field__input" :value="inputValue" v-on="inputEvents" readonly />
          </template>
        </date-picker>
      </label>
      <div
        class="field__label"
        :class="{
          'field__label--filled': data.source,
          'field__label--invalid': v.sendDataOther.source.$dirty && !v.sendDataOther.source.required
        }"
      >
        <div class="field__title">Джерело</div>
        <Treeselect
          v-model="data.source"
          :options="constants.RENT_PROPERTY_SOURCES"
          :disable-branch-nodes="true"
          :searchable="false"
          class="tSelect"
          placeholder="Джерело"
          openDirection="bottom"
        />
      </div>
      <div
        v-if="data.source === localConstants.PROPERTY_SOURCES_SEEKER_ID"
        class="field__label"
        :class="{
          'field__label--filled': data.seeker,
          'field__label--invalid': v.sendDataOther.seeker.$dirty && !v.sendDataOther.seeker.required
        }"
      >
        <div class="field__title">Шукач</div>
        <SeekerSearch :seeker="data.seeker" @select="seeker => (data.seeker = seeker)" />
      </div>
      <div
        class="field__label"
        :class="{
          'field__label--filled': data.lastCallAt
        }"
      >
        <div class="field__title">Останній дзвінок</div>
        <date-picker
          v-model="data.lastCallAt"
          isDark
          color="blue"
          :popover="{visibility: 'focus', placement: 'top-start'}"
        >
          <template v-slot="{inputValue, inputEvents}">
            <input class="field__input" :value="inputValue" v-on="inputEvents" readonly />
          </template>
        </date-picker>
      </div>
    </div>
  </div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Multiselect from 'vue-multiselect'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import {mapState} from 'vuex'
import localConstants from '@/utils/localConstants'
import SeekerSearch from '@/components/SeekerSearch'

export default {
  name: 'UploadDataOtherRent',
  props: ['data', 'v'],
  components: {
    Treeselect,
    Multiselect,
    DatePicker,
    SeekerSearch
  },
  data: () => ({
    searchActive: false,
    localConstants
  }),
  computed: {
    ...mapState({
      constants: s => s.main.constants,
      users: s => s.users.users
    }),
    isRented() {
      return this.data.relevance?.id === localConstants.RENT_RELEVANCE.RENTED
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
