export default function apartmentTypes(value) {
  let type;

  switch (value) {
    case 5:
      type = "Гуртожиток";
      break;
    case 6:
      type = "Гостинка";
      break;
    case 7:
      type = "Малосімейка";
      break;
    case 8:
      type = "Повноцінна";
      break;
    case 9:
      type = "Хрущовка";
      break;
    case 10:
      type = "Покращене";
      break;
    case 11:
      type = "Чешка";
      break;
    case 12:
      type = "Новобудова";
      break;
    case 13:
      type = "Сталінка";
      break;
    case 14:
      type = "Довоєнне";
      break;
  }
  return type
}
