import axios from 'axios';

export default {
  state: {
    buyersFavApt: [],
    buyerFavApt: {},
    paginationFavBuyersApt: {},
    pageFavBuyersApt: 1,
    buyersFavHouse: [],
    buyerFavAHouse: {},
    paginationFavBuyersHouse: {},
    pageFavBuyersHouse: 1,
    buyersFavCom: [],
    buyerFavCom: {},
    paginationFavBuyersCom: {},
    pageFavBuyersCom: 1,
  },
  mutations: {
    setFavBuyers(state, {buyers, type}) {
      type === 'apartment' ? state.buyersFavApt = state.buyersFavApt.concat(buyers) : null
      type === 'house' ? state.buyersFavHouse = state.buyersFavHouse.concat(buyers) : null
      type === 'commerce' ? state.buyersFavCom = state.buyersFavCom.concat(buyers) : null
    },
    setFavBuyer(state, {buyer, type}) {
      type === 'apartment' ? state.buyerFavApt = buyer : null
      type === 'house' ? state.buyerFavAHouse = buyer : null
      type === 'commerce' ? state.buyerFavCom = buyer : null
    },
    paginationFavBuyers(state, {paginationBuyers, type}) {
      type === 'apartment' ? state.paginationFavBuyersApt = paginationBuyers : null
      type === 'house' ? state.paginationFavBuyersHouse = paginationBuyers : null
      type === 'commerce' ? state.paginationFavBuyersCom = paginationBuyers : null
    },
    resetFavBuyers(state, type) {
      if (type === 'apartment') {
        state.buyersFavApt = []
        state.pageFavBuyersApt = 1
      } else if (type === 'house') {
        state.buyersFavHouse = []
        state.pageFavBuyersHouse = 1
      } else if (type === 'commerce') {
        state.buyersFavCom = []
        state.pageFavBuyersCom = 1
      } else {
        state.buyersFavApt = []
        state.pageFavBuyersApt = 1
      }
    },
    changePageFavBuy(state, type) {
      type === 'apartment' ? state.pageFavBuyersApt++ : null
      type === 'house' ? state.pageFavBuyersHouse++ : null
      type === 'commerce' ? state.pageFavBuyersCom++ : null
    }
  },
  actions: {
    async fetchFavoritesBuyers({commit, getters}, type) {
      let page = Number
      let way = String
      type === 'apartment' ? page = getters.pageFavBuyersApt : null
      type === 'house' ? page = getters.pageFavBuyersHouse : null
      type === 'commerce' ? page = getters.pageFavBuyersCom : null

      type === 'apartment' ? way = 'apartments' : null
      type === 'house' ? way = 'houses' : null
      type === 'commerce' ? way = 'commerce' : null

      try {
        const url = process.env.VUE_APP_BACKEND;
        const buyers = await axios.get(url + '/buyers/' + way + '/favorites', {
          params: {
            page,
            ...getters.filterParamsBuyers,
            ...getters.sortParamsBuyers,
          }
        });
        commit('paginationFavBuyers', {paginationBuyers: buyers.data.pagination, type})
        commit('setFavBuyers', {buyers: buyers.data.favorites, type})
        commit('changePageFavBuy', type)
      } catch (e) {
        commit("setError", e);
      }
    },
  },
  getters: {
    buyersFavApt: s => s.buyersFavApt,
    buyerFavApt: s => s.buyerFavApt,
    paginationFavBuyersApt: s => s.paginationFavBuyersApt,
    pageFavBuyersApt: s => s.pageFavBuyersApt,

    buyersFavHouse: s => s.buyersFavHouse,
    buyerFavAHouse: s => s.buyerFavAHouse,
    paginationFavBuyersHouse: s => s.paginationFavBuyersHouse,
    pageFavBuyersHouse: s => s.pageFavBuyersHouse,

    buyersFavCom: s => s.buyersFavCom,
    buyerFavCom: s => s.buyerFavCom,
    paginationFavBuyersCom: s => s.paginationFavBuyersCom,
    pageFavBuyersCom: s => s.pageFavBuyersCom,
  }
}