var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c(
        "div",
        { staticClass: "period main__row main__row--dark color--grey" },
        [
          _vm._l(_vm.monthsInYear, function(month) {
            return _c(
              "div",
              {
                key: month.id,
                staticClass: "period__item",
                class: { "period__item--active": month.active },
                on: {
                  click: function($event) {
                    return _vm.selectMonth(month)
                  }
                }
              },
              [_vm._v(" " + _vm._s(month.name) + " ")]
            )
          }),
          _c(
            "span",
            [
              _c("date-picker", {
                attrs: {
                  "is-range": "",
                  isDark: "",
                  color: "blue",
                  popover: { visibility: "focus" }
                },
                on: { input: _vm.setPeriod },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var inputValue = ref.inputValue
                      var inputEvents = ref.inputEvents
                      return [
                        _c("div", { staticClass: "dates-container" }, [
                          _c(
                            "input",
                            _vm._g(
                              {
                                staticClass: "field__input",
                                domProps: { value: inputValue.start }
                              },
                              inputEvents.start
                            )
                          ),
                          _c(
                            "input",
                            _vm._g(
                              {
                                staticClass: "field__input",
                                domProps: { value: inputValue.end }
                              },
                              inputEvents.end
                            )
                          )
                        ])
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.date,
                  callback: function($$v) {
                    _vm.date = $$v
                  },
                  expression: "date"
                }
              })
            ],
            1
          )
        ],
        2
      )
    ]),
    _c("div", [
      _c("div", { staticClass: "main__row main__row--grid grid-3" }, [
        _c("span", [
          _c("span", { staticClass: "color--red" }, [_vm._v("Відмови")]),
          _c("br"),
          _c("span", [_vm._v(_vm._s(_vm.report.calls.rejectedNumber))])
        ]),
        _c("span", [
          _c("span", { staticClass: "color--green" }, [_vm._v("ДЗВІНКИ")]),
          _c("br"),
          _c("span", [_vm._v(_vm._s(_vm.report.calls.totalNumber))])
        ]),
        _c("span", [
          _c("span", { staticClass: "color--yellow" }, [_vm._v("Думають")]),
          _c("br"),
          _c("span", [_vm._v(_vm._s(_vm.report.calls.unconfirmedNumber))])
        ])
      ]),
      _c(
        "div",
        { staticClass: "main__row main__row--dark main__row--grid grid-7" },
        _vm._l(_vm.constants.ACTION_SHOWING_REJECTION_REASONS, function(
          reason
        ) {
          return _c("span", [
            _c("span", { staticClass: "color--red" }, [
              _vm._v(_vm._s(reason.name))
            ]),
            _c("br"),
            _c("span", [_vm._v(_vm._s(_vm.getRejectedCallNumber(reason.id)))])
          ])
        }),
        0
      )
    ]),
    _c("div", [
      _c("div", { staticClass: "main__row main__row--grid grid-3" }, [
        _c("span", [
          _c("span", { staticClass: "color--red" }, [_vm._v("Відмови")]),
          _c("br"),
          _c("span", [_vm._v(_vm._s(_vm.report.showings.rejectedNumber))])
        ]),
        _c("span", [
          _c("span", { staticClass: "color--green" }, [_vm._v("ПОКАЗИ")]),
          _c("br"),
          _c("span", [_vm._v(_vm._s(_vm.report.showings.totalNumber))])
        ]),
        _c("span", [
          _c("span", { staticClass: "color--yellow" }, [_vm._v("Думають")]),
          _c("br"),
          _c("span", [_vm._v(_vm._s(_vm.report.showings.unconfirmedNumber))])
        ])
      ]),
      _c(
        "div",
        { staticClass: "main__row main__row--dark main__row--grid grid-7" },
        _vm._l(_vm.constants.ACTION_SHOWING_REJECTION_REASONS, function(
          reason
        ) {
          return _c("span", [
            _c("span", { staticClass: "color--red" }, [
              _vm._v(_vm._s(reason.name))
            ]),
            _c("br"),
            _c("span", [
              _vm._v(_vm._s(_vm.getRejectedShowingNumber(reason.id)))
            ])
          ])
        }),
        0
      )
    ]),
    _c("div", [
      _c(
        "div",
        {
          staticClass: "main__row",
          staticStyle: { "justify-content": "center" }
        },
        [_vm._v("РЕКЛАМА")]
      ),
      _c(
        "div",
        { staticClass: "main__row main__row--dark main__row--grid grid-4" },
        _vm._l(
          _vm.constants.ACTION_ADVERT_TYPES.filter(function(x) {
            return ![40, 50].includes(x.id)
          }),
          function(type) {
            return _c("span", [
              _c("span", [_vm._v(_vm._s(type.label))]),
              _c("br"),
              _c("span", [_vm._v(_vm._s(_vm.getAdvertNumber(type.id)))])
            ])
          }
        ),
        0
      ),
      _c(
        "div",
        { staticClass: "main__row main__row--dark main__row--grid grid-2" },
        _vm._l(
          _vm.constants.ACTION_ADVERT_TYPES.filter(function(x) {
            return [40, 50].includes(x.id)
          }),
          function(type) {
            return _c("span", [
              _c("span", [_vm._v(_vm._s(type.label))]),
              _c("br"),
              _c("span", [_vm._v(_vm._s(_vm.getAdvertNumber(type.id)))])
            ])
          }
        ),
        0
      ),
      _c(
        "div",
        { staticClass: "main__row main__row--dark main__row--grid grid-2" },
        [
          _c(
            "span",
            _vm._l(
              _vm.localConstants.ACTION_ADVERT_TYPES.Socials.children,
              function(child) {
                return _c("span", [
                  _c("span", [
                    _vm._v(
                      _vm._s(child.label) +
                        " - " +
                        _vm._s(_vm.getAdvertNumber(child.id))
                    )
                  ]),
                  _c("br")
                ])
              }
            ),
            0
          ),
          _c(
            "span",
            _vm._l(
              _vm.localConstants.ACTION_ADVERT_TYPES.Websites.children,
              function(child) {
                return _c("span", [
                  _c("span", [
                    _vm._v(
                      _vm._s(child.label) +
                        " - " +
                        _vm._s(_vm.getAdvertNumber(child.id))
                    )
                  ]),
                  _c("br")
                ])
              }
            ),
            0
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }