<template>
  <div class="company">
    <Multiselect
      :options="foundCompanies"
      :value="selectedCompany"
      :disabled="isDisabled"
      label="name"
      trackBy="id"
      :showLabels="false"
      :searchable="true"
      placeholder="Введіть назву компанії"
      class="mSelect"
      :internal-search="false"
      :closeOnSelect="true"
      :multiple="false"
      @select="select"
      @search-change="search"
    >
      <span slot="noOptions">Введіть назву компанії</span>
      <span slot="noResult">Компанію не знайдено</span>
      <span slot="singleLabel" slot-scope="props">{{ props.option.name }} ({{ props.option.type }})</span>
      <template slot="option" slot-scope="props"> {{ props.option.name }} ({{ props.option.type }}) </template>
    </Multiselect>
    <div v-if="isCreateButtonVisible" class="company__button--wrapper" @click="create">
      <i class="i-plus company__button"></i>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import CompanyUpload from '@/components/CompanyUpload'
import _ from 'lodash'

export default {
  name: 'CompanyUpload',
  components: {
    Multiselect,
    CompanyUpload
  },
  props: {
    selected: { default: null },
    type: { default: null }, // COMPANY_TYPES.AGENCY | COMPANY_TYPES.DEVELOPER
    isDisabled: { default: false },
    isCreateButtonVisible: { default: false },
  },
  data: () => ({
    foundCompanies: [],
    selectedCompany: null,
  }),
  created() {
    this.selectedCompany = this.selected
  },
  watch: {
    selected: function(company) {
      if (company !== this.selectedCompany) {
        this.selectedCompany = company
      }
    }
  },
  methods: {
    search: _.debounce(async function(query) {
      if (query.length < 2) {
        this.foundCompanies = []
        return
      }

      this.foundCompanies = await this.$store.dispatch('companies/search', {query, type: this.type })
    }, 500),
    create() {
      this.$modal.display(
        CompanyUpload,
        {},
        {transition: 'none', classes: 'modalArchive', width: '500px', height: '350px', name: 'CompanyUpload'}
      )
    },
    select(company) {
      this.selectedCompany = company
      this.$emit('select', this.selectedCompany)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.company {
  display: flex;

  &__button {
    color: #fff;

    &--wrapper {
      background-color: $grey_dark;
      font-size: 12px;
      height: 47px;
      width: 47px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      cursor: pointer;
      margin-left: 10px;

      &:hover {
        background-color: $blue;
      }
    }
  }
}
</style>
