<template>
  <div class="navbar__bottom">
    <div class="container">
      <div class="navbar__bottom-inner">
        <div class="navbar__bottom-left" :class="{'navbar__bottom-left--source': group.id === 'sources'}">
          <div class="field__label">
            <Multiselect
              v-model="group"
              :options="groups"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              placeholder="Вид"
              class="mSelect-sort"
              :allowEmpty="false"
              @select="changeGroup"
            />
          </div>
          <div class="field__label">
            <Multiselect
              v-model="type"
              :options="types"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              placeholder="Тип"
              class="mSelect-sort"
              :allowEmpty="false"
              @select="changeType"
            />
          </div>
          <div class="field__label">
            <Multiselect
              v-model="term"
              :options="terms"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              placeholder="Період"
              class="mSelect-sort"
              @select="changeTerm"
              :allowEmpty="false"
            />
          </div>
          <div v-if="group.id === 'sources'" class="field__label">
            <Treeselect
              v-model="source"
              :options="propertySources"
              :searchable="false"
              :clearable="false"
              class="tSelect stSelect"
              placeholder="Джерело"
              openDirection="bottom"
              @input="changeSource"
            />
          </div>
        </div>
        <div class="navbar__bottom-right">
          <div v-if="activeTerm === 'day'" class="stats__date">
            <date-picker
              v-model="date"
              is-range
              isDark
              color="blue"
              :popover="{visibility: 'focus'}"
              @input="changeDate"
            >
              <template v-slot="{inputValue, inputEvents}">
                <div class="stats__date-inner">
                  <input :value="inputValue.start" v-on="inputEvents.start" class="field__input" />
                  <input :value="inputValue.end" v-on="inputEvents.end" class="field__input" />
                </div>
              </template>
            </date-picker>
          </div>
        </div>
      </div>
      <div class="navbar__period period__wrapper">
        <div class="period__inner">
          <div v-if="activeTerm === 'week'" class="period">
            <div
              v-for="week of weeksInYear"
              :key="week.id"
              class="period__item"
              :class="{'period__item--active': week.active}"
              @click="selectWeek(week)"
            >
              {{ week.name }} ({{week.start}} - {{week.end}})
            </div>
          </div>
          <div v-if="activeTerm === 'month'" class="period">
            <div
              v-for="month of monthsInYear"
              :key="month.id"
              class="period__item"
              :class="{'period__item--active': month.active}"
              @click="selectMonth(month)"
            >
              {{ month.name }}
            </div>
          </div>
          <div v-if="activeTerm === 'quarter'" class="period">
            <div
              v-for="quarter of quartersInYear"
              :key="quarter.id"
              class="period__item"
              :class="{'period__item--active': quarter.active}"
              @click="selectQuarter(quarter)"
            >
              {{ quarter.name }}
            </div>
          </div>
          <div v-if="activeTerm === 'year'" class="period">
            <div
              v-for="year of yearInCentury"
              :key="year.id"
              class="period__item"
              :class="{'period__item--active': year.active}"
              @click="selectYear(year)"
            >
              {{ year.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Treeselect from '@riophae/vue-treeselect'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import moment from 'moment'
import {mapActions, mapState, mapMutations} from 'vuex'

export default {
  name: 'StatsNavbar',
  components: {
    Multiselect,
    Treeselect,
    DatePicker
  },
  data: () => ({
    date: null,
    types: [
      {name: 'Квартири', id: 'apartment'},
      {name: 'Будинки', id: 'house'},
      {name: 'Комерція', id: 'commerce'}
    ],
    type: {name: 'Квартири', id: 'apartment'},
    groups: [
      {name: 'Джерела', id: 'sources'},
      {name: 'Реклама', id: 'ads'},
      {name: 'Договір', id: 'contracts'},
    ],
    group: {name: 'Джерела', id: 'sources'},
    terms: [
      {name: 'День', id: 'day'},
      {name: 'Тиждень', id: 'week'},
      {name: 'Місяць', id: 'month'},
      {name: 'Квартал', id: 'quarter'},
      {name: 'Рік', id: 'year'}
    ],
    term: {name: 'Місяць', id: 'month'},
    year: moment().get('year'),
    activeTerm: 'month',
    monthsInYear: [
      {name: 'Січень', id: 0, active: false},
      {name: 'Лютий', id: 1, active: false},
      {name: 'Березень', id: 2, active: false},
      {name: 'Квітень', id: 3, active: false},
      {name: 'Травень', id: 4, active: false},
      {name: 'Червень', id: 5, active: false},
      {name: 'Липень', id: 6, active: false},
      {name: 'Серпень', id: 7, active: false},
      {name: 'Версень', id: 8, active: false},
      {name: 'Жовтень', id: 9, active: false},
      {name: 'Листопад', id: 10, active: false},
      {name: 'Грудень', id: 11, active: false}
    ],
    weeksInYear: [],
    quartersInYear: [
      {name: 'Перший', id: 1, active: false},
      {name: 'Другий', id: 2, active: false},
      {name: 'Третій', id: 3, active: false},
      {name: 'Четвертий', id: 4, active: false}
    ],
    yearInCentury: [
      {name: '2021', id: 2021, active: false},
      {name: '2022', id: 2022, active: false},
      {name: '2023', id: 2023, active: false},
      {name: '2024', id: 2024, active: false},
      {name: '2025', id: 2025, active: false},
      {name: '2026', id: 2026, active: false},
      {name: '2027', id: 2027, active: false},
      {name: '2028', id: 2028, active: false},
      {name: '2029', id: 2029, active: false},
      {name: '2030', id: 2030, active: false}
    ],
    source: 0
  }),
  computed: {
    ...mapState({
      constants: s => s.main.constants || {}
    }),
    propertySources() {
      return [ { id: 0, label: 'Всі' }, ...this.constants?.PROPERTY_SOURCES ?? [] ]
    }
  },
  watch: {
    activeTerm(term) {
      if (term === 'week') {
        setTimeout(() => {
          const period = document.querySelector('.period')
          const activePeriod = document.querySelector('.period__item--active')
          period.scrollLeft = activePeriod.offsetLeft
        }, 0)
      }
    }
  },
  async created() {
    for (let i = 1; i < moment().weeksInYear(); i++) {
      const start = moment()
        .year(this.year)
        .week(i)
        .startOf('week')
        .format('DD-MM')
      const end = moment()
        .year(this.year)
        .week(i)
        .endOf('week')
        .format('DD-MM')

      this.weeksInYear.push({
        id: i,
        name: i,
        start,
        end,
        active: false
      })
    }

    this.monthsInYear[moment().get('month')].active = true
  },
  methods: {
    ...mapActions({
      fetch: 'stats/objects/fetch'
    }),
    ...mapMutations({
      setType: 'stats/objects/setType',
      setGroup: 'stats/objects/setGroup',
      setPeriod: 'stats/objects/setPeriod',
      setSource: 'stats/objects/setSource'
    }),
    async changeDate() {
      const date = {
        'period[from]': moment(this.date.start).format('DD-MM-YYYY'),
        'period[till]': moment(this.date.end).format('DD-MM-YYYY')
      }
      this.term = {name: 'День', id: 'day'}
      this.setPeriod(date)
      await this.fetch()
    },
    async changeType(type) {
      this.setType(type.id)
      await this.fetch()
    },
    async changeGroup(group) {
      this.setGroup(group.id)
      await this.fetch()
    },
    async changeSource(source) {
      this.setSource(source)
      await this.fetch()
    },
    changeTerm(term) {
      this.activeTerm = term.id
      if (term.id === 'month') {
        const monthID = moment().get('month')
        this.selectMonth({id: monthID})
      } else if (term.id === 'week') {
        const monthID = moment().get('week')
        this.selectWeek({id: monthID})
      } else if (term.id === 'quarter') {
        const quarterID = moment().get('quarter')
        this.selectQuarter({id: quarterID})
      } else if (term.id === 'year') {
        const yearID = moment().get('year')
        this.selectYear({id: yearID})
      }
    },
    async selectWeek(week) {
      const weekStart = moment()
        .year(this.year)
        .week(week.id)
        .startOf('week')
        .format('DD-MM-YYYY')
      const weekEnd = moment()
        .year(this.year)
        .week(week.id)
        .endOf('week')
        .format('DD-MM-YYYY')

      const range = {
        'period[from]': weekStart,
        'period[till]': weekEnd
      }

      this.weeksInYear.forEach(week => (week.active = false))
      this.weeksInYear[week.id - 1].active = true
      this.setPeriod(range)
      await this.fetch()
    },
    async selectMonth(month) {
      const monthStart = moment()
        .year(this.year)
        .month(month.id)
        .startOf('month')
        .format('DD-MM-YYYY')

      const monthEnd = moment()
        .year(this.year)
        .month(month.id)
        .endOf('month')
        .format('DD-MM-YYYY')

      const range = {
        'period[from]': monthStart,
        'period[till]': monthEnd
      }

      this.monthsInYear.forEach(month => (month.active = false))
      this.monthsInYear[month.id].active = true

      this.setPeriod(range)
      await this.fetch()
    },
    async selectQuarter(quarter) {
      const quarterStart = moment()
        .year(this.year)
        .quarter(quarter.id)
        .startOf('quarter')
        .format('DD-MM-YYYY')

      const quarterEnd = moment()
        .year(this.year)
        .quarter(quarter.id)
        .endOf('quarter')
        .format('DD-MM-YYYY')

      const range = {
        'period[from]': quarterStart,
        'period[till]': quarterEnd
      }
      this.quartersInYear.forEach(quarter => (quarter.active = false))
      this.quartersInYear[quarter.id - 1].active = true

      this.setPeriod(range)
      await this.fetch()
    },
    async selectYear(year) {
      const yearStart = moment()
        .year(year.id)
        .startOf('year')
        .format('DD-MM-YYYY')

      const yearEnd = moment()
        .year(year.id)
        .endOf('year')
        .format('DD-MM-YYYY')

      const range = {
        'period[from]': yearStart,
        'period[till]': yearEnd
      }

      this.yearInCentury.forEach(year => (year.active = false))
      this.yearInCentury.find(y => y.id === year.id).active = true

      this.setPeriod(range)
      await this.fetch()
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style.scss';
</style>
