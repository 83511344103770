export default function buildingMaterials(value) {
  let type;

  switch (value) {
    case 1:
      type = "Цегла";
      break;
    case 2:
      type = "Блоки";
      break;
    case 3:
      type = "Дерево";
      break;
    case 4:
      type = "Інше";
      break;
    case 5:
      type = "Панель";
      break;
  }
  return type
}
