var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upload__container upload-action" }, [
    _c("div", { staticClass: "upload__top" }, [
      _c("div", { staticClass: "upload__title" }, [
        _vm.user
          ? _c("span", [_vm._v("Редагування користувача")])
          : _c("span", [_vm._v("Додавання користувача")])
      ]),
      _c("div", { staticClass: "upload__buttons" }, [
        _c(
          "div",
          {
            staticClass: "button button--fill button--grey",
            on: {
              click: function($event) {
                return _vm.$modal.hide("UploadUser")
              }
            }
          },
          [_vm._v("Скасувати")]
        ),
        _c(
          "div",
          { staticClass: "button button--fill", on: { click: _vm.send } },
          [_vm._v("Зберегти")]
        ),
        _c(
          "div",
          {
            staticClass: "button__icon",
            on: {
              click: function($event) {
                return _vm.$modal.hide("UploadUser")
              }
            }
          },
          [_c("i", { staticClass: "ri-close-circle-line" })]
        ),
        _c(
          "div",
          {
            staticClass: "button__icon button__icon--blue",
            on: { click: _vm.send }
          },
          [_c("i", { staticClass: "ri-save-3-line" })]
        )
      ])
    ]),
    _c("div", { staticClass: "upload__main" }, [
      _c("div", { staticClass: "upload__part upload__part-role" }, [
        _c("div", { staticClass: "upload__part-title" }, [_vm._v("Роль:")]),
        _c("div", { staticClass: "upload__part-main" }, [
          _c(
            "div",
            {
              staticClass: "indicator indicator--rounded indicator--yellow",
              class: { "indicator--active": _vm.role === 3 },
              on: {
                click: function($event) {
                  _vm.role = 3
                }
              }
            },
            [_vm._v(" Офіс-Менеджер ")]
          ),
          _c(
            "div",
            {
              staticClass: "indicator indicator--rounded indicator--red",
              class: { "indicator--active": _vm.role === 4 },
              on: {
                click: function($event) {
                  _vm.role = 4
                }
              }
            },
            [_vm._v(" Адмін ")]
          ),
          _c(
            "div",
            {
              staticClass: "indicator indicator--rounded indicator--blue",
              class: { "indicator--active": _vm.role === 2 },
              on: {
                click: function($event) {
                  _vm.role = 2
                }
              }
            },
            [_vm._v(" Агент ")]
          ),
          _c(
            "div",
            {
              staticClass: "indicator indicator--rounded indicator--grey",
              class: { "indicator--active": _vm.role === 1 },
              on: {
                click: function($event) {
                  _vm.role = 1
                }
              }
            },
            [_vm._v(" Гість ")]
          )
        ])
      ]),
      _c("div", { staticClass: "upload__part upload__part-rank" }, [
        _c("div", { staticClass: "upload__part-title" }, [_vm._v("Ранг:")]),
        _c("div", { staticClass: "upload__part-main" }, [
          _c(
            "div",
            {
              staticClass: "indicator indicator--rounded indicator--def",
              class: { "indicator--active": _vm.rank === 1 },
              on: {
                click: function($event) {
                  _vm.rank = 1
                }
              }
            },
            [_vm._v(" Стажер ")]
          ),
          _c(
            "div",
            {
              staticClass: "indicator indicator--rounded indicator--def",
              class: { "indicator--active": _vm.rank === 2 },
              on: {
                click: function($event) {
                  _vm.rank = 2
                }
              }
            },
            [_vm._v(" Новачок ")]
          ),
          _c(
            "div",
            {
              staticClass: "indicator indicator--rounded indicator--def",
              class: { "indicator--active": _vm.rank === 3 },
              on: {
                click: function($event) {
                  _vm.rank = 3
                }
              }
            },
            [_vm._v(" Агент ")]
          ),
          _c(
            "div",
            {
              staticClass: "indicator indicator--rounded indicator--def",
              class: { "indicator--active": _vm.rank === 4 },
              on: {
                click: function($event) {
                  _vm.rank = 4
                }
              }
            },
            [_vm._v(" Професіонал ")]
          )
        ])
      ]),
      _c("div", { staticClass: "upload__part upload__part-name" }, [
        _c(
          "label",
          {
            staticClass: "field__label",
            class: {
              "field__label--filled": _vm.fullName
            }
          },
          [
            _c("div", { staticClass: "field__title field__title--required" }, [
              _vm._v("Логін")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.fullName,
                  expression: "fullName"
                }
              ],
              staticClass: "field__input",
              attrs: { placeholder: "Логін" },
              domProps: { value: _vm.fullName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.fullName = $event.target.value
                }
              }
            })
          ]
        ),
        _c(
          "label",
          {
            staticClass: "field__label",
            class: {
              "field__label--filled": _vm.lastName,
              "field__label--invalid":
                _vm.$v.lastName.$dirty && !_vm.$v.lastName.required
            }
          },
          [
            _c("div", { staticClass: "field__title field__title--required" }, [
              _vm._v("Прізвище")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.lastName,
                  expression: "lastName"
                }
              ],
              staticClass: "field__input",
              attrs: { placeholder: "Прізвище" },
              domProps: { value: _vm.lastName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.lastName = $event.target.value
                }
              }
            })
          ]
        ),
        _c(
          "label",
          {
            staticClass: "field__label",
            class: {
              "field__label--filled": _vm.firstName,
              "field__label--invalid":
                _vm.$v.firstName.$dirty && !_vm.$v.firstName.required
            }
          },
          [
            _c("div", { staticClass: "field__title field__title--required" }, [
              _vm._v("Ім'я")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.firstName,
                  expression: "firstName"
                }
              ],
              staticClass: "field__input",
              attrs: { placeholder: "Ім'я" },
              domProps: { value: _vm.firstName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.firstName = $event.target.value
                }
              }
            })
          ]
        ),
        _c(
          "label",
          {
            staticClass: "field__label",
            class: {
              "field__label--filled": _vm.fathersName
            }
          },
          [
            _c("div", { staticClass: "field__title field__title--required" }, [
              _vm._v("По Батькові")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.fathersName,
                  expression: "fathersName"
                }
              ],
              staticClass: "field__input",
              attrs: { placeholder: "По Батькові" },
              domProps: { value: _vm.fathersName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.fathersName = $event.target.value
                }
              }
            })
          ]
        )
      ]),
      _c("div", { staticClass: "upload__part upload__part--half" }, [
        _c(
          "label",
          {
            staticClass: "field__label",
            class: {
              "field__label--filled": _vm.email,
              "field__label--invalid":
                (_vm.$v.email.$dirty && !_vm.$v.email.required) ||
                (_vm.$v.email.$dirty && !_vm.$v.email.email)
            }
          },
          [
            _c("div", { staticClass: "field__title field__title--required" }, [
              _vm._v("E-mail")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email"
                }
              ],
              staticClass: "field__input",
              attrs: { placeholder: "E-mail" },
              domProps: { value: _vm.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.email = $event.target.value
                }
              }
            })
          ]
        ),
        _c(
          "label",
          {
            staticClass: "field__label",
            class: {
              "field__label--filled": _vm.password,
              "field__label--invalid":
                _vm.$v.password.$dirty &&
                !_vm.$v.password.required &&
                !_vm.$v.password.minLength
            }
          },
          [
            _c("div", { staticClass: "field__title field__title--required" }, [
              _vm._v("Пароль")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password"
                }
              ],
              staticClass: "field__input",
              attrs: { placeholder: "Пароль" },
              domProps: { value: _vm.password },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.password = $event.target.value
                }
              }
            })
          ]
        )
      ]),
      _c("div", { staticClass: "upload__part upload__part--third" }, [
        _c(
          "div",
          {
            staticClass: "field__label",
            class: {
              "field__label--filled": _vm.isOnContract,
              "field__label--invalid":
                _vm.$v.isOnContract.$dirty && !_vm.$v.isOnContract.required
            }
          },
          [
            _c("div", { staticClass: "field__title" }, [_vm._v("Контракт")]),
            _c("Multiselect", {
              staticClass: "mSelect",
              attrs: {
                options: _vm.choice,
                label: "name",
                trackBy: "id",
                showLabels: false,
                searchable: false,
                maxHeight: 200,
                placeholder: "Контракт"
              },
              model: {
                value: _vm.isOnContract,
                callback: function($$v) {
                  _vm.isOnContract = $$v
                },
                expression: "isOnContract"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "field__label",
            class: {
              "field__label--filled": _vm.isOfficial,
              "field__label--invalid":
                _vm.$v.isOfficial.$dirty && !_vm.$v.isOfficial.required
            }
          },
          [
            _c("div", { staticClass: "field__title" }, [_vm._v("Офіційно")]),
            _c("Multiselect", {
              staticClass: "mSelect",
              attrs: {
                options: _vm.official,
                label: "name",
                trackBy: "id",
                showLabels: false,
                searchable: false,
                maxHeight: 200,
                placeholder: "Офіційно"
              },
              model: {
                value: _vm.isOfficial,
                callback: function($$v) {
                  _vm.isOfficial = $$v
                },
                expression: "isOfficial"
              }
            })
          ],
          1
        ),
        _c(
          "label",
          {
            staticClass: "field__label field__label-tel",
            class: {
              "field__label--filled": _vm.phone,
              "field__label--invalid":
                (_vm.$v.phone.$dirty && !_vm.$v.phone.required) ||
                (_vm.$v.phone.$dirty && !_vm.$v.phone.minLength)
            }
          },
          [
            _c("div", { staticClass: "field__title field__title--required" }, [
              _vm._v("Телефон")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.phone,
                  expression: "phone"
                },
                {
                  name: "mask",
                  rawName: "v-mask",
                  value: "##########",
                  expression: "'##########'"
                }
              ],
              staticClass: "field__input",
              attrs: { placeholder: "Телефон" },
              domProps: { value: _vm.phone },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.phone = $event.target.value
                }
              }
            })
          ]
        )
      ]),
      _vm.user
        ? _c("div", { staticClass: "upload__part" }, [
            _c("label", { staticClass: "field__label" }, [
              _c(
                "button",
                {
                  staticClass: "button button--fill is-red",
                  on: { click: _vm.openDelModal }
                },
                [_vm._v("Видалити")]
              )
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }