<template>
  <div class="upload__choice rent">
    <div class="rent__top">
      <div class="rent__title">Виберіть об'єкт</div>
    </div>
    <div class="rent__main">
      <div class="rent__card" @click="select('apartment')">
        <div class="rent__icon">
          <i class="ri-building-4-fill"></i>
        </div>
        <div class="rent__name">Квартира</div>
      </div>
      <div class="rent__card" @click="select('house')">
        <div class="rent__icon">
          <i class="ri-home-5-fill"></i>
        </div>
        <div class="rent__name">Будинок</div>
      </div>
      <div class="rent__card" @click="select('commerce')">
        <div class="rent__icon">
          <i class="ri-store-fill"></i>
        </div>
        <div class="rent__name">Комерція</div>
      </div>
    </div>
  </div>
</template>

<script>
import Upload from '@/views/Rent/Upload'

export default {
  name: 'UploadChoice',
  methods: {
    select(type) {
      this.$modal.display(
        Upload,
        {type},
        {transition: 'none', classes: 'modal', width: '1000px', height: '92%', clickToClose: false, name: 'UploadRent'},
      )
      this.$modal.hide('UploadChoice')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "style.scss";
</style>
