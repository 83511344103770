<template>
  <div class="info__inner">
    <div class="info__header">
      <div class="info__nav">
        <div class="nav__link" @click="block = 'main'" :class="{'nav__link--active': block === 'main'}">
          Основна
        </div>
        <div class="nav__link" @click="block = 'contacts'" :class="{'nav__link--active': block === 'contacts'}">
          Контакти
        </div>
      </div>
      <div class="info__buttons">
        <div class="info__buttons-right">
          <div
            v-if="user.role === localConstants.USER_ROLES.ADMIN"
            class="card__button button__icon button__icon--border"
            @click.stop.prevent
          >
            <Multiselect
              class="mSelect-dropdown"
              :options="list"
              :searchable="false"
              :show-labels="false"
              :reset-after="true"
              :value="selectedMenuItem"
              placeholder=""
              open-direction="bottom"
              @select="actions"
            />
          </div>
          <div class="button__icon button--onmobile" @click="$modal.hide('InfoBuilding')">
            <i class="ri-close-fill"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="info__content">
      <div class="info__main" v-if="block === 'main'">
        <div class="main__header">
          <div class="main__header-bottom">
            <div class="main__id">ID {{ building.id }}</div>
            <div class="main__date"><i class="i-calendar"></i> {{ building.createdAt | date }}</div>
          </div>
        </div>
        <div class="main__content">
          <div class="main__row main__row--dark">
            <div class="main__row-left">
              <i class="i-employees"></i>
              <div class="main__text">Автор</div>
            </div>
            <div class="main__row-right">
              <div class="main__row-letter">
                <div>{{ building.authorName[0] }}</div>
              </div>
              <div class="main__text">{{ building.authorName }}</div>
            </div>
          </div>
          <div class="main__row main__row--dark">
            <div class="main__text">ОСББ/Управління</div>
            <div class="main__text">{{ building.cooperativeTypeName }}</div>
          </div>
          <div class="main__row main__row--dark">
            <div class="main__text">Назва</div>
            <div class="main__text">"{{ building.cooperativeName }}"</div>
          </div>
          <div class="main__row main__row--dark">
            <div class="main__text">Останній дзвінок</div>
            <div class="main__text">
              <i class="i-calendar"></i>
              <div v-if="building.lastCallAt">{{ moment(building.lastCallAt).format('DD.MM.YYYY') }}</div>
              <div v-else>-</div>
            </div>
          </div>
          <div class="main__row">
            <div class="main__text">Вулиця</div>
            <div class="main__text">{{ building.street }}</div>
          </div>
          <div class="main__row">
            <div class="main__text">№ буд.</div>
            <div class="main__text">{{ building.buildingNo }}</div>
          </div>
          <div class="main__row">
            <div class="main__text">Поверхів</div>
            <div class="main__text">{{ building.floorsNumber }}</div>
          </div>
          <div class="main__row">
            <div class="main__text">Рік</div>
            <div class="main__text">{{ building.year ? building.year : '-' }}</div>
          </div>
          <div class="main__row">
            <div class="main__text">Аварійність</div>
            <div class="main__text">{{ building.emergency != null ? (building.emergency ? 'Так' : 'Ні') : '-' }}</div>
          </div>
          <div class="main__row main__row--area">
            <div class="main__text">Коментар</div>
            <div class="main__text">
              <span class="card__span">{{ building.comment }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="info__contacts" v-if="block === 'contacts'">
        <button class="contacts__button button button--fill" @click="changeLastCall">Зберегти дзвінок</button>
        <div class="contacts__item" v-for="contact in building.contacts" :key="contact.id">
          <div class="contacts__header">
            <div class="contacts__letter">{{ contact.name[0] }}</div>
            <div class="contacts__name">{{ contact.name }}</div>
            <div class="contacts__role">Роль: {{ contact.roles.map(x => x.name).join(' / ') }}</div>
            <div class="contacts__date">Дата народження: {{ contact.birthday }}</div>
            <div v-if="contact.company" class="contacts__company">
              Компанія: "{{ contact.company.name }}"
              <span v-if="contact.jobPosition">({{ contact.jobPosition.name }})</span>
            </div>
          </div>
          <div class="contacts__phones" v-for="phone in contact.phones" :key="phone.id">
            <div class="contacts__phone-wrapper">
              <a :href="'tel:' + phone.phone" @click.stop><i class="ri-phone-fill"></i></a>
              <div class="contacts__phone">{{ phone.phone }}</div>
              <div class="contacts__messengers">
                <button
                  class="button__circle"
                  :class="{'button__circle--blue': phone.isOnViber}"
                  @click="sendMessage({...phone, message: 'viber'})"
                >
                  <i class="i-viber"></i>
                </button>
                <i
                  class="i-telegram button__circle"
                  :class="{
                    'button__circle--blue': phone.isOnTelegram
                  }"
                ></i>
                <i
                  class="i-whatsapp button__circle"
                  :class="{
                    'button__circle--blue': phone.isOnWhatsapp
                  }"
                ></i>
                <i
                  class="i-messanger button__circle"
                  :class="{
                    'button__circle--blue': phone.isOnFacebook
                  }"
                ></i>
              </div>
            </div>
          </div>
          <div class="contacts__rel">
            <Relation v-for="(rel, i) in contact.relations" :key="i" :relation="rel" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import localConstants from '@/utils/localConstants'
import {mapState, mapGetters} from 'vuex'
import moment from 'moment'
import Relation from '@/components/Relation'
import UploadBuilding from '@/views/Buildings/Upload'

export default {
  name: 'InfoBuilding',
  props: ['building'],
  components: {
    Multiselect,
    Relation,
  },
  data: () => ({
    localConstants,
    moment,
    block: 'main',
    list: ['Редагувати'],
    selectedMenuItem: null,
  }),
  computed: {
    ...mapGetters(['user']),
    ...mapState({
      users: s => s.users.users,
      constants: s => s.main.constants,
    }),
  },
  async created() {
    if (this.user.role === localConstants.USER_ROLES.ADMIN) {
      this.list.push('Видалити')
    }
    if (this.building.contacts?.length > 0) {
      const relations = await this.$store.dispatch('contacts/fetchRelations', this.building.contacts.map(x => x.id))
      for (const contact of this.building.contacts) {
        contact.relations = relations.filter(x => x.id === contact.id)
      }
    }
  },
  methods: {
    actions(option) {
      if (option === 'Редагувати') {
        this.$modal.display(UploadBuilding, {building: this.building}, {name: 'modalUploadBuilding'})
      } else if (option === 'Видалити') {
        this.$store.dispatch('buildings/delete', this.building.id)
      }
    },
    async changeLastCall() {
      try {
        await this.$store.dispatch('buildings/toggleLastCall', this.building.id)
        this.building.lastCallAt = new Date()
        this.$modal.hide('InfoBuilding')
      } catch (err) {
        this.$store.commit('setError', err)
      }
    },
    sendMessage(phone) {
      const number = phone.phone.slice(1, 4) + phone.phone.slice(6, 9) + phone.phone.slice(10, 14)
      switch (phone.message) {
        case 'viber':
          if (phone.isOnViber) document.location.href = `viber://chat?number=${number}`
          break
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/info.scss';
</style>
<style lang="scss">
@import '@/assets/styles/_variables.scss';

.chart__tooltip {
  color: $blue;
  background-color: transparent;
}

.apexcharts-text {
  fill: $white;
}
</style>

<style lang="scss" scoped>
@import 'style';
.main__text {
  width: 100%;
  & > textarea {
    resize: none;
    width: 100%;
    &:disabled {
      background-color: transparent;
      color: #fff;
      border: none;
    }
  }
}
</style>
