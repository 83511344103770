export default function districts(value) {
  let type;

  switch (value) {
    case 43:
      type = "Автовокзал";
      break;
    case 44:
      type = "Центр";
      break;
    case 45:
      type = "Північний";
      break;
    case 46:
      type = "Чайка";
      break;
    case 47:
      type = "Ювілейний";
      break;
    case 48:
      type = "Пивзавод";
      break;
    case 49:
      type = "Боярка";
      break;
    case 50:
      type = "Відін";
      break;
    case 51:
      type = "Басів кут";
      break;
    case 52:
      type = "Зоопарк";
      break;
    case 53:
      type = "Новий двір";
      break;
    case 54:
      type = "Колоденка";
      break;
    case 55:
      type = "Тинне";
      break;
    case 56:
      type = "Робітничий";
      break;
  }
  return type
}
