<template>
  <div class="info__inner">
    <div class="info__header">
      <div class="info__nav">
        <div class="info__archive">
          <i class="ri-archive-line link__sidebar_icon"></i>
        </div>
        <div class="nav__link" @click="block = 'main'" :class="{'nav__link--active': block === 'main'}">Основна</div>
        <div class="nav__link" @click="block = 'photo'" :class="{'nav__link--active': block === 'photo'}">Фото</div>
        <div class="nav__link" @click="block = 'other'" :class="{'nav__link--active': block === 'other'}">
          Детальніше
        </div>
        <div class="nav__link" @click="block = 'contacts'" :class="{'nav__link--active': block === 'contacts'}">
          Контакти
        </div>
      </div>
      <div class="info__buttons">
        <div class="info__buttons-left">
          <div class="info__icon is-red">
            <i v-if="info.type === 'apartment'" class="ri-building-4-fill"></i>
            <i v-if="info.type === 'house'" class="ri-home-5-fill"></i>
            <i v-if="info.type === 'commerce'" class="ri-store-fill"></i>
          </div>
        </div>
        <div class="info__buttons-right">
          <div class="button__icon" @click.stop.prevent>
            <Multiselect
              class="mSelect-dropdown"
              :options="list"
              :searchable="false"
              :show-labels="false"
              :reset-after="true"
              :value="item"
              placeholder
              open-direction="bottom"
              @select="actions"
            />
          </div>
          <div class="button__icon button--onmobile" @click="$modal.hide('InfoRent')">
            <i class="ri-close-fill"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="info__content">
      <div class="info__main" v-if="block === 'main'">
        <div class="main__header">
          <div class="main__header-top">
            <div class="main__price card__price">$ {{ info.price }}</div>
            <div class="main__indicators">
              <div :class="{
                'card__tag card__tag--green main__indicator': info.relevance === localConstants.RENT_RELEVANCE.RELEVANT,
                'card__tag card__tag--yellow main__indicator': info.relevance === localConstants.RENT_RELEVANCE.RENTED,
                }">
                <span class="card__span">{{ info.relevanceName }}</span>
                <span v-if="info.relevance === localConstants.RENT_RELEVANCE.RENTED"> до {{ info.rentedTill | date }}</span>
              </div>
            </div>
          </div>
          <div class="main__header-middle">{{ info.title }}</div>
          <div class="main__header-bottom">
            <div class="main__id">ID {{ info.id }}</div>
            <div class="main__date">
              <i class="i-calendar"></i>
              {{ info.createdAt | date }}
            </div>
            <Relation v-for="(rel, i) in info.relations" type="info-tile" :relation="rel" :compact="true" :key="i"/>
            <div class="main__address">
              <i class="i-location"></i>
              <span>{{ info.settlement }},</span>
              <span v-if="info.districtName">{{ info.districtName }},</span>
              <span>вул. {{ info.street }}</span>
              <span>{{ info.houseNo }}</span>
              <span>кв.{{ info.aptNo }}</span>
            </div>
          </div>
        </div>
        <div class="main__content">
          <div class="main__row main__row--dark">
            <div class="main__row-left">
              <i class="i-employees"></i>
              <div class="main__text">Автор</div>
            </div>
            <div class="main__row-right">
              <div class="main__row-letter">
                <div class>{{ info.authorName[0] }}</div>
              </div>
              <div class="main__text">{{ info.authorName }}</div>
            </div>
          </div>
          <div class="main__row main__row--dark">
            <div class="main__row-left">
              <i class="i-employees"></i>
              <div class="main__text">Відповідальний агент</div>
            </div>
            <div class="main__row-right">
              <div class="main__row-letter">
                <div class>{{ info.responsibleName[0] }}</div>
              </div>
              <div class="main__text">{{ info.responsibleName }}</div>
            </div>
          </div>
          <div class="main__row">
            <div class="main__text">К-сть кімнат</div>
            <div class="main__text">{{ info.noOfRooms }}</div>
          </div>
          <div class="main__row" v-if="info.type === 'apartment'">
            <div class="main__text">Вид/Тип</div>
            <div class="main__text">
              {{ info.material | apartmentMaterial }}/{{ info.buildingType | apartmentType }}
            </div>
          </div>
          <div class="main__row" v-else-if="info.type === 'house'">
            <div class="main__text">Вид/Тип</div>
            <div class="main__text">{{ info.material | buildingMaterial }}/{{ info.buildingType | buildingType }}</div>
          </div>
          <div class="main__row" v-else>
            <div class="main__text">Призначення</div>
            <div class="main__text">{{ info.buildingType | commerceType }}</div>
          </div>
          <div class="main__row">
            <div class="main__text">Поверх</div>
            <div class="main__text">{{ info.floor }}/{{ info.floors }}</div>
          </div>
          <div class="main__row">
            <div class="main__text">Площа м²</div>
            <div class="main__text">{{ info.squareTotal }}/{{ info.squareLiving }}/{{ info.squareKitchen }}</div>
          </div>
          <div class="main__row" v-if="info.squareLand">
            <div class="main__text">Площа ділянки м²</div>
            <div class="main__text">{{ info.squareLand }}</div>
          </div>
          <div class="main__row" v-if="info.type !== 'house'">
            <div class="main__text">Ремонт/Авт. опал.</div>
            <div class="main__text">
              <span class="card__span" v-if="info.renovated">Є</span>
              <span class="card__span" v-else>Немає</span>
              <span class="card__span">/</span>
              <span class="card__span">{{ info.autonomousHeatType }}</span>
            </div>
          </div>
          <div class="main__row" v-if="info.type === 'house'">
            <div class="main__text">Ремонт</div>
            <div class="main__text">
              <span class="card__span" v-if="info.renovated">Є</span>
              <span class="card__span" v-else>Немає</span>
            </div>
          </div>
          <div class="main__row">
            <div class="main__text">Під іпотеку</div>
            <div class="main__text">{{ info.mortgagePurchase ? 'Так' : 'Ні' }}</div>
          </div>
          <div class="main__row">
            <div class="main__text">Джерело</div>
            <div class="main__text">{{ source }}</div>
          </div>
          <div class="main__row" v-if="info.type === 'house'">
            <div class="main__text">Гараж</div>
            <div class="main__text">
              <span class="card__span" v-if="info.garage">Є</span>
              <span class="card__span" v-else>Немає</span>
            </div>
          </div>
          <div class="main__row" v-if="info.type === 'house'">
            <div class="main__text">Рік побудови</div>
            <div class="main__text">
              <span class="card__span">{{ info.builtAt }}</span>
            </div>
          </div>
          <div class="main__row" v-if="info.type === 'house'">
            <div class="main__text">Кадастровий номер</div>
            <div class="main__text">{{ info.registrationNo }}</div>
          </div>
          <div class="main__row main__row--blue">
            <div class="main__text">Причина видалення</div>
            <div class="main__text">{{ archivedReason }}</div>
          </div>
          <div class="main__row main__row--blue">
            <div class="main__text">Видалено до</div>
            <div class="main__text">
              <i class="i-calendar"></i>
              {{ info.archivedTill | date }}
            </div>
          </div>
          <div class="main__row main__row--blue">
            <div class="main__text">Дата видалення</div>
            <div class="main__text">
              <i class="i-calendar"></i>
              {{ info.archivedAt | date }}
            </div>
          </div>
          <div class="main__row main__row--area">
            <div class="main__text">Опис</div>
            <div class="main__text">
              <textarea id="autoresizing" disabled>{{ info.description }}</textarea>
            </div>
          </div>
          <div class="main__row main__row--area">
            <div class="main__text">Причина продажу</div>
            <div class="main__text">
              <span class="card__span">{{ info.reasonToSell }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="info__photo" v-if="block === 'photo'">
        <div class="photo__header">Фото</div>
        <div class="photo__content">
          <img :src="img.fileURL" class="photo" v-for="img in info.photos" :key="img.id" />
        </div>
      </div>
      <div class="info__contacts" v-if="block === 'contacts'">
        <div class="contacts__item" v-for="contact in info.contacts" :key="contact.id">
          <div class="contacts__header">
            <div class="contacts__letter">{{ contact.name[0] }}</div>
            <div class="contacts__name">{{ contact.name }}</div>
            <div class="contacts__role">Роль: {{ contact.roles.map(x => x.name).join(' / ') }}</div>
            <div class="contacts__date">Дата народження: {{ contact.birthday }}</div>
            <div v-if="contact.company" class="contacts__company">
              Компанія: "{{ contact.company.name }}"
              <span v-if="contact.jobPosition">({{ contact.jobPosition.name }})</span>
            </div>
          </div>
          <div class="contacts__phones" v-for="phone in contact.phones" :key="phone.id">
            <div class="contacts__phone-wrapper">
              <a :href="'tel:' + phone.phone" @click.stop><i class="ri-phone-fill"></i></a>
              <div class="contacts__phone">{{ phone.phone }}</div>
              <div class="contacts__messengers">
                <i
                  class="i-viber button__icon button__icon--circle"
                  :class="{'button__icon--border--active': phone.isOnViber}"
                ></i>
                <i
                  class="i-telegram button__icon button__icon--circle"
                  :class="{
                    'button__icon--border--active': phone.isOnTelegram
                  }"
                ></i>
                <i
                  class="i-whatsapp button__icon button__icon--circle"
                  :class="{
                    'button__icon--border--active': phone.isOnWhatsapp
                  }"
                ></i>
                <i
                  class="i-messanger button__icon button__icon--circle"
                  :class="{
                    'button__icon--border--active': phone.isOnFacebook
                  }"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="info__other" v-if="block === 'other'"></div>
    </div>
  </div>
</template>

<script>
import localConstants from '@/utils/localConstants'
import {mapGetters} from 'vuex'
import Multiselect from 'vue-multiselect'
import ModalArchive from '@/components/ModalArchive'
import Relation from '@/components/Relation'

export default {
  name: 'InfoArchiveRent',
  props: ['info'],
  components: {
    Multiselect,
    Relation,
  },
  computed: {
    ...mapGetters(['constants']),
  },
  data: () => ({
    localConstants,
    block: 'main',
    list: ['Відновити'],
    item: '',
    userRole: null,
    archivedReason: null,
    source: null
  }),
  created() {
    this.userRole = this.$store.getters.user.role
    if (this.userRole === 4) {
      this.list.push('Редагувати', 'Видалити')
    } else if (this.info.responsibleID === this.$store.getters.user.id) {
      this.list.push('Редагувати')
    }
    this.archivedReason = this.constants.RENT_ARCHIVED_REASONS.find(r => r.id === this.info.archivedReason).name
    this.soldBy = this.constants.SOLD_BYS.find(sb => sb.id === this.info.soldBy)?.name
    this.source = this.getPath(this.$store.getters.constants.RENT_PROPERTY_SOURCES, this.info.source)
  },
  mounted() {
    let textarea = document.querySelector('#autoresizing')
    function autoResize() {
      textarea.style.height = 'auto'
      textarea.style.height = textarea.scrollHeight + 'px'
      textarea.disable = 'true'
    }
    autoResize()
  },
  methods: {
    actions(option) {
      const id = this.info.id
      const type = this.$route.meta.class
      if (option === 'Видалити') {
        this.$store.dispatch('deleteArchiveRent', {type, id})
        this.$router.go()
      } else if (option === 'Відновити') {
        this.$store.dispatch('restoreArchiveRent', {type, id})
        this.$router.go()
      } else if (option === 'Редагувати') {
        this.$modal.display(
          ModalArchive,
          {info: this.info, entity: 'rent', action: 'update'},
          {transition: 'none', classes: 'modalArchive', width: '500px', height: '350px', name: 'ModalArchive'}
        )
      }
    },
    getPath(sources, id) {
      let path = ''
      for (const source of sources) {
        path = source.label
        if (source.id === id) {
          path = source.label
          return path
        } else if (source.children && source.children.length > 0) {
          let childPath = this.getPath(source.children, id)
          if (childPath !== '') {
            return path + ' > ' + childPath
          }
        }
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/info.scss';
</style>
