<template>
  <div class="upload__container upload-building">
    <div class="upload__left">
      <div class="upload__navigation-title">Категорії</div>
      <div class="upload__navigation">
        <div class="upload__navigation-link-wrapper" @click="select('mainDataBox')">
          <div
            class="upload__navigation-link"
            :class="{
              'upload__navigation-link--active': mainDataBox,
              'upload__navigation-link--error': this.$v.sendData.$invalid && this.$v.sendData.$dirty
            }"
          >
            Основне
          </div>
          <div class="upload__navigation-icon"></div>
        </div>
        <div class="upload__navigation-link-wrapper" @click="select('contactsBox')">
          <div
            class="upload__navigation-link"
            :class="{
              'upload__navigation-link--active': contactsBox,
              'upload__navigation-link--error': contactsBoxError
            }"
          >
            Контакти
          </div>
          <div class="upload__navigation-icon"></div>
        </div>
      </div>
    </div>

    <div class="upload__right">
      <div class="upload__top">
        <div class="upload__title">

          <span v-if="building">Редагування Року будівлі - ID {{ building.id }} </span>
          <span v-else>Додавання Року будівлі </span>
        </div>
        <div class="upload__icons">
          <i class="ri-user-search-line"></i>
        </div>
        <div class="upload__buttons">
          <div class="upload__button button button--fill button--grey" @click="closeModal">Скасувати</div>
          <div class="upload__button button button--fill" @click="send">Зберегти</div>

          <div class="button__icon" @click="closeModal">
            <i class="ri-close-circle-line"></i>
          </div>
          <div class="button__icon button__icon--blue" @click="send">
            <i class="ri-save-3-line"></i>
          </div>
        </div>
      </div>
      <div class="upload__navigation--mobile">
        <div class="upload__navigation-link-wrapper" @click="select('mainDataBox')">
          <div
            class="upload__navigation-link"
            :class="{
              'upload__navigation-link--active': mainDataBox,
              'upload__navigation-link--error': this.$v.sendData.$invalid && this.$v.sendData.$dirty
            }"
          >
            Основне
          </div>
          <div class="upload__navigation-icon"></div>
        </div>
        <div class="upload__navigation-link-wrapper" @click="select('contactsBox')">
          <div
            class="upload__navigation-link"
            :class="{
              'upload__navigation-link--active': contactsBox,
              'upload__navigation-link--error': contactsBoxError
            }"
          >
            Контакти
          </div>
          <div class="upload__navigation-icon"></div>
        </div>
      </div>
      <div class="upload__main">
        <div v-if="mainDataBox" class="data__inner data_main">
          <div class="data__top">
            <div
              class="field__label"
              :class="{
                'field__label--filled': sendData.street,
                'field__label--invalid': $v.sendData.street.$dirty && !$v.sendData.street.required
              }"
            >
              <div class="field__title">Вулиця</div>
              <Multiselect
                :options="streets"
                v-model="sendData.street"
                :showLabels="false"
                placeholder="Вулиця"
                class="mSelect"
              >
                <span slot="noResult">Отакої, вулиці немає :(</span>
              </Multiselect>
            </div>
          </div>
          <div class="data__middle">
            <div
              class="field__label"
              :class="{
                'field__label--filled': sendData.buildingNo,
                'field__label--invalid': $v.sendData.buildingNo.$dirty && !$v.sendData.buildingNo.required
              }"
            >
              <div class="field__title field__title--required">№ буд.</div>
              <input class="field__input" v-model="sendData.buildingNo" />
            </div>
            <div
              class="field__label"
              :class="{
                'field__label--filled': sendData.floorsNumber,
                'field__label--invalid': $v.sendData.floorsNumber.$dirty && !$v.sendData.floorsNumber.required
              }"
            >
              <div class="field__title field__title--required">Поверхів</div>
              <input class="field__input" v-model="sendData.floorsNumber" />
            </div>
            <div
              class="field__label"
              :class="{
                'field__label--filled': sendData.year,
              }"
            >
              <div class="field__title field__title--required">Рік</div>
              <input class="field__input" v-model="sendData.year" />
            </div>
            <div
              class="field__label"
              :class="{
                'field__label--filled': sendData.emergency,
              }"
            >
              <div class="field__title">Аварійність</div>
              <Multiselect
                :options="localConstants.YES_NO"
                v-model="sendData.emergency"
                label="name"
                trackBy="id"
                :showLabels="false"
                :searchable="false"
                placeholder="Аварійність"
                class="mSelect"
              />
            </div>
            <div
              class="field__label"
              :class="{
                'field__label--filled': sendData.cooperativeType,
                'field__label--invalid': $v.sendData.cooperativeType.$dirty && !$v.sendData.cooperativeType.required
              }"
            >
              <div class="field__title">ОСББ/Управління</div>
              <Multiselect
                :options="constants.COOPERATIVE_TYPES"
                v-model="sendData.cooperativeType"
                label="name"
                trackBy="id"
                :showLabels="false"
                :searchable="false"
                placeholder="ОСББ/Управління"
                class="mSelect"
              />
            </div>
            <div
              class="field__label"
              :class="{
                'field__label--filled': sendData.cooperativeName,
                'field__label--invalid': $v.sendData.cooperativeName.$dirty && !$v.sendData.cooperativeName.required
              }"
            >
              <div class="field__title field__title--required">Назва ОСББ чи Управління</div>
              <input class="field__input" v-model="sendData.cooperativeName" />
            </div>
          </div>
          <div class="data__bottom">
            <label
              class="field__label"
              :class="{
                'field__label--filled': sendData.comment,
              }"
            >
              <div class="field__title">Коментар</div>
              <textarea class="field__text" v-model="sendData.comment"></textarea>
            </label>
          </div>
        </div>
        <div v-if="contactsBox" class="data__inner data_contacts">
          <article class="data_contacts__top">
            <ContactSearch
              :selectedContacts="selectedContacts"
              title="Виберіть контакт"
              placeholder="Введіть телефон або імʼя"
              :permittedRoles="permittedRoles"
              @sendContacts="contacts => (selectedContacts = contacts)"
            />
          </article>
          <article class="data_contacts__bottom">
            <Contact :newContacts="newContacts" :visibleBox="visibleBox" :permittedRoles="permittedRoles" />
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Contact from '@/components/Contact'
import ContactSearch from '@/components/ContactSearch'
import Multiselect from 'vue-multiselect'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import moment from 'moment'
import {mapState} from 'vuex'
import localConstants from '@/utils/localConstants'
import {required, requiredIf, minLength} from 'vuelidate/lib/validators'

export default {
  name: 'UploadBuildingYear',
  props: {
    building: Object
  },
  components: {
    Multiselect,
    DatePicker,
    Contact,
    ContactSearch
  },
  data: () => ({
    localConstants,
    mainDataBox: true,
    contactsBox: false,
    sendData: {
      street: null,
      buildingNo: null,
      floorsNumber: null,
      year: null,
      emergency: null,
      cooperativeType: null,
      cooperativeName: null,
      comment: null,
    },
    selectedContacts: [],
    newContacts: [],
    visibleBox: []
  }),
  computed: {
    ...mapState({
      constants: s => s.main.constants,
      users: s => s.users.users,
      streets: s => s.main.streets
    }),
    contactsBoxError() {
      if (this.$v.contactsIds) {
        return this.$v.contactsIds.$invalid && this.$v.contactsIds.$dirty
      } else if (this.$v.newContacts) {
        return this.$v.newContacts.$invalid && this.$v.newContacts.$dirty
      }
      return false
    },
    permittedRoles() {
      return [this.localConstants.CONTACT_ROLES.BUILDING_YEAR]
    },
    contactsIds() {
      return this.selectedContacts?.map(c => c.id) ?? []
    }
  },
  methods: {
    select(box) {
      this.mainDataBox = false
      this.contactsBox = false
      this[box] = true
    },
    async send() {      
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      const data = {
        street: this.sendData.street,
        buildingNo: this.sendData.buildingNo,
        floorsNumber: this.sendData.floorsNumber,
        year: this.sendData.year,
        emergency: this.sendData.emergency?.id,
        cooperativeType: this.sendData.cooperativeType.id,
        cooperativeName: this.sendData.cooperativeName,
        comment: this.sendData.comment,

        contactsIds: this.contactsIds,
        lastCallAt: this.sendData.lastCallAt
          ? moment(this.sendData.lastCallAt).utcOffset(0, true).toISOString()
          : null,
      }

      const newContacts = this.newContacts.map(c => ({
          name: c.name,
          phones: c.phones.map(x => ({
            phone: x.phone,
            isOnTelegram: x.isOnTelegram,
            isOnFacebook: x.isOnFacebook,
            isOnViber: x.isOnViber,
            isOnWhatsapp: x.isOnWhatsapp
          })),
          roles: c.roles.map(x => x.id),
          companyId: c.company?.id || null,
          jobPosition: c.jobPosition?.id || null,
          birthday: c.birthday?.format() || null
        }))
      if (this.building) {
        await this.$store.dispatch('buildings/edit', {id: this.building.id, data, newContacts})
      } else {
        await this.$store.dispatch('buildings/create', {data, newContacts})
      }
      this.closeModal();
    },
    closeModal() {
      this.$modal.hide('modalUploadBuilding')
    }
  },
  async created() {
    if (!this.$store.getters.users.length) {
      await this.$store.dispatch('users/fetch')
    }

    if (this.building) {
      this.sendData.street = this.building.street
      this.sendData.buildingNo = this.building.buildingNo
      this.sendData.floorsNumber = this.building.floorsNumber
      this.sendData.year = this.building.year
      this.sendData.emergency = this.localConstants.YES_NO.find(x => x.id === this.building.emergency)
      this.sendData.cooperativeType = this.constants.COOPERATIVE_TYPES.find(x => x.id === this.building.cooperativeType)
      this.sendData.cooperativeName = this.building.cooperativeName
      this.sendData.comment = this.building.comment
      this.selectedContacts = this.building.contacts
    }

    await this.$store.dispatch('fetchStreets')
  },
  validations: {
    sendData: {
      street: { required },
      buildingNo: { required },
      floorsNumber: { required },
      year: {},
      emergency: {},
      cooperativeType: { required },
      cooperativeName: { required },
      lastCallAt: {},
      comment: {},
    },
    contactsIds: {
      required: requiredIf(function() {
        if (this.newContacts.length) {
          return false
        } else {
          return true
        }
      })
    },
    newContacts: {
      required: requiredIf(function() {
        if (this.newContacts.length) {
          return true
        } else {
          return false
        }
      }),
      $each: {
        birthday: {},
        name: {required},
        phones: {
          required,
          $each: {
            phone: {required, minLength: minLength(10)}
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
.upload__main {
  overflow-y: scroll;
}
</style>

<style lang="scss">
.upload-building .vc-popover-content-wrapper {
  position: fixed !important;
}
</style>
