const filtersList = {
  motivation: ['A', 'B', 'C', 'AA'],
  rooms: [
    {name: '1', id: 1},
    {name: '2', id: 2},
    {name: '3', id: 3},
    {name: '4', id: 4},
    {name: '5+', id: 5}
  ],
  houseMaterial: [
    {name: 'Цегла', id: 1},
    {name: 'Блоки', id: 2},
    {name: 'Дерево', id: 3},
    {name: 'Інше', id: 4}
  ],
  houseType: [
    {name: 'Окремий', id: 1},
    {name: 'Частина', id: 2},
    {name: 'Котедж', id: 3},
    {name: 'Дача', id: 4}
  ],
  houseFloors: [1, 2, 3, 4],
  apartmentMaterial: [
    {name: 'Цегла', id: 1},
    {name: 'Панель', id: 5}
  ],
  apartmentType: [
    {name: 'Гуртожиток', id: 5},
    {name: 'Гостинка', id: 6},
    {name: 'Малосімейка', id: 7},
    {name: 'Повноцінна', id: 8},
    {name: 'Хрущовка', id: 9},
    {name: 'Покращене', id: 10},
    {name: 'Чешка', id: 11},
    {name: 'Новобудова', id: 12},
    {name: 'Сталінка', id: 13},
    {name: 'Довоєнне', id: 14}
  ],
  apartmentFloors: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
  commerceType: [
    {name: 'Склад', id: 1},
    {name: 'Торгове', id: 2},
    {name: 'Кафе', id: 3},
    {name: 'Офіс', id: 4},
    {name: 'Виробниче', id: 5},
    {name: 'Вільне', id: 6},
    {name: 'Інше', id: 7}
  ],
  commerceFloors: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
  contract: ['НЕД', 'ЕД', 'Немає'],
  check: [
    {name: 'Є', id: true},
    {name: 'Немає', id: false}
  ],
  status: [
    {name: 'Заявка', id: 1},
    {name: 'Оброблений', id: 2},
    {name: 'В рекламі', id: 3}
  ],
  AUTONOMOUS_HEAT_TYPE: ['Газове', 'Електро', 'Котельня', 'Немає'],
  ARCHIVED_REASONS: [
    {name: 'Під Завдатком', id: 1},
    {name: 'Продано', id: 2},
    {name: 'Продано за Ціну', id: 3},
    {name: 'Передумали', id: 4},
    {name: 'Призупинили Продаж', id: 5}
  ],
  SOLD_BYS: [
    {name: 'Невідомо', id: 1},
    {name: 'Інше АН', id: 2},
    {name: 'Наше АН', id: 3}
  ],
  USER_ROLES: [
    {name: 'Гість', id: 1},
    {name: 'Агент', id: 2},
    {name: 'Офіс-Менеджер', id: 3},
    {name: 'Адміністратор', id: 4}
  ],
  USER_RANKS: [
    {name: 'Стажер', id: 1},
    {name: 'Новачок', id: 2},
    {name: 'Агент', id: 3},
    {name: 'Професіонал', id: 4},
    {name: 'Немає', id: null}
  ],
  BUYER_APT_FLOORS: [
    {name: 'Перший', id: 1},
    {name: 'Середній', id: 2},
    {name: 'Останній', id: 3}
  ],
  BUYER_HOUSE_RANGES: [
    {name: 'Місто', id: 1},
    {name: 'До 5 км.', id: 2},
    {name: 'Від 5 км.', id: 3}
  ],
  PROPERTY_SOURCES: [
    {
      id: 1,
      label: "Пошук заявки (об'єкта)",
      children: [
        {
          id: 11,
          label: 'Сайти',
          children: [
            {
              id: 111,
              label: 'Olx'
            },
            {
              id: 112,
              label: 'Domria'
            },
            {
              id: 113,
              label: 'Бомбер'
            },
            {
              id: 115,
              label: 'Бесплатка'
            },
            {
              id: 116,
              label: 'Realno.estate'
            },
            {
              id: 114,
              label: 'Інше'
            }
          ]
        },
        {
          id: 12,
          label: 'Розклейка КУПЛЮ'
        },
        {
          id: 15,
          label: 'Соц. Мережі'
        }
      ]
    },
    {
      id: 2,
      label: 'Реклама АН',
      children: [
        {
          id: 21,
          label: 'Зовнішня',
          children: [
            {
              id: 211,
              label: 'Банери'
            },
            {
              id: 212,
              label: 'Наклейки/табл'
            },
            {
              id: 213,
              label: 'Інше'
            },
            {
              id: 214,
              label: 'Листівки'
            }
          ]
        },
        {
          id: 22,
          label: 'Інтернет+Телефонія',
          children: [
            {
              id: 221,
              label: 'Фейсбук'
            },
            {
              id: 222,
              label: 'Інстаграм'
            },
            {
              id: 223,
              label: 'Ютуб'
            },
            {
              id: 224,
              label: 'HALDEN.estate'
            },
            {
              id: 225,
              label: 'Сайти інші'
            },
            {
              id: 226,
              label: 'СМС-розсилки'
            }
          ]
        },
        {
          id: 23,
          label: 'Рекомендація',
          children: [
            {
              id: 231,
              label: 'Знайомий'
            },
            {
              id: 232,
              label: 'Колега'
            },
            {
              id: 233,
              label: 'Шукач'
            },
            {
              id: 24,
              label: 'Повторне звернення'
            }
          ]
        }
      ]
    },
    {
      id: 3,
      label: 'Інше АН',
      children: [
        {
          id: 31,
          label: 'Телеграм'
        },
        {
          id: 32,
          label: 'Сайти'
        },
        {
          id: 33,
          label: 'Особисте'
        },
        {
          id: 34,
          label: 'Соц. мережі'
        },
        {
          id: 35,
          label: 'Внутрішні бази'
        }
      ]
    },
    {
      id: 13,
      label: 'Внутрішня база',
      children: [
        {
          id: 131,
          label: 'Архів'
        },
        {
          id: 132,
          label: 'Заміна'
        }
      ]
    },
    {
      id: 5,
      label: 'Особистий Агента'
    },
    {
      id: 4,
      label: 'Невідомо'
    },
    {
      id: 6,
      label: 'Забудовник'
    }
  ],
  BUYER_SOURCES: [
    {
      id: 1,
      label: 'Реклама НЕД',
      children: [
        {
          id: 11,
          label: 'Сайти',
          children: [
            {
              id: 111,
              label: 'Olx.ua'
            },
            {
              id: 112,
              label: 'Domria.com'
            },
            {
              id: 114,
              label: 'HALDEN.estate'
            },
            {
              id: 115,
              label: 'Бесплатка'
            },
            {
              id: 113,
              label: 'Інше'
            }
          ]
        },
        {
          id: 12,
          label: 'Розклейка продам'
        },
        {
          id: 13,
          label: 'Соціальні мережі',
          children: [
            {
              id: 131,
              label: 'Інстаграм'
            },
            {
              id: 132,
              label: 'Фейсбук групи'
            },
            {
              id: 135,
              label: 'Фейсбук HALDEN'
            },
            {
              id: 133,
              label: 'YouTube'
            },
            {
              id: 134,
              label: 'Телеграм-канал'
            }
          ]
        },
        {
          id: 14,
          label: 'Банер'
        },
        {
          id: 16,
          label: 'HALDEN.estate'
        }
      ]
    },
    {
      id: 2,
      label: 'Реклама ЕД',
      children: [
        {
          id: 21,
          label: 'Сайти',
          children: [
            {
              id: 211,
              label: 'Olx.ua'
            },
            {
              id: 212,
              label: 'Domria.com'
            },
            {
              id: 114,
              label: 'HALDEN.estate'
            },
            {
              id: 115,
              label: 'Бесплатка'
            },
            {
              id: 213,
              label: 'Інше'
            }
          ]
        },
        {
          id: 22,
          label: 'Розклейка продам'
        },
        {
          id: 23,
          label: 'Соціальні мережі',
          children: [
            {
              id: 231,
              label: 'Інстаграм'
            },
            {
              id: 232,
              label: 'Фейсбук групи'
            },
            {
              id: 234,
              label: 'Фейсбук HALDEN'
            },
            {
              id: 27,
              label: 'YouTube'
            },
            {
              id: 233,
              label: 'Телеграм-канал'
            }
          ]
        },
        {
          id: 24,
          label: 'Банер'
        },
        {
          id: 26,
          label: 'Хенгери'
        }
      ]
    },
    {
      id: 3,
      label: 'Інше АН',
      children: [
        {
          id: 31,
          label: 'Телеграм'
        },
        {
          id: 32,
          label: 'Сайти'
        },
        {
          id: 33,
          label: 'Особисте'
        },
        {
          id: 34,
          label: 'Соц. мережі'
        },
        {
          id: 35,
          label: 'Внутрішні бази'
        }
      ]
    },
    {
      id: 4,
      label: 'Реклама АН',
      children: [
        {
          id: 41,
          label: 'Зовнішня',
          children: [
            {
              id: 411,
              label: 'Банери'
            },
            {
              id: 412,
              label: 'Наклейки/табл'
            },
            {
              id: 413,
              label: 'Інше'
            },
            {
              id: 414,
              label: 'Листівки'
            }
          ]
        },
        {
          id: 42,
          label: 'Інтернет+Телефонія',
          children: [
            {
              id: 421,
              label: 'Фейсбук'
            },
            {
              id: 422,
              label: 'Інстаграм'
            },
            {
              id: 423,
              label: 'Ютуб'
            },
            {
              id: 424,
              label: 'HALDEN.estate'
            },
            {
              id: 425,
              label: 'Сайти інші'
            },
            {
              id: 426,
              label: 'СМС-розсилки'
            }
          ]
        },
        {
          id: 43,
          label: 'Рекомендація',
          children: [
            {
              id: 431,
              label: 'Знайомий'
            },
            {
              id: 432,
              label: 'Колега'
            },
            {
              id: 433,
              label: 'Шукач'
            },
            {
              id: 44,
              label: 'Повторне звернення'
            }
          ]
        }
      ]
    },
    {
      id: 5,
      label: 'Невідомо'
    },
    {
      id: 6,
      label: 'Особистий агента'
    },
    {
      id: 7,
      label: 'Внутрішня база',
      children: [
        {
          id: 71,
          label: 'Архів'
        },
        {
          id: 72,
          label: 'Заміна'
        }
      ]
    }
  ],
  SOLD_BYS: [
    {name: 'Невідомо', id: 1},
    {name: 'Інше АН', id: 2},
    {name: 'Наше АН', id: 3}
  ],
  ACTION_TYPES: [
    {name: 'Показ', id: 1},
    {name: 'Огляд', id: 2},
    {name: 'Зустріч', id: 3},
    {name: 'Дзвінок', id: 4}
  ],
  ACTION_RELATED_TYPES: [
    {name: 'Немає', id: 1},
    {name: 'ID', id: 2},
    {name: 'Інше АН', id: 3},
    {name: 'Агент Halden', id: 4}
  ],
  ACTION_RESULTS: [
    {name: 'Згода', id: 1},
    {name: 'Відмова', id: 2},
    {name: ' - ', id: 3}
  ]
}
export default filtersList
