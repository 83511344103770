var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upload__container upload-action" }, [
    _c("div", { staticClass: "upload__right" }, [
      _c("div", { staticClass: "upload__top" }, [
        _vm._m(0),
        _c("div", { staticClass: "upload__buttons" }, [
          _c(
            "div",
            {
              staticClass: "upload__button button button--fill button--grey",
              on: { click: _vm.closeModal }
            },
            [_vm._v("Скасувати")]
          ),
          _c(
            "div",
            {
              staticClass: "upload__button button button--fill",
              on: { click: _vm.send }
            },
            [_vm._v("Зберегти")]
          )
        ])
      ]),
      _c("div", { staticClass: "upload__main" }, [
        _c("div", { staticClass: "data__middle" }, [
          _c(
            "div",
            {
              staticClass: "field__label",
              class: {
                "field__label--filled": _vm.actionType,
                "field__label--invalid":
                  _vm.$v.actionType.$dirty && !_vm.$v.actionType.required
              }
            },
            [
              _c("div", { staticClass: "field__title" }, [_vm._v("Тип дії")]),
              _c("Multiselect", {
                staticClass: "mSelect",
                attrs: {
                  options: _vm.constants.ACTION_TYPES,
                  label: "name",
                  trackBy: "id",
                  showLabels: false,
                  searchable: false,
                  maxHeight: 200,
                  placeholder: "тип"
                },
                model: {
                  value: _vm.actionType,
                  callback: function($$v) {
                    _vm.actionType = $$v
                  },
                  expression: "actionType"
                }
              })
            ],
            1
          ),
          _vm.isActionProduction
            ? _c(
                "div",
                {
                  staticClass: "field__label",
                  class: {
                    "field__label--filled": _vm.actionSubtype,
                    "field__label--invalid":
                      _vm.$v.actionSubtype.$dirty &&
                      !_vm.$v.actionSubtype.required
                  }
                },
                [
                  _c("div", { staticClass: "field__title" }, [
                    _vm._v("Тип продукції")
                  ]),
                  _c("Multiselect", {
                    staticClass: "mSelect",
                    attrs: {
                      options: _vm.alloweProductionTypes,
                      label: "name",
                      trackBy: "id",
                      showLabels: false,
                      searchable: false,
                      maxHeight: 200,
                      placeholder: "Тип продукції"
                    },
                    model: {
                      value: _vm.actionSubtype,
                      callback: function($$v) {
                        _vm.actionSubtype = $$v
                      },
                      expression: "actionSubtype"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.isActionAdvert
            ? _c(
                "div",
                {
                  staticClass: "field__label",
                  class: {
                    "field__label--filled": _vm.actionSubtype,
                    "field__label--invalid":
                      _vm.$v.actionSubtype.$dirty &&
                      !_vm.$v.actionSubtype.required
                  }
                },
                [
                  _c("div", { staticClass: "field__title" }, [
                    _vm._v("Тип реклами")
                  ]),
                  _c("Treeselect", {
                    staticClass: "tSelect",
                    attrs: {
                      options: _vm.constants.ACTION_ADVERT_TYPES,
                      "disable-branch-nodes": true,
                      searchable: false,
                      placeholder: "Тип реклами",
                      openDirection: "bottom"
                    },
                    model: {
                      value: _vm.actionSubtype,
                      callback: function($$v) {
                        _vm.actionSubtype = $$v
                      },
                      expression: "actionSubtype"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          !_vm.isActionProduction && !_vm.isActionAdvert
            ? _c(
                "div",
                {
                  staticClass: "field__label",
                  class: {
                    "field__label--filled": _vm.result,
                    "field__label--invalid":
                      _vm.$v.result.$dirty && !_vm.$v.result.required
                  }
                },
                [
                  _c("div", { staticClass: "field__title" }, [
                    _vm._v("Результат дії")
                  ]),
                  _c("Multiselect", {
                    staticClass: "mSelect",
                    attrs: {
                      options: _vm.constants.ACTION_RESULTS,
                      label: "name",
                      trackBy: "id",
                      showLabels: false,
                      searchable: false,
                      maxHeight: 200,
                      placeholder: "результат"
                    },
                    model: {
                      value: _vm.result,
                      callback: function($$v) {
                        _vm.result = $$v
                      },
                      expression: "result"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          (_vm.isActionShowing || _vm.isActionViewing || _vm.isActionCall) &&
          _vm.isResultRejected
            ? _c(
                "div",
                {
                  staticClass: "field__label",
                  class: {
                    "field__label--filled": _vm.rejectionReason,
                    "field__label--invalid":
                      _vm.$v.rejectionReason.$dirty &&
                      !_vm.$v.rejectionReason.required
                  }
                },
                [
                  _c("div", { staticClass: "field__title" }, [
                    _vm._v("Причина")
                  ]),
                  _vm.isActionShowing || _vm.isActionCall
                    ? _c("Multiselect", {
                        staticClass: "mSelect",
                        attrs: {
                          options:
                            _vm.constants.ACTION_SHOWING_REJECTION_REASONS,
                          label: "name",
                          trackBy: "id",
                          showLabels: false,
                          searchable: false,
                          maxHeight: 200,
                          placeholder: "причина"
                        },
                        model: {
                          value: _vm.rejectionReason,
                          callback: function($$v) {
                            _vm.rejectionReason = $$v
                          },
                          expression: "rejectionReason"
                        }
                      })
                    : _vm._e(),
                  _vm.isActionViewing
                    ? _c("Multiselect", {
                        staticClass: "mSelect",
                        attrs: {
                          options:
                            _vm.constants.ACTION_VIEWING_REJECTION_REASONS,
                          label: "name",
                          trackBy: "id",
                          showLabels: false,
                          searchable: false,
                          maxHeight: 200,
                          placeholder: "причина"
                        },
                        model: {
                          value: _vm.rejectionReason,
                          callback: function($$v) {
                            _vm.rejectionReason = $$v
                          },
                          expression: "rejectionReason"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          (_vm.isActionShowing || _vm.isActionViewing || _vm.isActionCall) &&
          _vm.isResultRejected &&
          _vm.isRejectionReasonPrice
            ? _c(
                "div",
                {
                  staticClass: "field__label field__label__icon--currency",
                  class: {
                    "field__label--filled": _vm.buyerPrice,
                    "field__label--invalid":
                      _vm.$v.buyerPrice.$dirty && _vm.$v.buyerPrice.$error
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "field__title field__title--required" },
                    [_vm._v("Ціна покупця")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.buyerPrice,
                        expression: "buyerPrice",
                        modifiers: { number: true }
                      }
                    ],
                    staticClass: "field__input",
                    attrs: { min: "0", placeholder: "Ціна покупця" },
                    domProps: { value: _vm.buyerPrice },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.buyerPrice = _vm._n($event.target.value)
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ]
              )
            : _vm._e()
        ]),
        _vm.isActionNotary
          ? _c("div", { staticClass: "data__middle" }, [
              _c(
                "div",
                {
                  staticClass: "field__label field__label__icon--currency",
                  class: {
                    "field__label--filled": _vm.depositAmount,
                    "field__label--invalid":
                      _vm.$v.depositAmount.$dirty && _vm.$v.depositAmount.$error
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "field__title field__title--required" },
                    [_vm._v("Сума завдатку")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.depositAmount,
                        expression: "depositAmount",
                        modifiers: { number: true }
                      }
                    ],
                    staticClass: "field__input",
                    attrs: { min: "0", placeholder: "Сума завдатку" },
                    domProps: { value: _vm.depositAmount },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.depositAmount = _vm._n($event.target.value)
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "field__label",
                  class: {
                    "field__label--filled": _vm.depositEndDate,
                    "field__label--invalid":
                      _vm.$v.depositEndDate.$dirty &&
                      _vm.$v.depositEndDate.$error
                  }
                },
                [
                  _c("div", { staticClass: "field__title" }, [
                    _vm._v("Закінчення завдатку")
                  ]),
                  _c("date-picker", {
                    attrs: {
                      mode: "date",
                      is24hr: "",
                      isDark: "",
                      "minute-increment": 10,
                      popover: { visibility: "focus", placement: "top-start" }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var inputValue = ref.inputValue
                            var inputEvents = ref.inputEvents
                            return [
                              _c(
                                "input",
                                _vm._g(
                                  {
                                    staticClass: "field__input",
                                    staticStyle: { width: "100%" },
                                    attrs: { readonly: "" },
                                    domProps: { value: inputValue }
                                  },
                                  inputEvents
                                )
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3957308934
                    ),
                    model: {
                      value: _vm.depositEndDate,
                      callback: function($$v) {
                        _vm.depositEndDate = $$v
                      },
                      expression: "depositEndDate"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _vm.isActionNotary
          ? _c("div", { staticClass: "data__middle" }, [
              _c(
                "div",
                {
                  staticClass: "field__label",
                  class: {
                    "field__label--filled": _vm.notaryFullName,
                    "field__label--invalid":
                      _vm.$v.notaryFullName.$dirty &&
                      _vm.$v.notaryFullName.$error
                  }
                },
                [
                  _c("div", { staticClass: "field__title" }, [
                    _vm._v("ПІБ нотаріуса")
                  ]),
                  _c("Multiselect", {
                    staticClass: "mSelect",
                    attrs: {
                      options: _vm.localConstants.ACTION_NOTARIES,
                      showLabels: false,
                      searchable: true,
                      taggable: true,
                      multiple: false,
                      maxHeight: 200,
                      placeholder: "ПІБ нотаріуса",
                      "tag-placeholder": "Підтвердити"
                    },
                    on: { tag: _vm.setNotary },
                    model: {
                      value: _vm.notaryFullName,
                      callback: function($$v) {
                        _vm.notaryFullName = $$v
                      },
                      expression: "notaryFullName"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "field__label",
                  class: {
                    "field__label--filled": _vm.deedType,
                    "field__label--invalid":
                      _vm.$v.deedType.$dirty && _vm.$v.deedType.$error
                  }
                },
                [
                  _c("div", { staticClass: "field__title" }, [
                    _vm._v("Вид правочину")
                  ]),
                  _c("Multiselect", {
                    staticClass: "mSelect",
                    attrs: {
                      options: _vm.constants.ACTION_DEED_TYPES,
                      label: "name",
                      trackBy: "id",
                      showLabels: false,
                      searchable: false,
                      maxHeight: 200,
                      placeholder: "Вид правочину"
                    },
                    model: {
                      value: _vm.deedType,
                      callback: function($$v) {
                        _vm.deedType = $$v
                      },
                      expression: "deedType"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _vm.isActionNotary
          ? _c("div", { staticClass: "data__title" }, [
              _vm._v("Витрати покупця")
            ])
          : _vm._e(),
        _vm.isActionNotary
          ? _c("div", { staticClass: "data__triple" }, [
              _c(
                "div",
                {
                  staticClass: "field__label field__label__icon--percent",
                  class: {
                    "field__label--filled": _vm.buyerNotaryFee,
                    "field__label--invalid":
                      _vm.$v.buyerNotaryFee.$dirty &&
                      _vm.$v.buyerNotaryFee.$error
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "field__title field__title--required" },
                    [_vm._v("Послуги нотаріуса")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.buyerNotaryFee,
                        expression: "buyerNotaryFee",
                        modifiers: { number: true }
                      }
                    ],
                    staticClass: "field__input",
                    attrs: { placeholder: "Послуги нотаріуса" },
                    domProps: { value: _vm.buyerNotaryFee },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.buyerNotaryFee = _vm._n($event.target.value)
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "field__label field__label__icon--percent",
                  class: {
                    "field__label--filled": _vm.buyerTax,
                    "field__label--invalid":
                      _vm.$v.buyerTax.$dirty && _vm.$v.buyerTax.$error
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "field__title field__title--required" },
                    [_vm._v("Розмір податку")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.buyerTax,
                        expression: "buyerTax",
                        modifiers: { number: true }
                      }
                    ],
                    staticClass: "field__input",
                    attrs: { placeholder: "Розмір податку" },
                    domProps: { value: _vm.buyerTax },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.buyerTax = _vm._n($event.target.value)
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "field__label field__label__icon--currency",
                  class: {
                    "field__label--filled": _vm.buyerAgencyFee,
                    "field__label--invalid":
                      _vm.$v.buyerAgencyFee.$dirty &&
                      _vm.$v.buyerAgencyFee.$error
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "field__title field__title--required" },
                    [_vm._v("Комісія АН")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.buyerAgencyFee,
                        expression: "buyerAgencyFee",
                        modifiers: { number: true }
                      }
                    ],
                    staticClass: "field__input",
                    attrs: { placeholder: "Комісія АН" },
                    domProps: { value: _vm.buyerAgencyFee },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.buyerAgencyFee = _vm._n($event.target.value)
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ]
              )
            ])
          : _vm._e(),
        _vm.isActionNotary
          ? _c("div", { staticClass: "data__title" }, [
              _vm._v("Витрати власника")
            ])
          : _vm._e(),
        _vm.isActionNotary
          ? _c("div", { staticClass: "data__triple" }, [
              _c(
                "div",
                {
                  staticClass: "field__label field__label__icon--percent",
                  class: {
                    "field__label--filled": _vm.sellerNotaryFee,
                    "field__label--invalid":
                      _vm.$v.sellerNotaryFee.$dirty &&
                      _vm.$v.sellerNotaryFee.$error
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "field__title field__title--required" },
                    [_vm._v("Послуги нотаріуса")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.sellerNotaryFee,
                        expression: "sellerNotaryFee",
                        modifiers: { number: true }
                      }
                    ],
                    staticClass: "field__input",
                    attrs: { placeholder: "Послуги нотаріуса" },
                    domProps: { value: _vm.sellerNotaryFee },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.sellerNotaryFee = _vm._n($event.target.value)
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "field__label field__label__icon--percent",
                  class: {
                    "field__label--filled": _vm.sellerTax,
                    "field__label--invalid":
                      _vm.$v.sellerTax.$dirty && _vm.$v.sellerTax.$error
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "field__title field__title--required" },
                    [_vm._v("Розмір податку")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.sellerTax,
                        expression: "sellerTax",
                        modifiers: { number: true }
                      }
                    ],
                    staticClass: "field__input",
                    attrs: { placeholder: "Розмір податку" },
                    domProps: { value: _vm.sellerTax },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.sellerTax = _vm._n($event.target.value)
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "field__label field__label__icon--currency",
                  class: {
                    "field__label--filled": _vm.sellerAgencyFee,
                    "field__label--invalid":
                      _vm.$v.sellerAgencyFee.$dirty &&
                      _vm.$v.sellerAgencyFee.$error
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "field__title field__title--required" },
                    [_vm._v("Комісія АН")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.sellerAgencyFee,
                        expression: "sellerAgencyFee",
                        modifiers: { number: true }
                      }
                    ],
                    staticClass: "field__input",
                    attrs: { placeholder: "Комісія АН" },
                    domProps: { value: _vm.sellerAgencyFee },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.sellerAgencyFee = _vm._n($event.target.value)
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ]
              )
            ])
          : _vm._e(),
        _vm.isAdmin || _vm.isActionAdvert
          ? _c("div", { staticClass: "data__middle" }, [
              _vm.isAdmin
                ? _c(
                    "div",
                    {
                      staticClass: "field__label",
                      class: { "field__label--filled": _vm.agent }
                    },
                    [
                      _c("div", { staticClass: "field__title" }, [
                        _vm._v("Агент")
                      ]),
                      _c("Multiselect", {
                        staticClass: "mSelect",
                        attrs: {
                          options: _vm.users,
                          label: "fullName",
                          trackBy: "id",
                          showLabels: false,
                          searchable: false,
                          maxHeight: 200,
                          placeholder: "Агент"
                        },
                        model: {
                          value: _vm.agent,
                          callback: function($$v) {
                            _vm.agent = $$v
                          },
                          expression: "agent"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isActionAdvert
                ? _c(
                    "label",
                    {
                      staticClass: "field__label",
                      class: {
                        "field__label--filled": _vm.propertyRelatedID,
                        "field__label--invalid":
                          (_vm.$v.propertyRelatedID.$dirty &&
                            !_vm.$v.propertyRelatedID.required) ||
                          !_vm.$v.propertyRelatedID.exists
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "field__title field__title--required" },
                        [_vm._v("ID Об'єкту")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.propertyRelatedID,
                            expression: "propertyRelatedID"
                          }
                        ],
                        staticClass: "field__input",
                        attrs: { placeholder: "ID об'єкту" },
                        domProps: { value: _vm.propertyRelatedID },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.propertyRelatedID = $event.target.value
                          }
                        }
                      })
                    ]
                  )
                : _vm._e()
            ])
          : _vm._e(),
        !_vm.isActionProduction && !_vm.isActionAdvert
          ? _c("div", { staticClass: "data__middle" }, [
              _c(
                "div",
                {
                  staticClass: "field__label",
                  class: { "field__label--filled": _vm.propertyType }
                },
                [
                  _c("div", { staticClass: "field__title" }, [
                    _vm._v("Тип об'єкта")
                  ]),
                  _c("Multiselect", {
                    staticClass: "mSelect",
                    attrs: {
                      options: _vm.constants.ACTION_RELATED_TYPES,
                      label: "name",
                      trackBy: "id",
                      showLabels: false,
                      searchable: false,
                      maxHeight: 200,
                      "open-direction": "bottom",
                      placeholder: "тип"
                    },
                    model: {
                      value: _vm.propertyType,
                      callback: function($$v) {
                        _vm.propertyType = $$v
                      },
                      expression: "propertyType"
                    }
                  })
                ],
                1
              ),
              _vm.propertyType && _vm.propertyType.id === 2
                ? _c(
                    "label",
                    {
                      staticClass: "field__label",
                      class: {
                        "field__label--filled": _vm.propertyRelatedID,
                        "field__label--invalid": !_vm.$v.propertyRelatedID
                          .exists
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "field__title field__title--required" },
                        [_vm._v("ID Об'єкту")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.propertyRelatedID,
                            expression: "propertyRelatedID"
                          }
                        ],
                        staticClass: "field__input",
                        attrs: { placeholder: "ID об'єкту" },
                        domProps: { value: _vm.propertyRelatedID },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.propertyRelatedID = $event.target.value
                          }
                        }
                      })
                    ]
                  )
                : _vm._e(),
              _vm.propertyType && _vm.propertyType.id === 3
                ? _c(
                    "label",
                    {
                      staticClass: "field__label",
                      class: {
                        "field__label--filled": _vm.propertyRelatedID,
                        "field__label--invalid":
                          _vm.$v.propertyRelatedID.$dirty &&
                          !_vm.$v.propertyRelatedID.required
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "field__title field__title--required" },
                        [_vm._v("ID Об'єкту (AH)")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.propertyRelatedID,
                            expression: "propertyRelatedID"
                          }
                        ],
                        staticClass: "field__input",
                        attrs: { placeholder: "ID об'єкту (AH)" },
                        domProps: { value: _vm.propertyRelatedID },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.propertyRelatedID = $event.target.value
                          }
                        }
                      })
                    ]
                  )
                : _vm.propertyType && _vm.propertyType.id === 4
                ? _c(
                    "div",
                    {
                      staticClass: "field__label",
                      class: {
                        "field__label--filled": _vm.userID,
                        "field__label--invalid":
                          _vm.$v.userID.$dirty && !_vm.$v.userID.required
                      }
                    },
                    [
                      _c("div", { staticClass: "field__title" }, [
                        _vm._v("Агент")
                      ]),
                      _c("Multiselect", {
                        staticClass: "mSelect",
                        attrs: {
                          options: _vm.users,
                          label: "fullName",
                          trackBy: "id",
                          showLabels: false,
                          searchable: false,
                          maxHeight: 200,
                          placeholder: "Агент"
                        },
                        model: {
                          value: _vm.userID,
                          callback: function($$v) {
                            _vm.userID = $$v
                          },
                          expression: "userID"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          : _vm._e(),
        !_vm.isActionProduction && !_vm.isActionAdvert
          ? _c("div", { staticClass: "data__middle" }, [
              _c(
                "div",
                {
                  staticClass: "field__label",
                  class: { "field__label--filled": _vm.buyerType }
                },
                [
                  _c("div", { staticClass: "field__title" }, [
                    _vm._v("Покупець/орендар")
                  ]),
                  _c("Multiselect", {
                    staticClass: "mSelect",
                    attrs: {
                      options: _vm.constants.ACTION_RELATED_TYPES,
                      label: "name",
                      trackBy: "id",
                      showLabels: false,
                      searchable: false,
                      maxHeight: 200,
                      "open-direction": "bottom",
                      placeholder: "покупець/орендар"
                    },
                    model: {
                      value: _vm.buyerType,
                      callback: function($$v) {
                        _vm.buyerType = $$v
                      },
                      expression: "buyerType"
                    }
                  })
                ],
                1
              ),
              _vm.buyerType && _vm.buyerType.id === 2
                ? _c(
                    "label",
                    {
                      staticClass: "field__label",
                      class: {
                        "field__label--filled": _vm.buyerRelatedID,
                        "field__label--invalid": !_vm.$v.buyerRelatedID.exists
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "field__title field__title--required" },
                        [_vm._v("ID покупця/орендаря")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.buyerRelatedID,
                            expression: "buyerRelatedID"
                          }
                        ],
                        staticClass: "field__input",
                        attrs: { placeholder: "ID покупця/орендаря" },
                        domProps: { value: _vm.buyerRelatedID },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.buyerRelatedID = $event.target.value
                          }
                        }
                      })
                    ]
                  )
                : _vm._e(),
              _vm.buyerType && _vm.buyerType.id === 3
                ? _c(
                    "label",
                    {
                      staticClass: "field__label",
                      class: {
                        "field__label--filled": _vm.buyerRelatedID,
                        "field__label--invalid":
                          _vm.$v.buyerRelatedID.$dirty &&
                          !_vm.$v.buyerRelatedID.required
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "field__title field__title--required" },
                        [_vm._v("ID покупця/орендаря (AH)")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.buyerRelatedID,
                            expression: "buyerRelatedID"
                          }
                        ],
                        staticClass: "field__input",
                        attrs: { placeholder: "ID покупця/орендаря (АН)" },
                        domProps: { value: _vm.buyerRelatedID },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.buyerRelatedID = $event.target.value
                          }
                        }
                      })
                    ]
                  )
                : _vm.buyerType && _vm.buyerType.id === 4
                ? _c(
                    "div",
                    {
                      staticClass: "field__label",
                      class: {
                        "field__label--filled": _vm.buyuserID,
                        "field__label--invalid":
                          _vm.$v.buyuserID.$dirty && !_vm.$v.buyuserID.required
                      }
                    },
                    [
                      _c("div", { staticClass: "field__title" }, [
                        _vm._v("Агент")
                      ]),
                      _c("Multiselect", {
                        staticClass: "mSelect",
                        attrs: {
                          options: _vm.users,
                          label: "fullName",
                          trackBy: "id",
                          showLabels: false,
                          searchable: false,
                          maxHeight: 200,
                          placeholder: "Агент"
                        },
                        model: {
                          value: _vm.buyuserID,
                          callback: function($$v) {
                            _vm.buyuserID = $$v
                          },
                          expression: "buyuserID"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          : _vm._e(),
        _c("div", { staticClass: "data__middle" }, [
          _c(
            "div",
            {
              staticClass: "field__label",
              class: {
                "field__label--filled": _vm.actionAt,
                "field__label--invalid":
                  _vm.$v.actionAt.$dirty && !_vm.$v.actionAt.required
              }
            },
            [
              _c("div", { staticClass: "field__title" }, [
                _vm._v("Дата і час дії")
              ]),
              _c("date-picker", {
                attrs: {
                  mode: "dateTime",
                  is24hr: "",
                  isDark: "",
                  "minute-increment": 10,
                  popover: { visibility: "focus", placement: "top-start" }
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var inputValue = ref.inputValue
                      var inputEvents = ref.inputEvents
                      return [
                        _c(
                          "input",
                          _vm._g(
                            {
                              staticClass: "field__input",
                              staticStyle: { width: "100%" },
                              attrs: { readonly: "" },
                              domProps: { value: inputValue }
                            },
                            inputEvents
                          )
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.actionAt,
                  callback: function($$v) {
                    _vm.actionAt = $$v
                  },
                  expression: "actionAt"
                }
              })
            ],
            1
          ),
          _vm.isActionProduction || _vm.isActionAdvert
            ? _c(
                "label",
                {
                  staticClass: "field__label",
                  class: {
                    "field__label--filled": _vm.actionNumber,
                    "field__label--invalid":
                      _vm.$v.actionNumber.$dirty &&
                      !_vm.$v.actionNumber.required
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "field__title field__title--required" },
                    [_vm._v("Кількість")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.actionNumber,
                        expression: "actionNumber"
                      }
                    ],
                    staticClass: "field__input",
                    attrs: { placeholder: "Кількість" },
                    domProps: { value: _vm.actionNumber },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.actionNumber = $event.target.value
                      }
                    }
                  })
                ]
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "data__bottom" }, [
          _c(
            "label",
            {
              staticClass: "field__label",
              class: {
                "field__label--filled": _vm.description,
                "field__label--invalid":
                  _vm.$v.description.$dirty && !_vm.$v.description.required
              }
            },
            [
              _c("div", { staticClass: "field__title" }, [_vm._v("Деталі")]),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.description,
                    expression: "description"
                  }
                ],
                staticClass: "field__text",
                domProps: { value: _vm.description },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.description = $event.target.value
                  }
                }
              })
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "upload__title" }, [
      _c("span", [_vm._v("Нова Дія")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }