<template>
  <div>
    <div class="notificationBell" @click="openSidebar()">
      <div class="notification" style="display: grid; position: relative; width: 30px; height: 30px;">
        <svg class="notification-icon" xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 20 20"
          fill="#8996A8">
          <path
            d="M16 7a5.38 5.38 0 0 0-4.46-4.85C11.6 1.46 11.53 0 10 0S8.4 1.46 8.46 2.15A5.38 5.38 0 0 0 4 7v6l-2 2v1h16v-1l-2-2zm-6 13a3 3 0 0 0 3-3H7a3 3 0 0 0 3 3z"
            width="100%" height="100%" />
        </svg>
        <div v-if="count > 0" class="notification-counter"
          style="width: fit-content; position: absolute; text-align: center; top: 0px; left: calc(100% - 13.5px); transform: translateY(-40%); border-radius: 3px; background-color: rgb(5, 132, 217); color: white; font-size: 15px; padding: 1.5px 3px;">
          <div class="counter-wrapper">
            <span>{{ computedCounter }}</span>
            <div v-if="count > upperLimit">+</div>
          </div>
        </div>
      </div>
    </div>

    <div class="sidebar__backdrop" :class="{ 'shown': isOpen }" @click="closeSidebar()"></div>

    <div class="sidebar__wrapper" :class="{ 'shown': isOpen }">
      <div class="sidebar">
        <div class="sidebar__header">
          <div class="sidebar__inner" style="display: flex; justify-content: space-between; align-items: center;">
            <span>Сповіщення</span>
            <a href="javascript:void(0)" class="closebtn" @click="closeSidebar()">&times;</a>
          </div>
        </div>

        <div v-if="isAdmin" class="sidebar__tabs">
          <button @click="setOpenTab('personal')" :class="{ 'active': isTabPersonalOpen }">Особисті</button>
          <button @click="setOpenTab('all')" :class="{ 'active': isTabAllOpen }">Всі</button>
        </div>

        <div class="sidebar__main">
          <div class="sidebar__inner">
            <div v-if="!isLoading && notifications.length === 0">
              Немає сповіщень
            </div>

            <div v-for="notification of notifications" class="notification__card"
              :class="{ 'notification__card--unread': !notification.isRead }" @click="readNotification(notification)">
              <div class="notification__card__row" style="white-space: pre-wrap;">{{ notification.message }}</div>
              <div v-if="isAdmin && notification.userName" class="notification__card__row">
                <span>Агент</span>
                <span class="notification__card__agent">
                  <div class="user__icon">{{ notification.userName && notification.userName[0] }}</div>
                  <div class="user__name">{{ notification.userName }}</div>
                </span>
              </div>
              <div class="notification__card__row">
                <span class="notification__card__date">
                  <i class="i-calendar"></i>
                  <span>{{ notification.createdAt | date }}</span>
                </span>
                <span class="notification__card__relation">
                  <Relation type="notification-icon" :relation="notification.relation" />
                </span>
              </div>
            </div>

            <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="fetchNotifications"></infinite-loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import Relation from '@/components/Relation'

export default {
  name: 'NotificationBell',
  components: {
    InfiniteLoading,
    Relation,
  },
  data: () => ({
    isOpen: false,
    isLoading: false,
    upperLimit: 99,
    openTab: 'personal', // 'personal' | 'all'
  }),
  computed: {
    ...mapGetters({
      count: 'notifications/count',
      notifications: 'notifications/notifications',
      pagination: 'notifications/pagination',
      page: 'notifications/page',
      user: 'user',
    }),
    isAdmin() {
      return this.user.role === 4
    },
    computedCounter() {
      return this.count <= this.upperLimit ? Math.floor(this.count) : Math.floor(this.upperLimit)
    },
    hasMoreResults() {
      return this.page <= this.pagination?.lastPage
    },
    isTabPersonalOpen() {
      return this.openTab === 'personal'
    },
    isTabAllOpen() {
      return this.openTab === 'all'
    },
  },
  async created() {
    this.$store.dispatch('notifications/count')
  },
  methods: {
    async openSidebar() {
      this.isOpen = true
      await this.$store.commit('notifications/reset')
      await this.fetchNotifications()
    },
    closeSidebar() {
      this.isOpen = false
    },
    async readNotification(notification) {
      if (notification.isRead || notification.userId !== this.user.id)
        return

      await this.$store.dispatch('notifications/read', notification.id)
    },
    async setOpenTab(tab) {
      if (this.openTab === tab)
        return

      this.openTab = tab
      
      await this.$store.commit('notifications/reset')
      await this.fetchNotifications()
    },
    async fetchNotifications() {
      this.isLoading = true
      try {
        if (this.isTabPersonalOpen) {
          await this.$store.dispatch('notifications/fetchPersonal')
        } else if (this.isTabAllOpen) {
          await this.$store.dispatch('notifications/fetchAll')
        } else {
          throw new Error('Not implemented case')
        }
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    }
  },
}
</script>
<style lang="scss" scoped>
@import 'styles';
</style>