export default {
  install(Vue, options) {
    Vue.prototype.$message = function(text) {
      this.$toasted.show(text, {
        theme: 'toasted-primary',
        position: 'top-right',
        duration: 3000,
      })
    }

    Vue.prototype.$error = function(text) {
      this.$toasted.show(text, {
        theme: 'bubble',
        position: 'top-right',
        duration: 3000,
        className: 'error-tost'
      })
    }
  }
}
