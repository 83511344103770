<template>
  <div class="del__inner">
    <div class="del__header"></div>
    <div class="del__main">
      <div class="del__main-header">
        <div class="">Цю дію не можна скасувати. Це призведе до остаточного видалення користувача.</div>
        <div class="">{{ user.firstName }} {{ user.lastName }} ({{ user.fullName }})</div>
      </div>
      <div class="del__main-action">
        <label class="field__label">
          <div class="field__title">Введіть {{ user.fullName }} для підтвердження.</div>
          <input
            class="field__input"
            v-model="name"
            :placeholder="user.fullName"
          />
        </label>
        <button
          id="del-btn"
          class="button button--fill is-red"
          :class="{'is-disabled': !isDeleteAllowed}"
          @click="deleteUser"
        >
          Видалити
        </button>
      </div>
    </div>
    <div v-if="isBoom" class="boom">
      <img src="@/assets/images/animation.gif" alt="" />
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'DeleteUser',
  props: {
    user: {type: Object, default: null}
  },
  data: () => ({
    name: '',
    isBoom: false,
    isLoading: false
  }),
  computed: {
    isDeleteAllowed() {
      return this.name === this.user.fullName
    }
  },
  methods: {
    ...mapActions({
      delete: 'users/adminBlockUser',
      getUsers: 'users/adminFetch'
    }),
    async deleteUser(e) {
      if (this.isLoading) return
      if (e.detail === 10) {
        this.isBoom = true
        setTimeout(() => {
          this.isBoom = false
        }, 1700)
      }
      if (this.isDeleteAllowed) {
        this.isLoading = true
        try {
          await this.delete(this.user.id)
          this.$modal.hide('UploadUser')
          this.$modal.hide('DelUser')
          await this.getUsers(true)
        } finally {
          this.isLoading = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
