var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "notificationBell",
        on: {
          click: function($event) {
            return _vm.openSidebar()
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "notification",
            staticStyle: {
              display: "grid",
              position: "relative",
              width: "30px",
              height: "30px"
            }
          },
          [
            _c(
              "svg",
              {
                staticClass: "notification-icon",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "30px",
                  height: "30px",
                  viewBox: "0 0 20 20",
                  fill: "#8996A8"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M16 7a5.38 5.38 0 0 0-4.46-4.85C11.6 1.46 11.53 0 10 0S8.4 1.46 8.46 2.15A5.38 5.38 0 0 0 4 7v6l-2 2v1h16v-1l-2-2zm-6 13a3 3 0 0 0 3-3H7a3 3 0 0 0 3 3z",
                    width: "100%",
                    height: "100%"
                  }
                })
              ]
            ),
            _vm.count > 0
              ? _c(
                  "div",
                  {
                    staticClass: "notification-counter",
                    staticStyle: {
                      width: "fit-content",
                      position: "absolute",
                      "text-align": "center",
                      top: "0px",
                      left: "calc(100% - 13.5px)",
                      transform: "translateY(-40%)",
                      "border-radius": "3px",
                      "background-color": "rgb(5, 132, 217)",
                      color: "white",
                      "font-size": "15px",
                      padding: "1.5px 3px"
                    }
                  },
                  [
                    _c("div", { staticClass: "counter-wrapper" }, [
                      _c("span", [_vm._v(_vm._s(_vm.computedCounter))]),
                      _vm.count > _vm.upperLimit
                        ? _c("div", [_vm._v("+")])
                        : _vm._e()
                    ])
                  ]
                )
              : _vm._e()
          ]
        )
      ]
    ),
    _c("div", {
      staticClass: "sidebar__backdrop",
      class: { shown: _vm.isOpen },
      on: {
        click: function($event) {
          return _vm.closeSidebar()
        }
      }
    }),
    _c(
      "div",
      { staticClass: "sidebar__wrapper", class: { shown: _vm.isOpen } },
      [
        _c("div", { staticClass: "sidebar" }, [
          _c("div", { staticClass: "sidebar__header" }, [
            _c(
              "div",
              {
                staticClass: "sidebar__inner",
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "center"
                }
              },
              [
                _c("span", [_vm._v("Сповіщення")]),
                _c(
                  "a",
                  {
                    staticClass: "closebtn",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        return _vm.closeSidebar()
                      }
                    }
                  },
                  [_vm._v("×")]
                )
              ]
            )
          ]),
          _vm.isAdmin
            ? _c("div", { staticClass: "sidebar__tabs" }, [
                _c(
                  "button",
                  {
                    class: { active: _vm.isTabPersonalOpen },
                    on: {
                      click: function($event) {
                        return _vm.setOpenTab("personal")
                      }
                    }
                  },
                  [_vm._v("Особисті")]
                ),
                _c(
                  "button",
                  {
                    class: { active: _vm.isTabAllOpen },
                    on: {
                      click: function($event) {
                        return _vm.setOpenTab("all")
                      }
                    }
                  },
                  [_vm._v("Всі")]
                )
              ])
            : _vm._e(),
          _c("div", { staticClass: "sidebar__main" }, [
            _c(
              "div",
              { staticClass: "sidebar__inner" },
              [
                !_vm.isLoading && _vm.notifications.length === 0
                  ? _c("div", [_vm._v(" Немає сповіщень ")])
                  : _vm._e(),
                _vm._l(_vm.notifications, function(notification) {
                  return _c(
                    "div",
                    {
                      staticClass: "notification__card",
                      class: {
                        "notification__card--unread": !notification.isRead
                      },
                      on: {
                        click: function($event) {
                          return _vm.readNotification(notification)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "notification__card__row",
                          staticStyle: { "white-space": "pre-wrap" }
                        },
                        [_vm._v(_vm._s(notification.message))]
                      ),
                      _vm.isAdmin && notification.userName
                        ? _c(
                            "div",
                            { staticClass: "notification__card__row" },
                            [
                              _c("span", [_vm._v("Агент")]),
                              _c(
                                "span",
                                { staticClass: "notification__card__agent" },
                                [
                                  _c("div", { staticClass: "user__icon" }, [
                                    _vm._v(
                                      _vm._s(
                                        notification.userName &&
                                          notification.userName[0]
                                      )
                                    )
                                  ]),
                                  _c("div", { staticClass: "user__name" }, [
                                    _vm._v(_vm._s(notification.userName))
                                  ])
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "notification__card__row" }, [
                        _c(
                          "span",
                          { staticClass: "notification__card__date" },
                          [
                            _c("i", { staticClass: "i-calendar" }),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("date")(notification.createdAt))
                              )
                            ])
                          ]
                        ),
                        _c(
                          "span",
                          { staticClass: "notification__card__relation" },
                          [
                            _c("Relation", {
                              attrs: {
                                type: "notification-icon",
                                relation: notification.relation
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  )
                }),
                !_vm.isLoading && _vm.hasMoreResults
                  ? _c("infinite-loading", {
                      on: { infinite: _vm.fetchNotifications }
                    })
                  : _vm._e()
              ],
              2
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }