<template>
  <div class="search__wrapper">
    <label class="field__label search__label">
      <div v-if="title" class="field__title">{{ title }}</div>
      <div class="chips__wrapper" v-bind:class="{ 'chips__wrapper--compact': compact }">
        <div class="chips__box" v-for="c in selectedContacts" :key="c.id">
          <div class="chips__item">{{ c.name }} — {{ c.phones[0].phone }}</div>
          <i class="i-close chips__button" @click="delSelectedContact(c)"></i>
        </div>
        <input
          class="field__input search__input"
          :value="searchQuery"
          :placeholder="placeholder"
          @input="event => inputChange(event)"
        />
      </div>
      <div class="search__select" v-if="isListVisible">
        <div class="search__item" v-for="c in foundContactsWithoutSelected" :key="c.id">
          <div class="search__phone contact__phone">{{ c.phones[0].phone }}</div>
          <div class="search__name">
            <div>
              <span class="contact__name">{{ c.name }}</span>
              <span class="contact__roles">({{ c.roles.map(x => x.name).join(' / ') }})</span>
            </div>
            <div>
              <span v-if="c.company" class="contact__company">"{{ c.company.name }}"</span>
              <span v-if="c.jobPosition" class="contact__job-position">({{ c.jobPosition.name }})</span>
            </div>
          </div>
          <div v-if="!readonly" class="search__button__wrapper">
            <button class="search__button" @click="editContact(c)">
              <i class="ri-pencil-fill"></i>
            </button>
            <button :disabled="!hasAnyPermittedRole(c.roles)" class="search__button" @click="selectContact(c)">
              <i class="i-plus search__button__icon"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="search__screen" v-if="isListVisible" @click="isListVisible = !isListVisible"></div>
    </label>
  </div>
</template>

<script>
import _ from 'lodash'
import ModalEditContact from '@/views/Contacts/EditContact'
export default {
  name: 'ContactSearch',
  props: {
    selectedContacts: { default: () => [] },
    title: { default: undefined },
    placeholder: { default: undefined },
    readonly: { default: false },
    compact: { default: false },
    digitsOnly: { default: false },
    permittedRoles: { default: undefined },
    initialValue: { default: undefined  },
  },
  computed: {
    isAdmin() {
      return this.$store.getters.user.role === 4
    },
    foundContactsWithoutSelected() {
      return this.foundContacts.filter(c => !this.selectedContacts.some(sc => sc.id == c.id))
    },
    foundContacts() {
      return this.$store.getters['contacts/foundContacts']
    },
  },
  data: () => ({
    searchQuery: '',
    addedContacts: [],
    isListVisible: false,
  }),
  watch: {
    foundContactsWithoutSelected: function() {
      this.isListVisible = this.foundContactsWithoutSelected.length > 0
    },
    selectedContacts: function() {
      this.$emit('sendContacts', this.selectedContacts)
    },
  },
  created() {
    if (this.initialValue) {
      this.searchQuery = this.initialValue
    }
  },
  methods: {
    inputChange(event) {
      const { value } = event.target
      const inputRegex = this.digitsOnly ? /^[0-9]{1,10}$/ : /^['а-яА-ЯіІ0-9]+$/

      if (value != '' && value.match(inputRegex) == null) {
        event.target.value = this.searchQuery
        return
      }

      this.searchQuery = value
      this.$emit('inputChange', this.searchQuery)
      this.searchContacts(this, this.searchQuery)
    },
    searchContacts: _.debounce(async (v, query) => {
      if (query.length <= 2) {
        v.$store.commit('contacts/setFoundContacts', [])
        return
      }
      await v.$store.dispatch('contacts/search', { query }) || []
    }, 500),
    selectContact(c) {
      this.selectedContacts.push(c)
    },
    editContact(c) {
      this.$modal.display(ModalEditContact, {contact: c, editRolesOnly: !this.isAdmin}, {name: 'ModalEditContact'})
    },
    delSelectedContact(c) {
      this.selectedContacts.splice(
        this.selectedContacts.findIndex(co => co.id === c.id),
        1
      )
    },
    hasAnyPermittedRole(roles) {
      if (this.permittedRoles == null || this.permittedRoles.length === 0) {
        return true
      }

      return roles.map(role => role.id).some(roleId => this.permittedRoles.includes(roleId))
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>