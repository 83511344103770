<template>
  <div class="navbar__wrapper" id="navbar">
    <div class="navbar__inner">
      <div class="navbar__top">
        <div class="container">
          <div class="navbar__top-inner">
            <div class="button button--fill button-mobile-nav" @click="openSidebar">
              <i class="ri-menu-unfold-line"></i>
            </div>
            <div class="navbar__left-mobile">
              <div class="navbar__left-icon">
                {{ contactsNumber }}
              </div>
            </div>
            <div class="navbar__left">
              <div class="navbar__center-inner">
                <span class="link navbar__link navbar__link--active">
                  <span class="navbar__link-text">Контакти</span>
                  <span class="navbar__link-icon">{{ contactsNumber }}</span>
                </span>
              </div>
            </div>

            <div class="navbar__right">
              <button class="button button--fill" @click="createContact">Додати Контакт</button>
              <button class="button__icon" @click="createContact"><i class="ri-add-circle-line"></i></button>
              <div class="navbar__notifications">
                <Notifications />
              </div>
              <div class="user__block">
                <div class="user__icon">{{ letter }}</div>
                <div class="user__name">{{ name }}</div>
                <div class="user__param">
                  <Multiselect
                    class="mSelect-dropdown"
                    :options="list"
                    :searchable="false"
                    :show-labels="false"
                    :reset-after="true"
                    :value="uAction"
                    placeholder
                    @select="userfn"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="navbar__bottom">
        <div class="container">
          <div class="navbar__bottom-inner">
            <div class="navbar__left">
              <label class="search__label">
                <i class="ri-search-2-line"></i>
                <input class="search__input" v-model="search" placeholder="Введіть номер телефону або Ім'я" />
              </label>
            </div>
            <div class="navbar__right">
              <div class="button__wrapper">
                <div
                  class="button button--fill button--grey button--small button__filters"
                  :class="{'ri-filter-fill-blue': activeParams}"
                  @click="openFilter"
                >
                  <i class="ri-filter-fill">
                    <span v-if="activeParams">{{ activeParams }}</span>
                  </i>
                  <span class="button__text">Фільтри</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import CreateContact from '@/views/Contacts/EditContact'
import Filter from '@/views/Contacts/Filter'
import Sidebar from '@/components/Sidebar'
import SidebarMobile from '@/components/SidebarMobile'
import Notifications from '@/components/Notifications'

export default {
  name: 'ContactsNavbar',
  components: {
    Multiselect,
    Notifications,
  },
  data: () => ({
    name: '',
    letter: '',
    list: ['Аккаунт', 'Вийти'],
    uAction: '',
    search: ''
  }),
  watch: {
    async search() {
      this.searchObject(this)
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    activeParams() {
      return this.$store.getters['contacts/filterParamsActive']
    },
    contactsNumber() {
      return this.$store.getters['contacts/pagination']?.total ?? 0
    }
  },
  async created() {
    if (Object.keys(this.user).length === 0) {
      await this.$store.dispatch('getUser')
    }
    this.name = this.user.fullName
    this.letter = this.user?.fullName[0]
    if (this.$store.getters['contacts/contacts'].length === 0) {
      await this.$store.dispatch('contacts/fetch')
    }
  },
  methods: {
    userfn(option) {
      if (option === 'Вийти') {
        this.$store.dispatch('logout')
        this.$router.go('/login')
      }
    },
    createContact() {
      this.$modal.display(CreateContact, {}, {name: 'ModalEditContact'})
    },
    openFilter() {
      this.$modal.display(
        Filter,
        {},
        {
          transition: 'none',
          classes: 'modalFilter',
          width: '400px',
          height: '100%',
          shiftX: 1,
          shiftY: 1,
          name: 'FilterContacts',
          maxHeight: 3000
        }
      )
    },
    openSidebar() {
      const mobile = document.documentElement.clientWidth <= 768
      let SidebarModal = {}
      mobile ? (SidebarModal = SidebarMobile) : (SidebarModal = Sidebar)
      this.$modal.display(
        SidebarModal,
        {},
        {
          name: 'Sidebar',
          transition: 'none',
          classes: 'modalSidebar',
          width: '210px',
          height: '100%',
          maxHeight: 1024,
          shiftX: 0,
          shiftY: 0,
          adaptive: true
        }
      )
    },
    searchObject: _.debounce(v => {
      if (v.search.length >= 3) {
        v.$store.commit('contacts/reset')
        v.$store.commit('contacts/resetFilterParams')
        v.$store.commit('contacts/searchParams', { 'filter[nameOrPhone]': v.search })
        v.$store.dispatch('contacts/fetch')
      } else if (v.search === '') {
        v.$store.commit('contacts/reset')
        v.$store.commit('contacts/resetFilterParams')
        v.$store.commit('contacts/searchParams')
        v.$store.dispatch('contacts/fetch')
      }
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
@import 'style.scss';
</style>
