<template>
  <div class="favorites-layout">
    <NavbarFavorites />
    <Sidebar />
    <div class="page__wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import NavbarFavorites from '@/views/Favorites/Navbar'
import Sidebar from '@/components/Sidebar'

export default {
  name: 'FavoritesLayout',
  components: {
    NavbarFavorites,
    Sidebar
  },
  watch: {
    error(error) {
      const data = error.response?.data ?? error
      this.$error(data?.message ?? data?.error)
    }
  },
  created() {
    window.scrollTo(0, 0)
    if (!this.$store.getters.constants) {
      this.$store.dispatch('fetchConstants')
    }
  }
}
</script>

<style lang="scss" scoped>
.page__wrapper {
  top: 64px;
}
</style>
