<template>
  <div class="filters__inner">
    <div class="filters__top">
      <div class="filters__title">
        <span class="filters__title-span">Фільтри</span>
      </div>
      <div class="filters__navigation">
        <div class="filters__button button__icon button__icon--border" @click="reset">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.6308 10.459C14.0428 9.94771 13.0477 6.66536 15.1006 4.82486C17.2004 2.964 20.5462 4.45602 20.5462 7.26132C20.5462 9.25955 18.6947 10.8951 16.6308 10.459ZM18.0517 8.44735C18.4159 8.44735 18.6018 8.00411 18.3416 7.74528L17.8558 7.26132L18.3416 6.77736C18.7276 6.39337 18.1463 5.81153 17.7615 6.19502L17.2734 6.68117L16.7851 6.19502C16.4003 5.81153 15.8192 6.39359 16.205 6.77736L16.6909 7.26132L16.205 7.74528C15.9463 8.00264 16.1299 8.44735 16.4949 8.44735C16.6773 8.44735 16.7512 8.37191 17.038 8.07939C17.1039 8.01214 17.1811 7.9334 17.2734 7.84147C17.3626 7.93025 17.4376 8.00657 17.5021 8.07206C17.8022 8.37712 17.8713 8.44735 18.0517 8.44735ZM16.5 11.5C14.3483 11.1422 12.9343 9.17714 13.0541 7H4.50001C4.22386 7 4 7.22386 4 7.50001V9.99999C3.99988 10.1419 4.06006 10.2771 4.1655 10.372L9 14.723V20.5C8.99988 20.7761 9.22362 21.0001 9.49977 21.0002C9.57744 21.0002 9.65405 20.9822 9.72352 20.9475L12.7235 19.4475C12.893 19.3628 13.0001 19.1895 13 19V14.723L16.5 11.5Z"
              fill="#C2CDDC"
            />
          </svg>
        </div>
        <div class="filters__button button__icon button__icon--border filters__button--blue" @click="send">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.5 5H5.50001C5.22386 5 5 5.22386 5 5.50001V7.99999C4.99988 8.14185 5.06006 8.27708 5.1655 8.372L10 12.723V18.5C9.99988 18.7761 10.2236 19.0001 10.4998 19.0002C10.5774 19.0002 10.6541 18.9822 10.7235 18.9475L13.7235 17.4475C13.893 17.3628 14.0001 17.1895 14 17V12.723L18.8345 8.373C18.9402 8.27787 19.0004 8.1422 19 7.99999V5.50001C19 5.22386 18.7761 5 18.5 5Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="filters__main">
      <div class="filters__main-part" :class="{'filters__main-part--active': filtersShow.agent}">
        <div class="part__title" @click="filtersShow.agent = !filtersShow.agent">
          <span class="part__title-text" :class="{'text-blue': filterActive.agent}">Відповідальний агент</span>
          <span class="part__title-icon">
            <i class="i-chevron"></i>
          </span>
        </div>
        <div class="part__main">
          <div class="field__label">
            <Multiselect
              :options="users"
              v-model="params.agent"
              label="fullName"
              trackBy="id"
              :multiple="true"
              :showLabels="false"
              :searchable="false"
              :closeOnSelect="false"
              placeholder="Відповідальний агент"
            >
            </Multiselect>
          </div>
        </div>
      </div>
      <div class="filters__main-part" :class="{'filters__main-part--active': filtersShow.location}">
        <div class="part__title" @click="filtersShow.location = !filtersShow.location">
          <span class="part__title-text" :class="{'text-blue': filterActive.location}">Локація</span>
          <span class="part__title-icon">
            <i class="i-chevron"></i>
          </span>
        </div>
        <div class="part__main">
          <div class="field__label">
            <Multiselect
              :options="settlements"
              v-model="params.settlements"
              label="name"
              trackBy="name"
              :multiple="true"
              :showLabels="false"
              :closeOnSelect="false"
              placeholder="Виберіть населений пункт"
            />
          </div>
          <div class="field__label">
            <Multiselect
              :options="districtsRivne"
              v-model="params.districts"
              label="name"
              trackBy="name"
              :searchable="false"
              :multiple="true"
              :showLabels="false"
              :closeOnSelect="false"
              placeholder="Виберіть Район"
            />
          </div>
          <div class="field__label" v-if="type === 'house'">
            <Multiselect
              :options="constants.BUYER_HOUSE_RANGES"
              v-model="params.range"
              label="name"
              trackBy="id"
              :searchable="false"
              :multiple="true"
              :showLabels="false"
              :closeOnSelect="false"
              placeholder="Виберіть Розташування"
            />
          </div>
        </div>
      </div>
      <div class="filters__main-part" :class="{'filters__main-part--active': filtersShow.price}">
        <div class="part__title" @click="filtersShow.price = !filtersShow.price">
          <span class="part__title-text" :class="{'text-blue': filterActive.price}">Максимальна Ціна</span>
          <span class="part__title-icon">
            <i class="i-chevron"></i>
          </span>
        </div>
        <div class="part__main part__main--half">
          <label class="field__label">
            <input
              placeholder="Макс Ціна від"
              type="text"
              class="field__input"
              v-mask="'#######'"
              v-model="params.priceFrom"
            />
          </label>
          <label class="field__label">
            <input
              placeholder="Макс Ціна до"
              type="text"
              class="field__input"
              v-mask="'#######'"
              v-model="params.priceTill"
            />
          </label>
        </div>
      </div>
      <div
        v-if="affiliation === 'archive'"
        class="filters__main-part"
        :class="{'filters__main-part--active': filtersShow.archive}"
      >
        <div class="part__title" @click="filtersShow.archive = !filtersShow.archive">
          <span class="part__title-text" :class="{'text-blue': filterActive.archive}">Архів</span>
          <span class="part__title-icon">
            <i class="i-chevron"></i>
          </span>
        </div>
        <div class="part__main">
          <div class="field__label">
            <Multiselect
              :options="constants.RENT_ARCHIVED_REASONS"
              v-model="params.archivedReason"
              :multiple="true"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              :closeOnSelect="false"
              placeholder="Причина видалення"
            />
          </div>
        </div>
        <div class="part__main">
          <div class="field__title">Дата внесення в архів</div>
          <date-picker v-model="params.archivedAt" is-range isDark color="blue" :popover="{visibility: 'focus'}">
            <template v-slot="{inputValue, inputEvents}">
              <div class="datePicker__fields">
                <input class="field__input" :value="inputValue.start" v-on="inputEvents.start" />
                <input :value="inputValue.end" v-on="inputEvents.end" class="field__input" />
              </div>
            </template>
          </date-picker>
        </div>
        <div class="part__main">
          <div class="field__title">Видалено до</div>
          <date-picker v-model="params.archivedTill" is-range isDark color="blue" :popover="{visibility: 'focus'}">
            <template v-slot="{inputValue, inputEvents}">
              <div class="datePicker__fields">
                <input class="field__input" :value="inputValue.start" v-on="inputEvents.start" />
                <input :value="inputValue.end" v-on="inputEvents.end" class="field__input" />
              </div>
            </template>
          </date-picker>
        </div>
        <div class="part__main">
          <label class="field__label">
            <Multiselect
              :options="constants.SOLD_BYS"
              v-model="params.soldBy"
              :multiple="true"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              :closeOnSelect="false"
              placeholder="Ким Реалізовано"
            />
          </label>
        </div>
      </div>
      <div class="filters__main-part" :class="{'filters__main-part--active': filtersShow.rooms}">
        <div class="part__title" @click="filtersShow.rooms = !filtersShow.rooms">
          <div>
            <span class="part__title-text" :class="{'text-blue': filterActive.rooms}">Кількість кімнат</span>
            <span class="part__title-text" v-if="type === 'apartment'">/поверх</span>
          </div>
          <span class="part__title-icon">
            <i class="i-chevron"></i>
          </span>
        </div>
        <div class="part__main">
          <div class="field__label">
            <Multiselect
              :options="localConstants.PROPERTY_ROOMS"
              v-model="params.noOfRooms"
              label="name"
              trackBy="id"
              :multiple="true"
              :showLabels="false"
              :searchable="false"
              :closeOnSelect="false"
              placeholder="Кількість кімнат"
            />
          </div>
        </div>
        <div class="part__main" v-if="type === 'apartment'">
          <div class="field__label">
            <Multiselect
              :options="constants.BUYER_APT_FLOORS"
              v-model="params.floor"
              label="name"
              trackBy="id"
              :multiple="true"
              :showLabels="false"
              :searchable="false"
              :closeOnSelect="false"
              placeholder="Поверх"
            />
          </div>
        </div>
      </div>
      <div class="filters__main-part" :class="{'filters__main-part--active': filtersShow.type}">
        <div class="part__title" @click="filtersShow.type = !filtersShow.type">
          <span class="part__title-text" :class="{'text-blue': filterActive.type}">Вид/Тип</span>
          <span class="part__title-icon">
            <i class="i-chevron"></i>
          </span>
        </div>
        <div class="part__main">
          <div class="field__label">
            <Multiselect
              :options="constants.RENT_BUYER_APARTMENT_TYPES"
              v-model="params.buildingType"
              label="name"
              trackBy="id"
              :multiple="true"
              :showLabels="false"
              :searchable="false"
              :closeOnSelect="false"
              placeholder="Тип"
              v-if="type === 'apartment'"
            />
            <Multiselect
              :options="constants.HOUSE_TYPES"
              v-model="params.buildingType"
              label="name"
              trackBy="id"
              :multiple="true"
              :showLabels="false"
              :searchable="false"
              :closeOnSelect="false"
              placeholder="Тип"
              v-if="type === 'house'"
            />
            <Multiselect
              :options="constants.COMMERCE_BUILDING_TYPES"
              v-model="params.buildingType"
              label="name"
              trackBy="id"
              :multiple="true"
              :showLabels="false"
              :searchable="false"
              :closeOnSelect="false"
              placeholder="Тип"
              v-if="type === 'commerce'"
            />
          </div>
        </div>
      </div>
      <div
        v-if="type !== 'apartment'"
        class="filters__main-part"
        :class="{'filters__main-part--active': filtersShow.square}"
      >
        <div class="part__title" @click="filtersShow.square = !filtersShow.square">
          <span class="part__title-text" :class="{'text-blue': filterActive.square}">Площа</span>
          <span class="part__title-icon">
            <i class="i-chevron"></i>
          </span>
        </div>
        <div class="part__main part__main--half">
          <label class="field__label">
            <div class="field__title">Загальна від</div>
            <input type="text" class="field__input" v-mask="'###'" v-model="params.squareTotalFrom" />
          </label>
          <label class="field__label">
            <div class="field__title">Загальна до</div>
            <input type="text" class="field__input" v-mask="'###'" v-model="params.squareTotalTill" />
          </label>
        </div>
        <div class="part__main part__main--half" v-if="type === 'house'">
          <label class="field__label">
            <div class="field__title">Площа землі від</div>
            <input type="text" class="field__input" v-mask="'###'" v-model="params.squareLandFrom" />
          </label>
          <label class="field__label">
            <div class="field__title">Площа землі до</div>
            <input type="text" class="field__input" v-mask="'###'" v-model="params.squareLandTill" />
          </label>
        </div>
      </div>
      <div class="filters__main-part" :class="{'filters__main-part--active': filtersShow.renovated}">
        <div class="part__title" @click="filtersShow.renovated = !filtersShow.renovated">
          <span class="part__title-text" :class="{'text-blue': filterActive.renovated}">
            <span>Ремонт</span>
            <span class="part__title-text" v-if="type === 'house'">/гараж</span>
            <span class="part__title-text" v-else>/авт. опал</span>
          </span>
          <span class="part__title-icon">
            <i class="i-chevron"></i>
          </span>
        </div>
        <div class="part__main">
          <div class="field__label">
            <Multiselect
              :options="localConstants.PROPERTY_TOGGLE"
              v-model="params.renovated"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              placeholder="Ремонт"
            />
          </div>
          <div class="field__label" v-if="type === 'house'">
            <Multiselect
              :options="localConstants.PROPERTY_TOGGLE"
              v-model="params.garage"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              placeholder="Гараж"
            />
          </div>
          <div class="field__label" v-else>
            <Multiselect
              :options="localConstants.AUTONOMOUS_HEAT_TYPE"
              v-model="params.autonomousHeatType"
              :showLabels="false"
              :searchable="false"
              placeholder="Опалення"
            />
          </div>
        </div>
      </div>
      <div class="filters__main-part" :class="{'filters__main-part--active': filtersShow.other}">
        <div class="part__title" @click="filtersShow.other = !filtersShow.other">
          <span class="part__title-text" :class="{'text-blue': filterActive.other}">Додатково</span>
          <span class="part__title-icon">
            <i class="i-chevron"></i>
          </span>
        </div>
        <div class="part__main">
          <div class="field__label">
            <Multiselect
              :options="constants.RENT_RELEVANCE"
              v-model="params.relevance"
              label="name"
              trackBy="id"
              :multiple="false"
              :showLabels="false"
              :searchable="false"
              :closeOnSelect="true"
              placeholder="Актуальність"
            />
          </div>
        </div>
        <div class="part__main">
          <div class="field__label">
            <Multiselect
              :options="localConstants.PROPERTY_TOGGLE"
              v-model="params.relation"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              placeholder="Заміна"
            />
          </div>
        </div>
        <div class="part__main">
          <div class="field__title">Здано до</div>
          <date-picker v-model="params.rentedTill" is-range isDark color="blue" :popover="{visibility: 'focus'}">
            <template v-slot="{inputValue, inputEvents}">
              <div class="datePicker__fields">
                <input class="field__input" :value="inputValue.start" v-on="inputEvents.start" />
                <input :value="inputValue.end" v-on="inputEvents.end" class="field__input" />
              </div>
            </template>
          </date-picker>
        </div>
        <div class="part__main">
          <div class="field__title">Дата внесення</div>
          <date-picker v-model="params.created" is-range isDark color="blue" :popover="{visibility: 'focus'}">
            <template v-slot="{inputValue, inputEvents}">
              <div class="datePicker__fields">
                <input class="field__input" :value="inputValue.start" v-on="inputEvents.start" />
                <input :value="inputValue.end" v-on="inputEvents.end" class="field__input" />
              </div>
            </template>
          </date-picker>
        </div>
      </div>
      <div class="filters__main-part" :class="{'filters__main-part--active': filtersShow.source}">
        <div class="part__title" @click="filtersShow.source = !filtersShow.source">
          <span class="part__title-text" :class="{'text-blue': filterActive.source}">Джерело</span>
          <span class="part__title-icon">
            <i class="i-chevron"></i>
          </span>
        </div>
        <div class="part__main">
          <div class="field__label">
            <Treeselect
              v-model="params.source"
              :options="constants.RENT_BUYER_SOURCES"
              :searchable="false"
              :multiple="true"
              class="tSelect ftSelect"
              placeholder="Джерело"
            />
          </div>
        </div>
      </div>
      <div class="filters__main-part" :class="{'filters__main-part--active': filtersShow.contacts}">
        <div class="part__title" @click="filtersShow.contacts = !filtersShow.contacts">
          <span class="part__title-text" :class="{'text-blue': filterActive.contacts}">Контакти</span>
          <span class="part__title-icon">
            <i class="i-chevron"></i>
          </span>
        </div>
        <div class="part__main">
          <div class="field__label">
            <Multiselect
              :options="constants.CONTACT_ROLES"
              v-model="params.contactRoles"
              :multiple="true"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              :closeOnSelect="false"
              placeholder="Роль"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import localConstants from '@/utils/localConstants'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Multiselect from 'vue-multiselect'
import Treeselect from '@riophae/vue-treeselect'
import _ from 'lodash'

export default {
  name: 'FiltersRenters',
  components: {
    DatePicker,
    Multiselect,
    Treeselect
  },
  data: () => ({
    localConstants,
    type: '',
    affiliation: null,
    params: {},
    filtersShow: {
      location: false,
      price: false,
      type: false,
      rooms: false,
      square: false,
      agent: true,
      other: false,
      renovated: false,
      archive: false,
      source: false,
      contacts: false
    },
    settlements: [],
    districtsRivne: [],
    filterActive: []
  }),
  computed: {
    ...mapState({
      constants: s => s.main.constants,
      users: s => {
        const users = _.cloneDeep(s.users.users)
        users.push({id: 0, fullName: 'Безвідповідальний'})
        return users
      }
    }),
    paramsActive() {
      let params = 0
      for (const p in this.params) {
        if (!_.isEmpty(this.params[p])) {
          params++
        }
      }
      return params
    }
  },
  watch: {
    params: {
      deep: true,
      handler(p) {
        const a = this.filterActive
        this.filterActive.agent = p.agent.length;
        this.filterActive.location = p.districts.length || p.settlements.length;
        this.filterActive.price = p.priceFrom || p.priceTill
        this.filterActive.rooms = p.noOfRooms.length
        this.filterActive.type = p.buildingType.length
        this.filterActive.square = p.squareKitchenFrom
                                   || p.squareKitchenTill
                                   || p.squareLandFrom
                                   || p.squareLandTill
                                   || p.squareLivingFrom
                                   || p.squareLivingTill
                                   || p.squareTotalFrom
                                   || p.squareTotalTill;
        this.filterActive.renovated = p.renovated || p.autonomousHeatType;
        this.filterActive.other = p.created || p.relevance || p.rentedTill || p.relation;
        this.filterActive.archive = p.archivedAt || p.archivedTill || p.archivedReason.length || p.soldBy.length;
        this.filterActive.source = p.source.length;
        this.filterActive.contacts = p.contactRoles.length;
      }
    }
  },
  async created() {
    this.type = this.$route.meta.class
    this.affiliation = this.$route.meta.layout

    let paramsLocal = ''
    let paramsOriginal = ''
    if (this.affiliation === 'archive') {
      switch (this.type) {
        case 'apartment':
          paramsLocal = 'archive/renters/apartments/filterParamsLocal'
          paramsOriginal = 'archive/renters/apartments/filterParamsOriginal'
          break
        case 'house':
          paramsLocal = 'archive/renters/houses/filterParamsLocal'
          paramsOriginal = 'archive/renters/houses/filterParamsOriginal'
          break
        case 'commerce':
          paramsLocal = 'archive/renters/commerce/filterParamsLocal'
          paramsOriginal = 'archive/renters/commerce/filterParamsOriginal'
          break
      }
    } else {
      switch (this.type) {
        case 'apartment':
          paramsLocal = 'renters/apartments/filterParamsLocal'
          paramsOriginal = 'renters/apartments/filterParamsOriginal'
          break
        case 'house':
          paramsLocal = 'renters/houses/filterParamsLocal'
          paramsOriginal = 'renters/houses/filterParamsOriginal'
          break
        case 'commerce':
          paramsLocal = 'renters/commerce/filterParamsLocal'
          paramsOriginal = 'renters/commerce/filterParamsOriginal'
          break
      }
    }

    const user = this.$store.getters.user
    if (Object.keys(this.$store.getters[paramsLocal]).length) {
      this.params = _.cloneDeep(this.$store.getters[paramsLocal])
    } else {
      this.params = _.cloneDeep(this.$store.getters[paramsOriginal])
      if (!this.params.agent.length) {
        this.params.agent.push(user)
      }
    }
    if (Object.keys(this.$store.getters.user).length === 0) {
      await this.$store.dispatch('getUser')
    }
    await Promise.all([this.$store.dispatch('users/fetch'), this.$store.dispatch('fetchSettlements')])
    this.settlements = this.$store.getters.settlements
    this.districtsRivne = this.settlements.find(s => s.name === 'Рівне').districts
  },
  methods: {
    async send() {
      const p = this.params
      const settlements = p.settlements.map(s => s.name)
      const districts = p.districts.map(s => s.id)
      const relevance = p.relevance?.id || null
      const responsibleID = p.agent.map(s => s.id)
      const buildingType = p.buildingType.map(s => s.id)
      const noOfRooms = p.noOfRooms.map(s => s.id)
      const archivedReason = p.archivedReason.map(s => s.id)
      const soldBy = p.soldBy.map(s => s.id)
      const contactRoles = p.contactRoles.map(s => s.id)

      const range = p.range?.map(s => s.id)
      const floor = p.floor.map(s => s.id)
      if (this.affiliation === 'archive') {
        p.created = null
      } else {
        p.archivedTill = null
        p.archivedAt = null
      }
      let params = {
        'filter[range]': range,
        'filter[settlement]': settlements,
        'filter[districtID]': districts,
        'filter[source]': p.source,
        'filter[responsibleID]': responsibleID,
        'filter[maxPrice][till]': p.priceTill === '' ? null : p.priceTill,
        'filter[maxPrice][from]': p.priceFrom === '' ? null : p.priceFrom,
        'filter[buildingType]': buildingType,
        'filter[noOfRooms]': noOfRooms,
        'filter[floor]': floor,
        'filter[squareTotal][from]': p.squareTotalFrom === '' ? null : p.squareTotalFrom,
        'filter[squareTotal][till]': p.squareTotalTill === '' ? null : p.squareTotalTill,
        'filter[squareLand][from]': p.squareLandFrom === '' ? null : p.squareLandFrom,
        'filter[squareLand][till]': p.squareLandTill === '' ? null : p.squareLandTill,
        'filter[autonomousHeatType]': p.autonomousHeatType,
        'filter[isRenovated]': p.renovated == null ? null : p.renovated.id,
        'filter[hasGarage]': p.garage == null ? null : p.garage.id,
        'filter[relation]': p.relation == null ? null : p.relation.id,
        'filter[createdAt][from]': p.created == null ? null : date(p.created.start),
        'filter[createdAt][till]': p.created == null ? null : date(p.created.end),
        'filter[archivedAt][from]': p.archivedAt == null ? null : date(p.archivedAt.start),
        'filter[archivedAt][till]': p.archivedAt == null ? null : date(p.archivedAt.end),
        'filter[archivedTill][from]': p.archivedTill == null ? null : date(p.archivedTill.start),
        'filter[archivedTill][till]': p.archivedTill == null ? null : date(p.archivedTill.end),
        'filter[archivedReason]': archivedReason,
        'filter[soldBy]': soldBy,
        'filter[contactRoles]': contactRoles,
        'filter[relevance]': relevance,
        'filter[rentedTill][from]': p.rentedTill == null ? null : date(p.rentedTill.start),
        'filter[rentedTill][till]': p.rentedTill == null ? null : date(p.rentedTill.end),
      }

      let paramsLocal = ''
      let paramsSend = ''
      let paramsActive = ''
      if (this.affiliation === 'archive') {
        switch (this.type) {
          case 'apartment':
            paramsLocal = 'archive/renters/apartments/filterParamsLocal'
            paramsSend = 'archive/renters/apartments/filterParamsSend'
            paramsActive = 'archive/renters/apartments/filterParamsActive'
            break
          case 'house':
            paramsLocal = 'archive/renters/houses/filterParamsLocal'
            paramsSend = 'archive/renters/houses/filterParamsSend'
            paramsActive = 'archive/renters/houses/filterParamsActive'
            break
          case 'commerce':
            paramsLocal = 'archive/renters/commerce/filterParamsLocal'
            paramsSend = 'archive/renters/commerce/filterParamsSend'
            paramsActive = 'archive/renters/commerce/filterParamsActive'
            break
        }
      } else {
        switch (this.type) {
          case 'apartment':
            paramsLocal = 'renters/apartments/filterParamsLocal'
            paramsSend = 'renters/apartments/filterParamsSend'
            paramsActive = 'renters/apartments/filterParamsActive'
            break
          case 'house':
            paramsLocal = 'renters/houses/filterParamsLocal'
            paramsSend = 'renters/houses/filterParamsSend'
            paramsActive = 'renters/houses/filterParamsActive'
            break
          case 'commerce':
            paramsLocal = 'renters/commerce/filterParamsLocal'
            paramsSend = 'renters/commerce/filterParamsSend'
            paramsActive = 'renters/commerce/filterParamsActive'
            break
        }
      }

      this.$store.commit(paramsLocal, p)
      this.$store.commit(paramsSend, params)
      this.$store.commit(paramsActive, this.paramsActive)

      try {
        if (this.affiliation === 'archive') {
          if (this.type === 'house') {
            this.$store.commit('archive/renters/houses/reset')
            await this.$store.dispatch('archive/renters/houses/fetch')
          } else if (this.type === 'apartment') {
            this.$store.commit('archive/renters/apartments/reset')
            await this.$store.dispatch('archive/renters/apartments/fetch')
          } else if (this.type === 'commerce') {
            this.$store.commit('archive/renters/commerce/reset')
            await this.$store.dispatch('archive/renters/commerce/fetch')
          }
        } else {
          if (this.type === 'house') {
            this.$store.commit('renters/houses/reset')
            await this.$store.dispatch('renters/houses/fetch')
          } else if (this.type === 'apartment') {
            this.$store.commit('renters/apartments/reset')
            await this.$store.dispatch('renters/apartments/fetch')
          } else if (this.type === 'commerce') {
            this.$store.commit('renters/commerce/reset')
            await this.$store.dispatch('renters/commerce/fetch')
          }
        }
      } catch (err) {}

      function date(value) {
        let d = new Date(Date.parse(value)),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear()
        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day
        return [day, month, year].join('-') || null
      }
      this.$modal.hide('FilterRenters')
    },
    reset() {
      if (this.affiliation === 'archive') {
        switch (this.type) {
          case 'apartment':
            this.params = _.cloneDeep(this.$store.getters['archive/renters/apartments/filterParamsOriginal'])
            break
          case 'house':
            this.params = _.cloneDeep(this.$store.getters['archive/renters/houses/filterParamsOriginal'])
            break
          case 'commerce':
            this.params = _.cloneDeep(this.$store.getters['archive/renters/commerce/filterParamsOriginal'])
            break
        }
      } else {
        switch (this.type) {
          case 'apartment':
            this.params = _.cloneDeep(this.$store.getters['renters/apartments/filterParamsOriginal'])
            break
          case 'house':
            this.params = _.cloneDeep(this.$store.getters['renters/houses/filterParamsOriginal'])
            break
          case 'commerce':
            this.params = _.cloneDeep(this.$store.getters['renters/commerce/filterParamsOriginal'])
            break
        }
      }

      for (const f in this.filtersShow) {
        this.filtersShow[f] = false
      }
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import '@/assets/styles/_variables.scss';
.vc {
  &-text-base {
    font-family: $baseFont;
    background-color: $black_dark;
    color: #fff;
    border: none;
    font-weight: 400;
    font-size: 16px;
  }
}
</style>
<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@import './style.scss';
</style>
