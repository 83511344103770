import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    pagination: {},
    commerce: [],
    oneCommerce: {},
    page: 1,
    // SELECTION
    selectionActive: false,
    selectAllEnabled: false,
    // FILTER PARAMS
    searchParams: {},
    filterParamsLocal: {},
    filterParamsSend: {},
    filterParamsActive: 0,
    filterParamsOriginal: {
      districts: [],
      settlements: [],
      streets: [],
      created: null,
      priceFrom: null,
      priceTill: null,
      material: [],
      buildingType: [],
      floorFrom: null,
      floorTill: null,
      floor: [],
      floors: [],
      noOfRooms: [],
      autonomousHeatType: null,
      squareTotalTill: null,
      squareTotalFrom: null,
      squareLivingTill: null,
      squareLivingFrom: null,
      squareKitchenFrom: null,
      squareKitchenTill: null,
      squareLandFrom: null,
      squareLandTill: null,
      renovated: null,
      agent: [],
      garage: null,
      archivedAt: null,
      archivedTill: null,
      archivedReason: [],
      soldBy: [],
      relation: null,
      status: [],
      motivation: [],
      contract: [],
      source: [],
      contactRoles: []
    }
  },
  mutations: {
    set(state, commerce) {
      state.commerce = _.unionBy(state.commerce, commerce, 'id')
    },
    setCommerce(state, oneCommerce) {
      state.oneCommerce = oneCommerce
    },
    updateCommerce(state, commerce) {
      const foundCommerce = state.commerce.find(x => x.id === commerce.id)
      Object.assign(foundCommerce, commerce)
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.commerce = []
      state.page = 1
      state.selectionActive = false
      state.selectAllEnabled = false
    },
    changePage(state) {
      state.page++
    },
    // SELECTION
    setSelectionActive(state, isActive) {
      state.selectionActive = isActive
      state.commerce.forEach(commerce => {
        commerce.isSelected = false
      });
    },
    toggleSelectAll(state) {
      state.selectAllEnabled = !state.selectAllEnabled
      state.commerce.forEach(commerce => {
        commerce.isSelected = state.selectAllEnabled
      });
    },
    selectRent(state, params) {
      const commerce = state.commerce.find(x => x.id === params.id)
      commerce.isSelected = params.isSelected
    },
    // FILTER PARAMS
    filterParamsLocal(state, params) {
      state.filterParamsLocal = params
    },
    filterParamsSend(state, params) {
      state.filterParamsSend = params
    },
    filterParamsActive(state, number) {
      state.filterParamsActive = number
    },
    resetFilterParams(state) {
      state.filterParams = {}
      state.filterParamsActive = {}
    },
    searchParams(state, params) {
      state.searchParams = params
    }
  },
  actions: {
    async fetch({commit, getters}) {
      const page = getters.page
      try {
        const url = process.env.VUE_APP_BACKEND
        const commerce = await axios.get(url + '/archive/rent/properties/commerce', {
          params: {
            page,
            ...getters.filterParamsSend,
            ...getters.searchParams
          }
        })
        commit('pagination', commerce.data.pagination)
        commit('set', commerce.data.properties.map(x => ({ ...x, isSelected: false })))
        commit('changePage')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async fetchCommerce({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const oneCommerce = await axios.get(url + '/archive/rent/properties/commerce/' + id)
        commit('setCommerce', oneCommerce.data.item)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async restore({commit, dispatch}, id) {
      const url = process.env.VUE_APP_BACKEND
      try {
        await axios.patch(url + '/archive/rent/properties/commerce/' + id + '/restore')
        commit('reset')
        dispatch('fetch')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async restoreSelected({state, commit, dispatch}) {
      const url = process.env.VUE_APP_BACKEND
      try {
        const ids = state.commerce.filter(x => x.isSelected).map(x => x.id)
        await axios.patch(url + '/archive/rent/properties/commerce/restore', { ids })
        commit('reset')
        dispatch('fetch')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async delete({commit, dispatch}, id) {
      const url = process.env.VUE_APP_BACKEND
      try {
        await axios.delete(url + '/archive/rent/properties/commerce/' + id)
        commit('reset')
        dispatch('fetch')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async deleteSelected({state, commit, dispatch}) {
      const url = process.env.VUE_APP_BACKEND
      try {
        const ids = state.commerce.filter(x => x.isSelected).map(x => x.id)
        await axios.delete(url + '/archive/rent/properties/commerce/', { data: { ids } })
        commit('reset')
        dispatch('fetch')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async update({commit}, {id, data}) {
      const url = process.env.VUE_APP_BACKEND
      try {
        const response = await axios.put(url + '/archive/rent/properties/commerce/' + id, data)
        commit('updateCommerce', response.data.item)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    }
  },
  getters: {
    commerce: s => s.commerce,
    oneCommerce: s => s.oneCommerce,
    pagination: s => s.pagination,
    page: s => s.page,
    // FILTER PARAMS
    filterParamsOriginal: s => s.filterParamsOriginal,
    filterParamsLocal: s => s.filterParamsLocal,
    filterParamsSend: s => s.filterParamsSend,
    filterParamsActive: s => s.filterParamsActive,
    sortParams: s => s.sortParams,
    searchParams: s => s.searchParams,
    selectionActive: s => s.selectionActive
  }
}
