import axios from 'axios'

export default {
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: {}
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, token) {
      state.status = 'success'
      state.token = token
    },
    setUser(state, user) {
      state.user = user;
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = ''
      state.token = ''
    },
  },
  actions: {
    login({commit}, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        const url = process.env.VUE_APP_BACKEND;
        axios({ url: `${url}/auth/login`, data: user, method: 'POST' })
          .then(resp => {
            const token = resp.data.access_token
            localStorage.setItem('token', token)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            commit('auth_success', token)
            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            commit("setError", err);
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    async getUser({commit}) {
      try {
        const url = process.env.VUE_APP_BACKEND;
        const user = await axios.get(`${url}/users/me`);
        commit('setUser', user.data);
      } catch (e) {
        commit("setError", e);
        throw e;
      }
    },
    // register({ commit }, user) {
    //   return new Promise((resolve, reject) => {
    //     commit('auth_request')
    //     axios({ url: 'http://localhost:3000/register', data: user, method: 'POST' })
    //       .then(resp => {
    //         const token = resp.data.token
    //         const user = resp.data.user
    //         localStorage.setItem('token', token)
    //         // Add the following line:
    //         axios.defaults.headers.common['Authorization'] = token
    //         commit('auth_success', token, user)
    //         resolve(resp)
    //       })
    //       .catch(err => {
    //         commit('auth_error', err)
    //         localStorage.removeItem('token')
    //         reject(err)
    //       })
    //   })
    // },

    logout({commit}) {
      return new Promise((resolve, reject) => {
        commit('logout');
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization'];
        resolve();
      })
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    user: state => state.user
  }
};