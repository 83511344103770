<template>
  <div class="navbar__wrapper" id="navbar">
    <div class="navbar__inner">
      <div class="navbar__top">
        <div class="container">
          <div class="navbar__top-inner">
            <div class="navbar__left">
              <div class="button button--fill button-mobile-nav" @click="openSidebar">
                <i class="ri-menu-unfold-line"></i>
              </div>
              <div class="navbar__left-mobile">
                <div class="navbar__left-icon">
                  <i class="ri-focus-line"></i>
                  {{ actionsNumber }}
                </div>
              </div>
              <div class="navbar__center-inner">
                <div class="navbar__center-inner">
                  <span class="link navbar__link navbar__link--active">
                    <span class="navbar__link-text">Дії</span>
                    <span class="navbar__link-icon">{{ actionsNumber }}</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="navbar__right">
              <button class="button button--fill" @click="addAction">Додати дію</button>
              <button class="button__icon" @click="addAction"><i class="ri-add-circle-line"></i></button>
              <div class="navbar__notifications">
                <Notifications />
              </div>
              <div class="user__block">
                <div class="user__icon">{{ letter }}</div>
                <div class="user__name">{{ user }}</div>
                <div class="user__param">
                  <Multiselect
                    class="mSelect-dropdown"
                    :options="list"
                    :searchable="false"
                    :show-labels="false"
                    :reset-after="true"
                    :value="uAction"
                    placeholder
                    @select="userfn"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="navbar__middle">
        <div class="container">
          <div class="navbar__middle-inner">
            <div class="navbar__left">
              <label class="search__label">
                <i class="ri-search-2-line"></i>
                <input class="search__input" v-model="search" placeholder="Введіть ID..." />
              </label>
            </div>
            <div class="navbar__right">
              <button
                class="button button--small button--grey"
                :class="{'ri-filter-fill-blue': activeParams}"
                @click="openFilter"
              >
                <i class="ri-filter-fill">
                  <span v-if="activeParams">{{ activeParams }}</span>
                </i>
                <span class="button__text">Фільтри</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="navbar__bottom">
        <div class="container">
          <div class="actions__table table__titles">
            <div class="table__title">#</div>
            <div class="table__title">Агент</div>
            <div class="table__title">Тип</div>
            <div class="table__title">Дата</div>
            <div class="table__title">Час</div>
            <div class="table__title">Об’єкт</div>
            <div class="table__title">Покупець</div>
            <div class="table__title">Результат</div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Sidebar from '@/components/Sidebar'
import SidebarMobile from '@/components/SidebarMobile'
import UploadAction from '@/views/Actions/UploadAction'
import Filters from '@/views/Actions/FiltersActions'
import Notifications from '@/components/Notifications'

export default {
  name: 'NavbarActions',
  components: {
    Multiselect,
    Notifications,
  },
  data: () => ({
    user: {},
    letter: '',
    list: ['Аккаунт', 'Вийти'],
    uAction: '',
    search: null
  }),
  created() {
    if (Object.keys(this.$store.getters.filterParamsActionsAll).length) {
      this.params = _.cloneDeep(this.$store.getters.filterParamsActionsAll)
    } else {
      this.params = _.cloneDeep(this.$store.getters.filterParamsActionsOriginal)
    }

    if (Object.keys(this.$store.getters.user).length === 0) {
      this.$store.dispatch('getUser').then(() => {
        const user = this.$store.getters.user

        this.user = user.fullName
        this.letter = user?.fullName[0]
      })
    } else {
      const user = this.$store.getters.user

      this.user = user.fullName
      this.letter = user?.fullName[0]
    }
    if (!this.$store.getters.pagActions.total) {
      this.$store.dispatch('fetchActions')
    }
  },
  watch: {
    async search() {
      if (this.search) {
        this.searchActions(this)
      } else if (this.search === '') {
        this.search = null
        this.searchActions(this)
      }
    }
  },
  computed: {
    activeParams() {
      return this.$store.getters.filterActiveParams
    },
    actionsNumber() {
      return this.$store.getters.pagActions.total
    }
  },
  methods: {
    userfn(option) {
      if (option === 'Вийти') {
        this.$store.dispatch('logout')
        this.$router.go('/login')
      }
    },
    searchActions: _.debounce(v => {
      v.$store.commit('resetActions')
      v.$store.commit('searchParams', {'filter[relatedID]': v.search})
      v.$store.dispatch('fetchActions')
    }, 500),
    openSidebar() {
      const mobile = document.documentElement.clientWidth <= 768
      let SidebarModal = {}
      mobile ? (SidebarModal = SidebarMobile) : (SidebarModal = Sidebar)
      this.$modal.display(
        SidebarModal,
        {},
        {
          name: 'Sidebar',
          transition: 'none',
          classes: 'modalSidebar',
          width: '210px',
          height: '100%',
          maxHeight: 1024,
          shiftX: 0,
          shiftY: 0,
          adaptive: true
        }
      )
    },
    addAction() {
      const styles = this.$modal.styles
      this.$modal.show(
        UploadAction,
        {},
        {...styles, name: 'modalUploadAction'},
        {'before-open': this.$modal.open, 'before-close': this.$modal.close}
      )
    },
    openFilter() {
      this.$modal.display(
        Filters,
        {},
        {
          transition: 'none',
          classes: 'modalFilter',
          width: '400px',
          height: '100%',
          shiftX: 1,
          shiftY: 1,
          name: 'FilterActions',
          maxHeight: 3000
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
