<template>
  <section class="data__inner data_contacts">
    <article class="data__top">
      <ContactSearch
        :selectedContacts="selectedContacts"
        title="Виберіть контакт"
        placeholder="Введіть телефон або імʼя"
        :permittedRoles="permittedRoles"
        @sendContacts="contacts => $emit('selectContacts', contacts)"
      />
    </article>
    <article class="data__bottom">
      <Contact :newContacts="newContacts" :visibleBox="visibleBox" :permittedRoles="permittedRoles" />
    </article>
  </section>
</template>

<script>
import _ from 'lodash'
import localConstants from '@/utils/localConstants'
import Contact from '@/components/Contact'
import ContactSearch from '@/components/ContactSearch'

export default {
  name: 'UploadContacts',
  props: ['newContacts', 'selectedContacts', 'visibleBox', 'v'],
  components: {
    Contact,
    ContactSearch,
  },
  data: () => ({
    localConstants
  }),
  computed: {
    permittedRoles() {
      const { CLIENT, AGENCY, DEVELOPER } = this.localConstants.CONTACT_ROLES
      return [CLIENT, AGENCY, DEVELOPER]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@import './style.scss';
</style>
