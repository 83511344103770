export default function sourcePath(id, sources) {
  let path = ''
  for (const source of sources) {
    path = source.label
    if (source.id === id) {
      path = source.label
      return path
    } else if (source.children && source.children.length > 0) {
      let childPath = sourcePath(id, source.children)
      if (childPath !== '') {
        return path + ' > ' + childPath
      }
    }
  }
  return ''
}