import axios from 'axios'

export default {
  namespaced: true,
  state: {
    seeker: {},
    seekers: [],
    pagination: {},
    page: 1,
    // FILTER PARAMS
    searchParams: {},
    sortParams: {},
    filterParamsLocal: {},
    filterParamsSend: {},
    filterParamsActive: 0,
    filterParamsOriginal: {
      agent: [],
      type: [],
      street: null,
      activity: null,
      createdAt: null,
    }
  },
  mutations: {
    set(state, seekers) {
      state.seekers = _.unionBy(state.seekers, seekers, 'id')
    },
    setSeeker(state, seeker) {
      state.seeker = seeker
    },
    updateSeeker(state, seeker) {
      const foundSeeker = state.seekers.find(x => x.id === seeker.id)
      Object.assign(foundSeeker, seeker)
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.seekers = []
      state.page = 1
    },
    changePage(state) {
      state.page++
    },
    filterParamsLocal(state, params) {
      state.filterParamsLocal = params
    },
    filterParamsSend(state, params) {
      state.filterParamsSend = params
    },
    filterParamsActive(state, number) {
      state.filterParamsActive = number
    },
    sortParams(state, params) {
      state.sortParams = params
    },
    searchParams(state, params) {
      state.searchParams = params
    }
  },
  actions: {
    async fetch({commit, getters}) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND}/seekers`, {
          params: {
            page: getters.page,
            ...getters.filterParamsSend,
            ...getters.sortParams,
            ...getters.searchParams
          }
        })
        const { data, pagination } = response.data
        commit('pagination', pagination)
        commit('set', data)
        commit('changePage')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async fetchSeeker({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const response = await axios.get(url + '/seekers/search', {
          params: { id }
        })
        commit('setSeeker', response.data)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async toggleLastCall({commit}, id) {
      try {
        await axios.patch(`${process.env.VUE_APP_BACKEND}/seekers/${id}/call`)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async delete({commit, dispatch}, id) {
      try {
        await axios.delete(`${process.env.VUE_APP_BACKEND}/seekers/${id}`)
        commit('reset')
        await dispatch('fetch')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async create({commit, dispatch}, {data, newContacts}) {
      if (newContacts.length) {
        const contactsIds = await dispatch('contacts/create', newContacts, {root: true})
        data.contactsIds = data.contactsIds.concat(contactsIds)
      }
      try {
        await axios.post(`${process.env.VUE_APP_BACKEND}/seekers`, data)
        commit('reset')
        await dispatch('fetch')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async edit({commit, dispatch}, {id, data, newContacts}) {
      if (newContacts.length) {
        const contactsIds = await dispatch('contacts/create', newContacts, {root: true})
        data.contactsIds = data.contactsIds.concat(contactsIds)
      }
      try {
        const response = await axios.put(`${process.env.VUE_APP_BACKEND}/seekers/${id}`, data)
        commit('updateSeeker', response.data.seeker)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async search({commit}, {query}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const response = await axios.get(url + '/seekers/search', {
          params: { query }
        })
        return response?.data || []
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    }
  },
  getters: {
    seekers: s => s.seekers,
    seeker: s => s.seeker,
    pagination: s => s.pagination,
    page: s => s.page,
    // FILTER PARAMS
    filterParamsOriginal: s => s.filterParamsOriginal,
    filterParamsLocal: s => s.filterParamsLocal,
    filterParamsSend: s => s.filterParamsSend,
    filterParamsActive: s => s.filterParamsActive,
    sortParams: s => s.sortParams,
    searchParams: s => s.searchParams,
  }
}
