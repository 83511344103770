<template>
  <div class="upload__container upload-seeker">
    <div class="upload__left">
      <div class="upload__navigation-title">Категорії</div>
      <div class="upload__navigation">
        <div class="upload__navigation-link-wrapper" @click="select('mainDataBox')">
          <div
            class="upload__navigation-link"
            :class="{
              'upload__navigation-link--active': mainDataBox,
              'upload__navigation-link--error': this.$v.sendData.$invalid && this.$v.sendData.$dirty
            }"
          >
            Основне
          </div>
          <div class="upload__navigation-icon"></div>
        </div>
        <div class="upload__navigation-link-wrapper" @click="select('contactsBox')">
          <div
            class="upload__navigation-link"
            :class="{
              'upload__navigation-link--active': contactsBox,
              'upload__navigation-link--error': contactsBoxError
            }"
          >
            Контакти
          </div>
          <div class="upload__navigation-icon"></div>
        </div>
      </div>
    </div>

    <div class="upload__right">
      <div class="upload__top">
        <div class="upload__title">

          <span v-if="seeker">Редагування Шукача - ID {{ seeker.id }} </span>
          <span v-else>Додавання Шукача </span>
        </div>
        <div class="upload__icons">
          <i class="ri-user-search-line"></i>
        </div>
        <div class="upload__buttons">
          <div class="upload__button button button--fill button--grey" @click="closeModal">Скасувати</div>
          <div class="upload__button button button--fill" @click="send">Зберегти</div>

          <div class="button__icon" @click="closeModal">
            <i class="ri-close-circle-line"></i>
          </div>
          <div class="button__icon button__icon--blue" @click="send">
            <i class="ri-save-3-line"></i>
          </div>
        </div>
      </div>
      <div class="upload__navigation--mobile">
        <div class="upload__navigation-link-wrapper" @click="select('mainDataBox')">
          <div
            class="upload__navigation-link"
            :class="{
              'upload__navigation-link--active': mainDataBox,
              'upload__navigation-link--error': this.$v.sendData.$invalid && this.$v.sendData.$dirty
            }"
          >
            Основне
          </div>
          <div class="upload__navigation-icon"></div>
        </div>
        <div class="upload__navigation-link-wrapper" @click="select('contactsBox')">
          <div
            class="upload__navigation-link"
            :class="{
              'upload__navigation-link--active': contactsBox,
              'upload__navigation-link--error': contactsBoxError
            }"
          >
            Контакти
          </div>
          <div class="upload__navigation-icon"></div>
        </div>
      </div>
      <div class="upload__main">
        <div v-if="mainDataBox" class="data__inner data_main">
          <div class="data__top">
            <div
              class="field__label"
              :class="{
                'field__label--filled': sendData.responsibleAgent,
                'field__label--invalid': $v.sendData.responsibleAgent.$dirty && !$v.sendData.responsibleAgent.required
              }"
            >
              <div class="field__title">Відповідальний агент</div>
              <Multiselect
                :options="users"
                v-model="sendData.responsibleAgent"
                label="fullName"
                trackBy="id"
                :showLabels="false"
                :searchable="false"
                placeholder="Відповідальний агент"
                class="mSelect"
              />
            </div>
          </div>
          <div class="data__middle">
            <div
              class="field__label"
              :class="{
                'field__label--filled': sendData.type,
                'field__label--invalid': $v.sendData.type.$dirty && !$v.sendData.type.required
              }"
            >
              <div class="field__title">Звідки</div>
              <Multiselect
                :options="constants.SEEKER_TYPES"
                v-model="sendData.type"
                label="name"
                trackBy="id"
                :showLabels="false"
                :searchable="false"
                placeholder="Звідки"
                class="mSelect"
              />
            </div>
            <div
              v-if="sendData.type && sendData.type.id === localConstants.SEEKER_TYPES.ACTIVITY"
              class="field__label"
              :class="{
                'field__label--filled': sendData.activity,
                'field__label--invalid': $v.sendData.activity.$dirty && !$v.sendData.activity.required
              }"
            >
              <div class="field__title">Діяльність</div>
              <Multiselect
                :options="constants.SEEKER_ACTIVITIES"
                v-model="sendData.activity"
                :showLabels="false"
                :searchable="false"
                placeholder="Діяльність"
                class="mSelect"
              />
            </div>
            <div
              v-if="sendData.type && sendData.type.id === localConstants.SEEKER_TYPES.COOPERATIVE"
              class="field__label"
              :class="{
                'field__label--filled': sendData.street,
                'field__label--invalid': $v.sendData.street.$dirty && !$v.sendData.street.required
              }"
            >
              <div class="field__title">Вулиця</div>
              <Multiselect
                :options="streets"
                v-model="sendData.street"
                :showLabels="false"
                placeholder="Вулиця"
                class="mSelect"
              >
                <span slot="noResult">Отакої, вулиці немає :(</span>
              </Multiselect>
            </div>
            <div
              v-if="sendData.type && sendData.type.id === localConstants.SEEKER_TYPES.COOPERATIVE"
              class="field__label"
              :class="{
                'field__label--filled': sendData.houseNo,
                'field__label--invalid': $v.sendData.houseNo.$dirty && !$v.sendData.houseNo.required
              }"
            >
              <div class="field__title field__title--required">№ будинку</div>
              <input class="field__input" v-model="sendData.houseNo" />
            </div>
            <div
              class="field__label"
              :class="{
                'field__label--filled': sendData.lastCallAt
              }"
            >
              <div class="field__title">Останній дзвінок</div>
              <date-picker
                v-model="sendData.lastCallAt"
                isDark
                color="blue"
                :popover="{visibility: 'focus', placement: 'top-start'}"
              >
                <template v-slot="{inputValue, inputEvents}">
                  <input class="field__input field__input-last_call" :value="inputValue" v-on="inputEvents" readonly />
                </template>
              </date-picker>
            </div>
          </div>
          <div class="data__bottom">
            <div
              style="margin-top: 10px"
              class="field__label"
              :class="{
                'field__label--filled': sendData.description
              }"
            >
              <div class="field__title">Про Шукача</div>
              <textarea class="field__text" v-model="sendData.description"></textarea>
            </div>
          </div>
        </div>
        <div v-if="contactsBox" class="data__inner data_contacts">
          <article class="data_contacts__top">
            <ContactSearch
              :selectedContacts="selectedContacts"
              title="Виберіть контакт"
              placeholder="Введіть телефон або імʼя"
              :permittedRoles="permittedRoles"
              @sendContacts="contacts => (selectedContacts = contacts)"
            />
          </article>
          <article class="data_contacts__bottom">
            <Contact :newContacts="newContacts" :visibleBox="visibleBox" :permittedRoles="permittedRoles" />
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Contact from '@/components/Contact'
import ContactSearch from '@/components/ContactSearch'
import Multiselect from 'vue-multiselect'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import moment from 'moment'
import localConstants from '@/utils/localConstants'
import {required, requiredIf, minLength} from 'vuelidate/lib/validators'

export default {
  name: 'UploadSeeker',
  props: {
    seeker: Object
  },
  components: {
    Multiselect,
    DatePicker,
    Contact,
    ContactSearch
  },
  data: () => ({
    localConstants,
    mainDataBox: true,
    contactsBox: false,
    sendData: {
      description: null,
      responsibleAgent: null,
      lastCallAt: null,
      type: null,
      street: null,
      activity: null,
      houseNo: null
    },
    selectedContacts: [],
    newContacts: [],
    visibleBox: []
  }),
  computed: {
    ...mapGetters({
      users: 'users/users',
      constants: 'constants',
      streets: 'streets',
    }),
    contactsBoxError() {
      if (this.$v.contactsIds) {
        return this.$v.contactsIds.$invalid && this.$v.contactsIds.$dirty
      } else if (this.$v.newContacts) {
        return this.$v.newContacts.$invalid && this.$v.newContacts.$dirty
      }
      return false
    },
    permittedRoles() {
      return [this.localConstants.CONTACT_ROLES.SEEKER]
    },
    isCooperative() {
      return this.sendData.type?.id === localConstants.SEEKER_TYPES.COOPERATIVE
    },
    isActivity() {
      return this.sendData.type?.id === localConstants.SEEKER_TYPES.ACTIVITY
    },
    contactsIds() {
      return this.selectedContacts?.map(c => c.id) ?? []
    },
  },
  methods: {
    select(box) {
      this.mainDataBox = false
      this.contactsBox = false
      this[box] = true
    },
    async send() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      const data = {
        description: this.sendData.description,
        responsibleId: this.sendData.responsibleAgent.id,
        lastCallAt: this.sendData.lastCallAt
          ? moment(this.sendData.lastCallAt).utcOffset(0, true).toISOString()
          : null,
        type: this.sendData.type.id,
        activity: this.isActivity ? this.sendData.activity : null,
        street: this.isCooperative ? this.sendData.street : null,
        houseNo: this.isCooperative ? this.sendData.houseNo : null,
        contactsIds: this.contactsIds
      }

      const newContacts = this.newContacts.map(c => ({
          name: c.name,
          phones: c.phones.map(x => ({
            phone: x.phone,
            isOnTelegram: x.isOnTelegram,
            isOnFacebook: x.isOnFacebook,
            isOnViber: x.isOnViber,
            isOnWhatsapp: x.isOnWhatsapp
          })),
          roles: c.roles.map(x => x.id),
          companyId: c.company?.id || null,
          jobPosition: c.jobPosition?.id || null,
          birthday: c.birthday?.format() || null
        }))

      if (this.seeker) {
        await this.$store.dispatch('seekers/edit', {id: this.seeker.id, data, newContacts})
      } else {
        await this.$store.dispatch('seekers/create', {data, newContacts})
      }
      this.$modal.hide('modalUploadSeeker')
    },
    closeModal() {
      this.$modal.hide('modalUploadSeeker')
    }
  },
  async created() {
    if (!this.users.length) {
      await this.$store.dispatch('users/fetch')
    }

    if (this.seeker) {
      this.sendData.description = this.seeker.description
      this.sendData.responsibleAgent = this.users.find(x => x.id === this.seeker.responsibleId)
      this.sendData.lastCallAt = this.seeker.lastCallAt
      this.sendData.type = this.constants.SEEKER_TYPES.find(x => x.id === this.seeker.type)
      this.sendData.street = this.seeker.street
      this.sendData.activity = this.seeker.activity
      this.sendData.houseNo = this.seeker.houseNo
      this.selectedContacts = this.seeker.contacts
    }

    await this.$store.dispatch('fetchStreets')
  },
  validations: {
    sendData: {
      description: {},
      lastCallAt: {},
      responsibleAgent: {required},
      type: {required},
      street: {
        required: requiredIf(function() {
          return this.isCooperative
        })
      },
      activity: {
        required: requiredIf(function() {
          return this.isActivity
        })
      },
      houseNo: {
        required: requiredIf(function() {
          return this.isCooperative
        })
      }
    },
    contactsIds: {
      required: requiredIf(function() {
        if (this.newContacts.length) {
          return false
        } else {
          return true
        }
      })
    },
    newContacts: {
      required: requiredIf(function() {
        if (this.newContacts.length) {
          return true
        } else {
          return false
        }
      }),
      $each: {
        birthday: {},
        name: {required},
        phones: {
          required,
          $each: {
            phone: {required, minLength: minLength(10)}
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
.upload__main {
  overflow-y: scroll;
}
</style>

<style lang="scss">
.upload-seeker .vc-popover-content-wrapper {
  position: fixed !important;
}
</style>
