<template>
  <div class="contact__wrapper">
    <div class="contact__header">
      <div class="contact__title">Додавання нового контакту</div>
      <i class="i-plus button__icon button__icon--blue" @click="addContact"></i>
    </div>
    <div class="contact__list">
      <div class="contact__item" v-for="(contact, index) in newContacts" :key="index">
        <div class="contact__item-top">
          <div class="contact__date-label">Дата народження</div>
          <i class="contact__expand i-expand" :class="{'i-expand--open': visibleBox[index].v}" @click="toggleInfo(index)"></i>
          <div class="contact__name">
            <input class="field__input contact__input" placeholder="Введіть ім'я" v-model="contact.name" />
          </div>
          <div class="contact__date">
            <span>
              <i class="i-calendar"></i>
              <date-picker v-model="contact.birthday" isDark color="blue" :popover="{visibility: 'focus'}">
                <template v-slot="{inputValue, inputEvents}">
                  <input class="field__input" :value="inputValue" v-on="inputEvents" readonly />
                </template>
              </date-picker>
            </span>
          </div>
          <div class="contact__date--mobile">
            <input v-model="contact.birthday" class="field__date--mobile" type="date" />
          </div>
          <div class="contact__who field__label">
            <Multiselect
              :options="roles"
              v-model="contact.roles"
              :multiple="true"
              :closeOnSelect="false"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              placeholder="Роль"
              class="mSelect"
            />
          </div>
          <button class="button__icon">
            <i class="i-remove" @click="removeContact(index)"></i>
          </button>
        </div>
        <div v-if="isCompany(contact) && visibleBox[index].v" class="contact__item-middle">
          <CompanySearch
            :selected="contact.company"
            :type="getCompanyType(contact)"
            placeholder="Компанія"
            @select="(company) => contact.company = company"
          />

          <Multiselect
            :options="constants.CONTACT_JOB_POSITIONS"
            v-model="contact.jobPosition"
            label="name"
            trackBy="id"
            :showLabels="false"
            :searchable="false"
            placeholder="Посада"
          />
        </div>
        <div class="contact__item-bottom" v-if="visibleBox[index].v">
          <div class="contact__item-main">
            <div class="contacts__phones-box">
              <div class="contacts__phone-box" v-for="(phone, indexPhone) in contact.phones" :key="indexPhone">
                <div class="search__wrapper">
                  <div class="chips__wrapper--compact">
                    <input
                      class="field__input search__input"
                      :value="phone.phone"
                      placeholder="Введіть телефон"
                      @focus="() => phone.isListVisible = true"
                      @blur="() => phone.isListVisible = false"
                      @input="event => phoneInputChange(event, phone, index, indexPhone)"
                    />
                  </div>
                  <div class="search__select" v-if="phone.isListVisible">
                    <div class="search__item" v-for="c in phone.foundContacts" :key="c.id">
                      <div class="search__phone contact__phone">{{ c.phones[0].phone }}</div>
                      <div class="search__name">
                        <div>
                          <span class="contact__name">{{ c.name }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="button__circle"
                  :class="{'button__circle--blue': phone.isOnViber}"
                  @click="phone.isOnViber = !phone.isOnViber"
                >
                  <i class="i-viber"></i>
                </button>
                <button
                  class="button__circle"
                  :class="{'button__circle--blue': phone.isOnTelegram}"
                  @click="phone.isOnTelegram = !phone.isOnTelegram"
                >
                  <i class="i-telegram"></i>
                </button>

                <button
                  class="button__circle"
                  :class="{'button__circle--blue': phone.isOnWhatsapp}"
                  @click="phone.isOnWhatsapp = !phone.isOnWhatsapp"
                >
                  <i class="i-whatsapp"></i>
                </button>
                <button
                  class="button__circle"
                  :class="{'button__circle--blue': phone.isOnFacebook}"
                  @click="phone.isOnFacebook = !phone.isOnFacebook"
                >
                  <i class="i-messanger"></i>
                </button>
                <button class="button__circle" @click="removePhone(index, indexPhone)">
                  <i class="ri-close-fill"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="contact__item-footer" @click="addPhone(index)">
            <i class="i-plus"></i>
            <span class="contact__item-footer-title">Додати ще один номер</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import {mapGetters} from 'vuex'
import Multiselect from 'vue-multiselect'
import ContactSearch from '@/components/ContactSearch'
import CompanySearch from '@/components/CompanySearch'
import localConstants from '@/utils/localConstants'

export default {
  name: 'NewContact',
  props: ['newContacts', 'visibleBox', 'permittedRoles'],
  components: {
    DatePicker,
    Multiselect,
    ContactSearch,
    CompanySearch,
  },
  data: () => ({
    localConstants,
  }),
  computed: {
    ...mapGetters(['constants']),
    roles() {
      return this.permittedRoles
        ? this.constants.CONTACT_ROLES.filter(x => this.permittedRoles.some(id => id === x.id))
        : this.constants.CONTACT_ROLES
    }
  },
  methods: {
    phoneInputChange(event, phone, contactIndex, phoneIndex) {
      const { value } = event.target

      if (value != '' && value.match(/^[0-9]{1,10}$/) == null) {
        event.target.value = phone.phone
        return
      }

      phone.phone = value

      this.searchContacts(this, phone.phone, contactIndex, phoneIndex)
    },
    searchContacts: _.debounce(async (v, query, contactIndex, phoneIndex) => {
      const phone = v.newContacts[contactIndex].phones[phoneIndex]
      if (query.length <= 2) {
        phone.foundContacts = []
        phone.isListVisible = false
        return
      }
      phone.foundContacts = await v.$store.dispatch('contacts/search', { query, doNotCommit: true }) || []
      phone.isListVisible = phone.foundContacts.length > 0

    }, 500),
    addContact() {
      const newContact = {
        name: '',
        birthday: null,
        phones: [
          {
            phone: '',
            isOnTelegram: false,
            isOnFacebook: false,
            isOnViber: false,
            isOnWhatsapp: false,

            foundContacts: [],
            isListVisible: false,
          }
        ],
        company: null,
        jobPosition: null,
        roles: [],
      }
      if (this.newContacts.length > 0) {
        if (this.newContacts[this.newContacts.length - 1].name) {
          this.newContacts.push(newContact)
        }
      } else {
        this.newContacts.push(newContact)
      }
      this.visibleBox.push({v: true})
      if (this.visibleBox.length > 1) {
        this.visibleBox[this.visibleBox.length - 2].v = false
      }
    },
    addPhone(i) {
      const phone = {
        phone: '',
        isOnTelegram: false,
        isOnFacebook: false,
        isOnViber: false,
        isOnWhatsapp: false
      }
      if (this.newContacts[i].phones[this.newContacts[i].phones.length - 1].phone) {
        this.newContacts[i].phones.push(phone)
      }
    },
    removeContact(i) {
      this.newContacts.splice(i, 1)
      this.visibleBox.splice(i, 1)
    },
    removePhone(i, iP) {
      this.newContacts[i].phones.splice(iP, 1)
    },
    toggleInfo(i) {
      this.visibleBox[i].v = !this.visibleBox[i].v
    },
    isCompany(contact) {
      const isAgency = contact.roles?.some(x => x.id === this.localConstants.CONTACT_ROLES.AGENCY) || false
      const isDeveloper = contact.roles?.some(x => x.id === this.localConstants.CONTACT_ROLES.DEVELOPER) || false
      return isAgency || isDeveloper
    },
    getCompanyType(contact) {
      const isAgency = contact.roles?.some(x => x.id === this.localConstants.CONTACT_ROLES.AGENCY) || false
      const isDeveloper = contact.roles?.some(x => x.id === this.localConstants.CONTACT_ROLES.DEVELOPER) || false

      if (isAgency && isDeveloper) {
        return null
      } else if (isAgency) {
        return this.localConstants.COMPANY_TYPES.AGENCY
      } else if (isDeveloper) {
        return this.localConstants.COMPANY_TYPES.DEVELOPER
      }

      throw new Error('Contact is not a company')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@import './style.scss';
</style>
