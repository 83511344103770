<template>
  <div class="upload__container">
    <div class="upload__left">
      <div class="upload__navigation-title">Категорії</div>
      <div class="upload__navigation">
        <div class="upload__navigation-link-wrapper" @click="select('mainDataBox')">
          <div
            class="upload__navigation-link"
            :class="{
              'upload__navigation-link--active': mainDataBox,
              'upload__navigation-link--error': this.$v.sendData.$invalid && this.$v.sendData.$dirty
            }"
          >
            Параметри
          </div>
          <div class="upload__navigation-icon"></div>
        </div>
        <div class="upload__navigation-link-wrapper" @click="select('otherDataBox')">
          <div
            class="upload__navigation-link"
            :class="{
              'upload__navigation-link--active': otherDataBox,
              'upload__navigation-link--error': this.$v.sendDataOther.$invalid && this.$v.sendDataOther.$dirty
            }"
          >
            Додаткові
          </div>
          <div class="upload__navigation-icon"></div>
        </div>
        <div class="upload__navigation-link-wrapper" @click="select('contactsBox')">
          <div
            class="upload__navigation-link"
            :class="{
              'upload__navigation-link--active': contactsBox,
              'upload__navigation-link--error': contactsBoxError
            }"
          >
            Контакти
          </div>
          <div class="upload__navigation-icon"></div>
        </div>
      </div>
    </div>
    <div class="upload__right">
      <div class="upload__top">
        <div class="upload__title">
          <span v-if="info">Редагування Покупця </span>
          <span v-else>Додавання Покупця </span>
          <span v-if="type === 'apartment'">(Квартири)</span>
          <span v-if="type === 'house'">(Будинок)</span>
          <span v-if="type === 'commerce'">(Комерція)</span>
          <span v-if="id"> - ID {{ id }}</span>
        </div>
        <div class="upload__icons is-mobile">
          <i class="ri-shopping-bag-fill"></i>
          <i v-if="type === 'apartment'" class="ri-building-4-fill"></i>
          <i v-if="type === 'house'" class="ri-home-5-fill"></i>
          <i v-if="type === 'commerce'" class="ri-store-fill"></i>
        </div>
        <div class="upload__buttons">
          <div class="upload__button button button--fill button--grey" @click="$modal.hide('UploadBuyer')">
            Скасувати
          </div>
          <div class="upload__button button button--fill" @click="send">
            Зберегти
          </div>
          <div class="button__icon" @click="$modal.hide('UploadBuyer')">
            <i class="ri-close-circle-line"></i>
          </div>
          <div class="button__icon button__icon--blue" @click="send">
            <i class="ri-save-3-line"></i>
          </div>
        </div>
      </div>
      <div class="upload__navigation--mobile">
        <div class="upload__navigation-link-wrapper" @click="select('mainDataBox')">
          <div
            class="upload__navigation-link"
            :class="{
              'upload__navigation-link--active': mainDataBox,
              'upload__navigation-link--error': this.$v.sendData.$invalid && this.$v.sendData.$dirty
            }"
          >
            Параметри
          </div>
          <div class="upload__navigation-icon"></div>
        </div>
        <div class="upload__navigation-link-wrapper" @click="select('otherDataBox')">
          <div
            class="upload__navigation-link"
            :class="{
              'upload__navigation-link--active': otherDataBox,
              'upload__navigation-link--error': this.$v.sendDataOther.$invalid && this.$v.sendDataOther.$dirty
            }"
          >
            Додаткові
          </div>
          <div class="upload__navigation-icon"></div>
        </div>
        <div class="upload__navigation-link-wrapper" @click="select('contactsBox')">
          <div
            class="upload__navigation-link"
            :class="{
              'upload__navigation-link--active': contactsBox,
              'upload__navigation-link--error': contactsBoxError
            }"
          >
            Контакти
          </div>
          <div class="upload__navigation-icon"></div>
        </div>
      </div>
      <div class="upload__main">
        <div v-if="mainDataBox" class="data__inner data_main">
          <div class="data__part">
            <label
              class="field__label"
              :class="{
                'field__label--filled': sendData.settlement,
                'field__label--invalid': $v.sendData.settlement.$dirty && !$v.sendData.settlement.required
              }"
            >
              <div class="field__title field__title--required">
                Населений Пункт
              </div>
              <Multiselect
                :options="settlements"
                v-model="sendData.settlement"
                label="name"
                trackBy="name"
                :multiple="true"
                :showLabels="false"
                :allowEmpty="false"
                placeholder="Населений Пункт"
                class="mSelect"
              />
            </label>
            <label
              class="field__label"
              v-if="sendData.settlement.find(s => s.name === 'Рівне')"
              :class="{'field__label--filled': sendData.districtID.length}"
            >
              <div class="field__title field__title--required">Район</div>
              <Multiselect
                :options="districtsRivne"
                v-model="sendData.districtID"
                label="name"
                trackBy="id"
                :multiple="true"
                :showLabels="false"
                :searchable="false"
                group-values="dist"
                group-label="select"
                :group-select="true"
                placeholder="Район"
                class="mSelect"
              />
            </label>
            <label
              class="field__label"
              v-if="type === 'house'"
              :class="{
                'field__label--filled': sendData.range,
                'field__label--invalid': $v.sendData.range.$dirty && !$v.sendData.range.required
              }"
            >
              <div class="field__title field__title--required">Розташування</div>
              <Multiselect
                :options="constants.BUYER_HOUSE_RANGES"
                v-model="sendData.range"
                label="name"
                trackBy="id"
                :showLabels="false"
                :searchable="false"
                placeholder="Розташування"
                class="mSelect"
              />
            </label>
          </div>
          <div class="data__middle">
            <div
              class="field__label"
              :class="{
                'field__label--filled': sendData.noOfRooms,
                'field__label--invalid': $v.sendData.noOfRooms.$dirty && !$v.sendData.noOfRooms.required
              }"
            >
              <div class="field__title">Кількість кімнат</div>
              <Multiselect
                :options="localConstants.PROPERTY_ROOMS"
                v-model="sendData.noOfRooms"
                label="name"
                trackBy="id"
                :showLabels="false"
                :searchable="false"
                :maxHeight="200"
                class="mSelect"
                placeholder="К-сть кімнат"
              />
            </div>
            <label
              class="field__label"
              :class="{
                'field__label--filled': sendData.maxPrice,
                'field__label--invalid':
                  ($v.sendData.maxPrice.$dirty && !$v.sendData.maxPrice.required) || !$v.sendData.maxPrice.numeric
              }"
            >
              <div class="field__title field__title--required">Максимальна Ціна</div>
              <input
                class="field__input"
                v-model.number="sendData.maxPrice"
                v-mask="'XXXXXXX'"
                placeholder="Макс ціна"
              />
            </label>
            <div
              v-if="type !== 'commerce'"
              class="field__label"
              :class="{
                'field__label--filled': sendData.material.length,
                'field__label--invalid': $v.sendData.material.$dirty && !$v.sendData.material.required
              }"
            >
              <div class="field__title">Вид</div>
              <Multiselect
                v-if="type === 'apartment'"
                :options="constants.APARTMENT_MATERIALS"
                v-model="sendData.material"
                label="name"
                trackBy="id"
                :multiple="true"
                :showLabels="false"
                :searchable="false"
                :maxHeight="200"
                class="mSelect"
                placeholder="Вид"
              />
              <Multiselect
                v-if="type === 'house'"
                :options="constants.HOUSE_MATERIALS"
                v-model="sendData.material"
                :multiple="true"
                label="name"
                trackBy="id"
                :showLabels="false"
                :searchable="false"
                :maxHeight="200"
                class="mSelect"
                placeholder="Вид"
              />
            </div>
            <div
              class="field__label"
              :class="{
                'field__label--filled': sendData.buildingType.length,
                'field__label--invalid': $v.sendData.buildingType.$dirty && !$v.sendData.buildingType.required
              }"
            >
              <div v-if="type === 'commerce'" class="field__title field__title--required">Призначення</div>
              <div v-else class="field__title field__title--required">Тип</div>
              <Multiselect
                :options="PROPERTY_TYPES"
                v-model="sendData.buildingType"
                label="name"
                trackBy="id"
                :multiple="true"
                :showLabels="false"
                :searchable="false"
                :maxHeight="200"
                class="mSelect"
                placeholder="Тип"
                group-values="dist"
                group-label="select"
                :group-select="true"
              />
            </div>
            <div
              v-if="type === 'apartment'"
              class="field__label"
              :class="{
                'field__label--filled': sendData.floor.length,
                'field__label--invalid': $v.sendData.floor.$dirty && !$v.sendData.floor.required
              }"
            >
              <div class="field__title">Поверх</div>
              <Multiselect
                :options="PROPERTY_FLOORS"
                v-model="sendData.floor"
                label="name"
                trackBy="id"
                :multiple="true"
                :showLabels="false"
                :searchable="false"
                :maxHeight="200"
                class="mSelect"
                placeholder="Поверх"
                group-values="dist"
                group-label="select"
                :group-select="true"
              />
            </div>
            <div
              class="field__label"
              :class="{
                'field__label--filled': sendData.isRenovated,
                'field__label--invalid': $v.sendData.isRenovated.$dirty && !$v.sendData.isRenovated.required
              }"
            >
              <div class="field__title">Ремонт</div>
              <Multiselect
                :options="localConstants.PROPERTY_TOGGLE"
                v-model="sendData.isRenovated"
                label="name"
                trackBy="id"
                :showLabels="false"
                :searchable="false"
                :maxHeight="200"
                class="mSelect"
                placeholder="Ремонт"
              />
            </div>
            <div
              v-if="type !== 'house'"
              class="field__label"
              :class="{
                'field__label--filled': sendData.autonomousHeatType,
                'field__label--invalid':
                  $v.sendData.autonomousHeatType.$dirty && !$v.sendData.autonomousHeatType.required
              }"
            >
              <div class="field__title">Автономне опалення</div>
              <Multiselect
                :options="localConstants.AUTONOMOUS_HEAT_TYPE"
                v-model="sendData.autonomousHeatType"
                :showLabels="false"
                :searchable="false"
                :maxHeight="200"
                class="mSelect"
                placeholder="Опалення"
              />
            </div>
            <div
              v-if="type === 'house'"
              class="field__label"
              :class="{
                'field__label--filled': sendData.hasGarage,
                'field__label--invalid': $v.sendData.hasGarage.$dirty && !$v.sendData.hasGarage.required
              }"
            >
              <div class="field__title">Гараж</div>
              <Multiselect
                :options="localConstants.PROPERTY_TOGGLE"
                v-model="sendData.hasGarage"
                label="name"
                trackBy="id"
                :showLabels="false"
                :searchable="false"
                :maxHeight="200"
                class="mSelect"
                placeholder="Гараж"
              />
            </div>
            <label
              v-if="type !== 'apartment'"
              class="field__label"
              :class="{'field__label--filled': sendData.squareTotal}"
            >
              <div class="field__title">Загальна площа</div>
              <input class="field__input" v-model="sendData.squareTotal" v-mask="'###'" placeholder="Загальна площа" />
            </label>
            <label v-if="type === 'house'" class="field__label" :class="{'field__label--filled': sendData.squareLand}">
              <div class="field__title">Площа землі</div>
              <input class="field__input" v-model="sendData.squareLand" v-mask="'###'" placeholder="Площа земі" />
            </label>
          </div>

          <div class="data__bottom">
            <label
              class="field__label"
              :class="{
                'field__label--filled': sendData.description,
                'field__label--invalid': $v.sendData.description.$dirty && !$v.sendData.description.required
              }"
            >
              <div class="field__title">Опис</div>
              <textarea class="field__text" v-model="sendData.description"></textarea>
            </label>
          </div>
        </div>
        <DataOther :data="sendDataOther" :v="$v" v-if="otherDataBox" />
        <UploadContacts
          v-if="contactsBox"
          :newContacts="newContacts"
          :selectedContacts="selectedContacts"
          :visibleBox="visibleBox"
          :v="$v"
          @selectContacts="contacts => selectedContacts = contacts"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {required, numeric, minLength, requiredIf} from 'vuelidate/lib/validators'
import localConstants from '@/utils/localConstants'
import Multiselect from 'vue-multiselect'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import moment from 'moment'
import UploadContacts from './UploadContacts'
import DataOther from './UploadDataOther'

export default {
  name: 'UploadBuyers',
  props: {
    info: Object
  },
  components: {
    Multiselect,
    Treeselect,
    DataOther,
    UploadContacts
  },
  data: () => ({
    localConstants,
    mainDataBox: true,
    otherDataBox: false,
    contactsBox: false,
    sendData: {
      maxPrice: '',
      settlement: [{name: 'Рівне'}],
      districtID: [],
      material: [],
      buildingType: [],
      noOfRooms: '',
      floor: [],
      isRenovated: '',
      autonomousHeatType: '',
      description: '',
      range: null,
      hasGarage: null,
      squareTotal: null,
      squareLand: null
    },
    sendDataOther: {
      source: null,
      contract: null,
      reasonToBuy: '',
      responsibleID: null,
      buyerStatus: null,
      agent: null,
      lastCallAt: null,
      motivation: null,
      mortgagePurchase: null,
      seeker: null,
    },
    selectedContacts: [],
    newContacts: [],
    visibleBox: [],
    settlements: [],
    districtsRivne: [],
    type: '',
    id: null,
    PROPERTY_FLOORS: null,
    PROPERTY_TYPES: null
  }),
  computed: {
    ...mapState({
      constants: s => s.main.constants,
      users: s => s.users.users
    }),
    contactsBoxError() {
      let error1
      let error2
      if (this.$v.contactsIDs) {
        error1 = this.$v.contactsIDs.$invalid
        error2 = this.$v.contactsIDs.$dirty
      } else if (this.$v.newContacts) {
        error1 = this.$v.newContacts.$invalid
        error2 = this.$v.newContacts.$dirty
      }
      let error3 = false
      if (error1 && error2) {
        error3 = true
      }
      return error3
    },
    contactsIDs() {
      return this.selectedContacts?.map(c => c.id) ?? []
    },
  },
  methods: {
    async send() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      let data = {
        maxPrice: this.sendData.maxPrice,
        isRenovated: this.sendData.isRenovated?.id,
        description: this.sendData.description,
        noOfRooms: this.sendData.noOfRooms?.id,
        motivation: this.sendDataOther.motivation,
        contactsIDs: this.contactsIDs,
        settlement: this.sendData.settlement.map(s => s.name),
        contract: this.sendDataOther.contract,
        mortgagePurchase: this.sendDataOther.mortgagePurchase.id,
        source: this.sendDataOther.source,
        reasonToBuy: this.sendDataOther.reasonToBuy,
        responsibleID: this.sendDataOther.agent?.id,
        buyerStatus: this.sendDataOther.buyerStatus?.id,
        buildingType: this.sendData.buildingType.map(type => type.id),
        districtID: this.sendData.districtID.map(id => id.id)
      }
      data.seekerId = data.source === this.localConstants.BUYER_SOURCES_SEEKER_ID ? this.sendDataOther.seeker.id : null
      if (this.type === 'house') {
        data.range = this.sendData.range?.id
        data.hasGarage = this.sendData.hasGarage?.id
        if (this.sendData.squareTotal) {
          data.squareTotal = this.sendData.squareTotal
        }
        if (this.sendData.squareLand) {
          data.squareLand = this.sendData.squareLand
        }
      }
      if (this.type !== 'house') {
        data.autonomousHeatType = this.sendData.autonomousHeatType
      }
      if (this.type !== 'commerce') {
        data.material = this.sendData.material.map(m => m.id)
      }
      if (this.type === 'commerce') {
        if (this.sendData.squareTotal) {
          data.squareTotal = this.sendData.squareTotal
        }
      }
      if (this.type === 'apartment') {
        data.floor = this.sendData.floor.map(f => f.id)
      }
      if (this.sendDataOther.lastCallAt) {
        data.lastCallAt = moment(this.sendDataOther.lastCallAt).format('DD-MM-YYYY')
      }
      const newContacts = this.newContacts.map(c => ({
          name: c.name,
          phones: c.phones.map(x => ({
            phone: x.phone,
            isOnTelegram: x.isOnTelegram,
            isOnFacebook: x.isOnFacebook,
            isOnViber: x.isOnViber,
            isOnWhatsapp: x.isOnWhatsapp
          })),
          roles: c.roles.map(x => x.id),
          companyId: c.company?.id || null,
          jobPosition: c.jobPosition?.id || null,
          birthday: c.birthday?.format() || null
        }))
      let createBuyer = ''
      if (this.type === 'apartment') {
        if (this.info) {
          createBuyer = 'buyers/apartments/edit'
        } else {
          createBuyer = 'buyers/apartments/create'
        }
      } else if (this.type === 'house') {
        if (this.info) {
          createBuyer = 'buyers/houses/edit'
        } else {
          createBuyer = 'buyers/houses/create'
        }
      } else if (this.type === 'commerce') {
        if (this.info) {
          createBuyer = 'buyers/commerce/edit'
        } else {
          createBuyer = 'buyers/commerce/create'
        }
      }
      await this.$store.dispatch(createBuyer, {
        data,
        newContacts,
        id: this.id
      })
      this.$modal.hide('UploadBuyer')
    },
    select(box) {
      this.mainDataBox = this.otherDataBox = this.contactsBox = false
      this[box] = true
    }
  },
  async created() {
    this.type = this.$attrs.buyer
    this.PROPERTY_FLOORS = [
      {
        dist: [...this.constants.BUYER_APT_FLOORS],
        select: 'Всі'
      }
    ]
    this.PROPERTY_TYPES = [
      {
        dist: [...this.constants.APARTMENT_TYPES],
        select: 'Всі'
      }
    ]
    if (this.type === 'house') {
      this.PROPERTY_TYPES[0].dist = [...this.constants.HOUSE_TYPES]
    } else if (this.type === 'commerce') {
      this.PROPERTY_TYPES[0].dist = [...this.constants.COMMERCE_BUILDING_TYPES]
    }
    await Promise.all([
      this.$store.dispatch('users/fetch'),
      this.$store.dispatch('fetchSettlements'),
      this.$store.dispatch('fetchStreets')
    ])
    this.settlements = this.$store.getters.settlements
    this.districtsRivne = this.settlements.find(s => s.name === 'Рівне').districts
    this.districtsRivne = [
      {
        dist: [...this.districtsRivne],
        select: 'Всі'
      }
    ]

    if (this.info) {
      const info = this.info
      this.sendData = Object.assign(this.sendData, this.info)
      const data = this.sendData
      this.id = info.id
      data.settlement = info.settlement.map(s => {
        return {name: s}
      })
      data.noOfRooms = this.localConstants.PROPERTY_ROOMS.find(room => room.id === info.noOfRooms)
      data.isRenovated = data.isRenovated === false ? {id: false, name: 'Немає'} : {id: true, name: 'Є'}
      if (!info.districtID[0]) {
        data.districtID = []
      }

      if (this.type === 'apartment') {
        data.buildingType = info.buildingType.map(type => this.constants.APARTMENT_TYPES.find(t => t.id === type))
        data.material = info.material.map(material => this.constants.APARTMENT_MATERIALS.find(m => m.id === material))
        data.floor = info.floor.map(floor => this.constants.BUYER_APT_FLOORS.find(f => f.name === floor))
      } else if (this.type === 'house') {
        data.range = {name: info.range}
        data.hasGarage = data.hasGarage === false ? {id: false, name: 'Немає'} : {id: true, name: 'Є'}
        data.buildingType = info.buildingType.map(type => this.constants.HOUSE_TYPES.find(t => t.id === type))
        data.material = info.material.map(material => this.constants.HOUSE_MATERIALS.find(m => m.id === material))
      } else if (this.type === 'commerce') {
        data.buildingType = info.buildingType.map(type =>
          this.constants.COMMERCE_BUILDING_TYPES.find(t => t.id === type)
        )
      }

      this.selectedContacts = info.contacts

      this.sendDataOther.agent = this.users.find(user => user.id === info.responsibleID)
      this.sendDataOther.buyerStatus = this.constants.PROPERTY_STATUSES.find(status => status.id === info.buyerStatus)
      this.sendDataOther.motivation = info.motivation
      this.sendDataOther.source = info.source
      this.sendDataOther.seeker = info.seeker
      this.sendDataOther.reasonToBuy = info.reasonToBuy
      this.sendDataOther.contract = info.contract
      this.sendDataOther.lastCallAt = info.lastCallAt
      this.sendDataOther.mortgagePurchase = localConstants.YES_NO.find(status => status.id === info.mortgagePurchase)
    }
  },
  validations: {
    sendData: {
      maxPrice: {required, numeric},
      settlement: {required},
      buildingType: {required},
      noOfRooms: {required},
      isRenovated: {required},
      description: {required},
      floor: {
        required: requiredIf(function() {
          if (this.type === 'apartment') {
            return true
          }
        })
      },
      range: {
        required: requiredIf(function() {
          if (this.type === 'house') {
            return true
          }
        })
      },
      hasGarage: {
        required: requiredIf(function() {
          if (this.type === 'house') {
            return true
          }
        })
      },
      material: {
        required: requiredIf(function() {
          if (this.type !== 'commerce') {
            return true
          }
        })
      },
      autonomousHeatType: {
        required: requiredIf(function() {
          if (this.type !== 'house') {
            return true
          }
        })
      }
    },
    sendDataOther: {
      source: {required},
      seeker: { 
        required: requiredIf(function() {
          return this.sendDataOther.source == this.localConstants.BUYER_SOURCES_SEEKER_ID
        }),
      },
      motivation: {required},
      contract: {required},
      reasonToBuy: {required},
      agent: {required},
      buyerStatus: {required},
      lastCallAt: {},
      mortgagePurchase: {required}
    },
    contactsIDs: {
      required: requiredIf(function() {
        if (this.newContacts.length) {
          return false
        } else {
          return true
        }
      })
    },
    newContacts: {
      required: requiredIf(function() {
        if (this.newContacts.length) {
          return true
        } else {
          return false
        }
      }),
      $each: {
        birthday: {},
        name: {required},
        phones: {
          required,
          $each: {
            phone: {required, minLength: minLength(10)}
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>

<style lang="scss">
.multiselect__tags {
  max-height: 70px;
  overflow: auto;
}
</style>
