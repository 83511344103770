import axios from 'axios'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    stats: [],
    period: {
      'period[from]': moment().year(moment().get('year')).month(moment().get('month')).startOf('month').format('DD-MM-YYYY'),
      'period[till]': moment().year(moment().get('year')).month(moment().get('month')).endOf('month').format('DD-MM-YYYY')
    },
    type: 'apartment',
    group: 'sources',
    sourceID: 0
  },
  mutations: {
    set(state, stats) {
      state.stats = stats
    },
    setType(state, type) {
      state.type = type
    },
    setPeriod(state, period) {
      state.period = period
    },
    setGroup(state, group) {
      state.group = group
    },
    setSource(state, id) {
      state.sourceID = id
    },
    reset(state) {
      state.type = 'apartment'
      state.group = 'sources'
      
      const year = moment().get('year')
      const month = moment().get('month')
      state.period = {
        'period[from]': moment().year(year).month(month).startOf('month').format('DD-MM-YYYY'),
        'period[till]': moment().year(year).month(month).endOf('month').format('DD-MM-YYYY')
      },
      state.sourceID = 0
    }
  },
  actions: {
    async fetch({commit, state}) {
      try {
        const {type, period, group, sourceID} = state
        const url = process.env.VUE_APP_BACKEND
        let source = ''
        if (group === 'sources') source = `/${sourceID}`
        const stats = await axios.get(url + `/stats/properties/${group}${source}` , {
          params: {
            ...period,
            type
          }
        })
        commit('set', stats.data)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    }
  }
}
