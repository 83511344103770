<template>
  <div class="navbar__wrapper">
    <div class="navbar__inner">
      <div class="navbar__top">
        <div class="container">
          <div class="navbar__top-inner">
            <div class="navbar__left">
              <div class="button button--fill button-mobile-nav" @click="openSidebar">
                <i class="ri-menu-unfold-line"></i>
              </div>
              <div class="navbar__center-inner">
                <span class="link navbar__link navbar__link--active">
                  <span class="navbar__link-text">Користувачі</span>
                  <span class="navbar__link-icon">{{ usersNumber }}</span>
                </span>
              </div>
            </div>
            <div class="navbar__right">
              <button class="button button--fill" @click="createUser">
                Додати користувача
              </button>
              <button class="button__icon" @click="createUser"><i class="ri-add-circle-line"></i></button>
              <div class="navbar__notifications">
                <Notifications />
              </div>
              <div class="user__block">
                <div class="user__icon">{{ userLetter }}</div>
                <div class="user__name">{{ userName }}</div>
                <div class="user__param">
                  <Multiselect
                    class="mSelect-dropdown"
                    :options="list"
                    :searchable="false"
                    :show-labels="false"
                    :reset-after="true"
                    :value="uAction"
                    placeholder=""
                    @select="userfn"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Sidebar from '@/components/Sidebar'
import SidebarMobile from '@/components/SidebarMobile'
import UploadUser from '../Upload'
import {mapState, mapActions} from 'vuex'
import Notifications from '@/components/Notifications'
import _ from 'lodash'

export default {
  name: 'NavbarUsers',
  components: {
    Multiselect,
    Notifications,
  },
  data: () => ({
    userName: '',
    userLetter: '',
    list: ['Аккаунт', 'Вийти'],
    uAction: ''
  }),
  async created() {
    if (_.isEmpty(this.userInfo)) await this.getUser()
    this.userName = this.userInfo.fullName
    this.userLetter = this.userName[0]
    if (!this.usersNumber) this.fetch()
  },
  computed: {
    ...mapState({
      usersNumber: s => s.users.pagination?.total,
      users: s => s.users.users,
      userInfo: s => s.auth.user
    })
  },
  methods: {
    ...mapActions({
      fetch: 'users/adminFetch',
      getUser: 'getUser',
    }),
    userfn(option) {
      if (option === 'Вийти') {
        this.$store.dispatch('logout')
        this.$router.go('/login')
      }
    },
    openSidebar() {
      const mobile = document.documentElement.clientWidth <= 768
      let SidebarModal = {}
      mobile ? (SidebarModal = SidebarMobile) : (SidebarModal = Sidebar)
      this.$modal.display(
        SidebarModal,
        {},
        {
          name: 'Sidebar',
          transition: 'none',
          classes: 'modalSidebar',
          width: '210px',
          height: '100%',
          maxHeight: 1024,
          shiftX: 0,
          shiftY: 0,
          adaptive: true
        }
      )
    },
    createUser() {
      this.$modal.display(
        UploadUser,
        {},
        {transition: 'none', classes: 'modal', width: '1000px', height: '92%', clickToClose: false, name: 'UploadUser'}
      )
    }
  }
}
</script>
