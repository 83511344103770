var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search__wrapper" }, [
    _c("label", { staticClass: "field__label search__label" }, [
      _vm.title
        ? _c("div", { staticClass: "field__title" }, [
            _vm._v(_vm._s(_vm.title))
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "chips__wrapper",
          class: { "chips__wrapper--compact": _vm.compact }
        },
        [
          _vm._l(_vm.selectedContacts, function(c) {
            return _c("div", { key: c.id, staticClass: "chips__box" }, [
              _c("div", { staticClass: "chips__item" }, [
                _vm._v(_vm._s(c.name) + " — " + _vm._s(c.phones[0].phone))
              ]),
              _c("i", {
                staticClass: "i-close chips__button",
                on: {
                  click: function($event) {
                    return _vm.delSelectedContact(c)
                  }
                }
              })
            ])
          }),
          _c("input", {
            staticClass: "field__input search__input",
            attrs: { placeholder: _vm.placeholder },
            domProps: { value: _vm.searchQuery },
            on: {
              input: function(event) {
                return _vm.inputChange(event)
              }
            }
          })
        ],
        2
      ),
      _vm.isListVisible
        ? _c(
            "div",
            { staticClass: "search__select" },
            _vm._l(_vm.foundContactsWithoutSelected, function(c) {
              return _c("div", { key: c.id, staticClass: "search__item" }, [
                _c("div", { staticClass: "search__phone contact__phone" }, [
                  _vm._v(_vm._s(c.phones[0].phone))
                ]),
                _c("div", { staticClass: "search__name" }, [
                  _c("div", [
                    _c("span", { staticClass: "contact__name" }, [
                      _vm._v(_vm._s(c.name))
                    ]),
                    _c("span", { staticClass: "contact__roles" }, [
                      _vm._v(
                        "(" +
                          _vm._s(
                            c.roles
                              .map(function(x) {
                                return x.name
                              })
                              .join(" / ")
                          ) +
                          ")"
                      )
                    ])
                  ]),
                  _c("div", [
                    c.company
                      ? _c("span", { staticClass: "contact__company" }, [
                          _vm._v('"' + _vm._s(c.company.name) + '"')
                        ])
                      : _vm._e(),
                    c.jobPosition
                      ? _c("span", { staticClass: "contact__job-position" }, [
                          _vm._v("(" + _vm._s(c.jobPosition.name) + ")")
                        ])
                      : _vm._e()
                  ])
                ]),
                !_vm.readonly
                  ? _c("div", { staticClass: "search__button__wrapper" }, [
                      _c(
                        "button",
                        {
                          staticClass: "search__button",
                          on: {
                            click: function($event) {
                              return _vm.editContact(c)
                            }
                          }
                        },
                        [_c("i", { staticClass: "ri-pencil-fill" })]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "search__button",
                          attrs: {
                            disabled: !_vm.hasAnyPermittedRole(c.roles)
                          },
                          on: {
                            click: function($event) {
                              return _vm.selectContact(c)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "i-plus search__button__icon"
                          })
                        ]
                      )
                    ])
                  : _vm._e()
              ])
            }),
            0
          )
        : _vm._e(),
      _vm.isListVisible
        ? _c("div", {
            staticClass: "search__screen",
            on: {
              click: function($event) {
                _vm.isListVisible = !_vm.isListVisible
              }
            }
          })
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }