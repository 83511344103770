<template>
  <div class="editContact__wrapper">
    <div class="editContact__header">
      <div class="header__title">
        <span v-if="contact">Редагування <span v-if="editRolesOnly">Ролей</span> Контакту</span>
        <span v-else>Новий Контакт</span>
      </div>
      <div class="header__buttons">
        <div class="header__button button button--fill button--grey" @click="hideModal">Скасувати</div>
        <div class="header__button button button--fill" @click="send">Зберегти</div>
        <div class="button__icon" @click="hideModal">
          <i class="ri-close-circle-line"></i>
        </div>
        <div class="button__icon button__icon--blue" @click="send">
          <i class="ri-save-3-line"></i>
        </div>
      </div>
    </div>
    <div class="editContact__main">
      <div class="main__part">
        <label
          class="field__label"
          :class="{
            'field__label--filled': newContact.name,
            'field__label--invalid': $v.newContact.name.$dirty && !$v.newContact.name.required
          }"
        >
          <div class="field__title field__title--required">ПІБ</div>
          <input :disabled="editRolesOnly" class="field__input" v-model="newContact.name" placeholder="Введіть ім'я" />
        </label>
        <label
          class="field__label"
          :class="{
            'field__label--filled': newContact.roles,
            'field__label--invalid': $v.newContact.roles.$dirty && !$v.newContact.roles.required
          }"
        >
          <div class="field__title field__title--required">Роль</div>
          <Multiselect
            :options="constants.CONTACT_ROLES"
            v-model="newContact.roles"
            :multiple="true"
            :closeOnSelect="false"
            label="name"
            trackBy="id"
            :showLabels="false"
            :searchable="false"
            placeholder="Роль"
            class="mSelect"
          />
        </label>
        <div
          v-if="isCompany"
          class="field__label"
          :class="{
            'field__label--filled': newContact.company,
            'field__label--invalid': $v.newContact.company.$dirty && !$v.newContact.company.required
          }"
        >
          <div class="field__title field__title--required">Компанія</div>
          <CompanySearch
            :selected="newContact.company"
            :isDisabled="isCompanyFieldDisabled"
            :isCreateButtonVisible="!isCompanyFieldDisabled"
            :type="companyType"
            @select="(company) => newContact.company = company"
          />
        </div>
        <label
          v-if="isCompany"
          class="field__label"
          :class="{
            'field__label--filled': newContact.jobPosition,
            'field__label--invalid': $v.newContact.jobPosition.$dirty && !$v.newContact.jobPosition.required
          }"
        >
          <div class="field__title field__title--required">Посада</div>
          <Multiselect
            :options="constants.CONTACT_JOB_POSITIONS"
            v-model="newContact.jobPosition"
            :disabled="isJobPositionFieldDisabled"
            label="name"
            trackBy="id"
            :showLabels="false"
            :searchable="false"
            placeholder="Посада"
          />
        </label>
        <div
          class="field__label"
          :class="{
            'field__label--filled': newContact.birthday,
            'field__label--invalid': $v.newContact.birthday.$dirty && !$v.newContact.birthday.required
          }"
        >
          <div class="field__title field__title--required">День Народження</div>
          <div class="field__date">
            <date-picker v-if="!editRolesOnly" v-model="newContact.birthday" isDark color="blue" :popover="{visibility: 'focus'}">
              <template v-slot="{inputValue, inputEvents}">
                <input class="field__input" :value="inputValue" v-on="inputEvents" readonly />
              </template>
            </date-picker>
            <input v-else class="field__input" :value="newContact.birthday | date" disabled />
          </div>
          <input v-model="newContact.birthday" :disabled="editRolesOnly" class="field__date--mobile" type="date" />
        </div>
      </div>
      <div class="main__part">
        <div class="main__phone" v-for="(phone, i) in newContact.phones" :key="i">
          <div class="main__phone-title">Телефон</div>
          <div class="main__phone-inner">
            <input
              :disabled="editRolesOnly"
              class="field__input main__phone-input"
              :class="{
                'field__input--invalid':
                  !$v.newContact.phones.$each[i].phone.required && $v.newContact.phones.$each[i].phone.$dirty
              }"
              placeholder="Введіть телефон"
              v-mask="'##########'"
              v-model="phone.phone"
            />
            <div class="main__phone-box">
              <button
                :disabled="editRolesOnly"
                class="button__circle"
                :class="{'button__circle--blue': phone.isOnViber}"
                @click="phone.isOnViber = !phone.isOnViber"
              >
                <i class="i-viber"></i>
              </button>
              <button
                :disabled="editRolesOnly"
                class="button__circle"
                :class="{'button__circle--blue': phone.isOnTelegram}"
                @click="phone.isOnTelegram = !phone.isOnTelegram"
              >
                <i class="i-telegram"></i>
              </button>

              <button
                :disabled="editRolesOnly"
                class="button__circle"
                :class="{'button__circle--blue': phone.isOnWhatsapp}"
                @click="phone.isOnWhatsapp = !phone.isOnWhatsapp"
              >
                <i class="i-whatsapp"></i>
              </button>
              <button
                :disabled="editRolesOnly"
                class="button__circle"
                :class="{'button__circle--blue': phone.isOnFacebook}"
                @click="phone.isOnFacebook = !phone.isOnFacebook"
              >
                <i class="i-messanger"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!editRolesOnly" class="main__footer" @click="addPhone()">
        <i class="i-plus"></i>
        <span class="main__footer-title">Додати ще один номер</span>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import {required, minLength, requiredIf} from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import _ from 'lodash'
import moment from 'moment'
import localConstants from '@/utils/localConstants'
import CompanySearch from '@/components/CompanySearch'

export default {
  name: 'EditContact',
  props: ['contact', 'editRolesOnly'],
  components: {
    DatePicker,
    Multiselect,
    CompanySearch,
  },
  computed: {
    isAgency() {
      return this.newContact.roles?.some(x => x.id === this.localConstants.CONTACT_ROLES.AGENCY) || false
    },
    isDeveloper() {
      return this.newContact.roles?.some(x => x.id === this.localConstants.CONTACT_ROLES.DEVELOPER) || false
    },
    isCompany() {
      return this.isAgency || this.isDeveloper
    },
    isCompanyFieldDisabled() {
      return this.editRolesOnly && this.newContact.company != null
    },
    isJobPositionFieldDisabled() {
      return this.editRolesOnly && this.newContact.jobPosition != null
    },
    companyType() {
      if (this.isAgency && this.isDeveloper) {
        return null
      }

      return this.isAgency ? this.localConstants.COMPANY_TYPES.AGENCY : this.localConstants.COMPANY_TYPES.DEVELOPER
    }
  },
  data: () => ({
    newContact: {},
    constants: null,
    moment,
    localConstants,
  }),
  created() {
    const mobile = document.documentElement.clientWidth <= 480
    const constants = (this.constants = this.$store.getters.constants)
    if (this.contact) {
      this.newContact = _.cloneDeep(this.contact)
      if (mobile) {
        this.newContact.birthday = this.newContact.birthday ? this.formatDate(this.newContact.birthday, 'mobile') : null
      } else {
        this.newContact.birthday = this.newContact.birthday
          ? this.formatDate(this.newContact.birthday, 'desktop')
          : null
      }
    } else {
      this.newContact = {
        name: '',
        birthday: null,
        roles: null,
        phones: [
          {
            phone: '',
            isOnTelegram: false,
            isOnFacebook: false,
            isOnViber: false,
            isOnWhatsapp: false
          }
        ]
      }
    }
  },
  methods: {
    async send() {
      const mobile = document.documentElement.clientWidth <= 480
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      if (this.editRolesOnly) {
        await this.sendRoles()
        this.hideModal()
        return
      }

      const id = this.newContact.id
      const contact = {
        name: this.newContact.name,
        roles: this.newContact.roles.map(x => x.id),
        phones: this.newContact.phones,
        companyId: this.isCompany ? this.newContact.company?.id : null,
        jobPosition: this.isCompany ? this.newContact.jobPosition?.id : null,
      }
      if (this.newContact.birthday) {
        if (mobile) {
          contact.birthday = this.formatDate(this.newContact.birthday.format(), 'format')
        } else {
          contact.birthday = this.newContact.birthday.format()
        }
      }
      if (id) {
        await this.$store.dispatch('contacts/update', {contact, id})
      } else {
        await this.$store.dispatch('contacts/create', [contact])
      }
      this.hideModal()
    },
    async sendRoles() {
      const id = this.newContact.id
      const payload = {
        roles: this.newContact.roles.map(x => x.id),
        companyId: this.isCompany ? this.newContact.company?.id : null,
        jobPosition: this.isCompany ? this.newContact.jobPosition?.id : null,
      }
      await this.$store.dispatch('contacts/patch', {payload, id})
    },
    delPhone(i) {
      this.newContact.phones.splice(i, 1)
    },
    addPhone() {
      const newPhone = {
        phone: '',
        isOnTelegram: false,
        isOnFacebook: false,
        isOnViber: false,
        isOnWhatsapp: false
      }
      this.newContact.phones.push(newPhone)
    },
    hideModal() {
      this.$modal.hide('ModalEditContact')
    },
    formatDate(str, type) {
      let day = null,
        month = null,
        year = null,
        date = null
      if (type === 'mobile') {
        day = str.substring(0, 2)
        month = str.substring(3, 5)
        year = str.substring(6, 10)
        date = `${year}.${month}.${day}`
      } else if (type === 'desktop') {
        day = parseInt(str.substring(0, 2))
        month = parseInt(str.substring(3, 5))
        year = parseInt(str.substring(6, 10))
        date = new Date(year, month - 1, day)
      } else if (type === 'format') {
        year = str.substring(0, 4)
        month = str.substring(5, 7)
        day = str.substring(8, 10)
        date = `${day}.${month}.${year}`
      }
      return date
    },
  },
  validations: {
    newContact: {
      name: {required},
      roles: {required},
      company: {
        required: requiredIf(function() {
          return this.isCompany
        }) },
      jobPosition: {
        required: requiredIf(function() {
          return this.isCompany
        }) },
      birthday: {},
      phones: {
        $each: {
          phone: {
            required,
            minLength: minLength(10)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style.scss';
</style>
