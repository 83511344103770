import axios from 'axios'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    stats: [],
    period: {
      'period[from]': moment().year(moment().get('year')).month(moment().get('month')).startOf('month').format('DD-MM-YYYY'),
      'period[till]': moment().year(moment().get('year')).month(moment().get('month')).endOf('month').format('DD-MM-YYYY')
    },
  },
  mutations: {
    set(state, stats) {
      state.stats = stats
    },
    setPeriod(state, period) {
      state.period = period
    },
    reset(state) {
      state.period = null

      const year = moment().get('year')
      const month = moment().get('month')
      state.period = {
        'period[from]': moment().year(year).month(month).startOf('month').format('DD-MM-YYYY'),
        'period[till]': moment().year(year).month(month).endOf('month').format('DD-MM-YYYY')
      }
    }
  },
  actions: {
    async fetch({commit, state}) {
      try {
        const {period} = state
        const stats = await axios.get(`${process.env.VUE_APP_BACKEND}/stats/seekers/`, {
          params: { ...period }
        })
        commit('set', stats.data)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
  }
}
