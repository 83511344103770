const constants = {
  YES_NO: [
    {name: 'Так', id: true},
    {name: 'Ні', id: false}
  ],
  PROPERTY_TOGGLE: [
    {name: 'Є', id: true},
    {name: 'Немає', id: false}
  ],
  PROPERTY_ROOMS: [
    {name: '1', id: 1},
    {name: '2', id: 2},
    {name: '3', id: 3},
    {name: '4', id: 4},
    {name: '5+', id: 5}
  ],
  PROPERTY_APARTMENT_FLOORS: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
  PROPERTY_COMMERCE_FLOORS: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
  PROPERTY_HOUSE_FLOORS: [1, 2, 3, 4],
  // ARCHIVED_REASONS_BUYER: [
  //   {
  //     id: 1,
  //     name: 'Під Завдатком'
  //   },
  //   {
  //     id: 2,
  //     name: 'Куплено'
  //   },
  //   {
  //     id: 4,
  //     name: 'Передумали'
  //   },
  //   {
  //     id: 5,
  //     name: 'Призупинили Купівлю'
  //   }
  // ],
  // ARCHIVED_REASONS: [
  //   {name: 'Під Завдатком', id: 1},
  //   {name: 'Продано', id: 2},
  //   {name: 'Продано за Ціну', id: 3},
  //   {name: 'Передумали', id: 4},
  //   {name: 'Призупинили Продаж', id: 5}
  // ],
  SOLD_BYS: [
    {name: 'Невідомо', id: 1},
    {name: 'Інше АН', id: 2},
    {name: 'Наше АН', id: 3}
  ],
  AUTONOMOUS_HEAT_TYPE: ['Газове', 'Електро', 'Котельня', 'Немає'],
  ACTION_NOTARIES: ['Коломис', 'Фісюк/Чубай', 'Матвєєва'],
  SEEKER_TYPES: {
    COOPERATIVE: 1,
    CLIENT: 2,
    ACTIVITY: 3,
    OTHER: 4,
  },
  USER_ROLES: {
    GUEST: 1,
    AGENT: 2,
    MANAGER: 3,
    ADMIN: 4,
  },
  CONTACT_ROLES: {
    CLIENT: 1,
    AGENCY: 2,
    DEVELOPER: 3,
    SEEKER: 4,
    BUILDING_YEAR: 5,
    RESELLER: 6,
	  CANDIDATE: 7,
  },
  PROPERTY_SOURCES_SEEKER_ID: 233,
  BUYER_SOURCES_SEEKER_ID: 433,
  COMPANY_TYPES: {
    AGENCY: 1,
    DEVELOPER: 2,
  },
  RENT_RELEVANCE: {
    RELEVANT: 1,
    RENTED: 2,
  },
  ACTION_ADVERT_TYPES: {
    PostingForObject: {
      id: 10,
      label: 'Розклейка під обʼєкт'
    },
    BannerForObject: {
      id: 20,
      label: 'Банер під обʼєкт'
    },
    NeighborsNotification: {
      id: 30,
      label: 'Повідомлення сусідів'
    },
    Socials: {
      id: 40,
      label: 'Соціальні мережі',
      children: [
        { id: 41, label: 'Інстаграм' },
        { id: 42, label: 'Тік-ток' },
        { id: 43, label: 'Фейсбук' },
        { id: 44, label: 'Телеграм-канал' },
      ]
    },
    Websites: {
      id: 50,
      label: 'Сайти',
      children: [
        { id: 51, label: 'OLX' },
        { id: 52, label: 'Dom Ria' },
        { id: 53, label: 'Rieltor' },
        { id: 54, label: 'Бесплатка' },
        { id: 55, label: 'HALDEN.estate' },
        { id: 56, label: 'Інші сайти' },
      ]
    },
    AgentsNotification: {
      id: 60,
      label: 'Повідомлення агентів'
    },
  }
}

export default constants
