<template>
  <div v-if="type === 'info-row'" class="rel__block" :class="{ 'rel__block--golden': relation.isGolden }" @click="openRelation">
    <div class="rel__circle" :class="{'rel__circle--red': isArchived}"></div>
    <div v-if="relation.contactableType === 'properties'" class="rel__text">Об'єкт / </div>
    <div v-if="relation.contactableType === 'buyers'" class="rel__text">Покупець / </div>
    <div v-if="relation.contactableType === 'seekers'" class="rel__text">Шукач / </div>
    <div v-if="relation.contactableType === 'buildings'" class="rel__text">Рік Будинку / </div>
    <div v-if="relation.contactableSubtype === 'apartment'" class="rel__text">Квартира / </div>
    <div v-if="relation.contactableSubtype === 'house'" class="rel__text">Будинок / </div>
    <div v-if="relation.contactableSubtype === 'commerce'" class="rel__text">Комерція / </div>
    <div class="rel__text">ID {{ relation.contactableId }} / </div>
    <div class="rel__text">{{ relation.createdAt | date }}</div>
    <div v-if="relation.isSoldByOurAgency" class="rel__text"> / Наше АН</div>
  </div>
  <div v-else-if="type === 'info-tile'" class="tile__item main__relationId" @click="openRelation">
    <i v-if="relation.contactableType === 'buyers'" class="ri-user-line"></i>
    <i
      v-if="relation.contactableType === 'properties'"
      :class="{
        'ri-building-4-line': relation.contactableSubtype === 'apartment',
        'ri-home-5-line': relation.contactableSubtype === 'house',
        'ri-store-line': relation.contactableSubtype === 'commerce',
      }"
    ></i>
    <span style="margin-left: 6px">{{ relation.contactableId }}</span>
  </div>
  <div v-else-if="type === 'card-tile'" class="card__rel" @click.stop.prevent="openRelation">
    ID {{ relation.contactableId }} <i class="i-relation"></i>
  </div>
  <div v-else-if="type === 'notification-icon'" class="notification-icon" :class="{ 'archived': isArchived }" @click.stop.prevent="openRelation">
    <i v-if="isAction" class="ri-focus-line"></i>
    <i v-if="isBuyer" class="ri-shopping-bag-fill"></i>
    <i v-if="isRent" class="ri-lock-unlock-fill"></i>
    <i v-if="isRenter" class="ri-key-2-line"></i>
    <i
      v-if="isProperty || isRent || isBuyer || isRenter" :class="{
        'ri-building-4-line': isApartment,
        'ri-home-5-line': isHouse,
        'ri-store-line': isCommerce,
      }"
    ></i>
    <span style="margin-left: 6px">{{ relation.contactableId }}</span>
  </div>
</template>

<script>
export default {
  name: 'Relation',
  props: {
    relation: {},
    type: {
      default: 'info-row' // 'info-row' | 'info-tile' | 'card-tile' | 'notification-icon'
    },
  },
  data: () => ({
    constants: null
  }),
  beforeCreate() {
    this.InfoBuyer = require('@/views/Buyers/Info').default                   // Using of import InfoBuyer or InfoObject throws the error
    this.InfoObject = require('@/views/Objects/Info').default                 // "Unknown custom element: <Relation> - did you register the component correctly"
    this.InfoObjectArchived = require('@/views/Archive/Objects/Info').default // when InfoBuyer or InfoObject is open
    this.InfoSeeker = require('@/views/Seekers/Info').default
    this.InfoBuilding = require('@/views/Buildings/Info').default
    this.InfoRenter = require('@/views/Renters/Info').default
    this.InfoRent = require('@/views/Rent/Info').default
    this.InfoRentArchived = require('@/views/Archive/Rent/Info').default
    this.InfoAction = require('@/views/Actions/InfoAction').default
  },
  computed: {
    isProperty() {
      return this.relation.contactableType === 'properties' && !this.relation.isRent
    },
    isRent() {
      return this.relation.contactableType === 'properties' && this.relation.isRent
    },
    isBuyer() {
      return this.relation.contactableType === 'buyers' && !this.relation.isRent
    },
    isRenter() {
      return this.relation.contactableType === 'buyers' && this.relation.isRent
    },
    isSeeker() {
      return this.relation.contactableType === 'seekers'
    },
    isBuilding() {
      return this.relation.contactableType === 'buildings'
    },
    isAction() {
      return this.relation.contactableType === 'actions'
    },
    isApartment() {
      return this.relation.contactableSubtype === 'apartment'
    },
    isHouse() {
      return this.relation.contactableSubtype === 'house'
    },
    isCommerce() {
      return this.relation.contactableSubtype === 'commerce'
    },
    isArchived() {
      return this.relation.isArchived
    },
  },
  methods: {
    async openRelation() {
      const path = this.getPath()

      await this.$store.dispatch(path.fetch, this.relation.contactableId)
      const data = this.$store.getters[path.get]
      
      if (this.isBuyer) {
        this.openBuyerRelation(data)
      } else if (this.isRenter) {
        this.openRenterRelation(data)
      } else if (this.isProperty) {
        this.openPropertyRelation(data)
      } else if (this.isRent) {
        this.openRentRelation(data)
      } else if (this.isSeeker) {
        this.openSeekerRelation(data)
      } else if (this.isBuilding) {
        this.openBuildingRelation(data)
      } else if (this.isAction) {
        this.openActionRelation(data)
      }
    },
    openBuyerRelation(data) {
      this.$modal.display(this.InfoBuyer, {buyer: data}, { ...this.$modal.styles, name: 'InfoBuyer'})
    },
    openRenterRelation(data) {
      this.$modal.display(this.InfoRenter, {renter: data}, { ...this.$modal.styles, name: 'InfoRenter'})
    },
    openPropertyRelation(data) {
      if (this.isArchived) {
        this.$modal.show(this.InfoObjectArchived, {info: data}, { ...this.$modal.styles, name: 'InfoObject'})
        return
      }

      this.$modal.display(this.InfoObject, {info: data}, { ...this.$modal.styles, name: 'InfoObject'})
    },
    openRentRelation(data) {
      if (this.isArchived) {
        this.$modal.show(this.InfoRentArchived, {info: data}, { ...this.$modal.styles, name: 'InfoRent'})
        return
      }

      this.$modal.display(this.InfoRent, {info: data}, { ...this.$modal.styles, name: 'InfoRent'})
    },
    openSeekerRelation(data) {
      this.$modal.display(this.InfoSeeker, {seeker: data}, { ...this.$modal.styles, name: 'InfoSeeker'})
    },    
    openBuildingRelation(data) {
      this.$modal.display(this.InfoBuilding, {building: data}, { ...this.$modal.styles, name: 'InfoBuilding'})
    },
    openActionRelation(data) {
      this.$modal.display(this.InfoAction, {action: data}, { ...this.$modal.styles, name: 'InfoAction'})
    },
    getPath() {
      if (this.isSeeker) {
        return { fetch: 'seekers/fetchSeeker', get: 'seekers/seeker'}
      }
      if (this.isBuilding) {
        return { fetch: 'buildings/fetchBuilding', get: 'buildings/building'}
      }
      if (this.isAction) {
        return { fetch: 'fetchAction', get: 'action'}
      }

      let entity
      if (this.isProperty) {
        entity = 'objects'
      } else if (this.isBuyer) {
        entity = 'buyers'
      } else if (this.isRent) {
        entity = 'rent'
      } else if (this.isRenter) {
        entity = 'renters'
      }

      let path
      if (this.isApartment) {
        path = { fetch: `${entity}/apartments/fetchApartment`, get: `${entity}/apartments/apartment`}
      } else if (this.isHouse) {
        path = { fetch: `${entity}/houses/fetchHouse`, get: `${entity}/houses/house`}
      } else if (this.isCommerce) {
        path = { fetch: `${entity}/commerce/fetchCommerce`, get: `${entity}/commerce/oneCommerce`}
      }

      if (this.isArchived) {
        return { fetch: 'archive/' + path.fetch, get: 'archive/' + path.get }
      }

      return path
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'styles';
</style>
