import axios from 'axios'

export default {
  namespaced: true,
  state: {
    count: 0,
    notifications: [],
    pagination: {},
    page: 1,
    
  },
  mutations: {
    set(state, notifications) {
      state.notifications = _.unionBy(state.notifications, notifications, 'id')
    },
    setCount(state, count) {
      state.count = count
    },
    readNotification(state, notificationId) {
      const foundNotification = state.notifications.find(x => x.id === notificationId)
      Object.assign(foundNotification, { isRead: true })
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.notifications = []
      state.page = 1
      state.pagination = {}
    },
    changePage(state) {
      state.page++
    },
  },
  actions: {
    async fetchPersonal({commit, getters}) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND}/notifications`, {
          params: { page: getters.page }
        })
        const { data, pagination } = response.data
        commit('pagination', pagination)
        commit('set', data)
        commit('changePage')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async fetchAll({commit, getters}) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND}/notifications/all`, {
          params: { page: getters.page }
        })
        const { data, pagination } = response.data
        commit('pagination', pagination)
        commit('set', data)
        commit('changePage')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async count({commit}) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND}/notifications/count`)
        commit('setCount', response.data.count)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async read({commit, dispatch}, id) {
      try {
        const response = await axios.patch(`${process.env.VUE_APP_BACKEND}/notifications/${id}/read`)
        if (response.data.success) {
          commit('readNotification', id)
          await dispatch('count')
        }
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
  },
  getters: {
    notifications: s => s.notifications,
    count: s => s.count,
    pagination: s => s.pagination,
    page: s => s.page,
  }
}
