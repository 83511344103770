export default function apartmentMaterials(value) {
  let type;

  switch (value) {
    case 1:
      type = "Цегла";
      break;
    case 5:
      type = "Панель";
      break;
  }
  return type
}
