import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    pagination: {},
    users: [],
    user: {},
    page: 1
  },
  mutations: {
    set(state, users) {
      state.users = _.unionBy(state.users, users, 'id')
    },
    updateUser(state, user) {
      const foundUser = state.users.find(x => x.id === user.id)
      Object.assign(foundUser, user)
    },
    setUser(state, user) {
      state.user = user
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.users = []
      state.page = 1
    },
    changePage(state) {
      state.page++
    }
  },
  actions: {
    async fetch({commit, getters}) {
      const page = getters.page
      try {
        const url = process.env.VUE_APP_BACKEND
        const users = await axios.get(url + '/users', {
          params: {page}
        })
        commit('pagination', users.data.pagination)
        commit('set', users.data.users)
        commit('changePage')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async adminFetch({commit, state}, reset) {
      if (reset) commit('reset')
      const page = state.page
      try {
        const url = process.env.VUE_APP_BACKEND
        const users = await axios.get(url + '/users/for-admin/', {
          params: {page}
        })
        commit('pagination', users.data.pagination)
        commit('set', users.data.users)
        commit('changePage')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async adminBlockUser({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.put(url + `/users/${id}/block`)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async fetchUser({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const user = await axios.get(url + '/users/' + id)
        commit('setUser', user.data.property)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async create({commit, dispatch}, user) {
      const url = process.env.VUE_APP_BACKEND
      try {
        await axios.post(url + '/users', user)
        commit('reset')
        dispatch('adminFetch')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async update({commit}, {id, user}) {
      const url = process.env.VUE_APP_BACKEND
      try {
        const response = await axios.put(url + '/users/' + id, user)
        commit('updateUser', response.data.user)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
  },
  getters: {
    users: s => s.users,
    user: s => s.user,
    pagination: s => s.pagination,
    page: s => s.page
  },
}
