import axios from 'axios';

export default {
  state: {
    rentersFavApt: [],
    renterFavApt: {},
    paginationFavRentersApt: {},
    pageFavRentersApt: 1,
    rentersFavHouse: [],
    renterFavAHouse: {},
    paginationFavRentersHouse: {},
    pageFavRentersHouse: 1,
    rentersFavCom: [],
    renterFavCom: {},
    paginationFavRentersCom: {},
    pageFavRentersCom: 1,
  },
  mutations: {
    setFavRenters(state, {renters, type}) {
      type === 'apartment' ? state.rentersFavApt = state.rentersFavApt.concat(renters) : null
      type === 'house' ? state.rentersFavHouse = state.rentersFavHouse.concat(renters) : null
      type === 'commerce' ? state.rentersFavCom = state.rentersFavCom.concat(renters) : null
    },
    setFavRenter(state, {renter, type}) {
      type === 'apartment' ? state.renterFavApt = renter : null
      type === 'house' ? state.renterFavAHouse = renter : null
      type === 'commerce' ? state.renterFavCom = renter : null
    },
    paginationFavRenters(state, {paginationRenters, type}) {
      type === 'apartment' ? state.paginationFavRentersApt = paginationRenters : null
      type === 'house' ? state.paginationFavRentersHouse = paginationRenters : null
      type === 'commerce' ? state.paginationFavRentersCom = paginationRenters : null
    },
    resetFavRenters(state, type) {
      if (type === 'apartment') {
        state.rentersFavApt = []
        state.pageFavRentersApt = 1
      } else if (type === 'house') {
        state.rentersFavHouse = []
        state.pageFavRentersHouse = 1
      } else if (type === 'commerce') {
        state.rentersFavCom = []
        state.pageFavRentersCom = 1
      } else {
        state.rentersFavApt = []
        state.pageFavRentersApt = 1
      }
    },
    changePageFavBuy(state, type) {
      type === 'apartment' ? state.pageFavRentersApt++ : null
      type === 'house' ? state.pageFavRentersHouse++ : null
      type === 'commerce' ? state.pageFavRentersCom++ : null
    }
  },
  actions: {
    async fetchFavoritesRenters({commit, getters}, type) {
      let page = Number
      let way = String
      type === 'apartment' ? page = getters.pageFavRentersApt : null
      type === 'house' ? page = getters.pageFavRentersHouse : null
      type === 'commerce' ? page = getters.pageFavRentersCom : null

      type === 'apartment' ? way = 'apartments' : null
      type === 'house' ? way = 'houses' : null
      type === 'commerce' ? way = 'commerce' : null

      try {
        const url = process.env.VUE_APP_BACKEND;
        const renters = await axios.get(url + '/rent/buyers/' + way + '/favorites', {
          params: {
            page,
            ...getters.filterParamsRenters,
            ...getters.sortParamsRenters,
          }
        });
        commit('paginationFavRenters', {paginationRenters: renters.data.pagination, type})
        commit('setFavRenters', {renters: renters.data.favorites, type})
        commit('changePageFavBuy', type)
      } catch (e) {
        commit("setError", e);
      }
    },
  },
  getters: {
    rentersFavApt: s => s.rentersFavApt,
    renterFavApt: s => s.renterFavApt,
    paginationFavRentersApt: s => s.paginationFavRentersApt,
    pageFavRentersApt: s => s.pageFavRentersApt,

    rentersFavHouse: s => s.rentersFavHouse,
    renterFavAHouse: s => s.renterFavAHouse,
    paginationFavRentersHouse: s => s.paginationFavRentersHouse,
    pageFavRentersHouse: s => s.pageFavRentersHouse,

    rentersFavCom: s => s.rentersFavCom,
    renterFavCom: s => s.renterFavCom,
    paginationFavRentersCom: s => s.paginationFavRentersCom,
    pageFavRentersCom: s => s.pageFavRentersCom,
  }
}