import apartments from './apartments'
import houses from './houses'
import commerce from './commerce'

export default {
  namespaced: true,

  state: {
  },
  mutations: {
  },
  actions: {},
  getters: {
  },
  modules: {
    apartments,
    houses,
    commerce
  }
}
