var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Multiselect",
    {
      staticClass: "mSelect",
      attrs: {
        options: _vm.foundSeekers,
        label: "name",
        trackBy: "id",
        showLabels: false,
        searchable: true,
        placeholder: "Введіть ID або імʼя шукача",
        "internal-search": false,
        closeOnSelect: true,
        multiple: false
      },
      on: { select: _vm.select, "search-change": _vm.search },
      scopedSlots: _vm._u([
        {
          key: "singleLabel",
          fn: function(props) {
            return _c("span", {}, [
              _vm._v(
                "ID " +
                  _vm._s(props.option.id) +
                  " | " +
                  _vm._s(props.option.name)
              )
            ])
          }
        },
        {
          key: "option",
          fn: function(props) {
            return [
              _vm._v(
                " ID " +
                  _vm._s(props.option.id) +
                  " | " +
                  _vm._s(props.option.name) +
                  " "
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.selectedSeeker,
        callback: function($$v) {
          _vm.selectedSeeker = $$v
        },
        expression: "selectedSeeker"
      }
    },
    [
      _c("span", { attrs: { slot: "noOptions" }, slot: "noOptions" }, [
        _vm._v("Введіть ID або імʼя шукача")
      ]),
      _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
        _vm._v("Шукача не знайдено")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }