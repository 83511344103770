var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upload__choice objects" }, [
    _vm._m(0),
    _c("div", { staticClass: "objects__main" }, [
      _c(
        "div",
        {
          staticClass: "objects__card",
          on: {
            click: function($event) {
              return _vm.select("apartment")
            }
          }
        },
        [
          _vm._m(1),
          _c("div", { staticClass: "objects__name" }, [_vm._v("Квартира")])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "objects__card",
          on: {
            click: function($event) {
              return _vm.select("house")
            }
          }
        },
        [
          _vm._m(2),
          _c("div", { staticClass: "objects__name" }, [_vm._v("Будинок")])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "objects__card",
          on: {
            click: function($event) {
              return _vm.select("commerce")
            }
          }
        },
        [
          _vm._m(3),
          _c("div", { staticClass: "objects__name" }, [_vm._v("Комерція")])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "objects__top" }, [
      _c("div", { staticClass: "objects__title" }, [_vm._v("Виберіть об'єкт")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "objects__icon" }, [
      _c("i", { staticClass: "ri-building-4-fill" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "objects__icon" }, [
      _c("i", { staticClass: "ri-home-5-fill" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "objects__icon" }, [
      _c("i", { staticClass: "ri-store-fill" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }