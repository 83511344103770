var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar__wrapper" }, [
    _c("div", { staticClass: "sidebar" }, [
      _c("div", { staticClass: "sidebar__inner" }, [
        _c("div", { staticClass: "sidebar__header" }),
        _c(
          "div",
          { staticClass: "sidebar__main" },
          [
            _c(
              "div",
              {
                staticClass: "link link__sidebar link__sidebar-list",
                class: {
                  "link__sidebar--active":
                    _vm.componentName === "ObjectsApartment" ||
                    _vm.componentName === "ObjectsHouse" ||
                    _vm.componentName === "ObjectsCommerce"
                }
              },
              [
                _vm._m(0),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/objects/apartments",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Квартири")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/objects/houses",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Будинки")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/objects/commerce",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Комерція")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "link link__sidebar link__sidebar-list",
                class: {
                  "link__sidebar--active":
                    _vm.componentName === "buyersApt" ||
                    _vm.componentName === "buyersHouse" ||
                    _vm.componentName === "buyersCom"
                }
              },
              [
                _vm._m(1),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/buyers/apartments",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Квартири")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/buyers/houses",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Будинки")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/buyers/commerce",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Комерція")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "link link__sidebar link__sidebar-list",
                class: {
                  "link__sidebar--active":
                    _vm.componentName === "RentApartment" ||
                    _vm.componentName === "RentHouse" ||
                    _vm.componentName === "RentCommerce"
                }
              },
              [
                _vm._m(2),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/rent/apartments",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Квартири")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/rent/houses",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Будинки")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/rent/commerce",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Комерція")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "link link__sidebar link__sidebar-list",
                class: {
                  "link__sidebar--active":
                    _vm.componentName === "rentersApt" ||
                    _vm.componentName === "rentersHouse" ||
                    _vm.componentName === "rentersCom"
                }
              },
              [
                _vm._m(3),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/renters/apartments",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Квартири")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/renters/houses",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Будинки")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/renters/commerce",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Комерція")]
                )
              ],
              1
            ),
            _vm.$store.getters.user.rank === 4
              ? _c(
                  "router-link",
                  {
                    staticClass: "link link__sidebar",
                    attrs: {
                      to: "/users",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "ri-user-line link__sidebar_icon" }),
                    _c(
                      "span",
                      { staticClass: "link__text link__sidebar_text" },
                      [_vm._v("Користувачі")]
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "router-link",
              {
                staticClass: "link link__sidebar",
                attrs: {
                  to: "/contacts",
                  "active-class": "link__sidebar--active"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.hideSidebar($event)
                  }
                }
              },
              [
                _c("i", { staticClass: "ri-contacts-line link__sidebar_icon" }),
                _c("span", { staticClass: "link__text link__sidebar_text" }, [
                  _vm._v("Контакти")
                ])
              ]
            ),
            _c(
              "router-link",
              {
                staticClass: "link link__sidebar",
                attrs: {
                  to: "/seekers",
                  "active-class": "link__sidebar--active"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.hideSidebar($event)
                  }
                }
              },
              [
                _c("i", {
                  staticClass: "ri-user-search-line link__sidebar_icon"
                }),
                _c("span", { staticClass: "link__text link__sidebar_text" }, [
                  _vm._v("Шукачі")
                ])
              ]
            ),
            _c(
              "router-link",
              {
                staticClass: "link link__sidebar",
                attrs: {
                  to: "/buildings",
                  "active-class": "link__sidebar--active"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.hideSidebar($event)
                  }
                }
              },
              [
                _c("i", { staticClass: "ri-hotel-line link__sidebar_icon" }),
                _c("span", { staticClass: "link__text link__sidebar_text" }, [
                  _vm._v("Рік будинку")
                ])
              ]
            ),
            _vm.$store.getters.user.rank === 4
              ? _c(
                  "div",
                  {
                    staticClass: "link link__sidebar link__sidebar-list",
                    class: {
                      "link__sidebar--active":
                        _vm.componentName === "StatsObjects" ||
                        _vm.componentName === "StatsBuyers" ||
                        _vm.componentName === "StatsActions"
                    }
                  },
                  [
                    _vm._m(4),
                    _c(
                      "router-link",
                      {
                        staticClass: "link__sidebar-bottom",
                        attrs: {
                          to: "/stats/objects",
                          "active-class": "link__sidebar--active"
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.hideSidebar($event)
                          }
                        }
                      },
                      [_vm._v("Об'єкти")]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "link__sidebar-bottom",
                        attrs: {
                          to: "/stats/buyers",
                          "active-class": "link__sidebar--active"
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.hideSidebar($event)
                          }
                        }
                      },
                      [_vm._v("Покупці")]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "link__sidebar-bottom",
                        attrs: {
                          to: "/stats/actions",
                          "active-class": "link__sidebar--active"
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.hideSidebar($event)
                          }
                        }
                      },
                      [_vm._v("Дії")]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "link__sidebar-bottom",
                        attrs: {
                          to: "/stats/seekers",
                          "active-class": "link__sidebar--active"
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.hideSidebar($event)
                          }
                        }
                      },
                      [_vm._v("Шукачі")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "line" }),
            _c(
              "router-link",
              {
                staticClass: "link link__sidebar",
                attrs: {
                  to: "/actions",
                  "active-class": "link__sidebar--active"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.hideSidebar($event)
                  }
                }
              },
              [
                _c("i", { staticClass: "ri-focus-line link__sidebar_icon" }),
                _c("span", { staticClass: "link__text link__sidebar_text" }, [
                  _vm._v("Дії")
                ])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "link link__sidebar link__sidebar-list is-red",
                class: {
                  "link__sidebar--active":
                    _vm.componentName === "ArchiveObjectsApartments" ||
                    _vm.componentName === "ArchiveObjectsHouses" ||
                    _vm.componentName === "ArchiveObjectsCommerce"
                }
              },
              [
                _vm._m(5),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/archive/objects/apartments",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Квартири")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/archive/objects/houses",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Будинки")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/archive/objects/commerce",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Комерція")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "link link__sidebar link__sidebar-list is-red",
                class: {
                  "link__sidebar--active":
                    _vm.componentName === "ArchiveBuyersApartments" ||
                    _vm.componentName === "ArchiveBuyersHouses" ||
                    _vm.componentName === "ArchiveBuyersCommerce"
                }
              },
              [
                _vm._m(6),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/archive/buyers/apartments",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Квартири")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/archive/buyers/houses",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Будинки")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/archive/buyers/commerce",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Комерція")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "link link__sidebar link__sidebar-list is-red",
                class: {
                  "link__sidebar--active":
                    _vm.componentName === "ArchiveRentApartments" ||
                    _vm.componentName === "ArchiveRentHouses" ||
                    _vm.componentName === "ArchiveRentCommerce"
                }
              },
              [
                _vm._m(7),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/archive/rent/apartments",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Квартири")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/archive/rent/houses",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Будинки")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/archive/rent/commerce",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Комерція")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "link link__sidebar link__sidebar-list is-red",
                class: {
                  "link__sidebar--active":
                    _vm.componentName === "ArchiveRentersApartments" ||
                    _vm.componentName === "ArchiveRentersHouses" ||
                    _vm.componentName === "ArchiveRentersCommerce"
                }
              },
              [
                _vm._m(8),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/archive/renters/apartments",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Квартири")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/archive/renters/houses",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Будинки")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/archive/renters/commerce",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Комерція")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "link link__sidebar link__sidebar-list",
                class: {
                  "link__sidebar--active":
                    _vm.componentName === "FavoriteObjectsApartments" ||
                    _vm.componentName === "FavoriteObjectsHouses" ||
                    _vm.componentName === "FavoriteObjectsCommerce"
                }
              },
              [
                _vm._m(9),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/favorites/objects/apartments",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Квартири")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/favorites/objects/houses",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Будинки")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/favorites/objects/commerce",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Комерція")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "link link__sidebar link__sidebar-list",
                class: {
                  "link__sidebar--active":
                    _vm.componentName === "FavoriteBuyersApartments" ||
                    _vm.componentName === "FavoriteBuyersHouses" ||
                    _vm.componentName === "FavoriteBuyersCommerce"
                }
              },
              [
                _vm._m(10),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/favorites/buyers/apartments",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Квартири")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/favorites/buyers/houses",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Будинки")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/favorites/buyers/commerce",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Комерція")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "link link__sidebar link__sidebar-list",
                class: {
                  "link__sidebar--active":
                    _vm.componentName === "FavoriteRentApartments" ||
                    _vm.componentName === "FavoriteRentHouses" ||
                    _vm.componentName === "FavoriteRentCommerce"
                }
              },
              [
                _vm._m(11),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/favorites/rent/apartments",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Квартири")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/favorites/rent/houses",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Будинки")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/favorites/rent/commerce",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Комерція")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "link link__sidebar link__sidebar-list",
                class: {
                  "link__sidebar--active":
                    _vm.componentName === "FavoriteRentersApartments" ||
                    _vm.componentName === "FavoriteRentersHouses" ||
                    _vm.componentName === "FavoriteRentersCommerce"
                }
              },
              [
                _vm._m(12),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/favorites/renters/apartments",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Квартири")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/favorites/renters/houses",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Будинки")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/favorites/renters/commerce",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Комерція")]
                )
              ],
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "sidebar__footer" })
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "link__sidebar-top" }, [
      _c("i", { staticClass: "ri-database-line link__sidebar_icon" }),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Об'єкти")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "link__sidebar-top" }, [
      _c("i", { staticClass: "ri-shopping-bag-fill link__sidebar_icon" }),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Покупці")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "link__sidebar-top" }, [
      _c("i", { staticClass: "ri-lock-unlock-fill link__sidebar_icon" }),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Орендодавці")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "link__sidebar-top" }, [
      _c("i", { staticClass: "ri-key-2-fill link__sidebar_icon" }),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Орендарі")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "link__sidebar-top" }, [
      _c("i", { staticClass: "ri-bar-chart-box-line link__sidebar_icon" }),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Статистика")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "link__sidebar-top" }, [
      _c("i", { staticClass: "ri-database-line link__sidebar_icon" }),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Архів ")
      ]),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Об'єкти")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "link__sidebar-top" }, [
      _c("i", { staticClass: "ri-shopping-bag-fill link__sidebar_icon" }),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Архів")
      ]),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Покупці")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "link__sidebar-top" }, [
      _c("i", { staticClass: "ri-database-line link__sidebar_icon" }),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Архів ")
      ]),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Орендодавці")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "link__sidebar-top" }, [
      _c("i", { staticClass: "ri-shopping-bag-fill link__sidebar_icon" }),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Архів")
      ]),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Орендарі")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "link__sidebar-top" }, [
      _c("i", { staticClass: "ri-database-line link__sidebar_icon" }),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Збережені ")
      ]),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Об'єкти")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "link__sidebar-top" }, [
      _c("i", { staticClass: "ri-shopping-bag-fill link__sidebar_icon" }),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Збережені ")
      ]),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Покупці")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "link__sidebar-top" }, [
      _c("i", { staticClass: "ri-database-line link__sidebar_icon" }),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Збережені ")
      ]),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Орендодавці")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "link__sidebar-top" }, [
      _c("i", { staticClass: "ri-shopping-bag-fill link__sidebar_icon" }),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Збережені ")
      ]),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Орендарі")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }