<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import ObjectsLayout from '@/layouts/ObjectsLayout'
import RentLayout from '@/layouts/RentLayout'
import BuyersLayout from '@/layouts/BuyersLayout'
import RentersLayout from '@/layouts/RentersLayout'
import FavoritesLayout from '@/layouts/FavoritesLayout'
import ContactsLayout from '@/layouts/ContactsLayout'
import LoginLayout from '@/layouts/LoginLayout'
import ArchiveLayout from '@/layouts/ArchiveLayout'
import ActionsLayout from '@/layouts/ActionsLayout'
import UsersLayout from '@/layouts/UsersLayout'
import StatsLayout from '@/layouts/StatsLayout'
import SeekersLayout from '@/layouts/SeekersLayout'
import BuildingsLayout from '@/layouts/BuildingsLayout'
import axios from 'axios'

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || 'login') + '-layout'
    }
  },
  components: {
    ObjectsLayout,
    RentLayout,
    BuyersLayout,
    RentersLayout,
    ContactsLayout,
    FavoritesLayout,
    LoginLayout,
    ArchiveLayout,
    ActionsLayout,
    UsersLayout,
    StatsLayout,
    SeekersLayout,
    BuildingsLayout,
  },
  created: function() {
    axios.interceptors.response.use(undefined, err => {
      return new Promise((resolve, reject) => {
        if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('logout')
          this.$router.go('/login')
        }
        throw err
      })
    })
    Date.prototype.format = function() {
      var mm = this.getMonth() + 1
      var dd = this.getDate()
      return [(dd > 9 ? '' : '0') + dd, (mm > 9 ? '' : '0') + mm, this.getFullYear()].join('-')
    }
    Date.prototype.time = function() {
      var mm = this.getMonth() + 1
      var dd = this.getDate()
      return (
        [(dd > 9 ? '' : '0') + dd, (mm > 9 ? '' : '0') + mm, this.getFullYear()].join('-') +
        ' ' +
        [
          this.getHours()
            .toString()
            .padStart(2, '0'),
          this.getMinutes()
            .toString()
            .padStart(2, '0')
        ].join(':')
      )
    }
    this.$modal.open = function() {
      document.body.style.top = `-${window.scrollY}px`
      document.body.style.position = 'fixed'
    }

    this.$modal.display = function(component, component_properties, modal_properties, modal_events) {
      const mobile = document.documentElement.clientWidth <= 480
      const fablet = document.documentElement.clientWidth <= 768
      fablet
        ? modal_properties.name === 'UploadChoice' ||
          modal_properties.name === 'ModalArchive' ||
          modal_properties.name === 'modalInfoAction'
          ? null
          : (modal_properties.height = '100%')
        : null
      mobile ? (modal_properties.height = '100%') : null
      fablet ? (modal_properties.maxHeight = 1025) : null

      this.$modal.show(
        component,
        component_properties,
        {...this.$modal.styles, ...modal_properties},
        {'before-open': this.$modal.open, 'before-close': this.$modal.close, ...modal_events}
      )
    }.bind(this)

    this.$modal.close = function() {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
    this.$modal.styles = {
      transition: 'none',
      overlayTransition: 'none',
      classes: 'modal',
      width: '1000px',
      maxWidth: 900,
      height: '92%',
      maxHeight: 900,
      adaptive: true
    }
    this.$modal.stylesSmall = {
      transition: 'none',
      classes: 'modal',
      width: '600px',
      maxWidth: 900,
      height: '65%',
      maxHeight: 900,
      adaptive: true
    }
  }
}
</script>

<style lang="scss">
@import './assets/styles/style.scss';
</style>
