import axios from 'axios'

export default {
  namespaced: true,
  actions: {
    async search({commit}, {query, type}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const response = await axios.get(url + '/companies/search', {params: {query, type}})
        return response.data || []
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async create({commit, dispatch}, company) {
      const url = process.env.VUE_APP_BACKEND
      try {
        let contact = await axios.post(url + '/companies', company)
        return contact.data?.success || false
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    }
  }
}
