import axios from 'axios'

export default {
  namespaced: true,
  state: {
    building: {},
    buildings: [],
    pagination: {},
    page: 1,
    // FILTER PARAMS
    searchParams: {},
    sortParams: {},
    filterParamsLocal: {},
    filterParamsSend: {},
    filterParamsActive: 0,
    filterParamsOriginal: {
      street: null,
      emergency: null,
      cooperativeType: null
    }
  },
  mutations: {
    set(state, buildings) {
      state.buildings = _.unionBy(state.buildings, buildings, 'id')
    },
    setBuilding(state, building) {
      state.building = building
    },
    updateBuilding(state, building) {
      const foundBuilding = state.buildings.find(x => x.id === building.id)
      Object.assign(foundBuilding, building)
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.buildings = []
      state.page = 1
    },
    changePage(state) {
      state.page++
    },
    filterParamsLocal(state, params) {
      state.filterParamsLocal = params
    },
    filterParamsSend(state, params) {
      state.filterParamsSend = params
    },
    filterParamsActive(state, number) {
      state.filterParamsActive = number
    },
    sortParams(state, params) {
      state.sortParams = params
    },
    searchParams(state, params) {
      state.searchParams = params
    }
  },
  actions: {
    async fetch({commit, getters}) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND}/buildings`, {
          params: {
            page: getters.page,
            ...getters.filterParamsSend,
            ...getters.sortParams,
            ...getters.searchParams
          }
        })
        const { data, pagination } = response.data
        commit('pagination', pagination)
        commit('set', data)
        commit('changePage')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async fetchBuilding({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const response = await axios.get(url + '/buildings/' + id)
        commit('setBuilding', response.data)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async toggleLastCall({commit}, id) {
      try {
        await axios.patch(`${process.env.VUE_APP_BACKEND}/buildings/${id}/call`)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async delete({commit, dispatch}, id) {
      try {
        await axios.delete(`${process.env.VUE_APP_BACKEND}/buildings/${id}`)
        commit('reset')
        await dispatch('fetch')
      } catch (e) {
        commit('setError', e, { root: true })
        throw e
      }
    },
    async create({commit, dispatch}, {data, newContacts}) {
      if (newContacts.length) {
        const contactsIds = await dispatch('contacts/create', newContacts, {root: true})
        data.contactsIds = data.contactsIds.concat(contactsIds)
      }
      try {
        await axios.post(`${process.env.VUE_APP_BACKEND}/buildings`, data)
        commit('reset')
        await dispatch('fetch')
      } catch (e) {
        commit('setError', e, { root: true })
        throw e
      }
    },
    async edit({commit, dispatch}, {id, data, newContacts}) {
      if (newContacts.length) {
        const contactsIds = await dispatch('contacts/create', newContacts, {root: true})
        data.contactsIds = data.contactsIds.concat(contactsIds)
      }
      try {
        const response = await axios.put(`${process.env.VUE_APP_BACKEND}/buildings/${id}`, data)
        commit('updateBuilding', response.data.building)
      } catch (e) {
        commit('setError', e, { root: true })
        throw e
      }
    }
  },
  getters: {
    buildings: s => s.buildings,
    building: s => s.building,
    pagination: s => s.pagination,
    page: s => s.page,
    // FILTER PARAMS
    filterParamsOriginal: s => s.filterParamsOriginal,
    filterParamsLocal: s => s.filterParamsLocal,
    filterParamsSend: s => s.filterParamsSend,
    filterParamsActive: s => s.filterParamsActive,
    sortParams: s => s.sortParams,
    searchParams: s => s.searchParams,
  }
}
