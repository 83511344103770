var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "info__inner" }, [
    _c("div", { staticClass: "info__header" }, [
      _c("div", { staticClass: "info__nav" }, [
        _vm._m(0),
        _c(
          "div",
          {
            staticClass: "nav__link",
            class: { "nav__link--active": _vm.block === "main" },
            on: {
              click: function($event) {
                _vm.block = "main"
              }
            }
          },
          [_vm._v("Основна")]
        ),
        _c(
          "div",
          {
            staticClass: "nav__link",
            class: { "nav__link--active": _vm.block === "photo" },
            on: {
              click: function($event) {
                _vm.block = "photo"
              }
            }
          },
          [_vm._v("Фото")]
        ),
        _c(
          "div",
          {
            staticClass: "nav__link",
            class: { "nav__link--active": _vm.block === "other" },
            on: {
              click: function($event) {
                _vm.block = "other"
              }
            }
          },
          [_vm._v(" Детальніше ")]
        ),
        _c(
          "div",
          {
            staticClass: "nav__link",
            class: { "nav__link--active": _vm.block === "contacts" },
            on: {
              click: function($event) {
                _vm.block = "contacts"
              }
            }
          },
          [_vm._v(" Контакти ")]
        )
      ]),
      _c("div", { staticClass: "info__buttons" }, [
        _c("div", { staticClass: "info__buttons-left" }, [
          _c("div", { staticClass: "info__icon is-red" }, [
            _vm.info.type === "apartment"
              ? _c("i", { staticClass: "ri-building-4-fill" })
              : _vm._e(),
            _vm.info.type === "house"
              ? _c("i", { staticClass: "ri-home-5-fill" })
              : _vm._e(),
            _vm.info.type === "commerce"
              ? _c("i", { staticClass: "ri-store-fill" })
              : _vm._e()
          ])
        ]),
        _c("div", { staticClass: "info__buttons-right" }, [
          _c(
            "div",
            {
              staticClass: "button__icon",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                }
              }
            },
            [
              _c("Multiselect", {
                staticClass: "mSelect-dropdown",
                attrs: {
                  options: _vm.list,
                  searchable: false,
                  "show-labels": false,
                  "reset-after": true,
                  value: _vm.item,
                  placeholder: "",
                  "open-direction": "bottom"
                },
                on: { select: _vm.actions }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "button__icon button--onmobile",
              on: {
                click: function($event) {
                  return _vm.$modal.hide("InfoRent")
                }
              }
            },
            [_c("i", { staticClass: "ri-close-fill" })]
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "info__content" }, [
      _vm.block === "main"
        ? _c("div", { staticClass: "info__main" }, [
            _c("div", { staticClass: "main__header" }, [
              _c("div", { staticClass: "main__header-top" }, [
                _c("div", { staticClass: "main__price card__price" }, [
                  _vm._v("$ " + _vm._s(_vm.info.price))
                ]),
                _c("div", { staticClass: "main__indicators" }, [
                  _c(
                    "div",
                    {
                      class: {
                        "card__tag card__tag--green main__indicator":
                          _vm.info.relevance ===
                          _vm.localConstants.RENT_RELEVANCE.RELEVANT,
                        "card__tag card__tag--yellow main__indicator":
                          _vm.info.relevance ===
                          _vm.localConstants.RENT_RELEVANCE.RENTED
                      }
                    },
                    [
                      _c("span", { staticClass: "card__span" }, [
                        _vm._v(_vm._s(_vm.info.relevanceName))
                      ]),
                      _vm.info.relevance ===
                      _vm.localConstants.RENT_RELEVANCE.RENTED
                        ? _c("span", [
                            _vm._v(
                              " до " +
                                _vm._s(_vm._f("date")(_vm.info.rentedTill))
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "main__header-middle" }, [
                _vm._v(_vm._s(_vm.info.title))
              ]),
              _c(
                "div",
                { staticClass: "main__header-bottom" },
                [
                  _c("div", { staticClass: "main__id" }, [
                    _vm._v("ID " + _vm._s(_vm.info.id))
                  ]),
                  _c("div", { staticClass: "main__date" }, [
                    _c("i", { staticClass: "i-calendar" }),
                    _vm._v(
                      " " + _vm._s(_vm._f("date")(_vm.info.createdAt)) + " "
                    )
                  ]),
                  _vm._l(_vm.info.relations, function(rel, i) {
                    return _c("Relation", {
                      key: i,
                      attrs: { type: "info-tile", relation: rel, compact: true }
                    })
                  }),
                  _c("div", { staticClass: "main__address" }, [
                    _c("i", { staticClass: "i-location" }),
                    _c("span", [_vm._v(_vm._s(_vm.info.settlement) + ",")]),
                    _vm.info.districtName
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.info.districtName) + ",")
                        ])
                      : _vm._e(),
                    _c("span", [_vm._v("вул. " + _vm._s(_vm.info.street))]),
                    _c("span", [_vm._v(_vm._s(_vm.info.houseNo))]),
                    _c("span", [_vm._v("кв." + _vm._s(_vm.info.aptNo))])
                  ])
                ],
                2
              )
            ]),
            _c("div", { staticClass: "main__content" }, [
              _c("div", { staticClass: "main__row main__row--dark" }, [
                _vm._m(1),
                _c("div", { staticClass: "main__row-right" }, [
                  _c("div", { staticClass: "main__row-letter" }, [
                    _c("div", {}, [_vm._v(_vm._s(_vm.info.authorName[0]))])
                  ]),
                  _c("div", { staticClass: "main__text" }, [
                    _vm._v(_vm._s(_vm.info.authorName))
                  ])
                ])
              ]),
              _c("div", { staticClass: "main__row main__row--dark" }, [
                _vm._m(2),
                _c("div", { staticClass: "main__row-right" }, [
                  _c("div", { staticClass: "main__row-letter" }, [
                    _c("div", {}, [_vm._v(_vm._s(_vm.info.responsibleName[0]))])
                  ]),
                  _c("div", { staticClass: "main__text" }, [
                    _vm._v(_vm._s(_vm.info.responsibleName))
                  ])
                ])
              ]),
              _c("div", { staticClass: "main__row" }, [
                _c("div", { staticClass: "main__text" }, [
                  _vm._v("К-сть кімнат")
                ]),
                _c("div", { staticClass: "main__text" }, [
                  _vm._v(_vm._s(_vm.info.noOfRooms))
                ])
              ]),
              _vm.info.type === "apartment"
                ? _c("div", { staticClass: "main__row" }, [
                    _c("div", { staticClass: "main__text" }, [
                      _vm._v("Вид/Тип")
                    ]),
                    _c("div", { staticClass: "main__text" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("apartmentMaterial")(_vm.info.material)
                          ) +
                          "/" +
                          _vm._s(
                            _vm._f("apartmentType")(_vm.info.buildingType)
                          ) +
                          " "
                      )
                    ])
                  ])
                : _vm.info.type === "house"
                ? _c("div", { staticClass: "main__row" }, [
                    _c("div", { staticClass: "main__text" }, [
                      _vm._v("Вид/Тип")
                    ]),
                    _c("div", { staticClass: "main__text" }, [
                      _vm._v(
                        _vm._s(_vm._f("buildingMaterial")(_vm.info.material)) +
                          "/" +
                          _vm._s(_vm._f("buildingType")(_vm.info.buildingType))
                      )
                    ])
                  ])
                : _c("div", { staticClass: "main__row" }, [
                    _c("div", { staticClass: "main__text" }, [
                      _vm._v("Призначення")
                    ]),
                    _c("div", { staticClass: "main__text" }, [
                      _vm._v(
                        _vm._s(_vm._f("commerceType")(_vm.info.buildingType))
                      )
                    ])
                  ]),
              _c("div", { staticClass: "main__row" }, [
                _c("div", { staticClass: "main__text" }, [_vm._v("Поверх")]),
                _c("div", { staticClass: "main__text" }, [
                  _vm._v(_vm._s(_vm.info.floor) + "/" + _vm._s(_vm.info.floors))
                ])
              ]),
              _c("div", { staticClass: "main__row" }, [
                _c("div", { staticClass: "main__text" }, [_vm._v("Площа м²")]),
                _c("div", { staticClass: "main__text" }, [
                  _vm._v(
                    _vm._s(_vm.info.squareTotal) +
                      "/" +
                      _vm._s(_vm.info.squareLiving) +
                      "/" +
                      _vm._s(_vm.info.squareKitchen)
                  )
                ])
              ]),
              _vm.info.squareLand
                ? _c("div", { staticClass: "main__row" }, [
                    _c("div", { staticClass: "main__text" }, [
                      _vm._v("Площа ділянки м²")
                    ]),
                    _c("div", { staticClass: "main__text" }, [
                      _vm._v(_vm._s(_vm.info.squareLand))
                    ])
                  ])
                : _vm._e(),
              _vm.info.type !== "house"
                ? _c("div", { staticClass: "main__row" }, [
                    _c("div", { staticClass: "main__text" }, [
                      _vm._v("Ремонт/Авт. опал.")
                    ]),
                    _c("div", { staticClass: "main__text" }, [
                      _vm.info.renovated
                        ? _c("span", { staticClass: "card__span" }, [
                            _vm._v("Є")
                          ])
                        : _c("span", { staticClass: "card__span" }, [
                            _vm._v("Немає")
                          ]),
                      _c("span", { staticClass: "card__span" }, [_vm._v("/")]),
                      _c("span", { staticClass: "card__span" }, [
                        _vm._v(_vm._s(_vm.info.autonomousHeatType))
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm.info.type === "house"
                ? _c("div", { staticClass: "main__row" }, [
                    _c("div", { staticClass: "main__text" }, [
                      _vm._v("Ремонт")
                    ]),
                    _c("div", { staticClass: "main__text" }, [
                      _vm.info.renovated
                        ? _c("span", { staticClass: "card__span" }, [
                            _vm._v("Є")
                          ])
                        : _c("span", { staticClass: "card__span" }, [
                            _vm._v("Немає")
                          ])
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "main__row" }, [
                _c("div", { staticClass: "main__text" }, [
                  _vm._v("Під іпотеку")
                ]),
                _c("div", { staticClass: "main__text" }, [
                  _vm._v(_vm._s(_vm.info.mortgagePurchase ? "Так" : "Ні"))
                ])
              ]),
              _c("div", { staticClass: "main__row" }, [
                _c("div", { staticClass: "main__text" }, [_vm._v("Джерело")]),
                _c("div", { staticClass: "main__text" }, [
                  _vm._v(_vm._s(_vm.source))
                ])
              ]),
              _vm.info.type === "house"
                ? _c("div", { staticClass: "main__row" }, [
                    _c("div", { staticClass: "main__text" }, [_vm._v("Гараж")]),
                    _c("div", { staticClass: "main__text" }, [
                      _vm.info.garage
                        ? _c("span", { staticClass: "card__span" }, [
                            _vm._v("Є")
                          ])
                        : _c("span", { staticClass: "card__span" }, [
                            _vm._v("Немає")
                          ])
                    ])
                  ])
                : _vm._e(),
              _vm.info.type === "house"
                ? _c("div", { staticClass: "main__row" }, [
                    _c("div", { staticClass: "main__text" }, [
                      _vm._v("Рік побудови")
                    ]),
                    _c("div", { staticClass: "main__text" }, [
                      _c("span", { staticClass: "card__span" }, [
                        _vm._v(_vm._s(_vm.info.builtAt))
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm.info.type === "house"
                ? _c("div", { staticClass: "main__row" }, [
                    _c("div", { staticClass: "main__text" }, [
                      _vm._v("Кадастровий номер")
                    ]),
                    _c("div", { staticClass: "main__text" }, [
                      _vm._v(_vm._s(_vm.info.registrationNo))
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "main__row main__row--blue" }, [
                _c("div", { staticClass: "main__text" }, [
                  _vm._v("Причина видалення")
                ]),
                _c("div", { staticClass: "main__text" }, [
                  _vm._v(_vm._s(_vm.archivedReason))
                ])
              ]),
              _c("div", { staticClass: "main__row main__row--blue" }, [
                _c("div", { staticClass: "main__text" }, [
                  _vm._v("Видалено до")
                ]),
                _c("div", { staticClass: "main__text" }, [
                  _c("i", { staticClass: "i-calendar" }),
                  _vm._v(
                    " " + _vm._s(_vm._f("date")(_vm.info.archivedTill)) + " "
                  )
                ])
              ]),
              _c("div", { staticClass: "main__row main__row--blue" }, [
                _c("div", { staticClass: "main__text" }, [
                  _vm._v("Дата видалення")
                ]),
                _c("div", { staticClass: "main__text" }, [
                  _c("i", { staticClass: "i-calendar" }),
                  _vm._v(
                    " " + _vm._s(_vm._f("date")(_vm.info.archivedAt)) + " "
                  )
                ])
              ]),
              _c("div", { staticClass: "main__row main__row--area" }, [
                _c("div", { staticClass: "main__text" }, [_vm._v("Опис")]),
                _c("div", { staticClass: "main__text" }, [
                  _c(
                    "textarea",
                    { attrs: { id: "autoresizing", disabled: "" } },
                    [_vm._v(_vm._s(_vm.info.description))]
                  )
                ])
              ]),
              _c("div", { staticClass: "main__row main__row--area" }, [
                _c("div", { staticClass: "main__text" }, [
                  _vm._v("Причина продажу")
                ]),
                _c("div", { staticClass: "main__text" }, [
                  _c("span", { staticClass: "card__span" }, [
                    _vm._v(_vm._s(_vm.info.reasonToSell))
                  ])
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.block === "photo"
        ? _c("div", { staticClass: "info__photo" }, [
            _c("div", { staticClass: "photo__header" }, [_vm._v("Фото")]),
            _c(
              "div",
              { staticClass: "photo__content" },
              _vm._l(_vm.info.photos, function(img) {
                return _c("img", {
                  key: img.id,
                  staticClass: "photo",
                  attrs: { src: img.fileURL }
                })
              }),
              0
            )
          ])
        : _vm._e(),
      _vm.block === "contacts"
        ? _c(
            "div",
            { staticClass: "info__contacts" },
            _vm._l(_vm.info.contacts, function(contact) {
              return _c(
                "div",
                { key: contact.id, staticClass: "contacts__item" },
                [
                  _c("div", { staticClass: "contacts__header" }, [
                    _c("div", { staticClass: "contacts__letter" }, [
                      _vm._v(_vm._s(contact.name[0]))
                    ]),
                    _c("div", { staticClass: "contacts__name" }, [
                      _vm._v(_vm._s(contact.name))
                    ]),
                    _c("div", { staticClass: "contacts__role" }, [
                      _vm._v(
                        "Роль: " +
                          _vm._s(
                            contact.roles
                              .map(function(x) {
                                return x.name
                              })
                              .join(" / ")
                          )
                      )
                    ]),
                    _c("div", { staticClass: "contacts__date" }, [
                      _vm._v("Дата народження: " + _vm._s(contact.birthday))
                    ]),
                    contact.company
                      ? _c("div", { staticClass: "contacts__company" }, [
                          _vm._v(
                            ' Компанія: "' + _vm._s(contact.company.name) + '" '
                          ),
                          contact.jobPosition
                            ? _c("span", [
                                _vm._v(
                                  "(" + _vm._s(contact.jobPosition.name) + ")"
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ]),
                  _vm._l(contact.phones, function(phone) {
                    return _c(
                      "div",
                      { key: phone.id, staticClass: "contacts__phones" },
                      [
                        _c("div", { staticClass: "contacts__phone-wrapper" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "tel:" + phone.phone },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                }
                              }
                            },
                            [_c("i", { staticClass: "ri-phone-fill" })]
                          ),
                          _c("div", { staticClass: "contacts__phone" }, [
                            _vm._v(_vm._s(phone.phone))
                          ]),
                          _c("div", { staticClass: "contacts__messengers" }, [
                            _c("i", {
                              staticClass:
                                "i-viber button__icon button__icon--circle",
                              class: {
                                "button__icon--border--active": phone.isOnViber
                              }
                            }),
                            _c("i", {
                              staticClass:
                                "i-telegram button__icon button__icon--circle",
                              class: {
                                "button__icon--border--active":
                                  phone.isOnTelegram
                              }
                            }),
                            _c("i", {
                              staticClass:
                                "i-whatsapp button__icon button__icon--circle",
                              class: {
                                "button__icon--border--active":
                                  phone.isOnWhatsapp
                              }
                            }),
                            _c("i", {
                              staticClass:
                                "i-messanger button__icon button__icon--circle",
                              class: {
                                "button__icon--border--active":
                                  phone.isOnFacebook
                              }
                            })
                          ])
                        ])
                      ]
                    )
                  })
                ],
                2
              )
            }),
            0
          )
        : _vm._e(),
      _vm.block === "other"
        ? _c("div", { staticClass: "info__other" })
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info__archive" }, [
      _c("i", { staticClass: "ri-archive-line link__sidebar_icon" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main__row-left" }, [
      _c("i", { staticClass: "i-employees" }),
      _c("div", { staticClass: "main__text" }, [_vm._v("Автор")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main__row-left" }, [
      _c("i", { staticClass: "i-employees" }),
      _c("div", { staticClass: "main__text" }, [_vm._v("Відповідальний агент")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }