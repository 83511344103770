<template>
  <div class="navbar__wrapper" id="navbar">
    <div class="navbar__inner">
      <div class="navbar__top">
        <div class="container">
          <div class="navbar__top-inner">
            <div class="navbar__left">
              <div class="button button--fill button-mobile-nav" @click="openSidebar">
                <i class="ri-menu-unfold-line"></i>
              </div>
              <div class="navbar__center-inner">
                <router-link active-class="navbar__link--active" :to="linkApartments" class="link navbar__link">
                  <span class="navbar__link-text">Квартири</span>
                  <span class="navbar__link-icon">{{ favoritesApt }}</span>
                </router-link>
                <router-link active-class="navbar__link--active" :to="linkHouses" class="link navbar__link">
                  <span class="navbar__link-text ">Будинки</span>
                  <span class="navbar__link-icon">{{ favoritesHouse }}</span>
                </router-link>
                <router-link active-class="navbar__link--active" :to="linkCommerce" class="link navbar__link">
                  <span class="navbar__link-text">Комерція</span>
                  <span class="navbar__link-icon">{{ favoritesCom }}</span>
                </router-link>
              </div>
            </div>
            <div class="navbar__right">
              <div class="navbar__notifications">
                <Notifications />
              </div>
              <div class="user__block">
                <div class="user__icon">{{ letter }}</div>
                <div class="user__name">{{ user }}</div>
                <div class="user__param">
                  <Multiselect
                    class="mSelect-dropdown"
                    :options="list"
                    :searchable="false"
                    :show-labels="false"
                    :reset-after="true"
                    :value="uAction"
                    placeholder=""
                    @select="userfn"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Sidebar from '@/components/Sidebar'
import SidebarMobile from '@/components/SidebarMobile'
import Notifications from '@/components/Notifications'

export default {
  name: 'NavbarFavorites',
  components: {
    Multiselect,
    Notifications,
  },
  data: () => ({
    user: {},
    letter: '',
    list: ['Аккаунт', 'Вийти'],
    uAction: '',
    search: null,
    itemSort: {name: 'Останні'},
    listSort: [
      {name: 'Останні', id: 'createdAt-desc'},
      {name: 'Перші', id: 'createdAt-asc'},
      {name: 'Загальна площа +', id: 'squareTotal-desc'},
      {name: 'Загальна площа -', id: 'squareTotal-asc'}
    ],
    dropdownActive: false
  }),
  computed: {
    favoritesApt() {
      if (this.type === 'buyers') {
        return this.$store.getters.paginationFavBuyersApt.total
      } else if (this.type === 'renters') {
        return this.$store.getters.paginationFavRentersApt.total
      } else if (this.type === 'objects') {
        return this.$store.getters.paginationFavObjectsApt.total
      } else if (this.type === 'rent') {
        return this.$store.getters['favorites/rent/apartmentsPagination'].total
      }
    },
    favoritesHouse() {
      if (this.type === 'buyers') {
        return this.$store.getters.paginationFavBuyersHouse.total
      } else if (this.type === 'renters') {
        return this.$store.getters.paginationFavRentersHouse.total
      } else if (this.type === 'objects') {
        return this.$store.getters.paginationFavObjectsHouse.total
      } else if (this.type === 'rent') {
        return this.$store.getters['favorites/rent/housesPagination'].total
      }
    },
    favoritesCom() {
      if (this.type === 'buyers') {
        return this.$store.getters.paginationFavBuyersCom.total
      } else if (this.type === 'renters') {
        return this.$store.getters.paginationFavRentersCom.total
      } else if (this.type === 'objects') {
        return this.$store.getters.paginationFavObjectsCom.total
      } else if (this.type === 'rent') {
        return this.$store.getters['favorites/rent/commercePagination'].total
      }
    },
    class() {
      return this.$route.meta.class
    },
    path() {
      return this.$route.path
    },
    type() {
      return this.$route.meta.type
    },
    linkApartments() {
      return `/favorites/${this.type}/apartments`
    },
    linkHouses() {
      return `/favorites/${this.type}/houses`
    },
    linkCommerce() {
      return `/favorites/${this.type}/commerce`
    }
  },
  async created() {
    if (Object.keys(this.$store.getters.user).length === 0) {
      await this.$store.dispatch('getUser')
    }
    const user = this.$store.getters.user
    this.user = user.fullName
    this.letter = user?.fullName[0]

    if (!this.$store.getters.paginationFavBuyersApt.total) {
      await this.$store.dispatch('fetchFavoritesBuyers', 'apartment')
    }
    if (!this.$store.getters.paginationFavBuyersHouse.total) {
      await this.$store.dispatch('fetchFavoritesBuyers', 'house')
    }
    if (!this.$store.getters.paginationFavBuyersCom.total) {
      await this.$store.dispatch('fetchFavoritesBuyers', 'commerce')
    }

    if (!this.$store.getters.paginationFavRentersApt.total) {
      await this.$store.dispatch('fetchFavoritesRenters', 'apartment')
    }
    if (!this.$store.getters.paginationFavRentersHouse.total) {
      await this.$store.dispatch('fetchFavoritesRenters', 'house')
    }
    if (!this.$store.getters.paginationFavRentersCom.total) {
      await this.$store.dispatch('fetchFavoritesRenters', 'commerce')
    }

    if (!this.$store.getters.paginationFavObjectsApt.total) {
      await this.$store.dispatch('fetchFavoritesObjects', 'apartment')
    }
    if (!this.$store.getters.paginationFavObjectsHouse.total) {
      await this.$store.dispatch('fetchFavoritesObjects', 'house')
    }
    if (!this.$store.getters.paginationFavObjectsCom.total) {
      await this.$store.dispatch('fetchFavoritesObjects', 'commerce')
    }

    if (!this.$store.getters['favorites/rent/apartmentsPagination'].total) {
      await this.$store.dispatch('favorites/rent/fetch', 'apartment')
    }
    if (!this.$store.getters['favorites/rent/housesPagination'].total) {
      await this.$store.dispatch('favorites/rent/fetch', 'house')
    }
    if (!this.$store.getters['favorites/rent/commercePagination'].total) {
      await this.$store.dispatch('favorites/rent/fetch', 'commerce')
    }
  },
  methods: {
    userfn(option) {
      if (option === 'Вийти') {
        this.$store.dispatch('logout')
        this.$router.go('/login')
      }
    },
    searchObject: _.debounce(v => {
      let request = {
        'filter[phoneOrID]': v.search
      }
      if (v.type === 'buyers') {
        v.$store.commit('resetFavBuyers', v.class)
        v.$store.commit('sortParamsBuyers', request)
        v.$store.dispatch('fetchFavoritesBuyers', v.class)
      } else if (v.type === 'renters') {
        v.$store.commit('resetFavRenters', v.class)
        v.$store.commit('sortParamsRenters', request)
        v.$store.dispatch('fetchFavoritesRenters', v.class)
      } else if (v.type === 'objects') {
        v.$store.commit('resetFavObjects', v.class)
        v.$store.commit('sortParams', request)
        v.$store.dispatch('fetchFavoritesObjects', v.class)
      } else if (v.type === 'rent') {
        v.$store.commit('favorites/rent/reset', v.class)
        v.$store.commit('sortParams', request)
        v.$store.dispatch('favorites/rent/fetch', v.class)
      }
    }, 500),
    async sorted(option) {
      let params = {
        sortBy: option.id
      }
      if (this.class === 'house') {
        this.$store.commit('resetBuyersHouse')
        this.$store.commit('sortParamsBuyers', params)
        this.$store.dispatch('fetchBuyersHouse')
      } else if (this.class === 'apartment') {
        this.$store.commit('resetBuyersApt')
        this.$store.commit('sortParamsBuyers', params)
        this.$store.dispatch('fetchBuyersApt')
      } else if (this.class === 'commerce') {
        this.$store.commit('resetBuyersCom')
        this.$store.commit('sortParamsBuyers', params)
        this.$store.dispatch('fetchBuyersCom')
      }
    },
    openFilter() {
      if (this.type === 'buyers') {
        this.$modal.show(
          FiltersBuyers,
          {},
          {transition: 'none', classes: 'modalFilter', width: '400px', height: '100%', shiftX: 1, shiftY: 1},
          {'before-open': this.$modal.open, 'before-close': this.$modal.close}
        )
      } else if (this.type === 'renters') {
        this.$modal.show(
          FiltersRenters,
          {},
          {transition: 'none', classes: 'modalFilter', width: '400px', height: '100%', shiftX: 1, shiftY: 1},
          {'before-open': this.$modal.open, 'before-close': this.$modal.close}
        )
      } else if (this.type === 'objects') {
        this.$modal.show(
          FiltersObjects,
          {},
          {transition: 'none', classes: 'modalFilter', width: '400px', height: '100%', shiftX: 1, shiftY: 1},
          {'before-open': this.$modal.open, 'before-close': this.$modal.close}
        )
      } else if (this.type === 'rent') {
        this.$modal.show(
          FiltersRent,
          {},
          {transition: 'none', classes: 'modalFilter', width: '400px', height: '100%', shiftX: 1, shiftY: 1},
          {'before-open': this.$modal.open, 'before-close': this.$modal.close}
        )
      }
    },
    openSidebar() {
      const mobile = document.documentElement.clientWidth <= 768
      let SidebarModal = {}
      mobile ? (SidebarModal = SidebarMobile) : (SidebarModal = Sidebar)
      this.$modal.display(
        SidebarModal,
        {},
        {
          name: 'Sidebar',
          transition: 'none',
          classes: 'modalSidebar',
          width: '210px',
          height: '100%',
          maxHeight: 1024,
          shiftX: 0,
          shiftY: 0,
          adaptive: true
        }
      )
    }
  },
  watch: {
    async search() {
      if (this.search) {
        this.searchObject(this)
      } else if (this.search === '') {
        this.search = null
        this.searchObject(this)
      }
    },
    path() {
      this.search = null
      this.itemSort = {name: 'Останні'}
    }
  }
}
</script>
