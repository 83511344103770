<template>
  <div class="data__inner data_main">
    <div class="data__top data__part">
      <label
        class="field__label"
        :class="{
          'field__label--filled': data.agent,
          'field__label--invalid': v.sendDataOther.agent.$dirty && !v.sendDataOther.agent.required
        }"
      >
        <div class="field__title">Відповідальний агент</div>
        <Multiselect
          :options="users"
          v-model="data.agent"
          @select="onAgentChange"
          label="fullName"
          trackBy="id"
          :showLabels="false"
          :searchable="false"
          placeholder="Відповідальний агент"
          class="mSelect"
        >
        </Multiselect>
      </label>
    </div>
    <div class="data__middle data__part">
      <label
        class="field__label"
        :class="{
          'field__label--filled': data.contract,
          'field__label--invalid': v.sendDataOther.contract.$dirty && !v.sendDataOther.contract.required
        }"
      >
        <div class="field__title">Договір</div>
        <Multiselect
          :options="constants.PROPERTY_CONTRACTS"
          v-model="data.contract"
          :showLabels="false"
          :searchable="false"
          placeholder="Договір"
          class="mSelect"
        />
      </label>
      <label
        class="field__label"
        :class="{
          'field__label--filled': data.motivation,
          'field__label--invalid': v.sendDataOther.motivation.$dirty && !v.sendDataOther.motivation.required
        }"
      >
        <div class="field__title">Мотивація</div>
        <Multiselect
          :options="constants.PROPERTY_MOTIVATIONS"
          v-model="data.motivation"
          :showLabels="false"
          :searchable="false"
          placeholder="Мотивація"
          class="mSelect"
        />
      </label>
      <label
        class="field__label"
        :class="{
          'field__label--filled': data.buyerStatus,
          'field__label--invalid': v.sendDataOther.buyerStatus.$dirty && !v.sendDataOther.buyerStatus.required
        }"
      >
        <div class="field__title">Статус</div>
        <Multiselect
          :options="constants.PROPERTY_STATUSES"
          v-model="data.buyerStatus"
          label="name"
          trackBy="id"
          :showLabels="false"
          :searchable="false"
          placeholder="Статус"
          class="mSelect"
        />
      </label>
      <label
        class="field__label"
        :class="{
          'field__label--filled': data.source,
          'field__label--invalid': v.sendDataOther.source.$dirty && !v.sendDataOther.source.required
        }"
      >
        <div class="field__title">Джерело</div>
        <Treeselect
          v-model="data.source"
          :options="constants.BUYER_SOURCES"
          :disable-branch-nodes="true"
          :searchable="false"
          class="tSelect"
          placeholder="Джерело"
          openDirection="bottom"
        />
      </label>
      <div
        v-if="data.source === localConstants.BUYER_SOURCES_SEEKER_ID"
        class="field__label"
        :class="{
          'field__label--filled': data.seeker,
          'field__label--invalid': v.sendDataOther.seeker.$dirty && !v.sendDataOther.seeker.required
        }"
      >
        <div class="field__title">Шукач</div>
        <SeekerSearch
          :seeker="data.seeker"
          @select="seeker => data.seeker = seeker"
        />
      </div>
      <label
        class="field__label"
        :class="{
          'field__label--filled': data.mortgagePurchase,
          'field__label--invalid': v.sendDataOther.mortgagePurchase.$dirty && !v.sendDataOther.mortgagePurchase.required
        }"
      >
        <div class="field__title">Під іпотеку</div>
        <Multiselect
          :options="localConstants.YES_NO"
          v-model="data.mortgagePurchase"
          label="name"
          trackBy="id"
          :showLabels="false"
          :searchable="false"
          placeholder="Під іпотеку"
          class="mSelect"
        />
      </label>
      <div
        class="field__label"
        :class="{
          'field__label--filled': data.lastCallAt,
          'field__label--invalid': v.sendDataOther.lastCallAt.$dirty && !v.sendDataOther.lastCallAt.required
        }"
      >
        <div class="field__title">Останній дзвінок</div>
        <date-picker
          v-model="data.lastCallAt"
          isDark
          color="blue"
          :popover="{visibility: 'focus', placement: 'top-start'}"
        >
          <template v-slot="{inputValue, inputEvents}">
            <input class="field__input" :value="inputValue" v-on="inputEvents" readonly />
          </template>
        </date-picker>
      </div>
    </div>
    <div class="data__bottom data__part">
      <label
        class="field__label"
        :class="{
          'field__label--filled': data.reasonToBuy,
          'field__label--invalid': v.sendDataOther.reasonToBuy.$dirty && !v.sendDataOther.reasonToBuy.required
        }"
      >
        <div class="field__title field__title--required">Причина купівлі</div>
        <textarea class="field__text" v-model="data.reasonToBuy"></textarea>
      </label>
    </div>
  </div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Multiselect from 'vue-multiselect'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import {mapState} from 'vuex'
import localConstants from '@/utils/localConstants'
import SeekerSearch from '@/components/SeekerSearch'

export default {
  name: 'UploadDataOtherBuyer',
  props: ['data', 'v'],
  components: {
    Treeselect,
    Multiselect,
    DatePicker,
    SeekerSearch,
  },
  data: () => ({
    searchActive: false,
    localConstants
  }),
  computed: {
    ...mapState({
      constants: s => s.main.constants,
      users: s => s.users.users
    })
  },
  methods: {
    onAgentChange(value) {
      this.data.contract = null
      this.data.buyerStatus = null
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
