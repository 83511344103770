var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "info__inner" }, [
    _c("div", { staticClass: "info__header" }, [
      _c("div", { staticClass: "info__header-right" }, [
        _c(
          "div",
          { staticClass: "button button--fill button--small button--disabled" },
          [
            _vm.action.actionType === 1
              ? _c("i", { staticClass: "ri-home-6-fill" })
              : _vm._e(),
            _vm.action.actionType === 2
              ? _c("i", { staticClass: "ri-eye-fill" })
              : _vm._e(),
            _vm.action.actionType === 3
              ? _c("i", { staticClass: "ri-organization-chart" })
              : _vm._e(),
            _vm.action.actionType === 4
              ? _c("i", { staticClass: "ri-phone-fill" })
              : _vm._e(),
            _vm.action.actionType === 5
              ? _c("i", { staticClass: "ri-money-dollar-box-fill" })
              : _vm._e(),
            _vm.action.actionType === 6
              ? _c("i", { staticClass: "ri-government-fill" })
              : _vm._e(),
            _vm.action.actionType === 8
              ? _c("i", { staticClass: "ri-article-fill" })
              : _vm._e(),
            _vm.action.actionType === 4
              ? _c("span", [_vm._v("Дзвінок по рекламі")])
              : _c("span", [_vm._v(_vm._s(_vm.action.actionName))])
          ]
        ),
        _vm.action.actionType === 8 || _vm.action.actionType === 9
          ? _c("div", { staticClass: "cell cell__result" }, [
              _c(
                "div",
                { staticClass: "indicator indicator--dot indicator--green" },
                [_vm._v(_vm._s(_vm.action.actionNumber))]
              )
            ])
          : _c("div", [
              _vm.action.result === 1
                ? _c(
                    "div",
                    {
                      staticClass: "indicator indicator--dot indicator--green"
                    },
                    [_vm._v("Згода")]
                  )
                : _vm.action.result === 2
                ? _c(
                    "div",
                    { staticClass: "indicator indicator--dot indicator--red" },
                    [_vm._v("Відмова")]
                  )
                : _vm.action.result === 3
                ? _c(
                    "div",
                    { staticClass: "indicator indicator--dot indicator--blue" },
                    [_vm._v("План")]
                  )
                : _vm.action.result === 4
                ? _c(
                    "div",
                    {
                      staticClass: "indicator indicator--dot indicator--orange"
                    },
                    [_vm._v("Думають")]
                  )
                : _vm._e()
            ])
      ]),
      _c("div", { staticClass: "info__header-left" }, [
        !_vm.action.isAgentBlocked
          ? _c(
              "div",
              {
                staticClass: "button__icon button__icon--small",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                  }
                }
              },
              [
                _c("Multiselect", {
                  staticClass: "mSelect-dropdown",
                  attrs: {
                    options: _vm.list,
                    searchable: false,
                    "show-labels": false,
                    "reset-after": true,
                    value: _vm.item,
                    placeholder: "",
                    "open-direction": "bottom"
                  },
                  on: { select: _vm.actions }
                })
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "button__icon button__icon--small button--onmobile",
            on: {
              click: function($event) {
                return _vm.$modal.hide("modalInfoAction")
              }
            }
          },
          [_c("i", { staticClass: "ri-close-line" })]
        )
      ])
    ]),
    _c("div", { staticClass: "info__main" }, [
      _c("div", { staticClass: "main__content" }, [
        _c("div", { staticClass: "main__row main__row--dark" }, [
          _vm._m(0),
          _c("div", { staticClass: "main__row-right" }, [
            _c("div", { staticClass: "main__row-letter" }, [
              _c("div", {}, [_vm._v(_vm._s(_vm.action.agentName[0]))])
            ]),
            _c("div", { staticClass: "main__text" }, [
              _vm._v(_vm._s(_vm.action.agentName))
            ])
          ])
        ]),
        _c("div", { staticClass: "main__row main__row--dark is-mobile" }, [
          _vm._m(1),
          _c("div", { staticClass: "main__row-right" }, [
            _vm.action.actionType === 1
              ? _c("i", { staticClass: "ri-home-6-fill" })
              : _vm._e(),
            _vm.action.actionType === 2
              ? _c("i", { staticClass: "ri-eye-fill" })
              : _vm._e(),
            _vm.action.actionType === 3
              ? _c("i", { staticClass: "ri-organization-chart" })
              : _vm._e(),
            _vm.action.actionType === 4
              ? _c("i", { staticClass: "ri-phone-fill" })
              : _vm._e(),
            _vm.action.actionType === 5
              ? _c("i", { staticClass: "ri-money-dollar-box-fill" })
              : _vm._e(),
            _vm.action.actionType === 6
              ? _c("i", { staticClass: "ri-government-fill" })
              : _vm._e(),
            _vm.action.actionType === 4
              ? _c("span", { staticClass: "main__text" }, [
                  _vm._v("Дзвінок по рекламі")
                ])
              : _c("span", { staticClass: "main__text" }, [
                  _vm._v(_vm._s(_vm.action.actionName))
                ])
          ])
        ]),
        _vm.action.actionType === 8
          ? _c("div", { staticClass: "main__row main__row--dark" }, [
              _c("div", { staticClass: "main__text" }, [
                _vm._v("Тип продукції")
              ]),
              _c("div", { staticClass: "main__text" }, [
                _vm._v(_vm._s(_vm.action.actionSubtypeName))
              ])
            ])
          : _vm._e(),
        _vm.action.actionType === 9
          ? _c("div", { staticClass: "main__row main__row--dark" }, [
              _c("div", { staticClass: "main__text" }, [_vm._v("Тип реклами")]),
              _c("div", { staticClass: "main__text" }, [
                _vm._v(_vm._s(_vm.action.actionSubtypeName))
              ])
            ])
          : _vm._e(),
        _c("div", { staticClass: "main__row main__row--dark" }, [
          _c("div", { staticClass: "main__text" }, [_vm._v("Дата і час")]),
          _c("div", { staticClass: "main__text" }, [
            _c("i", { staticClass: "ri-calendar-fill" }),
            _vm._v(" " + _vm._s(_vm._f("date")(_vm.action.actionAt)) + " "),
            _c("i", { staticClass: "ri-time-fill" }),
            _vm._v(" " + _vm._s(_vm.action.time) + " ")
          ])
        ]),
        _c("div", { staticClass: "main__row main__row--dark" }, [
          _c("div", { staticClass: "main__text" }, [_vm._v("Дата створення")]),
          _c("div", { staticClass: "main__text" }, [
            _c("i", { staticClass: "ri-calendar-fill" }),
            _vm._v(" " + _vm._s(_vm._f("date")(_vm.action.createdAt)) + " ")
          ])
        ]),
        _c("div", { staticClass: "main__row main__row--dark" }, [
          _c("div", { staticClass: "main__text" }, [_vm._v("Об'єкт")]),
          _c("div", { staticClass: "main__row-right" }, [
            _vm.action.propertyType === 3 && !_vm.action.propertyRelated
              ? _c("div", { staticClass: "main__text" }, [_vm._v("інше АН")])
              : (_vm.action.propertyType === 2 ||
                  _vm.action.propertyType === 3 ||
                  _vm.action.actionType === 9) &&
                _vm.action.propertyRelated
              ? _c(
                  "div",
                  { staticClass: "main__text main__text-modal" },
                  [
                    _c("Relation", {
                      attrs: {
                        type: "notification-icon",
                        relation: _vm.action.propertyRelated
                      }
                    }),
                    _vm.action.propertyType === 3
                      ? _c("span", [_vm._v(" інше АН")])
                      : _vm._e()
                  ],
                  1
                )
              : _vm.action.propertyType === 4
              ? _c("div", { staticClass: "main__text" }, [
                  _c("i", { staticClass: "ri-user-fill" }),
                  _vm._v(" " + _vm._s(_vm.action.propertyRelated) + " ")
                ])
              : _c("div", { staticClass: "main__text" }, [_vm._v("—")])
          ])
        ]),
        _c("div", { staticClass: "main__row main__row--dark" }, [
          _c("div", { staticClass: "main__text" }, [
            _vm._v("Покупець/орендар")
          ]),
          _c("div", { staticClass: "main__row-right" }, [
            _vm.action.buyerType === 3 && !_vm.action.buyerRelated
              ? _c("div", { staticClass: "main__text" }, [_vm._v("інше АН")])
              : (_vm.action.buyerType === 2 || _vm.action.buyerType === 3) &&
                _vm.action.buyerRelated
              ? _c(
                  "div",
                  { staticClass: "main__text main__text-modal" },
                  [
                    _c("Relation", {
                      attrs: {
                        type: "notification-icon",
                        relation: _vm.action.buyerRelated
                      }
                    }),
                    _vm.action.buyerType === 3
                      ? _c("span", [_vm._v(" інше АН")])
                      : _vm._e()
                  ],
                  1
                )
              : _vm.action.buyerType === 4
              ? _c("div", { staticClass: "main__text" }, [
                  _c("i", { staticClass: "ri-user-fill" }),
                  _vm._v(" " + _vm._s(_vm.action.buyerRelated) + " ")
                ])
              : _c("div", { staticClass: "main__text" }, [_vm._v("—")])
          ])
        ]),
        (_vm.action.actionType === 1 ||
          _vm.action.actionType === 2 ||
          _vm.action.actionType === 4) &&
        _vm.action.result === 2
          ? _c("div", { staticClass: "main__row main__row--dark" }, [
              _c("div", { staticClass: "main__text" }, [
                _vm._v("Причина відмови")
              ]),
              _c("div", { staticClass: "main__text" }, [
                _vm._v(_vm._s(this.rejectionReasonName))
              ])
            ])
          : _vm._e(),
        (_vm.action.actionType === 1 || _vm.action.actionType === 4) &&
        _vm.action.result === 2 &&
        _vm.action.rejectionReason === 1
          ? _c("div", { staticClass: "main__row main__row--dark" }, [
              _c("div", { staticClass: "main__text" }, [
                _vm._v("Ціна покупця")
              ]),
              _c("div", { staticClass: "main__text" }, [
                _vm._v(" " + _vm._s(this.action.buyerPrice) + " ")
              ])
            ])
          : _vm._e(),
        _vm.action.actionType === 6
          ? _c("div", { staticClass: "main__row main__row--dark" }, [
              _c("div", { staticClass: "main__text" }, [
                _vm._v("Сума завдатку")
              ]),
              _c("div", { staticClass: "main__text" }, [
                _vm._v(_vm._s(this.action.depositAmount) + "$")
              ])
            ])
          : _vm._e(),
        _vm.action.actionType === 6
          ? _c("div", { staticClass: "main__row main__row--dark" }, [
              _c("div", { staticClass: "main__text" }, [
                _vm._v("Закінчення завдатку")
              ]),
              _c("div", { staticClass: "main__text" }, [
                _c("i", { staticClass: "ri-calendar-fill" }),
                _vm._v(_vm._s(_vm._f("date")(_vm.action.depositEndDate)) + " ")
              ])
            ])
          : _vm._e(),
        _vm.action.actionType === 6
          ? _c("div", { staticClass: "main__row main__row--dark" }, [
              _c("div", { staticClass: "main__text" }, [
                _vm._v("ПІБ нотаріуса")
              ]),
              _c("div", { staticClass: "main__text" }, [
                _vm._v(_vm._s(_vm.action.notaryFullName))
              ])
            ])
          : _vm._e(),
        _vm.action.actionType === 6
          ? _c("div", { staticClass: "main__row main__row--dark" }, [
              _c("div", { staticClass: "main__text" }, [
                _vm._v("Вид правочину")
              ]),
              _c("div", { staticClass: "main__text" }, [
                _vm._v(_vm._s(_vm.deedType))
              ])
            ])
          : _vm._e(),
        _vm.action.actionType === 6
          ? _c("div", { staticClass: "main__row main__row--dark" }, [
              _c("div", { staticClass: "main__text" }, [
                _vm._v("Витрати покупця")
              ]),
              _c("div", { staticClass: "main__text" }, [
                _vm._v(
                  " Послуги нотаріуса: " +
                    _vm._s(_vm.action.buyerNotaryFee) +
                    "%"
                ),
                _c("br"),
                _vm._v(" Розмір податку: " + _vm._s(_vm.action.buyerTax) + "%"),
                _c("br"),
                _vm._v(
                  " Комісія АН: " + _vm._s(_vm.action.buyerAgencyFee) + "$ "
                )
              ])
            ])
          : _vm._e(),
        _vm.action.actionType === 6
          ? _c("div", { staticClass: "main__row main__row--dark" }, [
              _c("div", { staticClass: "main__text" }, [
                _vm._v("Витрати продавця")
              ]),
              _c("div", { staticClass: "main__text" }, [
                _vm._v(
                  " Послуги нотаріуса: " +
                    _vm._s(_vm.action.sellerNotaryFee) +
                    "%"
                ),
                _c("br"),
                _vm._v(
                  " Розмір податку: " + _vm._s(_vm.action.sellerTax) + "%"
                ),
                _c("br"),
                _vm._v(
                  " Комісія АН: " + _vm._s(_vm.action.sellerAgencyFee) + "$ "
                )
              ])
            ])
          : _vm._e(),
        _c("div", { staticClass: "main__row main__row--area" }, [
          _c("div", { staticClass: "main__text" }, [_vm._v("Опис")]),
          _c("div", { staticClass: "main__text" }, [
            _c("textarea", { attrs: { id: "autoresizing", disabled: "" } }, [
              _vm._v(_vm._s(_vm.action.details))
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main__row-left" }, [
      _c("div", { staticClass: "main__text" }, [_vm._v("Агент")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main__row-left" }, [
      _c("div", { staticClass: "main__text" }, [_vm._v("Тип")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }