<template>
  <div class="login__wrapper">
    <div class="login__inner">
      <router-view />
    </div>
  </div>
</template>

<script>
import messages from '@/utils/messages'

export default {
  name: 'LoginLayout',
  computed: {
    error() {
      return this.$store.getters.error
    }
  },
  watch: {
    error(error) {
      const data = error.response?.data ?? error
      this.$error(data?.message ?? data?.error)
    }
  }
};
</script>
<style lang="scss" scoped>

</style>
