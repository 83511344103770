var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upload__container" }, [
    _c("div", { staticClass: "upload__left" }, [
      _c("div", { staticClass: "upload__navigation-title" }, [
        _vm._v("Категорії")
      ]),
      _c("div", { staticClass: "upload__navigation" }, [
        _c(
          "div",
          {
            staticClass: "upload__navigation-link-wrapper",
            on: {
              click: function($event) {
                return _vm.select("mainDataBox")
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "upload__navigation-link",
                class: {
                  "upload__navigation-link--active": _vm.mainDataBox,
                  "upload__navigation-link--error":
                    this.$v.sendData.$invalid && this.$v.sendData.$dirty
                }
              },
              [_vm._v(" Параметри ")]
            ),
            _c("div", { staticClass: "upload__navigation-icon" })
          ]
        ),
        _c(
          "div",
          {
            staticClass: "upload__navigation-link-wrapper",
            on: {
              click: function($event) {
                return _vm.select("otherDataBox")
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "upload__navigation-link",
                class: {
                  "upload__navigation-link--active": _vm.otherDataBox,
                  "upload__navigation-link--error":
                    this.$v.sendDataOther.$invalid &&
                    this.$v.sendDataOther.$dirty
                }
              },
              [_vm._v(" Додаткові ")]
            ),
            _c("div", { staticClass: "upload__navigation-icon" })
          ]
        ),
        _c(
          "div",
          {
            staticClass: "upload__navigation-link-wrapper",
            on: {
              click: function($event) {
                return _vm.select("contactsBox")
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "upload__navigation-link",
                class: {
                  "upload__navigation-link--active": _vm.contactsBox,
                  "upload__navigation-link--error": _vm.contactsBoxError
                }
              },
              [_vm._v(" Контакти ")]
            ),
            _c("div", { staticClass: "upload__navigation-icon" })
          ]
        ),
        _c(
          "div",
          {
            staticClass: "upload__navigation-link-wrapper",
            on: {
              click: function($event) {
                return _vm.select("photosBox")
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "upload__navigation-link",
                class: { "upload__navigation-link--active": _vm.photosBox }
              },
              [_vm._v("Фото")]
            ),
            _c("div", { staticClass: "upload__navigation-icon" })
          ]
        )
      ])
    ]),
    _c("div", { staticClass: "upload__right" }, [
      _c("div", { staticClass: "upload__top" }, [
        _c("div", { staticClass: "upload__title" }, [
          _vm.info
            ? _c("span", [_vm._v("Редагування ")])
            : _c("span", [_vm._v("Додавання ")]),
          _vm.type === "apartment"
            ? _c("span", [_vm._v("Квартири")])
            : _vm._e(),
          _vm.type === "house" ? _c("span", [_vm._v("Будинку")]) : _vm._e(),
          _vm.type === "commerce" ? _c("span", [_vm._v("Комерції")]) : _vm._e(),
          _vm.id ? _c("span", [_vm._v(" - ID " + _vm._s(_vm.id))]) : _vm._e()
        ]),
        _c("div", { staticClass: "upload__icons" }, [
          _vm.type === "apartment"
            ? _c("i", { staticClass: "ri-building-4-fill" })
            : _vm._e(),
          _vm.type === "house"
            ? _c("i", { staticClass: "ri-home-5-fill" })
            : _vm._e(),
          _vm.type === "commerce"
            ? _c("i", { staticClass: "ri-store-fill" })
            : _vm._e()
        ]),
        _c("div", { staticClass: "upload__buttons" }, [
          _c(
            "div",
            {
              staticClass: "upload__button button button--fill button--grey",
              on: {
                click: function($event) {
                  return _vm.$modal.hide("UploadObject")
                }
              }
            },
            [_vm._v(" Скасувати ")]
          ),
          _c(
            "div",
            {
              staticClass: "upload__button button button--fill",
              on: { click: _vm.send }
            },
            [_vm._v(" Зберегти ")]
          ),
          _c(
            "div",
            {
              staticClass: "button__icon",
              on: {
                click: function($event) {
                  return _vm.$modal.hide("UploadObject")
                }
              }
            },
            [_c("i", { staticClass: "ri-close-circle-line" })]
          ),
          _c(
            "div",
            {
              staticClass: "button__icon button__icon--blue",
              on: { click: _vm.send }
            },
            [_c("i", { staticClass: "ri-save-3-line" })]
          )
        ])
      ]),
      _c("div", { staticClass: "upload__navigation--mobile" }, [
        _c(
          "div",
          {
            staticClass: "upload__navigation-link-wrapper",
            on: {
              click: function($event) {
                return _vm.select("mainDataBox")
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "upload__navigation-link",
                class: {
                  "upload__navigation-link--active": _vm.mainDataBox,
                  "upload__navigation-link--error":
                    this.$v.sendData.$invalid && this.$v.sendData.$dirty
                }
              },
              [_vm._v(" Параметри ")]
            ),
            _c("div", { staticClass: "upload__navigation-icon" })
          ]
        ),
        _c(
          "div",
          {
            staticClass: "upload__navigation-link-wrapper",
            on: {
              click: function($event) {
                return _vm.select("otherDataBox")
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "upload__navigation-link",
                class: {
                  "upload__navigation-link--active": _vm.otherDataBox,
                  "upload__navigation-link--error":
                    this.$v.sendDataOther.$invalid &&
                    this.$v.sendDataOther.$dirty
                }
              },
              [_vm._v(" Додаткові ")]
            ),
            _c("div", { staticClass: "upload__navigation-icon" })
          ]
        ),
        _c(
          "div",
          {
            staticClass: "upload__navigation-link-wrapper",
            on: {
              click: function($event) {
                return _vm.select("contactsBox")
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "upload__navigation-link",
                class: {
                  "upload__navigation-link--active": _vm.contactsBox,
                  "upload__navigation-link--error": _vm.contactsBoxError
                }
              },
              [_vm._v(" Контакти ")]
            ),
            _c("div", { staticClass: "upload__navigation-icon" })
          ]
        ),
        _c(
          "div",
          {
            staticClass: "upload__navigation-link-wrapper",
            on: {
              click: function($event) {
                return _vm.select("photosBox")
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "upload__navigation-link",
                class: { "upload__navigation-link--active": _vm.photosBox }
              },
              [_vm._v("Фото")]
            ),
            _c("div", { staticClass: "upload__navigation-icon" })
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "upload__main" },
        [
          _vm.mainDataBox
            ? _c("div", { staticClass: "data__inner data_main" }, [
                _c("div", { staticClass: "data__top" }, [
                  _c(
                    "label",
                    {
                      staticClass: "field__label",
                      class: {
                        "field__label--filled": _vm.sendData.title,
                        "field__label--invalid":
                          _vm.$v.sendData.title.$dirty &&
                          !_vm.$v.sendData.title.required
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "field__title field__title--required" },
                        [_vm._v("Заголовок")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sendData.title,
                            expression: "sendData.title"
                          }
                        ],
                        staticClass: "field__input",
                        attrs: { placeholder: "Введіть заголовок" },
                        domProps: { value: _vm.sendData.title },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.sendData, "title", $event.target.value)
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "label",
                    {
                      staticClass: "field__label",
                      class: {
                        "field__label--filled": _vm.sendData.price,
                        "field__label--invalid":
                          (_vm.$v.sendData.price.$dirty &&
                            !_vm.$v.sendData.price.required) ||
                          !_vm.$v.sendData.price.numeric
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "field__title field__title--required" },
                        [_vm._v("Ціна")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.sendData.price,
                            expression: "sendData.price",
                            modifiers: { number: true }
                          },
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "#######",
                            expression: "'#######'"
                          }
                        ],
                        staticClass: "field__input",
                        attrs: { type: "tel" },
                        domProps: { value: _vm.sendData.price },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.sendData,
                              "price",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ]
                  )
                ]),
                _c("div", { staticClass: "data__part" }, [
                  _c(
                    "div",
                    {
                      staticClass: "field__label",
                      class: {
                        "field__label--filled": _vm.sendData.settlement,
                        "field__label--invalid":
                          _vm.$v.sendData.settlement.$dirty &&
                          !_vm.$v.sendData.settlement.required
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "field__title field__title--required" },
                        [_vm._v("Населений Пункт")]
                      ),
                      _c("Multiselect", {
                        staticClass: "mSelect",
                        attrs: {
                          options: _vm.settlements,
                          label: "name",
                          trackBy: "name",
                          showLabels: false,
                          allowEmpty: false,
                          placeholder: "Населений Пункт"
                        },
                        model: {
                          value: _vm.sendData.settlement,
                          callback: function($$v) {
                            _vm.$set(_vm.sendData, "settlement", $$v)
                          },
                          expression: "sendData.settlement"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.sendData.settlement.name === "Рівне"
                    ? _c(
                        "div",
                        {
                          staticClass: "field__label",
                          class: {
                            "field__label--filled": _vm.sendData.districtID,
                            "field__label--invalid":
                              _vm.$v.sendData.districtID.$dirty &&
                              !_vm.$v.sendData.districtID.required
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "field__title field__title--required"
                            },
                            [_vm._v("Район")]
                          ),
                          _c("Multiselect", {
                            staticClass: "mSelect",
                            attrs: {
                              options: _vm.districtsRivne,
                              label: "name",
                              trackBy: "id",
                              showLabels: false,
                              searchable: false,
                              placeholder: "Район"
                            },
                            model: {
                              value: _vm.sendData.districtID,
                              callback: function($$v) {
                                _vm.$set(_vm.sendData, "districtID", $$v)
                              },
                              expression: "sendData.districtID"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "field__label",
                      class: {
                        "field__label--filled": _vm.sendData.street,
                        "field__label--invalid":
                          _vm.$v.sendData.street.$dirty &&
                          !_vm.$v.sendData.street.required
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "field__title field__title--required" },
                        [_vm._v("Вулиця")]
                      ),
                      _c(
                        "Multiselect",
                        {
                          staticClass: "mSelect",
                          attrs: {
                            options: _vm.streets,
                            showLabels: false,
                            placeholder: "Виберіть Вулицю"
                          },
                          model: {
                            value: _vm.sendData.street,
                            callback: function($$v) {
                              _vm.$set(_vm.sendData, "street", $$v)
                            },
                            expression: "sendData.street"
                          }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "noResult" }, slot: "noResult" },
                            [_vm._v("Отакої, вулиці немає :(")]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "data__middle" }, [
                  _c(
                    "label",
                    {
                      staticClass: "field__label",
                      class: {
                        "field__label--filled": _vm.sendData.houseNo
                      }
                    },
                    [
                      _c("div", { staticClass: "field__title" }, [
                        _vm._v("№ будинку")
                      ]),
                      _c("the-mask", {
                        staticClass: "field__input",
                        attrs: { mask: "IIIII", tokens: _vm.customToken },
                        model: {
                          value: _vm.sendData.houseNo,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.sendData,
                              "houseNo",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "sendData.houseNo"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.type === "apartment"
                    ? _c(
                        "label",
                        {
                          staticClass: "field__label",
                          class: {
                            "field__label--filled": _vm.sendData.aptNo
                          }
                        },
                        [
                          _c("div", { staticClass: "field__title" }, [
                            _vm._v("№ квартири")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.sendData.aptNo,
                                expression: "sendData.aptNo",
                                modifiers: { number: true }
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "###",
                                expression: "'###'"
                              }
                            ],
                            staticClass: "field__input",
                            attrs: { type: "tel" },
                            domProps: { value: _vm.sendData.aptNo },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.sendData,
                                  "aptNo",
                                  _vm._n($event.target.value)
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm.type === "commerce"
                    ? _c(
                        "div",
                        {
                          staticClass: "field__label",
                          class: {
                            "field__label--filled": _vm.sendData.floor,
                            "field__label--invalid":
                              _vm.$v.sendData.floor.$dirty &&
                              !_vm.$v.sendData.floor.required
                          }
                        },
                        [
                          _c("div", { staticClass: "field__title" }, [
                            _vm._v("Поверх")
                          ]),
                          _c(
                            "Multiselect",
                            {
                              staticClass: "mSelect",
                              attrs: {
                                options:
                                  _vm.localConstants.PROPERTY_COMMERCE_FLOORS,
                                showLabels: false,
                                maxHeight: 200,
                                searchable: false,
                                placeholder: "Поверх"
                              },
                              model: {
                                value: _vm.sendData.floor,
                                callback: function($$v) {
                                  _vm.$set(_vm.sendData, "floor", $$v)
                                },
                                expression: "sendData.floor"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "noResult" },
                                  slot: "noResult"
                                },
                                [_vm._v("Занадто високо 😱")]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.type === "commerce"
                    ? _c(
                        "div",
                        {
                          staticClass: "field__label",
                          class: {
                            "field__label--filled": _vm.sendData.floors,
                            "field__label--invalid":
                              _vm.$v.sendData.floors.$dirty &&
                              !_vm.$v.sendData.floors.required
                          }
                        },
                        [
                          _c("div", { staticClass: "field__title" }, [
                            _vm._v("Поверхів")
                          ]),
                          _c("Multiselect", {
                            staticClass: "mSelect",
                            attrs: {
                              options:
                                _vm.localConstants.PROPERTY_COMMERCE_FLOORS,
                              searchable: false,
                              showLabels: false,
                              maxHeight: 200,
                              placeholder: "Поверхів"
                            },
                            model: {
                              value: _vm.sendData.floors,
                              callback: function($$v) {
                                _vm.$set(_vm.sendData, "floors", $$v)
                              },
                              expression: "sendData.floors"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.type !== "commerce"
                    ? _c(
                        "div",
                        {
                          staticClass: "field__label",
                          class: {
                            "field__label--filled": _vm.sendData.material,
                            "field__label--invalid":
                              _vm.$v.sendData.material.$dirty &&
                              !_vm.$v.sendData.material.required
                          }
                        },
                        [
                          _c("div", { staticClass: "field__title" }, [
                            _vm._v("Вид")
                          ]),
                          _vm.type === "apartment"
                            ? _c("Multiselect", {
                                staticClass: "mSelect",
                                attrs: {
                                  options: _vm.constants.APARTMENT_MATERIALS,
                                  label: "name",
                                  trackBy: "id",
                                  showLabels: false,
                                  searchable: false,
                                  maxHeight: 200,
                                  placeholder: "Вид"
                                },
                                model: {
                                  value: _vm.sendData.material,
                                  callback: function($$v) {
                                    _vm.$set(_vm.sendData, "material", $$v)
                                  },
                                  expression: "sendData.material"
                                }
                              })
                            : _vm._e(),
                          _vm.type === "house"
                            ? _c("Multiselect", {
                                staticClass: "mSelect",
                                attrs: {
                                  options: _vm.constants.HOUSE_MATERIALS,
                                  label: "name",
                                  trackBy: "id",
                                  showLabels: false,
                                  searchable: false,
                                  maxHeight: 200,
                                  placeholder: "Вид"
                                },
                                model: {
                                  value: _vm.sendData.material,
                                  callback: function($$v) {
                                    _vm.$set(_vm.sendData, "material", $$v)
                                  },
                                  expression: "sendData.material"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "field__label",
                      class: {
                        "field__label--filled": _vm.sendData.buildingType,
                        "field__label--invalid":
                          _vm.$v.sendData.buildingType.$dirty &&
                          !_vm.$v.sendData.buildingType.required
                      }
                    },
                    [
                      _vm.type === "commerce"
                        ? _c(
                            "div",
                            {
                              staticClass: "field__title field__title--required"
                            },
                            [_vm._v("Призначення")]
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "field__title field__title--required"
                            },
                            [_vm._v("Тип")]
                          ),
                      _vm.type === "apartment"
                        ? _c("Multiselect", {
                            staticClass: "mSelect",
                            attrs: {
                              options: _vm.constants.APARTMENT_TYPES,
                              label: "name",
                              trackBy: "id",
                              showLabels: false,
                              searchable: false,
                              maxHeight: 200,
                              placeholder: "Тип"
                            },
                            model: {
                              value: _vm.sendData.buildingType,
                              callback: function($$v) {
                                _vm.$set(_vm.sendData, "buildingType", $$v)
                              },
                              expression: "sendData.buildingType"
                            }
                          })
                        : _vm._e(),
                      _vm.type === "house"
                        ? _c("Multiselect", {
                            staticClass: "mSelect",
                            attrs: {
                              options: _vm.constants.HOUSE_TYPES,
                              label: "name",
                              trackBy: "id",
                              showLabels: false,
                              searchable: false,
                              maxHeight: 200,
                              placeholder: "Тип"
                            },
                            model: {
                              value: _vm.sendData.buildingType,
                              callback: function($$v) {
                                _vm.$set(_vm.sendData, "buildingType", $$v)
                              },
                              expression: "sendData.buildingType"
                            }
                          })
                        : _vm._e(),
                      _vm.type === "commerce"
                        ? _c("Multiselect", {
                            staticClass: "mSelect",
                            attrs: {
                              options: _vm.constants.COMMERCE_BUILDING_TYPES,
                              label: "name",
                              trackBy: "id",
                              showLabels: false,
                              searchable: false,
                              maxHeight: 200,
                              placeholder: "Призначення"
                            },
                            model: {
                              value: _vm.sendData.buildingType,
                              callback: function($$v) {
                                _vm.$set(_vm.sendData, "buildingType", $$v)
                              },
                              expression: "sendData.buildingType"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.type === "house"
                    ? _c(
                        "label",
                        {
                          staticClass: "field__label",
                          class: {
                            "field__label--filled": _vm.sendData.squareLand,
                            "field__label--invalid":
                              _vm.$v.sendData.squareLand.$dirty &&
                              !_vm.$v.sendData.squareLand.required
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "field__title field__title--required"
                            },
                            [_vm._v(" Площа ділянки ")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sendData.squareLand,
                                expression: "sendData.squareLand"
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "###",
                                expression: "'###'"
                              }
                            ],
                            staticClass: "field__input",
                            attrs: { type: "tel" },
                            domProps: { value: _vm.sendData.squareLand },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.sendData,
                                  "squareLand",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "field__label",
                      class: {
                        "field__label--filled": _vm.sendData.noOfRooms,
                        "field__label--invalid":
                          _vm.$v.sendData.noOfRooms.$dirty &&
                          !_vm.$v.sendData.noOfRooms.required
                      }
                    },
                    [
                      _c("div", { staticClass: "field__title" }, [
                        _vm._v("Кількість кімнат")
                      ]),
                      _c("Multiselect", {
                        staticClass: "mSelect",
                        attrs: {
                          options: _vm.localConstants.PROPERTY_ROOMS,
                          label: "name",
                          trackBy: "id",
                          showLabels: false,
                          searchable: false,
                          maxHeight: 200,
                          placeholder: "к-сть кімнат"
                        },
                        model: {
                          value: _vm.sendData.noOfRooms,
                          callback: function($$v) {
                            _vm.$set(_vm.sendData, "noOfRooms", $$v)
                          },
                          expression: "sendData.noOfRooms"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "label",
                    {
                      staticClass: "field__label",
                      class: {
                        "field__label--filled": _vm.sendData.squareTotal,
                        "field__label--invalid":
                          _vm.$v.sendData.squareTotal.$dirty &&
                          !_vm.$v.sendData.squareTotal.required
                      }
                    },
                    [
                      _c("div", { staticClass: "field__title" }, [
                        _vm._v("Загальна площа")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sendData.squareTotal,
                            expression: "sendData.squareTotal"
                          },
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "###",
                            expression: "'###'"
                          }
                        ],
                        staticClass: "field__input",
                        attrs: { type: "tel" },
                        domProps: { value: _vm.sendData.squareTotal },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.sendData,
                              "squareTotal",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "label",
                    {
                      staticClass: "field__label",
                      class: {
                        "field__label--filled": _vm.sendData.squareLiving,
                        "field__label--invalid":
                          _vm.$v.sendData.squareLiving.$dirty &&
                          !_vm.$v.sendData.squareLiving.required
                      }
                    },
                    [
                      _vm.type === "commerce"
                        ? _c("div", { staticClass: "field__title" }, [
                            _vm._v("Корисна площа")
                          ])
                        : _c("div", { staticClass: "field__title" }, [
                            _vm._v("Житлова площа")
                          ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sendData.squareLiving,
                            expression: "sendData.squareLiving"
                          },
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "###",
                            expression: "'###'"
                          }
                        ],
                        staticClass: "field__input",
                        attrs: { type: "tel" },
                        domProps: { value: _vm.sendData.squareLiving },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.sendData,
                              "squareLiving",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  ),
                  _vm.type !== "commerce"
                    ? _c(
                        "label",
                        {
                          staticClass: "field__label",
                          class: {
                            "field__label--filled": _vm.sendData.squareKitchen,
                            "field__label--invalid":
                              _vm.$v.sendData.squareKitchen.$dirty &&
                              !_vm.$v.sendData.squareKitchen.required
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "field__title field__title--required"
                            },
                            [_vm._v("Площа кухні")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sendData.squareKitchen,
                                expression: "sendData.squareKitchen"
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##",
                                expression: "'##'"
                              }
                            ],
                            staticClass: "field__input",
                            attrs: { type: "tel" },
                            domProps: { value: _vm.sendData.squareKitchen },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.sendData,
                                  "squareKitchen",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm.type === "apartment"
                    ? _c(
                        "div",
                        {
                          staticClass: "field__label",
                          class: {
                            "field__label--filled": _vm.sendData.floor,
                            "field__label--invalid":
                              _vm.$v.sendData.floor.$dirty &&
                              !_vm.$v.sendData.floor.required
                          }
                        },
                        [
                          _c("div", { staticClass: "field__title" }, [
                            _vm._v("Поверх")
                          ]),
                          _c(
                            "Multiselect",
                            {
                              staticClass: "mSelect",
                              attrs: {
                                options:
                                  _vm.localConstants.PROPERTY_APARTMENT_FLOORS,
                                searchable: false,
                                showLabels: false,
                                maxHeight: 200,
                                placeholder: "Поверх"
                              },
                              model: {
                                value: _vm.sendData.floor,
                                callback: function($$v) {
                                  _vm.$set(_vm.sendData, "floor", $$v)
                                },
                                expression: "sendData.floor"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "noResult" },
                                  slot: "noResult"
                                },
                                [_vm._v("Занадто високо 😱")]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.type === "house"
                    ? _c(
                        "div",
                        {
                          staticClass: "field__label",
                          class: {
                            "field__label--filled": _vm.sendData.floors,
                            "field__label--invalid":
                              _vm.$v.sendData.floors.$dirty &&
                              !_vm.$v.sendData.floors.required
                          }
                        },
                        [
                          _c("div", { staticClass: "field__title" }, [
                            _vm._v("Поверхів")
                          ]),
                          _c("Multiselect", {
                            staticClass: "mSelect",
                            attrs: {
                              options: _vm.localConstants.PROPERTY_HOUSE_FLOORS,
                              showLabels: false,
                              searchable: false,
                              maxHeight: 200,
                              placeholder: "Поверхів"
                            },
                            model: {
                              value: _vm.sendData.floors,
                              callback: function($$v) {
                                _vm.$set(_vm.sendData, "floors", $$v)
                              },
                              expression: "sendData.floors"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.type === "apartment"
                    ? _c(
                        "div",
                        {
                          staticClass: "field__label",
                          class: {
                            "field__label--filled": _vm.sendData.floors,
                            "field__label--invalid":
                              _vm.$v.sendData.floors.$dirty &&
                              !_vm.$v.sendData.floors.required
                          }
                        },
                        [
                          _c("div", { staticClass: "field__title" }, [
                            _vm._v("Поверхів")
                          ]),
                          _c("Multiselect", {
                            staticClass: "mSelect",
                            attrs: {
                              options:
                                _vm.localConstants.PROPERTY_APARTMENT_FLOORS,
                              searchable: false,
                              showLabels: false,
                              maxHeight: 200,
                              placeholder: "Поверхів"
                            },
                            model: {
                              value: _vm.sendData.floors,
                              callback: function($$v) {
                                _vm.$set(_vm.sendData, "floors", $$v)
                              },
                              expression: "sendData.floors"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "field__label",
                      class: {
                        "field__label--filled": _vm.sendData.renovated,
                        "field__label--invalid":
                          _vm.$v.sendData.renovated.$dirty &&
                          !_vm.$v.sendData.renovated.required
                      }
                    },
                    [
                      _c("div", { staticClass: "field__title" }, [
                        _vm._v("Ремонт")
                      ]),
                      _c("Multiselect", {
                        staticClass: "mSelect",
                        attrs: {
                          options: _vm.localConstants.PROPERTY_TOGGLE,
                          label: "name",
                          trackBy: "id",
                          showLabels: false,
                          searchable: false,
                          maxHeight: 200,
                          placeholder: "Ремонт"
                        },
                        model: {
                          value: _vm.sendData.renovated,
                          callback: function($$v) {
                            _vm.$set(_vm.sendData, "renovated", $$v)
                          },
                          expression: "sendData.renovated"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.type !== "house"
                    ? _c(
                        "div",
                        {
                          staticClass: "field__label",
                          class: {
                            "field__label--filled":
                              _vm.sendData.autonomousHeatType,
                            "field__label--invalid":
                              _vm.$v.sendData.autonomousHeatType.$dirty &&
                              !_vm.$v.sendData.autonomousHeatType.required
                          }
                        },
                        [
                          _c("div", { staticClass: "field__title" }, [
                            _vm._v("Автономне опалення")
                          ]),
                          _c("Multiselect", {
                            staticClass: "mSelect",
                            attrs: {
                              options: _vm.localConstants.AUTONOMOUS_HEAT_TYPE,
                              showLabels: false,
                              searchable: false,
                              maxHeight: 200,
                              placeholder: "Опалення"
                            },
                            model: {
                              value: _vm.sendData.autonomousHeatType,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.sendData,
                                  "autonomousHeatType",
                                  $$v
                                )
                              },
                              expression: "sendData.autonomousHeatType"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.type === "house"
                    ? _c(
                        "div",
                        {
                          staticClass: "field__label",
                          class: {
                            "field__label--filled": _vm.sendData.garage,
                            "field__label--invalid":
                              _vm.$v.sendData.garage.$dirty &&
                              !_vm.$v.sendData.garage.required
                          }
                        },
                        [
                          _c("div", { staticClass: "field__title" }, [
                            _vm._v("Гараж")
                          ]),
                          _c("Multiselect", {
                            staticClass: "mSelect",
                            attrs: {
                              options: _vm.localConstants.PROPERTY_TOGGLE,
                              label: "name",
                              trackBy: "id",
                              showLabels: false,
                              searchable: false,
                              maxHeight: 200,
                              placeholder: "Гараж"
                            },
                            model: {
                              value: _vm.sendData.garage,
                              callback: function($$v) {
                                _vm.$set(_vm.sendData, "garage", $$v)
                              },
                              expression: "sendData.garage"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.type === "house"
                    ? _c(
                        "label",
                        {
                          staticClass: "field__label",
                          class: {
                            "field__label--filled":
                              _vm.sendData.builtAt &&
                              _vm.$v.sendData.builtAt.minLength,
                            "field__label--invalid":
                              _vm.$v.sendData.builtAt.$dirty &&
                              !_vm.$v.sendData.builtAt.minLength
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "field__title field__title--required"
                            },
                            [_vm._v(" Рік побудови ")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sendData.builtAt,
                                expression: "sendData.builtAt"
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "#### Q#",
                                expression: "'#### Q#'"
                              }
                            ],
                            staticClass: "field__input",
                            domProps: { value: _vm.sendData.builtAt },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.sendData,
                                  "builtAt",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    : _vm._e()
                ]),
                _c("div", { staticClass: "data__bottom" }, [
                  _vm.type === "house"
                    ? _c(
                        "label",
                        {
                          staticClass: "field__label field__label--text",
                          class: {
                            "field__label--filled":
                              _vm.sendData.registrationNo &&
                              _vm.$v.sendData.registrationNo.minLength,
                            "field__label--invalid":
                              _vm.$v.sendData.registrationNo.$dirty &&
                              !_vm.$v.sendData.registrationNo.minLength
                          }
                        },
                        [
                          _c("div", { staticClass: "field__title" }, [
                            _vm._v("Кадастровий номер")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sendData.registrationNo,
                                expression: "sendData.registrationNo"
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##########:##:###:####",
                                expression: "'##########:##:###:####'"
                              }
                            ],
                            staticClass: "field__input",
                            domProps: { value: _vm.sendData.registrationNo },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.sendData,
                                  "registrationNo",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "label",
                    {
                      staticClass: "field__label",
                      class: {
                        "field__label--filled": _vm.sendData.description,
                        "field__label--invalid":
                          _vm.$v.sendData.description.$dirty &&
                          !_vm.$v.sendData.description.required
                      }
                    },
                    [
                      _c("div", { staticClass: "field__title" }, [
                        _vm._v("Рекламний текст")
                      ]),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sendData.description,
                            expression: "sendData.description"
                          }
                        ],
                        staticClass: "field__text field__text--ads",
                        domProps: { value: _vm.sendData.description },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.sendData,
                              "description",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "label",
                    {
                      staticClass: "field__label",
                      class: {
                        "field__label--filled": _vm.sendData.comments,
                        "field__label--invalid":
                          _vm.$v.sendData.comments.$dirty &&
                          !_vm.$v.sendData.comments.required
                      },
                      staticStyle: { "margin-top": "20px" }
                    },
                    [
                      _c("div", { staticClass: "field__title" }, [
                        _vm._v("Коментарі")
                      ]),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sendData.comments,
                            expression: "sendData.comments"
                          }
                        ],
                        staticClass: "field__text",
                        domProps: { value: _vm.sendData.comments },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.sendData,
                              "comments",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ])
              ])
            : _vm._e(),
          _vm.otherDataBox
            ? _c("DataOther", { attrs: { data: _vm.sendDataOther, v: _vm.$v } })
            : _vm._e(),
          _vm.contactsBox
            ? _c("UploadContacts", {
                attrs: {
                  newContacts: _vm.newContacts,
                  selectedContacts: _vm.selectedContacts,
                  visibleBox: _vm.visibleBox,
                  v: _vm.$v
                },
                on: {
                  selectContacts: function(contacts) {
                    return (_vm.selectedContacts = contacts)
                  }
                }
              })
            : _vm._e(),
          _vm.photosBox
            ? _c("UploadPhotos", {
                attrs: {
                  files: _vm.files,
                  photos: _vm.info ? _vm.info.photos : null,
                  delPhotos: _vm.delPhotos
                }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }