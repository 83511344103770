export default function propertyStatus(value) {
  let type;

  switch (value) {
    case 1:
      type = "Заявка";
      break;
    case 2:
      type = "Оброблений";
      break;
    case 3:
      type = "В Рекламі";
      break;
  }
  return type
}