var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "editContact__wrapper" }, [
    _c("div", { staticClass: "editContact__header" }, [
      _c("div", { staticClass: "header__title" }, [
        _vm.contact
          ? _c("span", [
              _vm._v("Редагування "),
              _vm.editRolesOnly ? _c("span", [_vm._v("Ролей")]) : _vm._e(),
              _vm._v(" Контакту")
            ])
          : _c("span", [_vm._v("Новий Контакт")])
      ]),
      _c("div", { staticClass: "header__buttons" }, [
        _c(
          "div",
          {
            staticClass: "header__button button button--fill button--grey",
            on: { click: _vm.hideModal }
          },
          [_vm._v("Скасувати")]
        ),
        _c(
          "div",
          {
            staticClass: "header__button button button--fill",
            on: { click: _vm.send }
          },
          [_vm._v("Зберегти")]
        ),
        _c(
          "div",
          { staticClass: "button__icon", on: { click: _vm.hideModal } },
          [_c("i", { staticClass: "ri-close-circle-line" })]
        ),
        _c(
          "div",
          {
            staticClass: "button__icon button__icon--blue",
            on: { click: _vm.send }
          },
          [_c("i", { staticClass: "ri-save-3-line" })]
        )
      ])
    ]),
    _c("div", { staticClass: "editContact__main" }, [
      _c("div", { staticClass: "main__part" }, [
        _c(
          "label",
          {
            staticClass: "field__label",
            class: {
              "field__label--filled": _vm.newContact.name,
              "field__label--invalid":
                _vm.$v.newContact.name.$dirty &&
                !_vm.$v.newContact.name.required
            }
          },
          [
            _c("div", { staticClass: "field__title field__title--required" }, [
              _vm._v("ПІБ")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newContact.name,
                  expression: "newContact.name"
                }
              ],
              staticClass: "field__input",
              attrs: {
                disabled: _vm.editRolesOnly,
                placeholder: "Введіть ім'я"
              },
              domProps: { value: _vm.newContact.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.newContact, "name", $event.target.value)
                }
              }
            })
          ]
        ),
        _c(
          "label",
          {
            staticClass: "field__label",
            class: {
              "field__label--filled": _vm.newContact.roles,
              "field__label--invalid":
                _vm.$v.newContact.roles.$dirty &&
                !_vm.$v.newContact.roles.required
            }
          },
          [
            _c("div", { staticClass: "field__title field__title--required" }, [
              _vm._v("Роль")
            ]),
            _c("Multiselect", {
              staticClass: "mSelect",
              attrs: {
                options: _vm.constants.CONTACT_ROLES,
                multiple: true,
                closeOnSelect: false,
                label: "name",
                trackBy: "id",
                showLabels: false,
                searchable: false,
                placeholder: "Роль"
              },
              model: {
                value: _vm.newContact.roles,
                callback: function($$v) {
                  _vm.$set(_vm.newContact, "roles", $$v)
                },
                expression: "newContact.roles"
              }
            })
          ],
          1
        ),
        _vm.isCompany
          ? _c(
              "div",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled": _vm.newContact.company,
                  "field__label--invalid":
                    _vm.$v.newContact.company.$dirty &&
                    !_vm.$v.newContact.company.required
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "field__title field__title--required" },
                  [_vm._v("Компанія")]
                ),
                _c("CompanySearch", {
                  attrs: {
                    selected: _vm.newContact.company,
                    isDisabled: _vm.isCompanyFieldDisabled,
                    isCreateButtonVisible: !_vm.isCompanyFieldDisabled,
                    type: _vm.companyType
                  },
                  on: {
                    select: function(company) {
                      return (_vm.newContact.company = company)
                    }
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.isCompany
          ? _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled": _vm.newContact.jobPosition,
                  "field__label--invalid":
                    _vm.$v.newContact.jobPosition.$dirty &&
                    !_vm.$v.newContact.jobPosition.required
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "field__title field__title--required" },
                  [_vm._v("Посада")]
                ),
                _c("Multiselect", {
                  attrs: {
                    options: _vm.constants.CONTACT_JOB_POSITIONS,
                    disabled: _vm.isJobPositionFieldDisabled,
                    label: "name",
                    trackBy: "id",
                    showLabels: false,
                    searchable: false,
                    placeholder: "Посада"
                  },
                  model: {
                    value: _vm.newContact.jobPosition,
                    callback: function($$v) {
                      _vm.$set(_vm.newContact, "jobPosition", $$v)
                    },
                    expression: "newContact.jobPosition"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "field__label",
            class: {
              "field__label--filled": _vm.newContact.birthday,
              "field__label--invalid":
                _vm.$v.newContact.birthday.$dirty &&
                !_vm.$v.newContact.birthday.required
            }
          },
          [
            _c("div", { staticClass: "field__title field__title--required" }, [
              _vm._v("День Народження")
            ]),
            _c(
              "div",
              { staticClass: "field__date" },
              [
                !_vm.editRolesOnly
                  ? _c("date-picker", {
                      attrs: {
                        isDark: "",
                        color: "blue",
                        popover: { visibility: "focus" }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var inputValue = ref.inputValue
                              var inputEvents = ref.inputEvents
                              return [
                                _c(
                                  "input",
                                  _vm._g(
                                    {
                                      staticClass: "field__input",
                                      attrs: { readonly: "" },
                                      domProps: { value: inputValue }
                                    },
                                    inputEvents
                                  )
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2773341649
                      ),
                      model: {
                        value: _vm.newContact.birthday,
                        callback: function($$v) {
                          _vm.$set(_vm.newContact, "birthday", $$v)
                        },
                        expression: "newContact.birthday"
                      }
                    })
                  : _c("input", {
                      staticClass: "field__input",
                      attrs: { disabled: "" },
                      domProps: {
                        value: _vm._f("date")(_vm.newContact.birthday)
                      }
                    })
              ],
              1
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newContact.birthday,
                  expression: "newContact.birthday"
                }
              ],
              staticClass: "field__date--mobile",
              attrs: { disabled: _vm.editRolesOnly, type: "date" },
              domProps: { value: _vm.newContact.birthday },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.newContact, "birthday", $event.target.value)
                }
              }
            })
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "main__part" },
        _vm._l(_vm.newContact.phones, function(phone, i) {
          return _c("div", { key: i, staticClass: "main__phone" }, [
            _c("div", { staticClass: "main__phone-title" }, [
              _vm._v("Телефон")
            ]),
            _c("div", { staticClass: "main__phone-inner" }, [
              _c("input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: "##########",
                    expression: "'##########'"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: phone.phone,
                    expression: "phone.phone"
                  }
                ],
                staticClass: "field__input main__phone-input",
                class: {
                  "field__input--invalid":
                    !_vm.$v.newContact.phones.$each[i].phone.required &&
                    _vm.$v.newContact.phones.$each[i].phone.$dirty
                },
                attrs: {
                  disabled: _vm.editRolesOnly,
                  placeholder: "Введіть телефон"
                },
                domProps: { value: phone.phone },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(phone, "phone", $event.target.value)
                  }
                }
              }),
              _c("div", { staticClass: "main__phone-box" }, [
                _c(
                  "button",
                  {
                    staticClass: "button__circle",
                    class: { "button__circle--blue": phone.isOnViber },
                    attrs: { disabled: _vm.editRolesOnly },
                    on: {
                      click: function($event) {
                        phone.isOnViber = !phone.isOnViber
                      }
                    }
                  },
                  [_c("i", { staticClass: "i-viber" })]
                ),
                _c(
                  "button",
                  {
                    staticClass: "button__circle",
                    class: { "button__circle--blue": phone.isOnTelegram },
                    attrs: { disabled: _vm.editRolesOnly },
                    on: {
                      click: function($event) {
                        phone.isOnTelegram = !phone.isOnTelegram
                      }
                    }
                  },
                  [_c("i", { staticClass: "i-telegram" })]
                ),
                _c(
                  "button",
                  {
                    staticClass: "button__circle",
                    class: { "button__circle--blue": phone.isOnWhatsapp },
                    attrs: { disabled: _vm.editRolesOnly },
                    on: {
                      click: function($event) {
                        phone.isOnWhatsapp = !phone.isOnWhatsapp
                      }
                    }
                  },
                  [_c("i", { staticClass: "i-whatsapp" })]
                ),
                _c(
                  "button",
                  {
                    staticClass: "button__circle",
                    class: { "button__circle--blue": phone.isOnFacebook },
                    attrs: { disabled: _vm.editRolesOnly },
                    on: {
                      click: function($event) {
                        phone.isOnFacebook = !phone.isOnFacebook
                      }
                    }
                  },
                  [_c("i", { staticClass: "i-messanger" })]
                )
              ])
            ])
          ])
        }),
        0
      ),
      !_vm.editRolesOnly
        ? _c(
            "div",
            {
              staticClass: "main__footer",
              on: {
                click: function($event) {
                  return _vm.addPhone()
                }
              }
            },
            [
              _c("i", { staticClass: "i-plus" }),
              _c("span", { staticClass: "main__footer-title" }, [
                _vm._v("Додати ще один номер")
              ])
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }