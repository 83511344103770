import rent from './rent'

export default {
  namespaced: true,

  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    rent,
  }
}
