var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "data__inner data_contacts" }, [
    _c(
      "article",
      { staticClass: "data__top" },
      [
        _c("ContactSearch", {
          attrs: {
            selectedContacts: _vm.selectedContacts,
            title: "Виберіть контакт",
            placeholder: "Введіть телефон або імʼя",
            permittedRoles: _vm.permittedRoles
          },
          on: {
            sendContacts: function(contacts) {
              return _vm.$emit("selectContacts", contacts)
            }
          }
        })
      ],
      1
    ),
    _c(
      "article",
      { staticClass: "data__bottom" },
      [
        _c("Contact", {
          attrs: {
            newContacts: _vm.newContacts,
            visibleBox: _vm.visibleBox,
            permittedRoles: _vm.permittedRoles
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }