<template>
  <section class="data__inner data_uphoto">
    <div class="data__uphoto-input">
      <label class="field__label label_uphoto" id="dropbox">
        <input
          class="field__files input__uphoto"
          type="file"
          multiple
          accept="image/*"
          @change="handleFileUpload($event)"
        />
        <i class="i-upload"><span>Перетягніть зображення або натисніть, щоб вибрати</span></i>
        <span class="data__uphoto-text"></span>
      </label>
    </div>
    <div class="data__uphoto-wrapper">
      <div class="data__uphoto" v-for="(image, i) in imagePreview" :key="i" @click="del(i)">
        <img class="data__uphoto-img" :src="image" />
        <i class="i-close data__uphoto-remove"></i>
      </div>
    </div>
    <div class="data__uphoto-wrapper">
      <div class="data__uphoto" v-for="(image, i) in photos" :key="i" @click="delPhoto(image)">
        <img class="data__uphoto-img" :src="image.fileURL" />
        <i class="i-close data__uphoto-remove"></i>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["files", 'photos', 'delPhotos'],
  data:() => ({}),
  methods: {
    handleFileUpload($event) {
      let list = this.files;
      const files = $event.target.files;
      files.forEach(f => list.push(f));
    },
    drop() {
      const dropbox = document.getElementById("dropbox");
      dropbox.addEventListener("dragenter", dragenter, false);
      dropbox.addEventListener("dragover", dragover, false);
      dropbox.addEventListener("drop", drop, false);
      function dragenter(e) {
        e.stopPropagation();
        e.preventDefault();
      }
      function dragover(e) {
        e.stopPropagation();
        e.preventDefault();
      }
      let list = this.files;
      function drop(e) {
        e.stopPropagation();
        e.preventDefault();
        var files = e.dataTransfer.files;
        files.forEach(f => list.push(f));
      }
    },
    del(i) {
      this.files.splice(i, 1);
    },
    delPhoto(i) {
      this.delPhotos.push(i)
      this.photos.splice(this.photos.indexOf(i), 1)
    }
  },
  computed: {
    imagePreview() {
      let imgList = [];
      const list = this.files;
      list.forEach(f => imgList.push(window.URL.createObjectURL(f)));
      return imgList;
    }
  },
  mounted() {
    this.drop();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "./style.scss";
</style>