<template>
  <Multiselect
    :options="foundSeekers"
    v-model="selectedSeeker"
    label="name"
    trackBy="id"
    :showLabels="false"
    :searchable="true"
    placeholder="Введіть ID або імʼя шукача"
    class="mSelect"
    :internal-search="false"
    :closeOnSelect="true"
    :multiple="false"
    @select="select"
    @search-change="search"
  >
    <span slot="noOptions">Введіть ID або імʼя шукача</span>
    <span slot="noResult">Шукача не знайдено</span>
    <span slot="singleLabel" slot-scope="props">ID {{ props.option.id }} | {{ props.option.name }}</span>
    <template slot="option" slot-scope="props">
      ID {{ props.option.id }} | {{ props.option.name }}
    </template>
  </Multiselect>
</template>

<script>
import _ from 'lodash'
import Multiselect from 'vue-multiselect'
export default {
  name: 'SeekerSearch',
  props: ['seeker'],
  components: {
    Multiselect,
  },
  data: () => ({
    selectedSeeker: undefined,
    foundSeekers: [],
  }),
  methods: {
    search: _.debounce(
      async function(query) {
        if (query.length < 1) {
          this.foundSeekers = []
          return
        }

        const result = await this.$store.dispatch('seekers/search', { query })
        this.foundSeekers = result
      },
      500
    ),
     select(seeker) {
      this.selectedSeeker = seeker
      this.$emit('select', this.selectedSeeker)
     }
  },
  async created() {
    if (this.seeker) {
      this.selectedSeeker = this.seeker
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@import './style.scss';
</style>