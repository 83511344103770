<template>
  <div class="info__inner">
    <div class="info__header">
      <div class="info__header-right">
        <div class="button button--fill button--small button--disabled">
          <i v-if="action.actionType === 1" class="ri-home-6-fill"></i>
          <i v-if="action.actionType === 2" class="ri-eye-fill"></i>
          <i v-if="action.actionType === 3" class="ri-organization-chart"></i>
          <i v-if="action.actionType === 4" class="ri-phone-fill"></i>
          <i v-if="action.actionType === 5" class="ri-money-dollar-box-fill"></i>
          <i v-if="action.actionType === 6" class="ri-government-fill"></i>
          <i v-if="action.actionType === 8" class="ri-article-fill"></i>
          <span v-if="action.actionType === 4">Дзвінок по рекламі</span>
          <span v-else>{{ action.actionName }}</span>
        </div>
        <div v-if="action.actionType === 8 || action.actionType === 9" class="cell cell__result">
          <div class="indicator indicator--dot indicator--green">{{ action.actionNumber }}</div>
        </div>
        <div v-else>
          <div v-if="action.result === 1" class="indicator indicator--dot indicator--green">Згода</div>
          <div v-else-if="action.result === 2" class="indicator indicator--dot indicator--red">Відмова</div>
          <div v-else-if="action.result === 3" class="indicator indicator--dot indicator--blue">План</div>
          <div v-else-if="action.result === 4" class="indicator indicator--dot indicator--orange">Думають</div>
        </div>
      </div>
      <div class="info__header-left">
        <div v-if="!action.isAgentBlocked" class="button__icon button__icon--small" @click.stop.prevent>
          <Multiselect
            class="mSelect-dropdown"
            :options="list"
            :searchable="false"
            :show-labels="false"
            :reset-after="true"
            :value="item"
            placeholder
            open-direction="bottom"
            @select="actions"
          />
        </div>
        <div class="button__icon button__icon--small button--onmobile" @click="$modal.hide('modalInfoAction')">
          <i class="ri-close-line"></i>
        </div>
      </div>
    </div>
    <div class="info__main">
      <div class="main__content">
        <div class="main__row main__row--dark">
          <div class="main__row-left">
            <div class="main__text">Агент</div>
          </div>
          <div class="main__row-right">
            <div class="main__row-letter">
              <div class>{{ action.agentName[0] }}</div>
            </div>
            <div class="main__text">{{ action.agentName }}</div>
          </div>
        </div>
        <div class="main__row main__row--dark is-mobile">
          <div class="main__row-left">
            <div class="main__text">Тип</div>
          </div>
          <div class="main__row-right">
            <i v-if="action.actionType === 1" class="ri-home-6-fill"></i>
            <i v-if="action.actionType === 2" class="ri-eye-fill"></i>
            <i v-if="action.actionType === 3" class="ri-organization-chart"></i>
            <i v-if="action.actionType === 4" class="ri-phone-fill"></i>
            <i v-if="action.actionType === 5" class="ri-money-dollar-box-fill"></i>
            <i v-if="action.actionType === 6" class="ri-government-fill"></i>
            <span v-if="action.actionType === 4" class="main__text">Дзвінок по рекламі</span>
            <span v-else class="main__text">{{ action.actionName }}</span>
          </div>
        </div>
        <div v-if="action.actionType === 8" class="main__row main__row--dark">
          <div class="main__text">Тип продукції</div>
          <div class="main__text">{{ action.actionSubtypeName }}</div>
        </div>
        <div v-if="action.actionType === 9" class="main__row main__row--dark">
          <div class="main__text">Тип реклами</div>
          <div class="main__text">{{ action.actionSubtypeName }}</div>
        </div>
        <div class="main__row main__row--dark">
          <div class="main__text">Дата і час</div>
          <div class="main__text">
            <i class="ri-calendar-fill"></i>
            {{ action.actionAt | date }}
            <i class="ri-time-fill"></i>
            {{ action.time }}
          </div>
        </div>
        <div class="main__row main__row--dark">
          <div class="main__text">Дата створення</div>
          <div class="main__text">
            <i class="ri-calendar-fill"></i>
            {{ action.createdAt | date }}
          </div>
        </div>
        <div class="main__row main__row--dark">
          <div class="main__text">Об'єкт</div>
          <div class="main__row-right">
            <div v-if="action.propertyType === 3 && !action.propertyRelated" class="main__text">інше АН</div>
            <div
              v-else-if="(action.propertyType === 2 || action.propertyType === 3 || action.actionType === 9) && action.propertyRelated"
              class="main__text main__text-modal"
            >
              <Relation type="notification-icon" :relation="action.propertyRelated" />
              <span v-if="action.propertyType === 3"> інше АН</span>
            </div>
            <div v-else-if="action.propertyType === 4" class="main__text">
              <i class="ri-user-fill"></i>
              {{ action.propertyRelated }}
            </div>
            <div v-else class="main__text">—</div>
          </div>
        </div>
        <div class="main__row main__row--dark">
          <div class="main__text">Покупець/орендар</div>
          <div class="main__row-right">
            <div v-if="action.buyerType === 3 && !action.buyerRelated" class="main__text">інше АН</div>
            <div
              v-else-if="(action.buyerType === 2 || action.buyerType === 3) && action.buyerRelated"
              class="main__text main__text-modal"
            >
              <Relation type="notification-icon" :relation="action.buyerRelated" />
              <span v-if="action.buyerType === 3"> інше АН</span>
            </div>
            <div v-else-if="action.buyerType === 4" class="main__text">
              <i class="ri-user-fill"></i>
              {{ action.buyerRelated }}
            </div>
            <div v-else class="main__text">—</div>
          </div>
        </div>
        <div v-if="(action.actionType === 1 || action.actionType === 2 || action.actionType === 4) && action.result === 2" class="main__row main__row--dark">
          <div class="main__text">Причина відмови</div>
          <div class="main__text">{{ this.rejectionReasonName }}</div>
        </div>
        <div v-if="(action.actionType === 1 || action.actionType === 4) && action.result === 2 && action.rejectionReason === 1" class="main__row main__row--dark">
          <div class="main__text">Ціна покупця</div>
          <div class="main__text">
            {{ this.action.buyerPrice }}
          </div>
        </div>
        <div v-if="action.actionType === 6" class="main__row main__row--dark">
          <div class="main__text">Сума завдатку</div>
          <div class="main__text">{{ this.action.depositAmount }}$</div>
        </div>
        <div v-if="action.actionType === 6" class="main__row main__row--dark">
          <div class="main__text">Закінчення завдатку</div>
          <div class="main__text">
            <i class="ri-calendar-fill"></i>{{ action.depositEndDate | date }}
          </div>
        </div>
        <div v-if="action.actionType === 6" class="main__row main__row--dark">
          <div class="main__text">ПІБ нотаріуса</div>
          <div class="main__text">{{ action.notaryFullName }}</div>
        </div>
        <div v-if="action.actionType === 6" class="main__row main__row--dark">
          <div class="main__text">Вид правочину</div>
          <div class="main__text">{{ deedType }}</div>
        </div>
        <div v-if="action.actionType === 6" class="main__row main__row--dark">
          <div class="main__text">Витрати покупця</div>
          <div class="main__text">
            Послуги нотаріуса: {{ action.buyerNotaryFee }}%<br/>
            Розмір податку: {{ action.buyerTax }}%<br/>
            Комісія АН: {{ action.buyerAgencyFee }}$
          </div>
        </div>
        <div v-if="action.actionType === 6" class="main__row main__row--dark">
          <div class="main__text">Витрати продавця</div>
          <div class="main__text">
            Послуги нотаріуса: {{ action.sellerNotaryFee }}%<br/>
            Розмір податку: {{ action.sellerTax }}%<br/>
            Комісія АН: {{ action.sellerAgencyFee }}$
          </div>
        </div>
        <div class="main__row main__row--area">
          <div class="main__text">Опис</div>
          <div class="main__text">
            <textarea id="autoresizing" disabled>{{ action.details }}</textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import ModalUploadAction from '@/views/Actions/UploadAction'
import Relation from '@/components/Relation'

export default {
  name: 'InfoAction',
  props: ['action'],
  components: {
    Multiselect,
    Relation,
  },
  data: () => ({
    list: ['Редагувати', 'Видалити'],
    item: ''
  }),
  created() {
    const constants = this.$store.getters.constants
    this.deedType = constants.ACTION_DEED_TYPES.find(c => c.id === this.action.deedType)?.name
    if (this.action.actionType === 1 || this.action.actionType === 4) {
      this.rejectionReasonName = constants.ACTION_SHOWING_REJECTION_REASONS.find(c => c.id === this.action.rejectionReason)?.name
    } else if (this.action.actionType === 2) {
      this.rejectionReasonName = constants.ACTION_VIEWING_REJECTION_REASONS.find(c => c.id === this.action.rejectionReason)?.name
    }
  },
  mounted() {
    let textarea = document.querySelector('#autoresizing')
    function autoResize() {
      textarea.style.height = 'auto'
      textarea.style.height = textarea.scrollHeight + 'px'
      textarea.disable = 'true'
    }
    autoResize()
  },
  methods: {
    actions(option) {
      if (option === 'Редагувати') {
        const style = this.$modal.styles
        this.$modal.show(
          ModalUploadAction,
          {action: this.action},
          {...style, name: 'modalUploadAction'},
          {'before-open': this.$modal.open, 'before-close': this.$modal.close}
        )
      } else if (option === 'Видалити') {
        this.$store.dispatch('delActions', this.action.id)
        this.$modal.hide('modalInfoAction')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
.main__text {
  width: 100%;
  & > textarea {
    resize: none;
    width: 100%;
    &:disabled {
      background-color: transparent;
      color: #fff;
      border: none;
    }
  }
}
</style>
