<template>
  <div>
    <div>
      <div class="period main__row main__row--dark color--grey">
        <div
          v-for="month of monthsInYear"
          :key="month.id"
          class="period__item"
          :class="{'period__item--active': month.active}"
          @click="selectMonth(month)"
        >
          {{ month.name }}
        </div>
        <span>
          <date-picker
            v-model="date"
            is-range
            isDark
            color="blue"
            :popover="{visibility: 'focus'}"
            @input="setPeriod"
          >
            <template v-slot="{inputValue, inputEvents}">
              <div class="dates-container">
                <input :value="inputValue.start" v-on="inputEvents.start" class="field__input" />
                <input :value="inputValue.end" v-on="inputEvents.end" class="field__input" />
              </div>
            </template>
          </date-picker>
        </span>
      </div>
    </div>
    <div>
      <div class="main__row main__row--grid grid-3">
        <span>
          <span class="color--red">Відмови</span><br />
          <span>{{ report.calls.rejectedNumber }}</span>
        </span>
        <span>
          <span class="color--green">ДЗВІНКИ</span><br />
          <span>{{ report.calls.totalNumber }}</span>
        </span>
        <span>
          <span class="color--yellow">Думають</span><br />
          <span>{{ report.calls.unconfirmedNumber }}</span>
        </span>
      </div>
      <div class="main__row main__row--dark main__row--grid grid-7">
        <span v-for="reason in constants.ACTION_SHOWING_REJECTION_REASONS">
          <span class="color--red">{{ reason.name }}</span
          ><br />
          <span>{{ getRejectedCallNumber(reason.id) }}</span>
        </span>
      </div>
    </div>

    <div>
      <div class="main__row main__row--grid grid-3">
        <span>
          <span class="color--red">Відмови</span><br />
          <span>{{ report.showings.rejectedNumber }}</span>
        </span>
        <span>
          <span class="color--green">ПОКАЗИ</span><br />
          <span>{{ report.showings.totalNumber }}</span>
        </span>
        <span>
          <span class="color--yellow">Думають</span><br />
          <span>{{ report.showings.unconfirmedNumber }}</span>
        </span>
      </div>
      <div class="main__row main__row--dark main__row--grid grid-7">
        <span v-for="reason in constants.ACTION_SHOWING_REJECTION_REASONS">
          <span class="color--red">{{ reason.name }}</span
          ><br />
          <span>{{ getRejectedShowingNumber(reason.id) }}</span>
        </span>
      </div>
    </div>

    <div>
      <div class="main__row" style="justify-content: center;">РЕКЛАМА</div>
      <div class="main__row main__row--dark main__row--grid grid-4">
        <span v-for="type in constants.ACTION_ADVERT_TYPES.filter(x => ![40, 50].includes(x.id))">
          <span>{{ type.label }}</span
          ><br />
          <span>{{ getAdvertNumber(type.id) }}</span>
        </span>
      </div>
      <div class="main__row main__row--dark main__row--grid grid-2">
        <span v-for="type in constants.ACTION_ADVERT_TYPES.filter(x => [40, 50].includes(x.id))">
          <span>{{ type.label }}</span
          ><br />
          <span>{{ getAdvertNumber(type.id) }}</span>
        </span>
      </div>
      <div class="main__row main__row--dark main__row--grid grid-2">
        <span>
          <span v-for="child in localConstants.ACTION_ADVERT_TYPES.Socials.children">
            <span>{{ child.label }} - {{ getAdvertNumber(child.id) }}</span
            ><br />
          </span>
        </span>
        <span>
          <span v-for="child in localConstants.ACTION_ADVERT_TYPES.Websites.children">
            <span>{{ child.label }} - {{ getAdvertNumber(child.id) }}</span
            ><br />
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import localConstants from '@/utils/localConstants'
import {mapGetters} from 'vuex'
import moment from 'moment'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: 'ObjectReport',
  props: ['report', 'id'],
  components: {
    DatePicker,
  },
  computed: {
    ...mapGetters(['constants']),
  },
  data: () => ({
    localConstants,
    monthsInYear: [
      {name: 'Весь період', id: -1, active: true},
      {name: 'Січень', id: 0, active: false},
      {name: 'Лютий', id: 1, active: false},
      {name: 'Березень', id: 2, active: false},
      {name: 'Квітень', id: 3, active: false},
      {name: 'Травень', id: 4, active: false},
      {name: 'Червень', id: 5, active: false},
      {name: 'Липень', id: 6, active: false},
      {name: 'Серпень', id: 7, active: false},
      {name: 'Вересень', id: 8, active: false},
      {name: 'Жовтень', id: 9, active: false},
      {name: 'Листопад', id: 10, active: false},
      {name: 'Грудень', id: 11, active: false},
    ],
    date: {}
  }),
  methods: {
    getRejectedCallNumber(reasonId) {
      return this.report.calls.rejected.find(x => x.reasonId === reasonId)?.number || 0
    },
    getRejectedShowingNumber(reasonId) {
      return this.report.showings.rejected.find(x => x.reasonId === reasonId)?.number || 0
    },
    getAdvertNumber(typeId) {
      const type = this.constants.ACTION_ADVERT_TYPES.find(x => x.id === typeId)
      if (type?.children != null) {
        const ids = type.children.map(x => x.id)
        return this.report.advert.filter(x => ids.includes(x.typeId)).reduce((prev, curr) => prev + curr.number, 0)
      }
      return this.report.advert.find(x => x.typeId === typeId)?.number || 0
    },
    async selectMonth(month) {
      if (month.id === -1) {
        await this.$store.dispatch('objects/fetchReport', { id: this.id })
        this.activateSelectedMonth(month.id)
        return
      }

      const range = this.getRange(month.id)
      await this.$store.dispatch('objects/fetchReport', { id: this.id, range })
      this.activateSelectedMonth(month.id)
    },
    async setPeriod(dates) {
      this.deactivateAllMonths()
      await this.$store.dispatch('objects/fetchReport', {
        id: this.id,
        range: {
           'period[from]': moment(dates.start).format('DD-MM-YYYY'),
           'period[till]': moment(dates.end).format('DD-MM-YYYY'),
        }
      })
    },
    activateSelectedMonth(monthId) {
      this.deactivateAllMonths()
      this.monthsInYear.find(x => x.id === monthId).active = true
    },
    deactivateAllMonths() {
      this.monthsInYear.forEach(month => { month.active = false })
    },
    getRange(monthId) {
      const year = moment().get('year')
      const monthStart = moment()
        .year(year)
        .month(monthId)
        .startOf('month')
        .format('DD-MM-YYYY')

      const monthEnd = moment()
        .year(year)
        .month(monthId)
        .endOf('month')
        .format('DD-MM-YYYY')

      return {
        'period[from]': monthStart,
        'period[till]': monthEnd
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/info.scss';

.dates-container {
  display: flex;

  .field__input {
    padding: 0;
    text-align: center;
    width: 100px;

    &~ .field__input {
      margin-left: 10px;
    }
  }
}
.main__row--grid {
  text-align: center;
  display: grid !important;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;

  &.grid-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  &.grid-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &.grid-4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &.grid-7 {
    grid-template-columns: repeat(7, 1fr);
  }
}

@include fablet {
  .main__row--grid {
    &.grid-2 {
      grid-template-columns: repeat(2, 1fr);
    }

    &.grid-3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &.grid-4 {
      grid-template-columns: repeat(2, 1fr);
    }

    &.grid-7 {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}


.color {
  &--red {
    color: $red;
  }
  &--green {
    color: $green;
  }
  &--yellow {
    color: $yellow;
  }
  &--grey {
    color: $grey;
  }
}
</style>
