export default function houseTypes(value) {
  let type;

  switch (value) {
    case 1:
      type = "Окремий";
      break;
    case 2:
      type = "Частина";
      break;
    case 3:
      type = "Котедж";
      break;
    case 4:
      type = "Дача";
      break;
  }
  return type
}
